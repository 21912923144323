// @Vendors 
import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

// @ActionsTypes
import { 
	SET_FORM_STEP,
	SEARCH_SAIME,
	REGISTER_NEW_USER,
	SET_LABORALES,
	SET_ESTUDIOS,
	SET_DOMICILIO,
	SET_PROFILES
} from 'state/users/users.actionsTypes'

// @Components > Formularios
import SearchSaime from '../forms/SearchSaime'
import CrearCuenta from '../forms/CrearCuenta'
import DatosDomicilio from '../forms/DatosDomicilio'

// @Components > View
import DatosLaboralesView from '../Components/DatosLaboralesView'
import DatosPersonalesView from '../Components/DatosPersonalesView'
import DatosEstudiosView from '../Components/DatosEstudiosView'

export default function useSettingsForms() {
  	const dispatch = useDispatch()

	// Funcion para cambiar la posicion de los formularios a mostrar
	const nextPrev = useCallback( (actual) => {  	
		dispatch({ type: SET_FORM_STEP, payload: actual })
	}, [dispatch])

	  // Obetener la positicion actual de cual formulario se está mostrando; por defecto 0
  	const { actualVisible } = useSelector(state => state.usersReducer.get("registerFormStep"))
  	const loading = useSelector( state => state.appReducer.get("loading"))

  	
  	/* ** @Reducers ** */
  	const profiles = useSelector( state => state.usersReducer.get("profiles"))
  	const educativo = useSelector( state => state.usersReducer.get("educativo"))
  	const domicilio = useSelector( state => state.usersReducer.get("domicilio"))
  	const laborales = useSelector( state => state.usersReducer.get("laborales"))
  	
	// Lista de formularios a renderizar junto a sus correspondientes opciones
	const VIEWS = useMemo(() => [
		{
			// nextPrev -> 0
			title: "Ingresar cédula",
			initialValues: { 
				nacionalidad: profiles.nacionalidad,
				cedula: profiles.cedula
			},
			component: SearchSaime,
			onSubmit: function({ nacionalidad, cedula }) {
				dispatch({ type: SEARCH_SAIME, payload: { nacionalidad, cedula }})
			}
		},

		{
			// // nextPrev -> 1
			title: "Datos personales",
			component: DatosPersonalesView,
			onSubmit: function(data) {
				const payload = {...data, cedulado: false}
				dispatch({ type: SET_PROFILES, payload })
				nextPrev(2)
			}
		},

		{
			// // nextPrev -> 2
			title: "Datos domicilio",
			component: DatosDomicilio,
			onSubmit: function(payload) {
				dispatch({type: SET_DOMICILIO, payload})
				nextPrev(3)
			}
		},

		{
			// nextPrev -> 3
			title: "Datos Laborales",
			initialValues: undefined,
			component: DatosLaboralesView,
			onSubmit: function(data) {
				const payload = { ...data, labora: true}
				dispatch({ type: SET_LABORALES, payload })
				nextPrev(4)
			}
		},

		{
			// nextPrev -> 4
			title: "Datos estudios",
			initialValues: undefined,
			component: DatosEstudiosView,
			onSubmit: function(data) {
				const payload = { ...data, estudia: true}
				dispatch({ type: SET_ESTUDIOS, payload })
				nextPrev(5)
			}
		},

		{
			// nextPrev -> 5
			title: "Crear cuenta",
			initialValues: undefined,
			component: CrearCuenta,
			onSubmit: function(data) {
				const payload = {
					...profiles,
					...domicilio,
					...laborales,
					...educativo,
					...data
				}
				dispatch({ type: REGISTER_NEW_USER, payload })
			}
		},

	], [nextPrev, dispatch, profiles, domicilio, laborales, educativo])

	return {
		VIEWS,
		actualVisible,
		nextPrev,
		loading
	}
}