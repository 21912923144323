// @Vendors
import React, {useCallback} from 'react'
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup'

// @MateriaUI
import {
	Grid, 
	Grow
} from '@material-ui/core'

// @COmponents
import SimpleSelect from 'components/Form/SimpleSelect'
import AppField from 'components/Form/AppField'

// @Services
import { getEstados, getMunicipios, getParroquias } from 'utils/helpers'


const initial = {
	tipo_sector: '',
	sector_productivo: '',
	nombre_organismo: '',
	direccion_organismo: '',
	telefono_organismo: '',
	estado: '',
	municipio: '',
	parroquia_organismo: ''
}

const listTipoSector = [
	{ value: 0, label: "PUBLICO" },
	{ value: 1, label: "PRIVADO"}
]


function DatosLaborales({ onSubmit = () => console.log("no submit"), initialValues = initial, ActionsButtons="No buttons" }) {
	const [estados, setEdos] = React.useState([])
	const [municipios, setMuns] = React.useState([])
	const [parroquias, setPaqs] = React.useState([])

	const regLaborales = RegExp("^[0-9]+$")
	const onlyLetters = RegExp("^[a-zA-Z ]+$")
	const phoneRegex = RegExp(
  		/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
	)
	
	const formSchemas = Yup.object().shape({
		tipo_sector: Yup.string().required("¡Campo obligatorio!").matches(regLaborales, "Valor fuera de rango"),

		sector_productivo: Yup.string().required("¡Campo obligatorio!").matches(onlyLetters, "Valor fuera de rango"),
		
		nombre_organismo: Yup.string().required("¡Campo obligatorio!").matches(onlyLetters, "Valor fuera de rango"),
		
		direccion_organismo: Yup.string().required("¡Campo obligatorio!").matches(onlyLetters, "Valor fuera de rango, solo se acepta letras"),
		
		estado: Yup.string().required("¡Campo obligatorio!").matches(regLaborales, "Valor fuera de rango"),
		municipio: Yup.string().required("¡Campo obligatorio!").matches(regLaborales, "Valor fuera de rango"),
		parroquia_organismo: Yup.string().required("¡Campo obligatorio!").matches(regLaborales, "Valor fuera de rango"),
		
		telefono_organismo:  Yup.string().required("¡Campo obligatorio!").min(10, "Introduzca un teléfono válido, entre 7 y 11 dígitos").max(11, "Introduzca un teléfono válido, entre 7 y 11 dígitos").matches(phoneRegex, "Introduzca un teléfono válido, entre 7 y 11 dígitos"),
		//sector_productivo: 
	})

	const obtenerEstados = useCallback( async () => {
		try {
			const res = await getEstados()
			const { data } = res
			
			let payload = []
			for(let i=0; i < data.data.length; i++) {
				payload.push({ label: data.data[i].nombre, value: data.data[i].id })
			}
			setEdos(payload)
		} catch(err) {
			console.log(err)
		}
	},[])

	const obtenerMunicipios = useCallback( async (e, hdlchng) => {
		hdlchng(e)
		const {value} = e.target
		try {
			const res = await getMunicipios(value)
			const {data} = res
			let payload = []

			for(let i=0; i < data.data.length; i++) {
				payload.push({ label: data.data[i].nombre, value: data.data[i].id})
			}
			setMuns(payload)
		} catch(err) {
			console.log(err)
		}
	},[])

	const obtenerParroquias = useCallback( async (e, hdlchng) => {
		hdlchng(e)
		const {value} = e.target
		try {
			const res = await getParroquias(value)
			const {data} = res
			let payload = []
			for(let i=0; i < data.data.length; i++) {
				payload.push({ label: data.data[i].nombre, value: data.data[i].id })
			}

			setPaqs(payload)
		} catch(err) {
			console.log(err)
		}
	},[])

	React.useEffect( () => {
		obtenerEstados()
	}, [obtenerEstados])

	return (
		<Formik
			initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={formSchemas}
          >
          {({errors, touched, values, handleChange, handleBlur, handleSubmit}) => (
          	<Grow in={true} timeout={1000}>
          	<form onSubmit={handleSubmit}> 
				<Grid container maxwidth="md" spacing={3}>
					{/*  tipo_sector */}
		            <Grid item sm={12} md={3}>
						<SimpleSelect
	                		style={{minWidth: "160px"}}
	                		id="tipo_sector"
	                		name="tipo_sector"
	                		value={values.tipo_sector}
							onChange={handleChange}
							onBlur={handleBlur}
	                		label="Tipo de sector (*)"
		                    items={listTipoSector}
	                		helpertext={<ErrorMessage name="tipo_sector"/>}
	                		error={errors.tipo_sector && touched.tipo_sector} />

		            </Grid>

					{/*  sector_productivo */}
		            <Grid item sm={12} md={3}>
		            	<AppField
							id="sector_productivo"
							name="sector_productivo"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.sector_productivo}
							label="Sector productivo (*)"
							helperText={<ErrorMessage name="sector_productivo"/>}
							error={errors.sector_productivo && touched.sector_productivo}
						/>
		            </Grid>

					{/*  nombre_organismo */}
		            <Grid item sm={12} md={3}>
		            	<AppField
							id="nombre_organismo"
							name="nombre_organismo"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.nombre_organismo}
							label="Nombre del organismo u empresa (*)"
							helperText={<ErrorMessage name="nombre_organismo"/>}
							error={errors.nombre_organismo && touched.nombre_organismo}
						/>
		            </Grid>

					{/*  direccion_organismo */}
		            <Grid item sm={12} md={3}>
						<AppField  
							name="direccion_organismo"
							value={values.direccion_organismo}
							onChange={handleChange}
							onBlur={handleBlur}
							label="Dirección del organismo"
							helperText={<ErrorMessage name="direccion_organismo"/>}
							error={errors.direccion_organismo && touched.direccion_organismo}
						/>
		            </Grid>

					{/*  contacto */}
		            <Grid item sm={12} md={3}>
						<AppField  
							name="telefono_organismo"
							value={values.telefono_organismo}
							onChange={handleChange}
							onBlur={handleBlur}
							label="Teléfono de oficina (*)"
							helperText={<ErrorMessage name="telefono_organismo"/>}
							error={errors.telefono_organismo && touched.telefono_organismo}
						/>
		            </Grid>

					{/* estado */}
		            <Grid item sm={12} md={3}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="estado"
	                		name="estado"
	                		value={values.estado}
							onChange={(e) => obtenerMunicipios(e,handleChange)}
							onBlur={handleBlur}
	                		label="Estado (*)"
		                    items={estados}
	                		helpertext={<ErrorMessage name="estado"/>}
	                		error={errors.estado && touched.estado} />
		            </Grid>
		    
		            {/* municipio */}
		            <Grid item sm={12} md={3}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="municipio"
	                		name="municipio"
	                		value={values.municipio}
							onChange={(e) => obtenerParroquias(e,handleChange)}
							onBlur={handleBlur}
	                		label="Municipio (*)"
		                    items={municipios}
	                		helpertext={<ErrorMessage name="municipio"/>}
	                		error={errors.municipio && touched.municipio} />
		            </Grid>
		    
		            {/* parroquia */}
		            <Grid item sm={12} md={3}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="parroquia_organismo"
	                		name="parroquia_organismo"
	                		value={values.parroquia_organismo}
							onChange={handleChange}
							onBlur={handleBlur}
	                		label="Parroquia (*)"
		                    items={parroquias}
	                		helpertext={<ErrorMessage name="parroquia_organismo"/>}
	                		error={errors.parroquia_organismo && touched.parroquia_organismo} />
		            </Grid>
			        <Grid item sm={12} md={12}> {ActionsButtons} </Grid>
       			</Grid>
          	</form>
          	</Grow>
          )}
        </Formik>
	)

}

export default React.memo(DatosLaborales)