import React from "react";

const MujerRegister = () => {
  return (
    <svg width="930" height="744" viewBox="0 0 930 744" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M928.996 692.594C928.852 708.979 927.652 725.338 925.402 741.569C925.324 742.139 925.238 742.709 925.159 743.279L771.711 742.422C771.261 741.855 770.817 741.283 770.388 740.703C768.955 738.784 767.612 736.811 766.38 734.77C760.455 724.936 757.743 713.423 760.461 705.48L760.51 705.357C761.096 703.617 762.021 702.011 763.23 700.63C769.427 693.654 781.956 694.817 793.326 700.447C783.128 689.284 775.008 675.198 774.391 662.624C773.786 650.36 780.199 641.599 787.154 634.153C787.382 633.907 787.61 633.668 787.839 633.429C787.949 633.306 788.067 633.189 788.177 633.066C793.604 627.392 799.931 621.799 809.088 622.476C819.132 623.219 830.267 631.631 838.074 641.434C845.882 651.23 851.07 662.359 856.329 673.219C861.595 684.073 867.292 695.204 875.742 704.482C864.297 690.006 855.07 673.535 851.661 657.517C848.253 641.498 851.252 626.113 861.452 618.569C864.549 616.349 868.132 614.903 871.902 614.353C872.342 614.279 872.789 614.22 873.243 614.168C882.685 613.093 893.959 616.359 903.551 624.165C914.114 632.76 921.438 645.501 925.081 657.597C928.725 669.693 929.113 681.337 928.996 692.594Z" fill="#941B80" />
        <path d="M828.601 742.74L825.667 742.723C824.735 742.141 823.799 741.565 822.858 740.996C822.276 740.629 821.687 740.275 821.098 739.914C811.365 733.97 801.572 728.268 791.717 722.809C781.871 717.345 771.976 712.133 762.032 707.173C761.768 707.058 761.553 706.854 761.424 706.598C761.295 706.341 761.26 706.047 761.325 705.767C761.336 705.73 761.352 705.695 761.374 705.664C761.513 705.403 761.823 705.295 762.275 705.518C763.495 706.129 764.722 706.741 765.941 707.366C775.913 712.411 785.842 717.709 795.728 723.257C805.607 728.804 815.43 734.595 825.197 740.631C825.403 740.756 825.608 740.888 825.814 741.013C826.745 741.588 827.67 742.164 828.601 742.74Z" fill="white" />
        <path d="M866.434 742.951L864.62 742.941C864.211 742.368 863.808 741.795 863.399 741.222C853.784 727.74 844.171 714.257 834.561 700.774C818.822 678.7 803.088 656.627 787.358 634.553C787.265 634.434 787.196 634.298 787.154 634.153C787.02 633.665 787.379 633.378 787.839 633.429C788.033 633.456 788.219 633.525 788.384 633.63C788.549 633.735 788.69 633.875 788.797 634.039C797.621 646.418 806.443 658.792 815.263 671.162C830.92 693.125 846.574 715.086 862.227 737.044C863.222 738.438 864.218 739.839 865.214 741.233C865.623 741.805 866.032 742.378 866.434 742.951Z" fill="white" />
        <path d="M906.856 732.414C906.763 735.472 906.561 738.481 906.297 741.462C906.245 742.032 906.194 742.603 906.143 743.173L904.239 743.162C904.297 742.592 904.356 742.022 904.407 741.452C904.809 737.145 905.094 732.789 905.133 728.315C905.184 715.912 903.975 703.535 901.525 691.375C898.922 678.283 895.183 665.442 890.352 652.998C885.281 639.873 879.167 627.176 872.07 615.027C871.936 614.829 871.876 614.59 871.902 614.353C871.98 613.81 872.703 613.649 873.243 614.168C873.349 614.269 873.441 614.385 873.516 614.513C874.394 616.023 875.256 617.537 876.102 619.056C882.938 631.272 888.789 644.014 893.6 657.16C898.182 669.645 901.668 682.505 904.017 695.595C906.218 707.739 907.169 720.076 906.856 732.414Z" fill="white" />
        <path d="M628.948 716.35C628.873 724.912 628.246 733.461 627.07 741.943C627.029 742.241 626.984 742.538 626.943 742.836L546.755 742.388C546.519 742.092 546.288 741.793 546.063 741.49C545.314 740.488 544.613 739.456 543.969 738.39C540.873 733.251 539.455 727.234 540.876 723.083L540.901 723.019C541.208 722.11 541.691 721.271 542.323 720.549C545.561 716.904 552.109 717.511 558.05 720.454C552.721 714.62 548.477 707.259 548.155 700.688C547.839 694.279 551.19 689.701 554.825 685.81C554.944 685.681 555.063 685.556 555.183 685.431C555.241 685.367 555.302 685.306 555.36 685.242C558.196 682.277 561.502 679.354 566.287 679.707C571.536 680.096 577.355 684.492 581.435 689.615C585.515 694.734 588.226 700.549 590.974 706.225C593.726 711.897 596.703 717.714 601.119 722.562C595.138 714.997 590.316 706.39 588.535 698.019C586.754 689.648 588.321 681.608 593.651 677.666C595.27 676.506 597.142 675.75 599.112 675.462C599.342 675.424 599.576 675.393 599.813 675.366C604.747 674.804 610.639 676.511 615.651 680.59C621.017 685.199 624.926 691.269 626.903 698.061C628.807 704.382 629.01 710.467 628.948 716.35Z" fill="#941B80" />
        <path d="M576.484 742.554L574.951 742.546C574.464 742.241 573.975 741.941 573.483 741.643C573.179 741.451 572.871 741.266 572.563 741.078C567.477 737.972 562.359 734.992 557.209 732.139C552.064 729.284 546.893 726.56 541.697 723.968C541.559 723.908 541.447 723.802 541.379 723.667C541.312 723.533 541.293 723.38 541.327 723.233C541.333 723.214 541.341 723.196 541.353 723.179C541.425 723.043 541.587 722.987 541.824 723.103C542.461 723.423 543.102 723.742 543.74 724.069C548.951 726.706 554.139 729.474 559.306 732.373C564.468 735.272 569.602 738.298 574.705 741.453C574.813 741.518 574.92 741.587 575.027 741.652C575.514 741.953 575.997 742.254 576.484 742.554Z" fill="white" />
        <path d="M596.255 742.665L595.307 742.66C595.093 742.36 594.883 742.061 594.669 741.762C589.644 734.716 584.621 727.67 579.599 720.624C571.374 709.089 563.152 697.554 554.932 686.019C554.883 685.957 554.847 685.886 554.825 685.81C554.755 685.555 554.942 685.405 555.183 685.431C555.284 685.446 555.381 685.482 555.468 685.537C555.554 685.592 555.628 685.664 555.684 685.75C560.295 692.219 564.905 698.686 569.514 705.15C577.696 716.627 585.877 728.103 594.056 739.578C594.577 740.307 595.097 741.039 595.617 741.767C595.831 742.066 596.045 742.366 596.255 742.665Z" fill="white" />
        <path d="M617.379 737.159C617.33 738.757 617.225 740.329 617.086 741.887C617.06 742.185 617.033 742.483 617.006 742.781L616.011 742.775C616.042 742.477 616.072 742.179 616.099 741.881C616.309 739.631 616.458 737.354 616.478 735.017C616.505 728.535 615.873 722.067 614.593 715.713C613.232 708.871 611.279 702.16 608.754 695.657C606.104 688.799 602.909 682.163 599.2 675.815C599.13 675.711 599.099 675.587 599.112 675.462C599.153 675.179 599.531 675.095 599.813 675.366C599.869 675.419 599.917 675.48 599.956 675.546C600.415 676.335 600.865 677.127 601.307 677.92C604.88 684.304 607.937 690.963 610.451 697.833C612.846 704.357 614.667 711.077 615.895 717.918C617.045 724.264 617.542 730.711 617.379 737.159Z" fill="white" />
        <path d="M165.953 404.324H15.6724C11.5173 404.319 7.53368 402.666 4.59557 399.728C1.65745 396.79 0.00473497 392.806 0 388.651V208.137C0.00473497 203.982 1.65745 199.998 4.59557 197.06C7.53368 194.122 11.5173 192.469 15.6724 192.464H165.953C170.108 192.469 174.092 194.122 177.03 197.06C179.968 199.998 181.621 203.982 181.626 208.137V388.651C181.621 392.806 179.968 396.79 177.03 399.728C174.092 402.666 170.108 404.319 165.953 404.324V404.324ZM15.6724 194.464C12.0475 194.468 8.57224 195.91 6.00906 198.473C3.44588 201.037 2.00409 204.512 2 208.137V388.651C2.00409 392.276 3.44588 395.751 6.00906 398.315C8.57224 400.878 12.0475 402.32 15.6724 402.324H165.953C169.578 402.32 173.053 400.878 175.617 398.315C178.18 395.751 179.622 392.276 179.626 388.651V208.137C179.622 204.512 178.18 201.037 175.617 198.473C173.053 195.91 169.578 194.468 165.953 194.464H15.6724Z" fill="#3F3D56" />
        <path d="M125.942 207.303H55.6831C52.369 207.299 49.1918 205.981 46.8484 203.637C44.505 201.294 43.1868 198.117 43.1831 194.803V192.125C43.1868 188.811 44.505 185.634 46.8484 183.291C49.1918 180.947 52.369 179.629 55.6831 179.625H125.942C129.256 179.629 132.433 180.947 134.777 183.291C137.12 185.634 138.438 188.811 138.442 192.125V194.803C138.438 198.117 137.12 201.294 134.777 203.637C132.433 205.981 129.256 207.299 125.942 207.303V207.303Z" fill="#CCCCCC" />
        <path d="M159.729 305.508H21.8972C20.9623 305.509 20.0363 305.326 19.1723 304.969C18.3082 304.612 17.523 304.088 16.8615 303.427C16.2 302.767 15.6752 301.982 15.3172 301.118C14.9592 300.255 14.7749 299.329 14.7749 298.394C14.7749 297.459 14.9592 296.534 15.3172 295.67C15.6752 294.806 16.2 294.022 16.8615 293.361C17.523 292.7 18.3082 292.176 19.1723 291.819C20.0363 291.462 20.9623 291.279 21.8972 291.28H159.729C161.614 291.283 163.421 292.033 164.754 293.367C166.086 294.701 166.834 296.509 166.834 298.394C166.834 300.279 166.086 302.088 164.754 303.421C163.421 304.755 161.614 305.506 159.729 305.508Z" fill="#662583" />
        <path d="M159.729 335.742H21.8972C20.9623 335.743 20.0363 335.56 19.1723 335.203C18.3082 334.846 17.523 334.322 16.8615 333.661C16.2 333.001 15.6752 332.216 15.3172 331.352C14.9592 330.489 14.7749 329.563 14.7749 328.628C14.7749 327.693 14.9592 326.767 15.3172 325.904C15.6752 325.04 16.2 324.255 16.8615 323.595C17.523 322.934 18.3082 322.41 19.1723 322.053C20.0363 321.696 20.9623 321.513 21.8972 321.514H159.729C161.614 321.516 163.421 322.267 164.754 323.601C166.086 324.935 166.834 326.743 166.834 328.628C166.834 330.513 166.086 332.321 164.754 333.655C163.421 334.989 161.614 335.74 159.729 335.742V335.742Z" fill="#662583" />
        <path d="M122.826 275.274H58.8006C57.8656 275.275 56.9397 275.092 56.0756 274.735C55.2115 274.378 54.4263 273.854 53.7648 273.193C53.1033 272.533 52.5786 271.748 52.2205 270.884C51.8625 270.021 51.6782 269.095 51.6782 268.16C51.6782 267.225 51.8625 266.299 52.2205 265.436C52.5786 264.572 53.1033 263.787 53.7648 263.127C54.4263 262.466 55.2115 261.942 56.0756 261.585C56.9397 261.228 57.8656 261.045 58.8006 261.046H122.826C123.76 261.045 124.686 261.228 125.551 261.585C126.415 261.942 127.2 262.466 127.861 263.127C128.523 263.787 129.048 264.572 129.406 265.436C129.764 266.299 129.948 267.225 129.948 268.16C129.948 269.095 129.764 270.021 129.406 270.884C129.048 271.748 128.523 272.533 127.861 273.193C127.2 273.854 126.415 274.378 125.551 274.735C124.686 275.092 123.76 275.275 122.826 275.274V275.274Z" fill="#662583" />
        <path d="M485.188 224.698H334.908C330.753 224.694 326.769 223.041 323.831 220.103C320.893 217.165 319.24 213.181 319.235 209.026V28.5107C319.24 24.3556 320.893 20.372 323.831 17.4339C326.769 14.4958 330.753 12.8431 334.908 12.8384H485.188C489.344 12.8431 493.327 14.4958 496.265 17.4339C499.203 20.372 500.856 24.3556 500.861 28.5107V209.026C500.856 213.181 499.203 217.165 496.265 220.103C493.327 223.041 489.344 224.694 485.188 224.698V224.698ZM334.908 14.8384C331.283 14.8424 327.808 16.2842 325.244 18.8474C322.681 21.4106 321.239 24.8859 321.235 28.5107V209.026C321.239 212.651 322.681 216.126 325.244 218.689C327.808 221.252 331.283 222.694 334.908 222.698H485.188C488.813 222.694 492.289 221.252 494.852 218.689C497.415 216.126 498.857 212.651 498.861 209.026V28.5107C498.857 24.8859 497.415 21.4106 494.852 18.8474C492.289 16.2842 488.813 14.8424 485.188 14.8384H334.908Z" fill="#3F3D56" />
        <path d="M445.178 27.6773H374.919C371.605 27.6735 368.428 26.3554 366.084 24.012C363.741 21.6686 362.423 18.4913 362.419 15.1773V12.5C362.423 9.18594 363.741 6.00868 366.084 3.66528C368.428 1.32189 371.605 0.00372667 374.919 0H445.178C448.492 0.00372667 451.669 1.32189 454.012 3.66528C456.356 6.00868 457.674 9.18594 457.678 12.5V15.1773C457.674 18.4913 456.356 21.6686 454.012 24.012C451.669 26.3554 448.492 27.6735 445.178 27.6773V27.6773Z" fill="#CCCCCC" />
        <path d="M478.964 125.882H341.133C340.198 125.883 339.272 125.7 338.408 125.343C337.544 124.986 336.758 124.462 336.097 123.802C335.435 123.141 334.911 122.356 334.553 121.493C334.195 120.629 334.01 119.703 334.01 118.768C334.01 117.834 334.195 116.908 334.553 116.044C334.911 115.18 335.435 114.396 336.097 113.735C336.758 113.074 337.544 112.551 338.408 112.194C339.272 111.837 340.198 111.653 341.133 111.655H478.964C479.899 111.653 480.825 111.837 481.689 112.194C482.553 112.551 483.338 113.074 484 113.735C484.661 114.396 485.186 115.18 485.544 116.044C485.902 116.908 486.086 117.834 486.086 118.768C486.086 119.703 485.902 120.629 485.544 121.493C485.186 122.356 484.661 123.141 484 123.802C483.338 124.462 482.553 124.986 481.689 125.343C480.825 125.7 479.899 125.883 478.964 125.882V125.882Z" fill="#662583" />
        <path d="M478.964 156.116H341.132C340.198 156.116 339.273 155.932 338.41 155.575C337.547 155.217 336.763 154.693 336.102 154.033C335.442 153.372 334.918 152.588 334.56 151.725C334.203 150.862 334.019 149.937 334.019 149.002C334.019 148.068 334.203 147.143 334.56 146.28C334.918 145.417 335.442 144.633 336.102 143.972C336.763 143.311 337.547 142.787 338.41 142.43C339.273 142.072 340.198 141.888 341.132 141.888H478.964C479.898 141.888 480.823 142.072 481.686 142.43C482.549 142.787 483.334 143.311 483.994 143.972C484.655 144.633 485.179 145.417 485.536 146.28C485.894 147.143 486.078 148.068 486.078 149.002C486.078 149.937 485.894 150.862 485.536 151.725C485.179 152.588 484.655 153.372 483.994 154.033C483.334 154.693 482.549 155.217 481.686 155.575C480.823 155.932 479.898 156.116 478.964 156.116Z" fill="#662583" />
        <path d="M442.061 95.6482H378.036C377.101 95.6493 376.175 95.4661 375.311 95.1091C374.447 94.7521 373.662 94.2283 373 93.5676C372.339 92.9069 371.814 92.1223 371.456 91.2586C371.098 90.395 370.914 89.4692 370.914 88.5343C370.914 87.5994 371.098 86.6736 371.456 85.81C371.814 84.9463 372.339 84.1617 373 83.501C373.662 82.8403 374.447 82.3165 375.311 81.9595C376.175 81.6025 377.101 81.4193 378.036 81.4204H442.061C443.948 81.4204 445.757 82.1699 447.091 83.504C448.425 84.8381 449.175 86.6476 449.175 88.5343C449.175 90.421 448.425 92.2305 447.091 93.5646C445.757 94.8987 443.948 95.6482 442.061 95.6482Z" fill="#662583" />
        <path d="M791.789 528.844L659.832 600.758C656.182 602.742 651.893 603.198 647.907 602.024C643.921 600.85 640.564 598.143 638.571 594.496L552.189 435.992C550.205 432.341 549.75 428.052 550.924 424.067C552.098 420.081 554.805 416.723 558.451 414.731L690.408 342.817C694.059 340.832 698.348 340.377 702.333 341.551C706.319 342.725 709.677 345.432 711.669 349.078L798.051 507.583C800.035 511.234 800.49 515.523 799.317 519.508C798.143 523.494 795.436 526.852 791.789 528.844ZM559.408 416.487C556.227 418.225 553.866 421.154 552.841 424.631C551.817 428.109 552.214 431.85 553.945 435.035L640.327 593.539C642.066 596.72 644.994 599.082 648.472 600.106C651.949 601.13 655.69 600.733 658.875 599.002L790.832 527.088C794.013 525.35 796.375 522.421 797.399 518.944C798.423 515.466 798.026 511.725 796.295 508.54L709.913 350.036C708.175 346.855 705.246 344.493 701.769 343.469C698.291 342.445 694.55 342.842 691.365 344.573L559.408 416.487Z" fill="#3F3D56" />
        <path d="M662.376 374.993L600.684 408.614C597.772 410.196 594.352 410.559 591.173 409.623C587.994 408.687 585.316 406.528 583.727 403.619L582.445 401.269C580.863 398.357 580.5 394.936 581.436 391.757C582.372 388.578 584.531 385.9 587.44 384.311L649.132 350.69C652.044 349.107 655.464 348.744 658.643 349.681C661.822 350.617 664.5 352.776 666.089 355.684L667.371 358.035C668.953 360.947 669.316 364.368 668.38 367.547C667.444 370.726 665.284 373.403 662.376 374.993V374.993Z" fill="#CCCCCC" />
        <path d="M739.038 445.056L618.012 511.012C617.191 511.459 616.291 511.741 615.362 511.84C614.433 511.939 613.494 511.854 612.598 511.59C611.702 511.326 610.866 510.888 610.139 510.301C609.412 509.715 608.808 508.99 608.361 508.17C607.914 507.35 607.633 506.449 607.534 505.521C607.435 504.592 607.52 503.652 607.783 502.756C608.047 501.86 608.485 501.025 609.072 500.298C609.659 499.571 610.383 498.966 611.203 498.519L732.229 432.563C733.886 431.66 735.833 431.452 737.643 431.985C739.453 432.518 740.977 433.748 741.88 435.405C742.783 437.062 742.99 439.009 742.457 440.819C741.924 442.629 740.694 444.153 739.038 445.056V445.056Z" fill="#CCCCCC" />
        <path d="M753.505 471.603L632.48 537.56C631.659 538.007 630.759 538.288 629.83 538.387C628.901 538.486 627.962 538.401 627.066 538.138C626.169 537.874 625.334 537.436 624.607 536.849C623.88 536.262 623.276 535.538 622.829 534.718C622.382 533.897 622.101 532.997 622.002 532.068C621.902 531.139 621.987 530.2 622.251 529.304C622.515 528.408 622.953 527.572 623.54 526.845C624.127 526.118 624.851 525.514 625.671 525.067L746.697 459.11C747.517 458.663 748.418 458.382 749.346 458.283C750.275 458.184 751.215 458.269 752.111 458.533C753.007 458.797 753.842 459.235 754.569 459.821C755.296 460.408 755.901 461.132 756.348 461.953C756.795 462.773 757.076 463.673 757.175 464.602C757.274 465.531 757.189 466.47 756.925 467.367C756.661 468.263 756.223 469.098 755.637 469.825C755.05 470.552 754.326 471.156 753.505 471.603V471.603Z" fill="#CCCCCC" />
        <path d="M692.166 436.168L635.947 466.805C635.127 467.253 634.227 467.534 633.298 467.633C632.369 467.732 631.429 467.647 630.533 467.383C629.637 467.119 628.802 466.681 628.075 466.094C627.348 465.508 626.744 464.783 626.297 463.963C625.85 463.143 625.568 462.243 625.469 461.314C625.37 460.385 625.455 459.445 625.719 458.549C625.983 457.653 626.421 456.818 627.008 456.091C627.594 455.364 628.319 454.76 629.139 454.312L685.357 423.675C686.178 423.227 687.078 422.946 688.007 422.847C688.936 422.748 689.875 422.833 690.771 423.097C691.667 423.361 692.503 423.799 693.23 424.386C693.957 424.972 694.561 425.697 695.008 426.517C695.455 427.337 695.736 428.237 695.835 429.166C695.934 430.095 695.85 431.035 695.586 431.931C695.322 432.827 694.884 433.662 694.297 434.389C693.71 435.116 692.986 435.72 692.166 436.168V436.168Z" fill="#CCCCCC" />
        <path d="M279.208 163.475L208.92 204.284L209.924 206.014L280.212 165.204L279.208 163.475Z" fill="#3F3D56" />
        <path d="M282.194 174.428L292.122 157.132L272.179 157.179L282.194 174.428Z" fill="#3F3D56" />
        <path d="M487.233 431.942C487.074 431.942 486.918 431.904 486.776 431.832L216.628 293.059C216.392 292.938 216.215 292.728 216.134 292.475C216.053 292.223 216.076 291.949 216.197 291.714C216.318 291.478 216.527 291.3 216.779 291.219C217.032 291.137 217.306 291.159 217.541 291.28L487.69 430.052C487.89 430.155 488.049 430.322 488.143 430.526C488.236 430.73 488.258 430.959 488.205 431.177C488.152 431.395 488.028 431.589 487.851 431.728C487.675 431.867 487.457 431.942 487.233 431.942V431.942Z" fill="#3F3D56" />
        <path d="M480.081 438.479L500 437.5L489.195 420.738L480.081 438.479Z" fill="#3F3D56" />
        <path d="M765.974 546.682C764.568 545.982 763.332 544.983 762.354 543.754C761.376 542.525 760.679 541.097 760.313 539.57C759.947 538.042 759.92 536.453 760.234 534.915C760.549 533.376 761.196 531.925 762.132 530.664L754.099 452.124H771.459L777.843 528.814C779.858 530.526 781.174 532.918 781.543 535.537C781.912 538.155 781.308 540.817 779.845 543.02C778.382 545.222 776.162 546.812 773.605 547.487C771.049 548.162 768.333 547.875 765.974 546.682V546.682Z" fill="#A0616A" />
        <path d="M750.034 408.779C763.599 408.779 774.595 397.783 774.595 384.218C774.595 370.653 763.599 359.657 750.034 359.657C736.469 359.657 725.473 370.653 725.473 384.218C725.473 397.783 736.469 408.779 750.034 408.779Z" fill="#A0616A" />
        <path d="M744.445 497.758L707.789 489.753C706.825 489.541 705.958 489.019 705.32 488.267C704.681 487.514 704.307 486.574 704.255 485.588C703.831 477.409 703.527 456.249 709.828 436.495C711.045 432.707 713.009 429.203 715.604 426.188C718.199 423.173 721.373 420.709 724.937 418.942C728.501 417.175 732.383 416.141 736.354 415.901C740.325 415.661 744.304 416.219 748.055 417.544V417.544C752.013 418.935 755.632 421.147 758.676 424.035C761.719 426.923 764.117 430.422 765.713 434.302C767.309 438.182 768.067 442.356 767.936 446.549C767.805 450.742 766.79 454.861 764.956 458.634C756.827 475.327 751.707 487.415 749.739 494.564C749.434 495.673 748.714 496.623 747.729 497.217C746.744 497.811 745.569 498.005 744.445 497.758Z" fill="#662583" />
        <path d="M709.913 731.092L697.653 731.091L691.821 683.803L709.915 683.804L709.913 731.092Z" fill="#A0616A" />
        <path d="M688.896 727.588H712.54V742.475H674.009C674.009 738.526 675.577 734.74 678.369 731.948C681.161 729.156 684.947 727.588 688.896 727.588V727.588Z" fill="#2F2E41" />
        <path d="M832.504 715.203L821.761 721.109L793.866 682.482L809.722 673.764L832.504 715.203Z" fill="#A0616A" />
        <path d="M812.399 722.258L833.118 710.867L840.29 723.913L806.526 742.476C804.624 739.016 804.174 734.942 805.276 731.151C806.377 727.359 808.94 724.16 812.399 722.258V722.258Z" fill="#2F2E41" />
        <path d="M710.136 704.832L691.262 703.378C690.206 703.3 689.212 702.85 688.455 702.109C687.699 701.368 687.229 700.383 687.128 699.329C684.439 671.273 672.638 529.101 704.555 485.329L704.645 485.207L704.787 485.154C705.073 485.049 733.538 474.899 749.869 493.501C750.381 494.092 750.731 494.806 750.883 495.574C751.035 496.342 750.984 497.136 750.735 497.877L750.112 499.747C749.934 500.276 749.886 500.841 749.972 501.394C750.059 501.946 750.277 502.469 750.608 502.919C755.14 509.046 770.394 532.644 776.389 579.047C776.427 579.343 776.503 579.633 776.615 579.909L820.88 688.646C821.106 689.201 821.218 689.796 821.211 690.395C821.204 690.995 821.077 691.587 820.838 692.137C820.599 692.686 820.253 693.183 819.819 693.597C819.386 694.011 818.874 694.334 818.314 694.547L804.386 699.854C803.371 700.235 802.254 700.239 801.237 699.866C800.22 699.492 799.37 698.766 798.843 697.82L734.944 581.889C734.69 581.428 734.296 581.058 733.819 580.834C733.342 580.61 732.807 580.542 732.289 580.64C731.771 580.738 731.298 580.998 730.936 581.381C730.575 581.765 730.344 582.252 730.276 582.775L714.944 700.923C714.803 702.007 714.273 703.002 713.453 703.723C712.633 704.445 711.578 704.843 710.485 704.844C710.37 704.844 710.253 704.84 710.136 704.832Z" fill="#2F2E41" />
        <path d="M654.55 412.748C654.673 413.288 654.753 413.836 654.789 414.388L697.747 439.17L708.188 433.159L719.319 447.732L701.869 460.169C700.459 461.174 698.761 461.694 697.031 461.652C695.3 461.61 693.63 461.008 692.271 459.935L647.974 424.99C645.975 425.73 643.798 425.843 641.733 425.316C639.667 424.789 637.811 423.645 636.412 422.037C635.013 420.429 634.136 418.433 633.898 416.315C633.661 414.196 634.074 412.056 635.083 410.178C636.092 408.3 637.648 406.774 639.545 405.802C641.443 404.83 643.591 404.459 645.704 404.739C647.818 405.018 649.796 405.933 651.376 407.364C652.956 408.795 654.063 410.673 654.55 412.748V412.748Z" fill="#A0616A" />
        <path d="M698.337 436.693C698.467 436.06 698.731 435.462 699.113 434.939C699.494 434.417 699.984 433.984 700.549 433.668L720.262 422.646C722.881 420.624 726.194 419.724 729.476 420.142C732.758 420.56 735.74 422.263 737.769 424.876C739.798 427.489 740.707 430.801 740.298 434.084C739.89 437.367 738.196 440.354 735.588 442.39L719.871 458.711C719.422 459.177 718.878 459.54 718.277 459.777C717.675 460.014 717.029 460.118 716.384 460.082C715.738 460.047 715.107 459.873 714.535 459.572C713.963 459.271 713.462 458.85 713.067 458.338L699.183 440.344C698.788 439.832 698.508 439.241 698.362 438.611C698.216 437.981 698.207 437.327 698.337 436.693V436.693Z" fill="#662583" />
        <path d="M749.364 470.676C748.861 470.27 748.452 469.759 748.164 469.18C747.877 468.601 747.717 467.967 747.697 467.32L746.99 444.746C746.401 441.49 747.128 438.134 749.01 435.413C750.893 432.693 753.777 430.829 757.031 430.232C760.285 429.634 763.643 430.352 766.369 432.226C769.096 434.101 770.967 436.98 771.574 440.233L778.824 461.7C779.031 462.313 779.103 462.963 779.036 463.606C778.969 464.249 778.765 464.87 778.436 465.427C778.107 465.984 777.662 466.463 777.132 466.833C776.601 467.202 775.997 467.453 775.36 467.568L752.994 471.606C752.358 471.722 751.704 471.698 751.078 471.538C750.451 471.377 749.867 471.083 749.364 470.676V470.676Z" fill="#662583" />
        <path d="M746.147 378.569C739.834 382.889 731.545 387.331 725.098 382.73C720.864 379.709 719.53 374.114 721.247 369.205C724.334 360.376 732.816 356.489 740.928 353.51C751.472 349.637 762.953 346.556 773.839 349.33C784.724 352.103 794.344 362.643 792.379 373.703C790.799 382.597 782.409 389.997 783.6 398.952C784.799 407.964 794.934 412.708 803.882 414.319C812.829 415.929 822.842 416.447 829.272 422.874C837.476 431.073 835.427 445.727 827.708 454.383C819.99 463.04 808.342 466.964 797.055 469.631C782.1 473.165 765.926 475.023 751.722 469.157C737.519 463.292 726.467 447.503 730.581 432.697C732.319 426.444 736.413 421.158 740.37 416.013C744.326 410.868 748.333 405.487 749.842 399.174C751.1 393.913 750.169 387.805 746.68 383.859C746.046 383.169 745.646 382.295 745.54 381.363C745.433 380.431 745.624 379.489 746.086 378.673L746.147 378.569Z" fill="#2F2E41" />
        <path d="M926.311 743.5H545.311C545.046 743.5 544.791 743.395 544.604 743.207C544.416 743.02 544.311 742.765 544.311 742.5C544.311 742.235 544.416 741.98 544.604 741.793C544.791 741.605 545.046 741.5 545.311 741.5H926.311C926.576 741.5 926.831 741.605 927.018 741.793C927.206 741.98 927.311 742.235 927.311 742.5C927.311 742.765 927.206 743.02 927.018 743.207C926.831 743.395 926.576 743.5 926.311 743.5Z" fill="#3F3D56" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="929.013" height="743.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MujerRegister;
