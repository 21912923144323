//  @Vendors
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";

// @Material UI
import {
  Avatar,
  Typography,
  CircularProgress,
  Grid,
  Grow,
} from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";

// @Components
import ContactForm from "./ContactForm";

// @Stactis
import Animation from "statics/animations/contact.json";

import { makeStyles } from "@material-ui/core/styles";
import { SEND_EMAIL_CONTACT } from "state/users/users.actionsTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paperBox: {
    padding: "25px",
    boxSizing: "border-box",
    background: "#f9f9f9",
    borderRadius: "10px",
    boxShadow: "0 5px 10px 0 #c3b8b8",
  },
  mgBox: {
    marginTop: 50
  }
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Animation,

  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    id: "some-id",
  },
};

export default function Contacts() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const loading = useSelector((state) => state.appReducer.get("loading"));

  const handleSubmit  = (data)  => {
    dispatch({ type: SEND_EMAIL_CONTACT, payload: data })
    push("/")
  }

  return (
    <Grow in={true} timeout={1000}>
      <Grid container justify="space-around" className={classes.root}>
        <Grid className={classes.mgBox} item sm={4} align="center">
          <div className={classes.paperBox}>
            <Avatar className={classes.avatar}>
              <EmailIcon />
            </Avatar>
            <Typography align="center" component="h1" variant="h5">
              {loading ? "Cargando..." : "Contacto"}
            </Typography>
            <div style={{ display: loading ? "none" : "block" }}>
              <ContactForm onSubmit={handleSubmit} disabledButton={loading} />
              <br />
            </div>
            {loading && (
              <p style={{ textAlign: "center" }}>
                <br />
                <CircularProgress />
              </p>
            )}
          </div>
        </Grid>
        <Grid item sm={4}>
          <Lottie
            id="test"
            options={defaultOptions}
            isPaused={true}
            style={{ maxWidth: "600px", margin: "0" }}
          />
        </Grid>
      </Grid>
    </Grow>
  );
}
