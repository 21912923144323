// @Vendors
import React, { Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { upperFirst } from "lodash";
import { Grow } from "@material-ui/core";

//@Components
import ImgCurso from 'components/ImgCurso';

// @Moment
import moment from "moment";
import "moment/locale/es";

import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
  cursoRoot: {
    background: "#fff",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "4px 4px 9px 0px #ddd",
    margin: "20px 0",
  },
  cursoImage: {
    display: "flex",
    justifyContent: "center",
    "& svg": {
      height: "150px",
      width: "225px",
    },
  },
  verMas: {
    color: "#6c63ff",
    textDecoration: "none",
    position: "relative",
  },
  titulo: {
    color: "#6c63ff",
    fontWeight: 700,
  },
  title: {
    color: "#000",
    fontWeight: "600",
  },
  nameEnte: {
    color: "#6c63ff",
  },
  margin: {
    marginTop: "10px",
  },
  fontWevy: {
    fontWeight: "580",
    color: "#3e3c3c",
  },
  btnViewMore: {
    textDecoration: "none",
  },
}));
function Curso({
  curso: {
    id,
    nombre,
    descripcion,
    ente: { nombre: enteNombre, id: idEnte },
    fecha_inicio_clases,
    fecha_fin_clases,
    imagen,
    modalidad
  },
}) {
  const classes = useStyles();
  return (
    <Grow in={true} timeout={1200}>
      <Fragment>
        <Grid className={classes.cursoRoot} container>
          <Grid className={classes.cursoImage} item xs={12} sm={3}>
          {!imagen ? (
            <ImgCurso imagen={imagen} nombre={nombre} classe="cursoThumbial--home"/>
          ) : <img style={{width: '200px'}} alt="Imagen Oferta Academica Actual" src={`data:image/png;base64,${imagen}`}/>}
          </Grid>

          <Grid item xs={12} sm={9}>
            <Typography gutterBottom variant="h6" className={classes.title}>
              {nombre ? nombre : <Skeleton variant="text" width={300} />}
            </Typography>

            <Typography gutterBottom variant="body2">
              {descripcion ? (
                upperFirst(descripcion.slice(0, 250))
              ) : (
                <Skeleton variant="rect" height={50} />
              )}
            </Typography>

            <Typography
              gutterBottom
              variant="body2"
              className={classes.nameEnte}
            >
              {enteNombre ? enteNombre : <Skeleton variant="text" width={75} />}
            </Typography>

            <Grid justify="space-between" container>
              <Grid item xs={4}>
                {fecha_inicio_clases ? (
                  <Typography gutterBottom variant="body2">
                    <strong className={classes.fontWevy}>
                      Fecha de inicio del curso:
                    </strong>
                    <span>{moment(fecha_inicio_clases).format("l")}</span>
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
              </Grid>
              <Grid item xs={4}>
                {fecha_fin_clases ? (
                  <Typography gutterBottom align="right" variant="body2">
                    <strong className={classes.fontWevy}>
                      Fecha de cierre del curso:
                    </strong>
                    <span>{moment(fecha_fin_clases).format("l")}</span>
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
              </Grid>
            </Grid>
            <Grid>
            <Typography
              gutterBottom
              variant="body2"
              className={classes.nameEnte}
            >
               Modalidad: {(modalidad === 0) ? 'No definido' : (modalidad === 1 ? 'Presencial' : 'En linea')}
            </Typography>
            </Grid>
            <Link className={classes.btnViewMore} to={`/oferta-formativa/${id}`}>
              <Button
                className={classes.margin}
                variant="outlined"
                color="primary"
              >
                Ver más
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Fragment>
    </Grow>
  );
}

export default Curso;
