// @Vendors
import React, { useCallback } from 'react'
import {Formik, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import { upperFirst } from 'lodash'

// @MateriaUI
import {
	Grid,
	Grow,
	Tooltip,
	InputAdornment
} from '@material-ui/core'
// @Icons
import HelpIcon from '@material-ui/icons/Help';

// @Components
import SimpleSelect from 'components/Form/SimpleSelect'
import AppField from 'components/Form/AppField'

// @Services
import { getEstados, getMunicipios, getParroquias } from 'utils/helpers'

const initial = {
    direccion_habitacional: '',
    estado: '',
    municipio: '',
    parroquia_id:'',
		telefono_movil: ''
}

function DatosDomicilio({ onSubmit = () => console.log, initialValues = initial, ActionsButtons = "" }) {
	const [estados, setEdos] = React.useState([])
	const [municipios, setMuns] = React.useState([])
	const [parroquias, setPaqs] = React.useState([])
	
	const onlyLetters = RegExp("^[a-zA-ZñÑ0-9#., ]+$")
	const onlyNumers = RegExp("^[0-9]+$")
	const onlyTelefono = /^\(?(\d{4})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
	const formSchemas = Yup.object().shape({
		direccion_habitacional: Yup.string().required("¡Campo obligatorio!").matches(onlyLetters, "Valor fuera de rango"),
		estado: Yup.string().required("¡Campo obligatorio!"),
		municipio: Yup.string().required("¡Campo obligatorio!"),
		parroquia_id: Yup.string().required("¡Campo obligatorio!"),
		telefono_movil: Yup.string().required("¡Campo obligatorio!").matches(onlyNumers, "Valor fuera de rango, solo se permiten numeros").matches(onlyTelefono, "Teléfono móvil no valido"),
	})

	const obtenerEstados = useCallback( async () => {
		try {
			const res = await getEstados()
			const { data } = res.data

			const payload = data.map( edo => ({
				label: upperFirst(edo.nombre.toLowerCase()),
				value: edo.id
			}))

			setEdos(payload)
		} catch(err) {
			//console.log(err)
		}
	}, [])

	const obtenerMunicipios = useCallback( async (e, hdlchng) => {
		try {
			hdlchng(e)
			const { value } = e.target

			const res = await getMunicipios(value)
			const { data } = res.data
			
			const payload = data.map( mun => ({
				label: upperFirst(mun.nombre.toLowerCase()),
				value: mun.id
			}))
			
			setMuns(payload)
		} catch(err) {
			//console.log(err)
		}
	}, [])

	const obtenerParroquias = useCallback( async (e, hdlchng) => {
		try {
			hdlchng(e)
			const { value } = e.target

			const res = await getParroquias(value)
			const { data } = res.data

			const payload = data.map( paq => ({
				label: upperFirst(paq.nombre.toLowerCase()),
				value: paq.id
			}))

			setPaqs(payload)
		} catch(err) {
			//console.log(err)
		}
	}, [])

	React.useEffect( () => {
		obtenerEstados()
	}, [obtenerEstados])

	return (
		<Formik
			initialValues={initial}
            onSubmit={onSubmit}
            validationSchema={formSchemas}
          >
          {({errors, touched, values, handleChange, handleBlur, handleSubmit}) => (
          	<form onSubmit={handleSubmit} style={{width:"100%"}}> 
          		<Grow in={true} timeout={1000}>
				<Grid container spacing={3}>
			
					{/* estado */}
		            <Grid item sm={12} md={4}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="estado"
	                		name="estado"
	                		value={values.estado}
							onChange={(e) => obtenerMunicipios(e,handleChange)}
							onBlur={handleBlur}
	                		label="Estado (*)"
		                    items={estados}
	                		helpertext={<ErrorMessage name="estado"/>}
	                		error={errors.estado && touched.estado} />
		            </Grid>
		    
		            {/* municipio */}
		            <Grid item sm={12} md={4}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="municipio"
	                		name="municipio"
	                		value={values.municipio}
							onChange={(e) => obtenerParroquias(e,handleChange)}
							onBlur={handleBlur}
	                		label="Municipio (*)"
		                    items={municipios}
	                		helpertext={<ErrorMessage name="municipio"/>}
	                		error={errors.municipio && touched.municipio} />
		            </Grid>
		    
		            {/* parroquia */}
		            <Grid item sm={12} md={4}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="parroquia_id"
	                		name="parroquia_id"
	                		value={values.parroquia_id}
							onChange={handleChange}
							onBlur={handleBlur}
	                		label="Parroquia (*)"
		                    items={parroquias}
	                		helpertext={<ErrorMessage name="parroquia_id"/>}
	                		error={errors.parroquia_id && touched.parroquia_id} />
		            </Grid>


		        	{/* direccion_habitacional */}
		            <Grid item sm={6}>
		            	<AppField
							id="direccion_habitacional"
							name="direccion_habitacional"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.direccion_habitacional}
							label="Dirección de habitación (*)"
							helperText={<ErrorMessage name="direccion_habitacional"/>}
							error={errors.direccion_habitacional && touched.direccion_habitacional}
							InputProps={{
								endAdornment:
								<InputAdornment position="end">
									<Tooltip 
										title="Solo se permite ingresar letras, números y caractéres como NUMERALES (#), PUNTOS (.) y COMAS (,)">
										<HelpIcon />
									</Tooltip>
								</InputAdornment>
							}}
						/>
		            </Grid>

								<Grid item sm={6}>
								<AppField
							id="telefono_movil"
							name="telefono_movil"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.telefono_movil}
							label="Telefono móvil (*)"
							helperText={<ErrorMessage name="telefono_movil"/>}
							error={errors.telefono_movil && touched.telefono_movil}
							InputProps={{
								endAdornment:
								<InputAdornment position="end">
									<Tooltip 
										title="Solo se permite ingresar números">
										<HelpIcon />
									</Tooltip>
								</InputAdornment>
							}}
						/>
								</Grid>
			
			        <Grid item sm={12} md={12}> {ActionsButtons} </Grid>
       			</Grid>
          		</Grow>
          	</form>
          )}
        </Formik>
	)
}

export default React.memo(DatosDomicilio)
