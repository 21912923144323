import React from "react";

const mujerCursos = () => {
  return (
    <svg viewBox="0 0 1291 635" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M666.922 21.1799C672.771 21.1799 677.511 16.4386 677.511 10.5899C677.511 4.74128 672.771 0 666.922 0C661.074 0 656.333 4.74128 656.333 10.5899C656.333 16.4386 661.074 21.1799 666.922 21.1799Z"
        fill="#941B80"
      />
      <path
        d="M666.922 63.3853C672.771 63.3853 677.511 58.6441 677.511 52.7954C677.511 46.9467 672.771 42.2054 666.922 42.2054C661.074 42.2054 656.333 46.9467 656.333 52.7954C656.333 58.6441 661.074 63.3853 666.922 63.3853Z"
        fill="#941B80"
      />
      <path
        d="M711.132 21.1799C716.981 21.1799 721.721 16.4386 721.721 10.5899C721.721 4.74128 716.981 0 711.132 0C705.284 0 700.543 4.74128 700.543 10.5899C700.543 16.4386 705.284 21.1799 711.132 21.1799Z"
        fill="#941B80"
      />
      <path
        d="M711.132 63.3853C716.981 63.3853 721.721 58.6441 721.721 52.7954C721.721 46.9467 716.981 42.2054 711.132 42.2054C705.284 42.2054 700.543 46.9467 700.543 52.7954C700.543 58.6441 705.284 63.3853 711.132 63.3853Z"
        fill="#941B80"
      />
      <path
        d="M755.343 21.1799C761.191 21.1799 765.932 16.4386 765.932 10.5899C765.932 4.74128 761.191 0 755.343 0C749.495 0 744.754 4.74128 744.754 10.5899C744.754 16.4386 749.495 21.1799 755.343 21.1799Z"
        fill="#941B80"
      />
      <path
        d="M755.343 63.3853C761.191 63.3853 765.932 58.6441 765.932 52.7954C765.932 46.9467 761.191 42.2054 755.343 42.2054C749.495 42.2054 744.754 46.9467 744.754 52.7954C744.754 58.6441 749.495 63.3853 755.343 63.3853Z"
        fill="#941B80"
      />
      <path
        d="M755.343 105.591C761.191 105.591 765.932 100.85 765.932 95.0008C765.932 89.1522 761.191 84.4109 755.343 84.4109C749.495 84.4109 744.754 89.1522 744.754 95.0008C744.754 100.85 749.495 105.591 755.343 105.591Z"
        fill="#941B80"
      />
      <path
        d="M755.343 147.796C761.191 147.796 765.932 143.055 765.932 137.206C765.932 131.358 761.191 126.616 755.343 126.616C749.495 126.616 744.754 131.358 744.754 137.206C744.754 143.055 749.495 147.796 755.343 147.796Z"
        fill="#941B80"
      />
      <path
        d="M799.553 21.1799C805.401 21.1799 810.142 16.4386 810.142 10.5899C810.142 4.74128 805.401 0 799.553 0C793.705 0 788.964 4.74128 788.964 10.5899C788.964 16.4386 793.705 21.1799 799.553 21.1799Z"
        fill="#941B80"
      />
      <path
        d="M799.553 63.3853C805.401 63.3853 810.142 58.6441 810.142 52.7954C810.142 46.9467 805.401 42.2054 799.553 42.2054C793.705 42.2054 788.964 46.9467 788.964 52.7954C788.964 58.6441 793.705 63.3853 799.553 63.3853Z"
        fill="#941B80"
      />
      <path
        d="M799.553 105.591C805.401 105.591 810.142 100.85 810.142 95.0008C810.142 89.1522 805.401 84.4109 799.553 84.4109C793.705 84.4109 788.964 89.1522 788.964 95.0008C788.964 100.85 793.705 105.591 799.553 105.591Z"
        fill="#941B80"
      />
      <path
        d="M799.553 147.796C805.401 147.796 810.142 143.055 810.142 137.206C810.142 131.358 805.401 126.616 799.553 126.616C793.705 126.616 788.964 131.358 788.964 137.206C788.964 143.055 793.705 147.796 799.553 147.796Z"
        fill="#941B80"
      />
      <path
        d="M843.763 21.1799C849.611 21.1799 854.352 16.4386 854.352 10.5899C854.352 4.74128 849.611 0 843.763 0C837.915 0 833.174 4.74128 833.174 10.5899C833.174 16.4386 837.915 21.1799 843.763 21.1799Z"
        fill="#941B80"
      />
      <path
        d="M843.763 63.3853C849.611 63.3853 854.352 58.6441 854.352 52.7954C854.352 46.9467 849.611 42.2054 843.763 42.2054C837.915 42.2054 833.174 46.9467 833.174 52.7954C833.174 58.6441 837.915 63.3853 843.763 63.3853Z"
        fill="#941B80"
      />
      <path
        d="M887.973 21.1799C893.821 21.1799 898.562 16.4386 898.562 10.5899C898.562 4.74128 893.821 0 887.973 0C882.125 0 877.384 4.74128 877.384 10.5899C877.384 16.4386 882.125 21.1799 887.973 21.1799Z"
        fill="#941B80"
      />
      <path
        d="M887.973 63.3853C893.821 63.3853 898.562 58.6441 898.562 52.7954C898.562 46.9467 893.821 42.2054 887.973 42.2054C882.125 42.2054 877.384 46.9467 877.384 52.7954C877.384 58.6441 882.125 63.3853 887.973 63.3853Z"
        fill="#941B80"
      />
      <path
        d="M1211.24 451.833H1132.62C1132.62 454.092 1051.61 455.926 1051.61 455.926C1050.33 456.884 1049.17 457.983 1048.15 459.202C1045.07 462.872 1043.39 467.512 1043.4 472.302V475.577C1043.4 481.006 1045.56 486.211 1049.4 490.049C1053.24 493.888 1058.44 496.045 1063.87 496.047H1211.24C1216.66 496.042 1221.87 493.884 1225.7 490.046C1229.54 486.209 1231.7 481.005 1231.7 475.577V472.302C1231.7 466.876 1229.54 461.673 1225.7 457.836C1221.86 453.999 1216.66 451.84 1211.24 451.833V451.833Z"
        fill="#3F3D56"
      />
      <path
        d="M1149.01 495.234H1127.72V632.789H1149.01V495.234Z"
        fill="#3F3D56"
      />
      <path
        d="M1209.59 632.041C1209.59 634.341 1177.33 632.931 1137.54 632.931C1097.75 632.931 1065.5 634.341 1065.5 632.041C1065.5 629.741 1097.75 611.501 1137.54 611.501C1177.33 611.501 1209.59 629.741 1209.59 632.041Z"
        fill="#3F3D56"
      />
      <path
        d="M1031.1 613.949L1011.02 613.948L1001.47 536.51L1031.1 536.512L1031.1 613.949Z"
        fill="#FFB8B8"
      />
      <path
        d="M996.684 608.212H1035.4V632.59H972.308C972.308 626.124 974.876 619.924 979.447 615.352C984.019 610.78 990.219 608.212 996.684 608.212V608.212Z"
        fill="#2F2E41"
      />
      <path
        d="M999.761 602.743L980.608 596.732L994.677 519.987L1022.94 528.86L999.761 602.743Z"
        fill="#FFB8B8"
      />
      <path
        d="M968.644 586.966L1005.58 598.559L998.284 621.819L938.088 602.926C939.046 599.872 940.597 597.036 942.651 594.581C944.705 592.126 947.223 590.099 950.06 588.617C952.898 587.135 955.999 586.226 959.188 585.943C962.376 585.66 965.59 586.007 968.644 586.966V586.966Z"
        fill="#2F2E41"
      />
      <path
        d="M1157.47 438.409C1157.01 434.507 1156.18 430.658 1154.98 426.913L1151.24 422.197L1141.48 409.948L1141.05 409.965C1140.33 409.997 1137.33 410.161 1132.7 410.521H1132.65C1129.78 410.751 1126.28 411.045 1122.3 411.422C1121.25 411.537 1120.16 411.635 1119.03 411.75V428.846H1139.5C1140.49 428.847 1141.45 429.21 1142.2 429.867C1142.94 430.523 1143.43 431.429 1143.56 432.416C1143.57 432.596 1143.59 432.759 1143.59 432.94C1143.59 434.025 1143.16 435.066 1142.39 435.834C1141.62 436.602 1140.58 437.033 1139.5 437.034H1020.62C1019.1 438.039 1017.65 439.133 1016.26 440.309C1012.07 443.551 1009.06 448.069 1007.67 453.18C1000.72 483.704 988.067 540.626 984.333 557.345C984.063 558.605 984.124 559.914 984.512 561.142C984.899 562.371 985.6 563.478 986.544 564.354C987.241 565.004 988.059 565.51 988.951 565.844L1003.72 571.379C1004.33 571.604 1004.96 571.752 1005.6 571.822C1005.69 571.822 1005.75 571.838 1005.83 571.838C1005.99 571.852 1006.14 571.857 1006.29 571.854C1007.78 571.848 1009.24 571.392 1010.47 570.545C1011.7 569.697 1012.65 568.499 1013.18 567.105L1024.84 536.516L1042.81 489.354L1049.91 470.702C1058.15 472.634 1127.25 487.929 1150.38 463.284C1152.67 460.856 1154.47 458.016 1155.69 454.916C1157.64 449.645 1158.25 443.974 1157.47 438.409Z"
        fill="#2F2E41"
      />
      <path
        d="M1177.52 442.863C1177.16 441.406 1176.75 439.899 1176.27 438.376L1162.76 421.411L1162.34 421.427C1161.49 421.477 1157.44 421.69 1151.24 422.197C1144.19 422.787 1134.35 423.736 1123.3 425.227C1122.97 425.259 1122.63 425.309 1122.3 425.358C1121.22 425.505 1120.12 425.652 1119.03 425.816V428.846H1139.5C1140.49 428.847 1141.45 429.21 1142.2 429.867C1142.94 430.523 1143.43 431.429 1143.56 432.416C1143.57 432.596 1143.59 432.759 1143.59 432.94C1143.59 434.025 1143.16 435.066 1142.39 435.834C1141.62 436.602 1140.58 437.033 1139.5 437.034H1067.15C1063.99 438.065 1060.95 439.146 1058.03 440.309C1042.74 446.368 1031.29 454.31 1028.95 464.643C1028.17 468.114 1027.3 471.93 1026.38 475.991C1022.98 491.089 1018.83 509.578 1015.08 526.379V526.395C1014.31 529.867 1013.55 533.24 1012.81 536.516C1009.68 550.599 1007.03 562.536 1005.62 568.808C1005.59 568.988 1005.56 569.152 1005.52 569.332V569.365C1005.36 570.634 1005.52 571.923 1005.98 573.115L1006 573.131V573.148C1006.45 574.289 1007.18 575.298 1008.13 576.079C1008.76 576.601 1009.47 577.016 1010.24 577.307L1025.01 582.842C1025.83 583.15 1026.7 583.31 1027.58 583.317C1028.81 583.32 1030.02 583.01 1031.1 582.416C1032.65 581.578 1033.84 580.213 1034.47 578.568L1066.27 495.118L1071.2 482.165C1079.43 484.097 1148.53 499.376 1171.67 474.747C1176.24 469.799 1178.85 463.353 1179.01 456.619C1179.17 451.986 1178.67 447.354 1177.52 442.863V442.863Z"
        fill="#2F2E41"
      />
      <path
        d="M1175.7 298.397C1175.44 298.086 1175.16 297.775 1174.86 297.48C1170.5 292.895 1165.12 289.408 1159.15 287.304C1153.18 285.201 1146.8 284.541 1140.53 285.379C1135.05 286.097 1129.63 287.164 1124.3 288.572C1117.41 290.344 1111.1 293.883 1105.99 298.839C1102.02 302.696 1098.88 307.336 1096.8 312.466C1094.71 317.597 1093.71 323.106 1093.86 328.643C1093.93 330.887 1093.94 333.179 1093.91 335.504C1093.91 336.585 1093.89 337.682 1093.88 338.78H1110.02C1110.72 338.778 1111.42 338.86 1112.1 339.025C1114.07 339.496 1115.83 340.616 1117.08 342.206C1118.34 343.795 1119.02 345.76 1119.03 347.786V423.966C1120.12 424.277 1121.22 424.588 1122.3 424.916C1122.65 425.03 1122.97 425.128 1123.3 425.227C1123.68 425.341 1124.05 425.456 1124.41 425.57C1127.74 426.602 1130.99 427.694 1134.16 428.846H1139.49C1140.49 428.847 1141.45 429.21 1142.2 429.866C1142.94 430.523 1143.43 431.429 1143.56 432.415C1144.67 432.858 1145.75 433.3 1146.81 433.742C1150.6 435.314 1154.18 436.886 1157.47 438.409C1161.91 440.439 1165.86 442.372 1169.16 444.059C1170.2 444.589 1171.35 444.869 1172.52 444.877C1174.05 444.872 1175.55 444.379 1176.78 443.469C1177.03 443.28 1177.28 443.078 1177.52 442.863C1178.39 442.042 1179.05 441.018 1179.44 439.883C1179.84 438.747 1179.94 437.533 1179.76 436.346C1178.07 423.482 1177.57 410.49 1178.29 397.535C1178.74 386.646 1179.73 375.477 1181 364.686C1181.69 358.84 1182.46 353.108 1183.26 347.59C1184.03 342.317 1184.85 337.257 1185.65 332.475C1186.71 326.412 1186.36 320.188 1184.63 314.281C1182.9 308.373 1179.85 302.94 1175.7 298.397Z"
        fill="#662583"
      />
      <path
        d="M1108.58 299.74C1107.74 299.379 1106.88 299.078 1105.99 298.839C1101.63 297.664 1096.99 298.098 1092.92 300.061C1088.85 302.024 1085.62 305.386 1083.82 309.533L1072.57 335.505L1071.17 338.78H1110.02C1110.72 338.778 1111.42 338.86 1112.1 339.025L1113.41 335.996L1118.39 324.5C1120.37 319.915 1120.44 314.734 1118.61 310.092C1116.77 305.45 1113.16 301.727 1108.58 299.74V299.74Z"
        fill="#662583"
      />
      <path
        d="M1143.52 272.592C1165.73 272.592 1183.73 254.584 1183.73 232.371C1183.73 210.158 1165.73 192.151 1143.52 192.151C1121.31 192.151 1103.3 210.158 1103.3 232.371C1103.3 254.584 1121.31 272.592 1143.52 272.592Z"
        fill="#FFB8B8"
      />
      <path
        d="M1183.26 347.59L1160.31 348.212L1158.64 364.342L1158.26 368.076L1122.3 384.222L1119.03 385.696V414.402L1122.3 413.469L1132.65 410.521H1132.7L1178.29 397.535L1178.81 397.388C1182.49 396.324 1185.73 394.1 1188.05 391.047C1190.37 387.993 1191.64 384.273 1191.68 380.439L1191.79 361.394L1191.89 347.36L1183.26 347.59Z"
        fill="#FFB8B8"
      />
      <path
        d="M1190.73 315.69C1190.58 313.221 1189.94 310.807 1188.86 308.584C1187.77 306.361 1186.26 304.374 1184.41 302.735C1180.67 299.426 1175.76 297.739 1170.78 298.045C1165.79 298.352 1161.13 300.626 1157.82 304.368C1154.52 308.11 1152.83 313.013 1153.14 317.999L1155.64 358.873C1155.71 359.948 1156.01 360.996 1156.53 361.941C1157.05 362.886 1157.77 363.706 1158.64 364.342C1160.02 365.374 1161.73 365.879 1163.45 365.767L1181 364.686L1186.33 364.358C1187.29 364.311 1188.24 364.067 1189.12 363.642C1189.99 363.217 1190.77 362.62 1191.4 361.886C1191.54 361.73 1191.67 361.566 1191.79 361.395C1192.84 360.001 1193.35 358.283 1193.23 356.547L1190.73 315.69Z"
        fill="#662583"
      />
      <path
        d="M1124.41 425.571H1122.3V425.358C1121.22 425.505 1120.12 425.652 1119.03 425.816V428.846H1134.16C1130.98 427.699 1127.73 426.608 1124.41 425.571ZM1124.41 425.571H1122.3V425.358C1121.22 425.505 1120.12 425.652 1119.03 425.816V428.846H1134.16C1130.98 427.699 1127.73 426.608 1124.41 425.571ZM1139.5 425.571H1122.3V347.786C1122.3 345.123 1121.43 342.534 1119.82 340.408C1118.22 338.282 1115.97 336.733 1113.41 335.996C1112.31 335.664 1111.17 335.499 1110.02 335.505H964.292C961.036 335.508 957.915 336.804 955.612 339.106C953.31 341.408 952.015 344.53 952.011 347.786V428.027C952.012 431.284 953.307 434.407 955.609 436.71C957.912 439.013 961.035 440.308 964.292 440.309H1139.5C1141.31 440.306 1143.06 439.634 1144.41 438.423C1145.76 437.211 1146.61 435.545 1146.81 433.742C1146.85 433.476 1146.86 433.208 1146.86 432.94C1146.86 430.986 1146.08 429.113 1144.7 427.732C1143.32 426.351 1141.45 425.574 1139.5 425.571H1139.5ZM1143.59 432.94C1143.59 434.025 1143.16 435.066 1142.39 435.834C1141.62 436.602 1140.58 437.033 1139.5 437.034H964.292C961.904 437.032 959.614 436.083 957.925 434.394C956.237 432.705 955.287 430.415 955.286 428.027V347.786C955.287 345.398 956.237 343.108 957.925 341.419C959.614 339.73 961.904 338.781 964.292 338.78H1110.02C1110.72 338.778 1111.42 338.86 1112.1 339.025C1114.07 339.496 1115.83 340.616 1117.08 342.205C1118.34 343.795 1119.02 345.76 1119.03 347.786V428.846H1139.5C1140.49 428.847 1141.45 429.21 1142.2 429.867C1142.94 430.523 1143.43 431.429 1143.56 432.416C1143.57 432.596 1143.59 432.759 1143.59 432.94H1143.59ZM1124.41 425.571H1122.3V425.358C1121.22 425.505 1120.12 425.652 1119.03 425.816V428.846H1134.16C1130.98 427.699 1127.73 426.608 1124.41 425.571Z"
        fill="#3F3D56"
      />
      <path
        d="M1037.15 397.734C1042.58 397.734 1046.98 393.335 1046.98 387.909C1046.98 382.483 1042.58 378.084 1037.15 378.084C1031.73 378.084 1027.33 382.483 1027.33 387.909C1027.33 393.335 1031.73 397.734 1037.15 397.734Z"
        fill="#941B80"
      />
      <path
        d="M1134.93 216.417C1125.59 224.763 1113.06 233.72 1101.63 227.606C1094.12 223.591 1090.77 214.787 1092.53 206.453C1095.68 191.465 1108.64 183.367 1121.18 176.821C1137.49 168.311 1155.48 160.89 1173.74 163.102C1192 165.315 1209.84 180.405 1208.98 198.779C1208.29 213.556 1196.22 227.341 1200.04 241.632C1203.88 256.016 1221.34 261.585 1236.21 262.317C1251.08 263.049 1267.44 261.781 1279.24 270.864C1294.29 282.451 1294.05 306.681 1283.33 322.364C1272.62 338.047 1254.53 346.871 1236.77 353.58C1213.23 362.468 1187.35 368.89 1163.06 362.356C1138.76 355.821 1117.49 332.508 1121.05 307.598C1122.56 297.076 1128.09 287.631 1133.43 278.441C1138.77 269.252 1144.15 259.671 1145.27 249.101C1146.2 240.293 1143.41 230.57 1136.91 224.896C1135.73 223.908 1134.9 222.573 1134.53 221.082C1134.16 219.591 1134.28 218.021 1134.85 216.598L1134.93 216.417Z"
        fill="#2F2E41"
      />
      <path
        d="M2.94944 635H1269.15C1269.67 635 1270.17 634.795 1270.53 634.429C1270.9 634.063 1271.1 633.567 1271.1 633.05C1271.1 632.533 1270.9 632.037 1270.53 631.672C1270.17 631.306 1269.67 631.1 1269.15 631.1H2.94944C2.69293 631.1 2.43881 631.15 2.20161 631.247C1.96442 631.345 1.74884 631.488 1.5672 631.67C1.38555 631.851 1.24143 632.066 1.1431 632.303C1.04477 632.54 0.994141 632.794 0.994141 633.05C0.994141 633.307 1.04477 633.561 1.1431 633.798C1.24143 634.035 1.38555 634.25 1.5672 634.431C1.74884 634.612 1.96442 634.756 2.20161 634.853C2.43881 634.951 2.69293 635.001 2.94944 635V635Z"
        fill="#3F3D56"
      />
      <path
        d="M67.2007 472.302V475.577C67.2054 481.005 69.3633 486.209 73.2007 490.046C77.0381 493.884 82.2414 496.042 87.6684 496.047H235.036C240.463 496.045 245.668 493.888 249.506 490.049C253.344 486.211 255.501 481.006 255.503 475.577V472.302C255.514 467.512 253.832 462.872 250.754 459.202C249.73 457.983 248.571 456.884 247.299 455.926C247.299 455.926 166.28 454.092 166.28 451.833H87.6684C82.2423 451.84 77.0406 453.999 73.2038 457.836C69.367 461.673 67.2082 466.876 67.2007 472.302V472.302Z"
        fill="#3F3D56"
      />
      <path
        d="M89.3135 632.041C89.3135 634.341 121.57 632.931 161.36 632.931C201.15 632.931 233.406 634.341 233.406 632.041C233.406 629.741 201.15 611.501 161.36 611.501C121.57 611.501 89.3135 629.741 89.3135 632.041Z"
        fill="#3F3D56"
      />
      <path
        d="M268.766 615.195H288.841L298.391 537.758L268.763 537.76L268.766 615.195Z"
        fill="#A0616A"
      />
      <path
        d="M327.557 633.836L264.466 633.838L264.465 609.46L303.18 609.459C309.644 609.458 315.845 612.026 320.416 616.598C324.988 621.17 327.556 627.37 327.557 633.836L327.557 633.836Z"
        fill="#2F2E41"
      />
      <path
        d="M300.103 603.991L319.256 597.98L305.187 521.235L276.919 530.108L300.103 603.991Z"
        fill="#A0616A"
      />
      <path
        d="M361.776 604.174L301.58 623.066L294.281 599.806L331.22 588.213C337.388 586.277 344.073 586.871 349.803 589.864C355.534 592.858 359.841 598.005 361.776 604.174L361.776 604.174Z"
        fill="#2F2E41"
      />
      <path
        d="M286.673 568.35L249.95 471.944C241.712 473.879 172.621 489.169 149.48 464.529C141.55 456.084 140.003 443.848 144.883 428.162L158.388 411.19L158.807 411.212C163.914 411.481 283.964 418.21 292.193 454.425C299.132 484.957 311.804 541.88 315.522 558.599C315.899 560.332 315.641 562.142 314.795 563.7C313.949 565.258 312.572 566.46 310.914 567.088L296.146 572.626C295.323 572.936 294.451 573.094 293.572 573.094C292.077 573.094 290.617 572.641 289.386 571.794C288.154 570.946 287.208 569.746 286.673 568.35V568.35Z"
        fill="#2F2E41"
      />
      <path
        d="M265.387 579.813L228.664 483.407C220.425 485.342 151.335 500.631 128.194 475.992C120.264 467.547 118.717 455.311 123.597 439.625L137.102 422.653L137.521 422.675C142.627 422.944 262.678 429.673 270.907 465.888C277.846 496.42 290.518 553.343 294.236 570.062C294.613 571.795 294.355 573.605 293.509 575.163C292.663 576.721 291.286 577.923 289.628 578.551L274.86 584.089C274.037 584.399 273.165 584.557 272.286 584.557C270.791 584.557 269.331 584.104 268.099 583.256C266.868 582.409 265.922 581.209 265.387 579.813V579.813Z"
        fill="#2F2E41"
      />
      <path
        d="M232.354 392.774C226.912 392.772 221.563 391.356 216.831 388.666C212.1 385.976 208.148 382.103 205.362 377.427L193.7 357.668L221.42 342.516L233.19 358.656L313.053 350.241C313.442 349.538 313.879 348.863 314.361 348.22C315.894 346.204 317.834 344.532 320.055 343.315C322.276 342.098 324.729 341.362 327.253 341.156C329.789 340.938 332.342 341.266 334.74 342.117C337.139 342.968 339.327 344.322 341.159 346.089C344.096 348.905 345.995 352.632 346.548 356.664C347.101 360.696 346.275 364.796 344.205 368.3C342.909 370.483 341.168 372.367 339.093 373.83C337.019 375.293 334.659 376.301 332.168 376.788C329.677 377.275 327.111 377.231 324.639 376.657C322.166 376.083 319.843 374.994 317.82 373.46C317.352 373.101 316.902 372.718 316.473 372.313L239.982 391.817C237.489 392.453 234.927 392.774 232.354 392.774V392.774Z"
        fill="#A0616A"
      />
      <path
        d="M123.078 444.715C121.973 443.919 121.109 442.832 120.584 441.575C120.058 440.318 119.891 438.94 120.1 437.594C124.656 407.557 119.414 364.527 114.214 333.719C113.149 327.468 113.564 321.055 115.423 314.993C117.283 308.931 120.536 303.388 124.923 298.81C129.31 294.232 134.707 290.744 140.684 288.627C146.661 286.511 153.05 285.823 159.34 286.62C164.813 287.343 170.234 288.412 175.571 289.82V289.82C184.437 292.053 192.28 297.236 197.81 304.518C203.339 311.8 206.227 320.747 205.998 329.887C205.22 359.616 211.256 396.709 213.888 411.276C214.067 412.277 214.039 413.304 213.804 414.293C213.569 415.282 213.133 416.212 212.522 417.025C211.924 417.83 211.164 418.501 210.291 418.994C209.418 419.488 208.452 419.793 207.454 419.891C178.295 422.867 146.849 437.089 130.701 445.301C129.663 445.836 128.513 446.117 127.345 446.121C125.81 446.116 124.316 445.624 123.078 444.715Z"
        fill="#CCCCCC"
      />
      <path
        d="M201.789 367.24C200.888 366.886 200.066 366.356 199.371 365.682C198.676 365.008 198.121 364.203 197.739 363.314L181.477 325.744C180.493 323.474 179.965 321.032 179.924 318.558C179.884 316.084 180.331 313.627 181.241 311.326C182.15 309.025 183.505 306.925 185.226 305.148C186.947 303.371 189.002 301.95 191.272 300.967C193.543 299.984 195.985 299.459 198.458 299.42C200.932 299.382 203.389 299.831 205.689 300.743C207.989 301.654 210.087 303.01 211.863 304.733C213.639 306.456 215.058 308.512 216.039 310.784L232.301 348.353C233.074 350.147 233.105 352.174 232.386 353.991C231.667 355.807 230.258 357.264 228.466 358.043L207.429 367.15C206.541 367.537 205.586 367.745 204.618 367.76C203.65 367.775 202.689 367.598 201.79 367.24H201.789Z"
        fill="#CCCCCC"
      />
      <path
        d="M156.347 273.839C178.558 273.839 196.564 255.832 196.564 233.619C196.564 211.406 178.558 193.398 156.347 193.398C134.136 193.398 116.13 211.406 116.13 233.619C116.13 255.832 134.136 273.839 156.347 273.839Z"
        fill="#A0616A"
      />
      <path
        d="M132.043 269.352C131.474 270.009 130.778 270.546 129.997 270.93C129.216 271.314 128.367 271.538 127.498 271.588C126.629 271.638 125.759 271.513 124.94 271.221C124.12 270.929 123.368 270.475 122.726 269.887C113.759 262.627 106.646 253.338 101.976 242.787C96.7481 230.324 95.9819 215.114 101.932 203.432C106.962 193.556 115.992 187.269 125.007 182.131C128.462 179.954 132.249 178.353 136.219 177.392C138.224 176.937 140.303 176.906 142.32 177.303C144.338 177.7 146.25 178.515 147.933 179.695C149.72 181.054 151.102 182.874 151.932 184.959C152.763 187.044 153.009 189.316 152.646 191.531C156.769 186.098 162.328 181.926 168.696 179.484C172.603 177.802 176.968 177.51 181.065 178.655C186.854 180.588 191.139 186.434 193.523 192.715C195.452 197.524 196.158 202.737 195.579 207.885C195.327 210.344 194.577 212.725 193.375 214.885C192.173 217.045 190.545 218.937 188.589 220.448C183.867 223.706 177.783 223.375 172.291 221.933C166.8 220.491 161.47 218.046 155.882 217.364C150.295 216.682 144.102 218.16 140.773 223.144C138.429 226.654 137.876 231.278 137.398 235.685L134.91 258.609C134.734 261.889 134.028 265.118 132.818 268.172C132.613 268.598 132.353 268.994 132.043 269.352V269.352Z"
        fill="#2F2E41"
      />
      <path
        d="M277.507 517.066H212.22C211.162 517.07 210.117 516.832 209.165 516.37C208.213 515.909 207.378 515.236 206.726 514.403C206.058 513.559 205.592 512.574 205.363 511.523C205.134 510.472 205.148 509.382 205.404 508.337C211.511 482.755 211.511 456.094 205.404 430.511C205.148 429.466 205.134 428.377 205.363 427.325C205.592 426.274 206.058 425.289 206.726 424.445C207.378 423.612 208.213 422.94 209.165 422.478C210.117 422.017 211.162 421.778 212.22 421.782H277.507C279.176 421.785 280.789 422.381 282.059 423.462C283.329 424.543 284.175 426.04 284.445 427.686C288.704 455.349 288.704 483.5 284.445 511.162C284.175 512.808 283.329 514.305 282.059 515.386C280.789 516.468 279.176 517.063 277.507 517.066V517.066Z"
        fill="#CCCCCC"
      />
      <path
        d="M227.398 457.279V455.36C227.399 454.057 227.917 452.808 228.838 451.887C229.759 450.966 231.008 450.448 232.31 450.447H266.571C267.873 450.448 269.122 450.966 270.043 451.887C270.964 452.808 271.482 454.057 271.483 455.36V457.279C271.482 458.581 270.964 459.83 270.043 460.751C269.122 461.672 267.873 462.19 266.571 462.191H232.31C231.008 462.19 229.759 461.672 228.838 460.751C227.917 459.83 227.399 458.581 227.398 457.279V457.279Z"
        fill="white"
      />
      <path
        d="M227.398 477.494V475.574C227.399 474.272 227.917 473.023 228.838 472.102C229.759 471.181 231.008 470.663 232.31 470.661H266.571C267.873 470.663 269.122 471.181 270.043 472.102C270.964 473.023 271.482 474.272 271.483 475.574V477.494C271.482 478.796 270.964 480.045 270.043 480.966C269.122 481.887 267.873 482.405 266.571 482.407H232.31C231.008 482.405 229.759 481.887 228.838 480.966C227.917 480.045 227.399 478.796 227.398 477.494Z"
        fill="white"
      />
      <path
        d="M248.459 398.747C247.781 397.889 247.308 396.888 247.075 395.819C246.842 394.75 246.856 393.643 247.116 392.58C253.323 366.578 253.323 339.479 247.115 313.476C246.856 312.414 246.842 311.306 247.075 310.237C247.308 309.169 247.781 308.167 248.459 307.309C249.123 306.463 249.971 305.78 250.939 305.311C251.907 304.842 252.968 304.6 254.044 304.603H320.404C322.099 304.606 323.739 305.211 325.03 306.31C326.322 307.409 327.181 308.931 327.455 310.605C331.784 338.721 331.784 367.335 327.455 395.452C327.181 397.125 326.322 398.647 325.03 399.746C323.739 400.845 322.099 401.45 320.404 401.453H254.044C252.968 401.457 251.906 401.215 250.939 400.746C249.971 400.277 249.123 399.593 248.459 398.747Z"
        fill="#941B80"
      />
      <path
        d="M307.598 340.338H272.64C271.337 340.336 270.089 339.818 269.168 338.897C268.247 337.976 267.729 336.727 267.728 335.425V333.339C267.729 332.036 268.247 330.788 269.168 329.867C270.089 328.946 271.337 328.428 272.64 328.426H307.598C308.9 328.428 310.149 328.946 311.069 329.867C311.99 330.788 312.508 332.036 312.51 333.339V335.425C312.508 336.727 311.99 337.976 311.069 338.897C310.149 339.818 308.9 340.336 307.598 340.338V340.338Z"
        fill="white"
      />
      <path
        d="M307.598 360.885H272.64C271.337 360.883 270.089 360.365 269.168 359.444C268.247 358.523 267.729 357.275 267.728 355.972V353.886C267.729 352.583 268.247 351.335 269.168 350.414C270.089 349.493 271.337 348.975 272.64 348.973H307.598C308.9 348.975 310.149 349.493 311.069 350.414C311.99 351.335 312.508 352.583 312.51 353.886V355.972C312.508 357.275 311.99 358.523 311.069 359.444C310.149 360.365 308.9 360.883 307.598 360.885V360.885Z"
        fill="white"
      />
      <path
        d="M218.012 434.771C215.621 433.935 213.432 432.605 211.589 430.868C209.746 429.131 208.289 427.025 207.313 424.687C207.088 424.141 206.891 423.584 206.725 423.018L121.052 398.63C117.371 397.567 114.13 395.345 111.811 392.294C109.492 389.243 108.219 385.525 108.18 381.693L107.971 348.611L139.55 349.457L141.608 369.327L214.867 402.221C215.557 401.809 216.274 401.445 217.015 401.133H217.015C219.714 400.001 222.647 399.543 225.562 399.799C228.477 400.055 231.286 401.017 233.746 402.601C236.206 404.185 238.244 406.345 239.683 408.893C241.122 411.441 241.919 414.301 242.006 417.226C242.093 420.151 241.466 423.053 240.18 425.682C238.895 428.311 236.988 430.587 234.626 432.314C232.264 434.041 229.517 435.167 226.622 435.595C223.728 436.023 220.773 435.74 218.012 434.771V434.771Z"
        fill="#A0616A"
      />
      <path
        d="M476.705 250.652H342.437C338.53 250.647 334.784 249.093 332.021 246.33C329.259 243.567 327.705 239.821 327.7 235.914V90.1706C327.705 86.2632 329.259 82.5171 332.021 79.7542C334.784 76.9912 338.53 75.437 342.437 75.4325H476.705C480.612 75.437 484.358 76.9912 487.12 79.7542C489.883 82.5171 491.437 86.2632 491.442 90.1706V235.914C491.437 239.821 489.883 243.567 487.12 246.33C484.358 249.093 480.612 250.647 476.705 250.652V250.652ZM342.437 78.7077C339.398 78.7111 336.485 79.9199 334.336 82.0688C332.187 84.2178 330.978 87.1315 330.975 90.1706V235.914C330.978 238.953 332.187 241.867 334.336 244.016C336.485 246.165 339.398 247.373 342.437 247.377H476.705C479.744 247.373 482.657 246.165 484.806 244.016C486.955 241.867 488.163 238.953 488.167 235.914V90.1706C488.163 87.1315 486.955 84.2178 484.806 82.0688C482.657 79.9199 479.744 78.7111 476.705 78.7077H342.437Z"
        fill="#3F3D56"
      />
      <path
        d="M722.317 250.652H588.049C584.142 250.647 580.396 249.093 577.634 246.33C574.871 243.567 573.317 239.821 573.312 235.914V90.1706C573.317 86.2632 574.871 82.5171 577.634 79.7542C580.396 76.9912 584.142 75.437 588.049 75.4325H722.317C726.224 75.437 729.97 76.9912 732.733 79.7542C735.495 82.5171 737.049 86.2632 737.054 90.1706V235.914C737.049 239.821 735.495 243.567 732.733 246.33C729.97 249.093 726.224 250.647 722.317 250.652V250.652ZM588.049 78.7077C585.01 78.7111 582.097 79.9199 579.948 82.0688C577.799 84.2178 576.591 87.1315 576.587 90.1706V235.914C576.591 238.953 577.799 241.867 579.948 244.016C582.097 246.165 585.01 247.373 588.049 247.377H722.317C725.356 247.373 728.269 246.165 730.418 244.016C732.567 241.867 733.776 238.953 733.779 235.914V90.1706C733.776 87.1315 732.567 84.2178 730.418 82.0688C728.269 79.9199 725.356 78.7111 722.317 78.7077H588.049Z"
        fill="#3F3D56"
      />
      <path
        d="M369.479 136.022H356.38C354.426 136.02 352.553 135.243 351.172 133.862C349.79 132.48 349.013 130.607 349.011 128.653V115.553C349.013 113.599 349.79 111.726 351.172 110.345C352.553 108.963 354.426 108.186 356.38 108.184H369.479C371.432 108.186 373.305 108.963 374.687 110.345C376.068 111.726 376.845 113.599 376.847 115.553V128.653C376.845 130.607 376.068 132.48 374.687 133.862C373.305 135.243 371.432 136.02 369.479 136.022V136.022Z"
        fill="#662583"
      />
      <path
        d="M369.479 176.962H356.38C354.426 176.959 352.553 176.182 351.172 174.801C349.79 173.419 349.013 171.546 349.011 169.592V156.492C349.013 154.538 349.79 152.665 351.172 151.284C352.553 149.902 354.426 149.125 356.38 149.123H369.479C371.432 149.125 373.305 149.902 374.687 151.284C376.068 152.665 376.845 154.538 376.847 156.492V169.592C376.845 171.546 376.068 173.419 374.687 174.801C373.305 176.182 371.432 176.959 369.479 176.962V176.962Z"
        fill="#CCCCCC"
      />
      <path
        d="M465.219 128.059H404.062C402.76 128.057 401.511 127.539 400.59 126.618C399.669 125.697 399.151 124.449 399.15 123.146V121.06C399.151 119.758 399.669 118.509 400.59 117.588C401.511 116.667 402.76 116.149 404.062 116.147H465.219C466.521 116.149 467.77 116.667 468.69 117.588C469.611 118.509 470.129 119.758 470.131 121.06V123.146C470.129 124.449 469.611 125.697 468.69 126.618C467.77 127.539 466.521 128.057 465.219 128.059V128.059Z"
        fill="#662583"
      />
      <path
        d="M616.221 133.454H603.121C601.168 133.452 599.295 132.675 597.913 131.293C596.532 129.912 595.755 128.039 595.753 126.085V112.984C595.755 111.031 596.532 109.158 597.913 107.776C599.295 106.395 601.168 105.617 603.121 105.615H616.221C618.174 105.617 620.047 106.395 621.428 107.776C622.81 109.158 623.587 111.031 623.589 112.984V126.085C623.587 128.039 622.81 129.912 621.428 131.293C620.047 132.675 618.174 133.452 616.221 133.454V133.454Z"
        fill="#CCCCCC"
      />
      <path
        d="M711.96 125.49H650.804C649.501 125.489 648.253 124.971 647.332 124.05C646.411 123.129 645.893 121.88 645.892 120.578V118.492C645.893 117.189 646.411 115.94 647.332 115.019C648.253 114.098 649.501 113.58 650.804 113.579H711.96C713.263 113.58 714.511 114.098 715.432 115.019C716.353 115.94 716.871 117.189 716.872 118.492V120.578C716.871 121.88 716.353 123.129 715.432 124.05C714.511 124.971 713.263 125.489 711.96 125.49Z"
        fill="#CCCCCC"
      />
      <path
        d="M711.96 164.792H598.406C597.104 164.791 595.855 164.272 594.934 163.351C594.014 162.43 593.496 161.182 593.494 159.879V157.793C593.496 156.491 594.014 155.242 594.934 154.321C595.855 153.4 597.104 152.882 598.406 152.88H711.96C713.262 152.882 714.511 153.4 715.432 154.321C716.353 155.242 716.871 156.491 716.872 157.793V159.879C716.871 161.182 716.353 162.43 715.432 163.351C714.511 164.272 713.262 164.791 711.96 164.792V164.792Z"
        fill="#662583"
      />
      <path
        d="M711.96 192.631H598.406C597.104 192.629 595.855 192.111 594.934 191.19C594.014 190.269 593.496 189.02 593.494 187.718V185.632C593.496 184.329 594.014 183.08 594.934 182.159C595.855 181.238 597.104 180.72 598.406 180.719H711.96C713.262 180.72 714.511 181.238 715.432 182.159C716.353 183.08 716.871 184.329 716.872 185.632V187.718C716.871 189.02 716.353 190.269 715.432 191.19C714.511 192.111 713.262 192.629 711.96 192.631V192.631Z"
        fill="#CCCCCC"
      />
      <path
        d="M711.96 220.469H598.406C597.104 220.468 595.855 219.95 594.934 219.029C594.014 218.108 593.496 216.859 593.494 215.556V213.47C593.496 212.168 594.014 210.919 594.934 209.998C595.855 209.077 597.104 208.559 598.406 208.558H711.96C713.262 208.559 714.511 209.077 715.432 209.998C716.353 210.919 716.871 212.168 716.872 213.47V215.556C716.871 216.859 716.353 218.108 715.432 219.029C714.511 219.95 713.262 220.468 711.96 220.469V220.469Z"
        fill="#CCCCCC"
      />
      <path
        d="M967.93 250.652H833.662C829.754 250.647 826.009 249.093 823.246 246.33C820.483 243.567 818.929 239.821 818.925 235.914V90.1706C818.929 86.2632 820.483 82.5171 823.246 79.7542C826.009 76.9912 829.754 75.437 833.662 75.4325H967.93C971.837 75.437 975.582 76.9912 978.345 79.7542C981.108 82.5171 982.662 86.2632 982.666 90.1706V235.914C982.662 239.821 981.108 243.567 978.345 246.33C975.582 249.093 971.837 250.647 967.93 250.652V250.652ZM833.662 78.7077C830.623 78.7111 827.709 79.9199 825.561 82.0688C823.412 84.2178 822.203 87.1315 822.2 90.1706V235.914C822.203 238.953 823.412 241.867 825.561 244.016C827.709 246.165 830.623 247.373 833.662 247.377H967.93C970.968 247.373 973.882 246.165 976.031 244.016C978.179 241.867 979.388 238.953 979.391 235.914V90.1706C979.388 87.1315 978.179 84.2178 976.031 82.0688C973.882 79.9199 970.968 78.7111 967.93 78.7077H833.662Z"
        fill="#3F3D56"
      />
      <path
        d="M957.573 125.49H896.416C895.114 125.489 893.865 124.971 892.944 124.05C892.023 123.129 891.505 121.88 891.504 120.578V118.492C891.505 117.189 892.023 115.94 892.944 115.019C893.865 114.098 895.114 113.58 896.416 113.579H957.573C958.875 113.58 960.124 114.098 961.044 115.019C961.965 115.94 962.483 117.189 962.485 118.492V120.578C962.483 121.88 961.965 123.129 961.044 124.05C960.124 124.971 958.875 125.489 957.573 125.49V125.49Z"
        fill="#CCCCCC"
      />
      <path
        d="M957.572 164.792H844.019C842.716 164.791 841.468 164.272 840.547 163.351C839.626 162.43 839.108 161.182 839.106 159.879V157.793C839.108 156.491 839.626 155.242 840.547 154.321C841.468 153.4 842.716 152.882 844.019 152.88H957.572C958.875 152.882 960.123 153.4 961.044 154.321C961.965 155.242 962.483 156.491 962.485 157.793V159.879C962.483 161.182 961.965 162.43 961.044 163.351C960.123 164.272 958.875 164.791 957.572 164.792Z"
        fill="#CCCCCC"
      />
      <path
        d="M957.572 192.631H844.019C842.716 192.629 841.468 192.111 840.547 191.19C839.626 190.269 839.108 189.02 839.106 187.718V185.632C839.108 184.329 839.626 183.08 840.547 182.159C841.468 181.238 842.716 180.72 844.019 180.719H957.572C958.875 180.72 960.123 181.238 961.044 182.159C961.965 183.08 962.483 184.329 962.485 185.632V187.718C962.483 189.02 961.965 190.269 961.044 191.19C960.123 192.111 958.875 192.629 957.572 192.631Z"
        fill="#CCCCCC"
      />
      <path
        d="M957.572 220.469H844.019C842.716 220.468 841.468 219.95 840.547 219.029C839.626 218.108 839.108 216.859 839.106 215.556V213.47C839.108 212.168 839.626 210.919 840.547 209.998C841.468 209.077 842.716 208.559 844.019 208.558H957.572C958.875 208.559 960.123 209.077 961.044 209.998C961.965 210.919 962.483 212.168 962.485 213.47V215.556C962.483 216.859 961.965 218.108 961.044 219.029C960.123 219.95 958.875 220.468 957.572 220.469Z"
        fill="#CCCCCC"
      />
      <path
        d="M465.219 168.998H404.062C402.76 168.997 401.511 168.479 400.59 167.558C399.669 166.637 399.151 165.388 399.15 164.085V161.999C399.151 160.697 399.669 159.448 400.59 158.527C401.511 157.606 402.76 157.088 404.062 157.086H465.219C466.521 157.088 467.77 157.606 468.69 158.527C469.611 159.448 470.129 160.697 470.131 161.999V164.085C470.129 165.388 469.611 166.637 468.69 167.558C467.77 168.479 466.521 168.997 465.219 168.998Z"
        fill="#CCCCCC"
      />
      <path
        d="M369.479 217.901H356.38C354.426 217.898 352.553 217.121 351.172 215.74C349.79 214.358 349.013 212.485 349.011 210.532V197.431C349.013 195.477 349.79 193.604 351.172 192.223C352.553 190.841 354.426 190.064 356.38 190.062H369.479C371.432 190.064 373.305 190.841 374.687 192.223C376.068 193.604 376.845 195.477 376.847 197.431V210.532C376.845 212.485 376.068 214.358 374.687 215.74C373.305 217.121 371.432 217.898 369.479 217.901V217.901Z"
        fill="#CCCCCC"
      />
      <path
        d="M465.219 209.937H404.062C402.76 209.936 401.511 209.418 400.59 208.497C399.669 207.576 399.151 206.327 399.15 205.024V202.938C399.151 201.636 399.669 200.387 400.59 199.466C401.511 198.545 402.76 198.027 404.062 198.026H465.219C466.521 198.027 467.77 198.545 468.69 199.466C469.611 200.387 470.129 201.636 470.131 202.938V205.024C470.129 206.327 469.611 207.576 468.69 208.497C467.77 209.418 466.521 209.936 465.219 209.937Z"
        fill="#CCCCCC"
      />
      <path
        d="M869.679 118.009C869.679 117.971 869.685 117.935 869.685 117.897C869.685 115.962 869.303 114.045 868.563 112.257C867.822 110.469 866.737 108.844 865.368 107.476C864 106.107 862.375 105.021 860.587 104.281C858.799 103.54 856.883 103.159 854.948 103.159V118.009H869.679Z"
        fill="#662583"
      />
      <path
        d="M853.31 119.647V104.797C850.4 104.796 847.555 105.656 845.133 107.27C842.712 108.884 840.822 111.179 839.702 113.865C838.583 116.552 838.284 119.509 838.843 122.365C839.402 125.222 840.794 127.848 842.843 129.914C844.893 131.981 847.508 133.394 850.359 133.975C853.211 134.557 856.171 134.281 858.866 133.183C861.561 132.085 863.87 130.214 865.503 127.805C867.136 125.396 868.019 122.557 868.041 119.647H853.31Z"
        fill="#CCCCCC"
      />
      <path
        d="M108.462 363.134C107.819 362.411 107.326 361.566 107.011 360.651C106.696 359.735 106.566 358.766 106.628 357.8L109.14 316.938C109.288 314.467 109.922 312.049 111.005 309.823C112.088 307.597 113.599 305.606 115.453 303.965C117.306 302.324 119.464 301.064 121.805 300.258C124.145 299.452 126.622 299.115 129.093 299.267C131.564 299.419 133.98 300.057 136.204 301.144C138.428 302.231 140.416 303.746 142.054 305.602C143.692 307.458 144.948 309.619 145.75 311.961C146.552 314.303 146.885 316.78 146.729 319.251L144.217 360.113C144.095 362.062 143.204 363.884 141.741 365.178C140.278 366.472 138.361 367.133 136.411 367.015L113.53 365.608C112.564 365.552 111.618 365.304 110.748 364.879C109.878 364.455 109.101 363.862 108.462 363.134H108.462Z"
        fill="#CCCCCC"
      />
      <path
        d="M149.898 632.789H171.184L171.184 495.234H149.898L149.898 632.789Z"
        fill="#3F3D56"
      />
    </svg>
  );
};

export default mujerCursos;
