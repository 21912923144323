// @Vendors
import React from 'react'
import { Helmet } from 'react-helmet';

// @Components
import MenuBar from 'components/Menu'
import Footer from 'components/Footer'
import {
	Container,
	Typography,
	Button
} from "@material-ui/core"
import CachedIcon from '@material-ui/icons/Cached'

import useStyles from 'components/AppFrame/useStyles'
import FAVICO from 'statics/images/logos/logoMujer.jpg'
import useStylesButton from 'pages/Home/secciones/useStyles'

export default function Maintenance({ message = "¡En estos momentos estamos en mantenimiento!", title="Mantenimiento", description = "Esto se debe a posibles cambios realizados en el sistema y/o actualizaciones importantes para mejorar su experienca como usuario.", user = {}}) {
	const { classes } = useStyles()
	const { classes: BotonStyle  } = useStylesButton()
	
	return (
	    <div className={classes.container}>
	      <Helmet>
	        <title>MujeresTI | { title }</title>
	        <link rel="icon" type="image/jpg" href={FAVICO} sizes="64x64" />
	      </Helmet>

	      <div>
	        <MenuBar
	        	user={user}
	          	position='relative'
	          	linksMenu={[]}
	        />
	      </div>

	      <main role='main' className={classes.main}>
	      	<Container maxWidth="lg">
		      	<div className={classes.leftMaintenance}>
			      	<Typography variant="h4" color="primary">
			      		{ message }
			      	</Typography>
			      	
			      	<Typography>
			      		{ description }
			      	</Typography>

			      	<Typography color="secondary">
			      		Puede ingresar más tarde o <strong>refrescar</strong> la página ahora.
			      	</Typography>

			      	<div style={{ maxWidth: "70%"}}>
				      	<Button
				      		className={BotonStyle.Boton}
				      		variant="outlined"
				      		color="primary"
				      		startIcon={<CachedIcon/>}
				      		onClick={() => window.location.reload()}>
				      		Refrescar
				      	</Button>
			      	</div>
		      	</div>

	      	</Container>
	      </main>
	      <Footer />
	    </div>
	)
	
}