// @Vendors
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

// @Material ui
import { 
  Grid,
  Typography,
  Grow,
  Container,
  Button
} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"

// @Icons
//import SkipNextIcon from '@material-ui/icons/SkipNext';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

// @ActionsTypes
import { SET_CEDULADO } from 'state/users/users.actionsTypes'

// @Components
import WrapperForms from './Components/WrapperForms'

// @Hooks
import useSettingsForms from './hooks/useSettingsForms'

//@Stactis
import MujerRegister from "statics/images/textures/MujerRegister"

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "700",
    letterSpacing: "-1.2px",
    marginBottom: "10px"
  },
  boxCircle: {
    width: "200px",
    height: "190px",
    margin: "30px auto 0",
    boxSizing: "border-box",
    boxShadow: "0 7px 7px 0 #e6e6e6",
    padding: "15px",
    borderRadius: "50%",
    "& svg": {
      width: "95%",
      height: "100%"
    }
  },
  mainText: {
    lineHeight: "190%",
    margin: "15px 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify",
    },
  }
}))

function RegisterUser() {
  const clss = useStyles()
  const dispatch = useDispatch()

  const { nextPrev, actualVisible } = useSettingsForms()

  const handleNext = React.useCallback(() => {
    dispatch({ type: SET_CEDULADO, payload: false })
    nextPrev(1)
  }, [dispatch, nextPrev])

  useEffect(() => {
    nextPrev(0)
  }, [nextPrev])

  return (
    <Container>
      <Grid container spacing={4} justify="center" alignItems="flex-start">
        <Grow in={true} timeout={500} >
        <Grid item xs={12}>
            { /* Logo img*/}
            <div className={clss.boxCircle}>
              <MujerRegister />
            </div>
            <br/>
            { /* Title ppal */}
            <Typography variant="h4" align="center" gutterBottom className={clss.title} color="primary">
              Registro
            </Typography>
            <Typography 
              className={clss.mainText}
              variant="body2"
              align="justify"
            >
              En esta sección se solicitarán datos básicos para el registro de las interesadas en la oferta formativa propuesta, así como la pre-inscripción para los cursos disponibles. Es importante resaltar que los cupos varían entre cursos, por lo que la pre-inscripción a un curso no asegura la inscripción al mismo
            </Typography>            
          </Grid>
        </Grow>
        
        <Grid item xs={12}>
         <WrapperForms />
         { actualVisible === 0 && <Button endIcon={<DoubleArrowIcon />} align="center" color="primary" onClick={handleNext}>No poseo cédula</Button> }
        </Grid>
      </Grid>
    </Container>      
  )
}

export default RegisterUser