// @Vendors
import { put, takeLatest, call } from 'redux-saga/effects';

// @services
import {
  getCursosService,
  postAsignarService,
  postCrearCursoService,
  postActualizarCursoService,
  deleteCursoService,
  //getCategoriasService,
  getProfesoresService,
} from './cursos.services';
import { buildErrorsObj } from 'utils/helpers';

// @ActionsTypes

import {
  GET_CURSOS,
  SET_CURSOS,
  ASIGNAR_CURSOS,
  CREATE_CURSO,
  UPDATE_CURSO,
  DELETE_CURSO,
  GET_PROFESORES,
  SET_PROFESORES,
} from './cursos.actionsTypes';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
  REQUEST_FAILURE,
  MODAL_CLOSE,
} from 'state/app/app.actionTypes';

function* getCursosWorker({ payload = '' }) {
  try {
    yield put({ type: REQUEST_STARTED });

    const res = yield call(getCursosService, payload);
    const { data } = res;

    if (data.success) {
      yield put({ type: SET_CURSOS, payload: data.data });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* createCursoWorker({ payload = '' }) {
  try {
    yield put({
      type: REQUEST_STARTED,
    });

    const formData = new FormData();
    const {
      imagen,
      nombre,
      ente_id,
      fecha_fin,
      fecha_inicio,
      fecha_fin_clases,
      fecha_inicio_clases,
      descripcion,
      estatus,
      creado_por,
      capacidad,
      id,
      categorias,
      facilitadores,
    } = payload;

    console.log(payload);
    formData.append('imagen', imagen);
    formData.append('nombre', nombre);
    formData.append('creado_por', creado_por);
    formData.append('estatus', estatus);
    formData.append('ente_id', ente_id);
    formData.append('fecha_fin', fecha_fin);
    formData.append('fecha_inicio', fecha_inicio);
    formData.append('fecha_fin_clases', fecha_fin_clases);
    formData.append('fecha_inicio_clases', fecha_inicio_clases);
    formData.append('capacidad', capacidad);
    formData.append('descripcion', descripcion);
    formData.append('categorias', categorias);
    formData.append('facilitadores', facilitadores);
    formData.append('id', id);

    const res = yield call(postCrearCursoService, formData);

    const { data } = res;
    if (data.success) {
      yield put({
        type: REQUEST_SUCCESS,
        payload: 'Curso actualizado con exito!!',
      });

      yield put({
        type: REQUEST_FINISHED,
      });
      yield put({
        type: MODAL_CLOSE,
      });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* updateCursoWorker({ payload = '' }) {
  try {
    const formData = new FormData();
    const {
      id,
      imagen,
      nombre,
      ente_id,
      fecha_fin,
      fecha_inicio,
      fecha_fin_clases,
      fecha_inicio_clases,
      capacidad,
      descripcion,
      estatus,
      version,
      categorias,
      facilitadores,
    } = payload;
    formData.append('id', id);
    formData.append('imagen', imagen);
    formData.append('nombre', nombre);
    formData.append('ente_id', ente_id);
    formData.append('fecha_fin', fecha_fin);
    formData.append('fecha_inicio', fecha_inicio);
    formData.append('fecha_fin_clases', fecha_fin_clases);
    formData.append('fecha_inicio_clases', fecha_inicio_clases);
    formData.append('capacidad', capacidad);
    formData.append('descripcion', descripcion);
    formData.append('estatus', estatus);
    formData.append('version', version);
    formData.append('categorias', categorias);
    formData.append('facilitadores', facilitadores);

    const res = yield call(postActualizarCursoService, formData);

    const { data } = res;
    if (data.success) {
      yield put({
        type: REQUEST_SUCCESS,
        payload: 'Curso actualizado con exito!!',
      });

      yield put({
        type: REQUEST_FINISHED,
      });
      yield put({
        type: MODAL_CLOSE,
      });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* deleteCursoWorker({ payload = '' }) {
  try {
    const { id, cursos } = payload;

    const res = yield call(deleteCursoService, { params: { id } });

    const { data } = res;
    if (data.success) {
      yield put({
        type: REQUEST_SUCCESS,
        payload: 'Curso eliminado!!',
      });

      const newCursos = {
        count: cursos.count - 1,
        rows: cursos.rows.filter((curso) => curso.id !== id),
      };
      yield put({
        type: SET_CURSOS,
        payload: newCursos,
      });
      yield put({
        type: REQUEST_FINISHED,
      });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* postAsignarWorker({ payload = '' }) {
  try {
    yield put({
      type: REQUEST_STARTED,
    });

    const res = yield call(postAsignarService, payload);
    const { data } = res;

    if (data.success) {
      yield put({ type: SET_CURSOS, payload: data.data });

      yield put({
        type: REQUEST_SUCCESS,
        payload: 'Registrado al Curso con exito!!',
      });

      yield put({
        type: REQUEST_FINISHED,
      });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* getProfesoresWorker({ payload = '' }) {
  try {
    yield put({
      type: REQUEST_STARTED,
    });
    const params = {};
    const res = yield call(getProfesoresService, params);
    var newArray = [];

    res.data.data.rows.forEach((e) => {
      newArray.push({
        label: e.nombre,
        value: e.id,
      });
    });
    yield put({
      type: SET_PROFESORES,
      payload: newArray,
    });

    yield put({
      type: REQUEST_FINISHED,
    });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

// @Whatcher
function* requestWatcher() {
  yield takeLatest(GET_CURSOS, getCursosWorker);
  yield takeLatest(GET_PROFESORES, getProfesoresWorker);
  yield takeLatest(CREATE_CURSO, createCursoWorker);
  yield takeLatest(UPDATE_CURSO, updateCursoWorker);
  yield takeLatest(DELETE_CURSO, deleteCursoWorker);
  yield takeLatest(ASIGNAR_CURSOS, postAsignarWorker);
}

export default { requestWatcher };
