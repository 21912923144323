// @Vendors
import { all, fork } from "redux-saga/effects";

// @Sagas
import auth from "./auth/auth.sagas";
import users from "./users/users.sagas";
import cursos from "./cursos/cursos.sagas";
import categorias from "./categorias/categorias.sagas";

export default function* () {
  yield all([...Object.values(auth)].map(fork));
  yield all([...Object.values(users)].map(fork));
  yield all([...Object.values(cursos)].map(fork));
  yield all([...Object.values(categorias)].map(fork));
}
