// @Vendors
import { put, takeLatest, call } from 'redux-saga/effects';

// @services
import {
 

  crearCategoriasService,
  updateCategoriasService,
  deleteCategoriasService,
  getCategoriasService,

} from './categorias.services';
import { buildErrorsObj } from 'utils/helpers';

// @ActionsTypes

import {
  DELETE_CATEGORIAS,
  GET_CATEGORIAS,
  SET_CATEGORIAS,
  GET_CATEGORIAS_CHECK,
  SET_CATEGORIAS_CHECK,
  CREAR_CATEGORIAS,
  UPDATE_CATEGORIAS

} from './categorias.actionsTypes';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
  REQUEST_FAILURE,
  MODAL_CLOSE,

} from 'state/app/app.actionTypes';


function* getCategoriasWorker({ payload = '' }) {
  try {
    yield put({ type: REQUEST_STARTED });

    const res = yield call(getCategoriasService, payload);
    const { data } = res;

    if (data.success) {
      yield put({ type: SET_CATEGORIAS, payload: data.data });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}


function* crearCategoriasWorker({ payload = '' }) {
  try {
    yield put({ type: REQUEST_STARTED });

    console.log(payload);

    const res = yield call(crearCategoriasService,{...payload});
    const { data } = res;

    if (data.success) {
      yield put({
             type: REQUEST_SUCCESS,
             payload: 'Categoria creado con exito!!',
           });
    
           yield put({
             type: REQUEST_FINISHED,
           });
           yield put({
             type: MODAL_CLOSE,
           });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}


 function* deleteCategoriaWorker({ payload = '' }) {
   try {

 const {id, categorias} = payload
  
     const res = yield call(deleteCategoriasService, {params:{id}});

      const { data } = res;
     if (data.success) {
       yield put({
         type: REQUEST_SUCCESS,
         payload: 'Categoria eliminado!!',
       });

       const newCategoria = {
         count: (categorias.count-1),
         rows: categorias.rows.filter( cat => cat.id !== id )
       }
       yield put({
         type: SET_CATEGORIAS,
         payload:newCategoria
       });
       yield put({
         type: REQUEST_FINISHED,
       });
     } else {
       yield put({
         type: REQUEST_FAILURE,
         payload: { serverErrors: data.message },
       });
     } 
   } catch (err) {
     yield put({
       type: REQUEST_FAILURE,
       payload: buildErrorsObj(err),
     });
   }
 }



function* getCategoriasCheckWorker({ payload = '' }) {
  try {
    yield put({
      type: REQUEST_STARTED,
    });
    const params = {}
    const res = yield call(getCategoriasService, params)

    var catArray = [];

    res.data.data.rows.forEach((e) => {
      catArray.push({
        label: e.nombre,
        value: e.id,
      });
    });
  yield put ({
    type:SET_CATEGORIAS_CHECK, payload:catArray
  })


    yield put({
      type: REQUEST_FINISHED,
    });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* updateCategoriasWorker({ payload = '' }) {
  try {
;
    yield put({
      type: REQUEST_STARTED,
    });
const params = {...payload}

     const res = yield call(updateCategoriasService, params )

     const { data } = res;

     if (data.success) {
       yield put({
              type: REQUEST_SUCCESS,
              payload: 'Categoria actualizado con exito!!',
            });
    
            yield put({
              type: REQUEST_FINISHED,
            });
            yield put({
              type: MODAL_CLOSE,
            });
     } else {
       yield put({
         type: REQUEST_FAILURE,
         payload: { serverErrors: data.message },
       });
     }

    yield put({
      type: REQUEST_FINISHED,
    });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}


// @Whatcher
function* requestWatcher() {
  yield takeLatest(GET_CATEGORIAS_CHECK, getCategoriasCheckWorker);
  yield takeLatest(GET_CATEGORIAS, getCategoriasWorker);
  yield takeLatest(CREAR_CATEGORIAS, crearCategoriasWorker);
  yield takeLatest(UPDATE_CATEGORIAS, updateCategoriasWorker);
  yield takeLatest(DELETE_CATEGORIAS, deleteCategoriaWorker);

}

export default { requestWatcher };
