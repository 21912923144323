import React from "react";

function cendit() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 279.17 80.26">
    <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
    <path fill="#662483" d="M10.63,67.52C8.72,56.44,30.16,34.68,65.92,19.94,22.56,33.15-.64,56,0,68.68.59,79.86,16.3,84.66,42.09,74,21.52,78.49,11.9,75,10.63,67.52Z"/>
    <path fill="#662483" d="M30.62,58c-1.14-6.77,9.2-18.51,31.29-29.88C42.42,34.68,24.81,48.85,23.41,59,22.35,66.6,29.93,71,46.21,64,33.87,64.74,31.38,62.37,30.62,58Z"/>
    <path fill="#662483" d="M43.91,50.9c.18-4.82,5.8-9.38,14.61-14.32-12,5.47-20,11.56-18.74,15.94.75,2.55,2.93,4.42,10.73,2.21C45.31,54.81,43.86,52.31,43.91,50.9Z"/>
    <path fill="#662483" d="M155.75,7.43C150.83-1.31,132-3.89,96.05,8.3c32-7,43-4.37,46.1,4.18,1.31,3.62-1.71,9.54-8.79,16.53h10C154.18,19.65,158.1,11.61,155.75,7.43Z"/>
    <path fill="#662483" d="M134.32,14.83c-2.72-6-14.3-6.54-39.8,1,19.25-4.19,28.27-3.14,30.45,3.42.9,2.69-1.42,6.1-5.61,9.8h6C132.12,23.5,135.9,18.32,134.32,14.83Z"/>
    <path fill="#662483" d="M112,23.26c-.57-3.88-6.48-3.8-21,.5,11-2.4,12.62-1.67,14.2,1.14.53.94-.05,2.39-1.51,4.13h5.78C111.29,27.11,112.27,25.17,112,23.26Z"/>
    <path fill="#662483" d="M85,39.94c.15-3-2.27-4.5-5.81-4.5-7.59,0-11.31,5.53-11.54,10.17-.17,3.32,1.94,6.24,7.32,6.24,4.1,0,7.38-2.27,8.16-4.82h7.41c-3.36,6.75-8.58,9.29-15.89,9.29-9.43,0-14.75-3.9-14.41-10.71C60.63,37.77,68,31,79.36,31c7.78,0,13.53,3.09,13,9Z"/>
    <path fill="#662483" d="M128.7,31.92h7.52l8.15,17h.09l5.59-17h6.68l-8.2,23.84h-7.29L133,39h-.09l-5.58,16.73h-6.82Z"/>
    <path fill="#662483" d="M161.52,31.92h12c9.23,0,13.89,3.44,13.6,9.28-.42,8.32-7.14,14.56-19.91,14.56H153.32Zm.61,19.43h5.61c7.43,0,11.77-4.21,12-9.25.17-3.43-1.75-5.77-6.95-5.77h-5.47Z"/>
    <path fill="#662483" d="M195,31.92h7.33l-8.2,23.84H186.8Z"/><path fill="#662483" d="M215.15,36.33h-10.4l1.52-4.41h27.67l-1.52,4.41h-9.94l-6.63,19.43h-7.34Z"/>
    <polygon fill="#662483" points="91.08 55.65 116.66 55.65 118.19 51.24 99.89 51.24 101.9 45.4 118.56 45.4 119.98 41.32 103.32 41.32 105.05 36.22 123.06 36.22 124.54 31.81 99.24 31.81 91.08 55.65"/><path fill="#662483" d="M58.86,68a3.72,3.72,0,0,1-3.81,2.52c-2.19,0-3.32-1.13-3.12-3a4.76,4.76,0,0,1,4.85-4.25c1.73,0,3,.8,2.8,2.41H58.51c.12-1.09-.65-1.63-1.81-1.63A3.75,3.75,0,0,0,53,67.44a1.89,1.89,0,0,0,2.14,2.27A2.71,2.71,0,0,0,57.8,68Z"/><path fill="#662483" d="M60.65,68.05a3.64,3.64,0,0,0-.07.44c-.09.78.6,1.22,1.37,1.22a1.87,1.87,0,0,0,1.78-1h1a2.85,2.85,0,0,1-2.81,1.69c-1.92,0-2.34-1.15-2.25-2A3.48,3.48,0,0,1,63,65.17c1.71,0,2.38.81,2.23,2.23a2.91,2.91,0,0,1-.16.65Zm3.59-.73A1.1,1.1,0,0,0,63,65.9a2.27,2.27,0,0,0-2.16,1.42Z"/>
    <path fill="#662483" d="M67.05,65.28H68l-.26.76h0a3.06,3.06,0,0,1,2.16-.87c1.09,0,1.58.41,1.49,1.29a5.62,5.62,0,0,1-.27,1l-1,2.88h-1l1-3a6.12,6.12,0,0,0,.22-.78c0-.48-.38-.7-.94-.7a2.47,2.47,0,0,0-1.5.58,2.23,2.23,0,0,0-.75,1.06l-1,2.79h-1Z"/>
    <path fill="#662483" d="M74.61,65.28h1.16l-.25.73H74.36l-1,3a1.94,1.94,0,0,0-.07.33c0,.27.25.34.54.34a2.18,2.18,0,0,0,.56-.09l-.25.77a5.19,5.19,0,0,1-.68,0c-.71,0-1.29-.17-1.22-.82a2.53,2.53,0,0,1,.1-.42L73.4,66h-1l.25-.73h1l.51-1.49h1Z"/>
    <path fill="#662483" d="M76.83,65.28h.9l-.4,1.09h0a2.74,2.74,0,0,1,2.24-1.2,1.82,1.82,0,0,1,.33,0l-.31.88h-.29a2.16,2.16,0,0,0-.57,0,2.47,2.47,0,0,0-1.33.82,3.47,3.47,0,0,0-.6,1.18L76,70.33h-1Z"/>
    <path fill="#662483" d="M82.87,65.17c1.55,0,2.34.74,2.2,2.08a3.54,3.54,0,0,1-3.55,3.19c-1.53,0-2.36-.89-2.23-2.1A3.54,3.54,0,0,1,82.87,65.17ZM81.7,69.71a2.66,2.66,0,0,0,2.4-2.39c.09-.79-.33-1.42-1.38-1.42a2.68,2.68,0,0,0-2.48,2.43C80.16,69.15,80.65,69.71,81.7,69.71Z"/>
    <path fill="#662483" d="M91,63.36h1.15l2.26,5.77h0l1.94-5.77h1l-2.43,7H93.81L91.56,64.6h0l-2,5.73h-1Z"/>
    <path fill="#662483" d="M97.55,66.81a2.55,2.55,0,0,1,2.69-1.64c1.12,0,2.09.26,2,1.28a4.42,4.42,0,0,1-.28.92l-.51,1.38a3.19,3.19,0,0,0-.23.72c0,.23.09.31.24.31a1.16,1.16,0,0,0,.31-.07l-.21.62a2,2,0,0,1-.59.11c-.53,0-.79-.27-.68-.71l0,0a2.59,2.59,0,0,1-2,.73c-1,0-1.74-.39-1.63-1.42.15-1.46,1.81-1.56,3.1-1.66,1-.08,1.48-.13,1.55-.8s-.59-.66-1.1-.66c-.73,0-1.48.25-1.66.91Zm1,2.9a2.09,2.09,0,0,0,1.69-.66,3.7,3.7,0,0,0,.65-1.3h0a6.61,6.61,0,0,1-1.9.34c-.69.09-1.32.28-1.39.92C97.52,69.48,98,69.71,98.54,69.71Z"/>
    <path fill="#662483" d="M107.46,66.88c.06-.61-.39-1-1.13-1a2.59,2.59,0,0,0-2.5,2.37,1.15,1.15,0,0,0,1.28,1.44,2,2,0,0,0,1.81-1h1A2.89,2.89,0,0,1,105,70.44c-1.59,0-2.31-.82-2.17-2.17a3.45,3.45,0,0,1,3.54-3.1c1.31,0,2.08.54,2,1.71Z"/>
    <path fill="#662483" d="M110.14,65.28h1l-1.74,5h-.95Zm1.27-.9h-1l.35-1h1Z"/>
    <path fill="#662483" d="M114.93,65.17c1.55,0,2.34.74,2.2,2.08a3.52,3.52,0,0,1-3.55,3.19c-1.53,0-2.36-.89-2.23-2.1A3.55,3.55,0,0,1,114.93,65.17Zm-1.17,4.54a2.68,2.68,0,0,0,2.41-2.39,1.22,1.22,0,0,0-1.38-1.42,2.68,2.68,0,0,0-2.48,2.43C112.22,69.15,112.71,69.71,113.76,69.71Z"/>
    <path fill="#662483" d="M119.1,65.28H120l-.26.76h0a3.09,3.09,0,0,1,2.17-.87c1.09,0,1.57.41,1.48,1.29a4.85,4.85,0,0,1-.27,1l-1,2.88h-1l1-3a4.83,4.83,0,0,0,.22-.78c.05-.48-.38-.7-.94-.7a2.5,2.5,0,0,0-1.5.58,2.3,2.3,0,0,0-.75,1.06l-1,2.79h-1Z"/>
    <path fill="#662483" d="M124.62,66.81a2.55,2.55,0,0,1,2.69-1.64c1.12,0,2.1.26,2,1.28a4.42,4.42,0,0,1-.28.92l-.51,1.38a3.19,3.19,0,0,0-.23.72c0,.23.09.31.24.31a1.16,1.16,0,0,0,.31-.07l-.2.62a2.05,2.05,0,0,1-.6.11c-.53,0-.78-.27-.68-.71l0,0a2.59,2.59,0,0,1-2,.73c-1,0-1.74-.39-1.63-1.42.15-1.46,1.82-1.56,3.11-1.66,1-.08,1.47-.13,1.54-.8s-.59-.66-1.1-.66c-.73,0-1.48.25-1.66.91Zm1,2.9a2.12,2.12,0,0,0,1.7-.66,3.68,3.68,0,0,0,.64-1.3h0a6.75,6.75,0,0,1-1.91.34c-.69.09-1.32.28-1.38.92C124.59,69.48,125.07,69.71,125.61,69.71Z"/>
    <path fill="#662483" d="M131.85,63.36h1l-2.41,7h-1Z"/>
    <path fill="#662483" d="M140.23,70.33h-.9l.28-.73h0a2.51,2.51,0,0,1-2,.84c-1.48,0-2.19-.84-2.06-2.06a3.62,3.62,0,0,1,3.56-3.21c.71,0,1.56.21,1.71.88h0l.9-2.69h.94Zm-2.41-.62a2.81,2.81,0,0,0,2.53-2.51c.08-.75-.38-1.3-1.33-1.3a2.72,2.72,0,0,0-2.56,2.48C136.38,69.19,136.77,69.71,137.82,69.71Z"/>
    <path fill="#662483" d="M143.12,68.05a2.41,2.41,0,0,0-.08.44c-.08.78.6,1.22,1.38,1.22a1.87,1.87,0,0,0,1.77-1h1a2.84,2.84,0,0,1-2.81,1.69c-1.92,0-2.34-1.15-2.25-2a3.48,3.48,0,0,1,3.33-3.24c1.71,0,2.38.81,2.23,2.23a2.91,2.91,0,0,1-.16.65Zm3.58-.73a1.1,1.1,0,0,0-1.21-1.42,2.27,2.27,0,0,0-2.16,1.42Z"/>
    <path fill="#662483" d="M153.44,63.36h2.81c2,0,2.93,1,2.76,2.66a4.68,4.68,0,0,1-4.91,4.31H151Zm-1.1,6.19h1.85a3.71,3.71,0,0,0,3.74-3.43c.11-1-.23-2-2-2h-1.71Z"/>
    <path fill="#662483" d="M160.19,68.05a3.64,3.64,0,0,0-.07.44c-.09.78.6,1.22,1.37,1.22a1.88,1.88,0,0,0,1.78-1h1a2.85,2.85,0,0,1-2.81,1.69c-1.93,0-2.35-1.15-2.25-2a3.48,3.48,0,0,1,3.33-3.24c1.71,0,2.37.81,2.23,2.23a2.91,2.91,0,0,1-.16.65Zm3.59-.73a1.1,1.1,0,0,0-1.21-1.42,2.29,2.29,0,0,0-2.17,1.42Z"/>
    <path fill="#662483" d="M169.26,66.76c.1-.57-.46-.86-1.12-.86s-1.25.13-1.3.64.69.65,1.43.93,1.48.62,1.39,1.41c-.11,1.12-1.41,1.56-2.53,1.56-1.33,0-2.32-.42-2.17-1.7h.95c-.12.75.59,1,1.38,1,.62,0,1.35-.19,1.41-.77s-.69-.68-1.43-1-1.48-.6-1.4-1.36c.1-.94,1.24-1.45,2.31-1.45s2.15.42,2,1.59Z"/>
    <path fill="#662483" d="M171.26,66.81A2.55,2.55,0,0,1,174,65.17c1.13,0,2.1.26,2,1.28a5.09,5.09,0,0,1-.27.92l-.52,1.38a3.79,3.79,0,0,0-.23.72c0,.23.09.31.25.31a1.16,1.16,0,0,0,.31-.07l-.21.62a2,2,0,0,1-.6.11c-.53,0-.78-.27-.68-.71l0,0a2.56,2.56,0,0,1-2,.73c-1,0-1.73-.39-1.63-1.42.16-1.46,1.82-1.56,3.11-1.66,1-.08,1.47-.13,1.55-.8s-.6-.66-1.11-.66c-.73,0-1.47.25-1.65.91Zm1,2.9a2.11,2.11,0,0,0,1.7-.66,3.52,3.52,0,0,0,.64-1.3h0a6.74,6.74,0,0,1-1.9.34c-.7.09-1.32.28-1.39.92C171.23,69.48,171.71,69.71,172.25,69.71Z"/>
    <path fill="#662483" d="M177.82,65.28h.9l-.4,1.09h0a2.77,2.77,0,0,1,2.25-1.2,1.82,1.82,0,0,1,.33,0l-.32.88h-.28a2.2,2.2,0,0,0-.57,0,2.43,2.43,0,0,0-1.33.82,3.47,3.47,0,0,0-.6,1.18L177,70.33h-.95Z"/>
    <path fill="#662483" d="M181.56,65.28h.9l-.39,1.09h0a2.74,2.74,0,0,1,2.24-1.2,1.93,1.93,0,0,1,.34,0l-.32.88h-.28a2.24,2.24,0,0,0-.58,0,2.41,2.41,0,0,0-1.32.82,3.5,3.5,0,0,0-.61,1.18l-.78,2.24h-1Z"/>
    <path fill="#662483" d="M187.6,65.17c1.55,0,2.34.74,2.2,2.08a3.52,3.52,0,0,1-3.55,3.19c-1.53,0-2.36-.89-2.23-2.1A3.55,3.55,0,0,1,187.6,65.17Zm-1.17,4.54a2.68,2.68,0,0,0,2.41-2.39,1.22,1.22,0,0,0-1.38-1.42A2.67,2.67,0,0,0,185,68.33C184.89,69.15,185.38,69.71,186.43,69.71Z"/>
    <path fill="#662483" d="M192.43,63.36h1l-2.42,7H190Z"/>
    <path fill="#662483" d="M194.93,63.36h.95l-2.41,7h-1Z"/>
    <path fill="#662483" d="M199,65.17c1.56,0,2.34.74,2.21,2.08a3.54,3.54,0,0,1-3.55,3.19c-1.53,0-2.36-.89-2.24-2.1A3.55,3.55,0,0,1,199,65.17Zm-1.17,4.54a2.66,2.66,0,0,0,2.41-2.39c.08-.79-.33-1.42-1.38-1.42a2.68,2.68,0,0,0-2.48,2.43C196.33,69.15,196.83,69.71,197.87,69.71Z"/>
    <path fill="#662483" d="M206,68.05a3.73,3.73,0,0,0-.08.44c-.08.78.61,1.22,1.38,1.22a1.88,1.88,0,0,0,1.78-1h1a2.84,2.84,0,0,1-2.81,1.69c-1.93,0-2.35-1.15-2.26-2a3.5,3.5,0,0,1,3.34-3.24c1.7,0,2.37.81,2.23,2.23a3.6,3.6,0,0,1-.16.65Zm3.59-.73a1.1,1.1,0,0,0-1.22-1.42,2.29,2.29,0,0,0-2.16,1.42Z"/>
    <path fill="#662483" d="M216.3,63.36h1.07l-2.42,7h-1.06Z"/>
    <path fill="#662483" d="M218.41,65.28h.92l-.25.76h0a3,3,0,0,1,2.16-.87c1.09,0,1.58.41,1.48,1.29a4.85,4.85,0,0,1-.27,1l-1,2.88h-1l1.05-3a4.77,4.77,0,0,0,.21-.78c0-.48-.37-.7-.94-.7a2.52,2.52,0,0,0-1.5.58,2.37,2.37,0,0,0-.75,1.06l-1,2.79h-1Z"/><path fill="#662483" d="M225.3,70.33h-1l-.3-5h1l.06,4.2h0l2.95-4.2h1Z"/><path fill="#662483" d="M229.72,68.05a2.41,2.41,0,0,0-.08.44c-.09.78.6,1.22,1.38,1.22a1.87,1.87,0,0,0,1.77-1h1a2.84,2.84,0,0,1-2.81,1.69c-1.92,0-2.34-1.15-2.25-2A3.48,3.48,0,0,1,232,65.17c1.71,0,2.38.81,2.23,2.23a2.91,2.91,0,0,1-.16.65Zm3.58-.73a1.1,1.1,0,0,0-1.21-1.42,2.27,2.27,0,0,0-2.16,1.42Z"/>
    <path fill="#662483" d="M238.78,66.76c.1-.57-.45-.86-1.12-.86s-1.25.13-1.3.64.7.65,1.43.93,1.48.62,1.39,1.41c-.11,1.12-1.4,1.56-2.53,1.56-1.32,0-2.32-.42-2.17-1.7h1c-.12.75.59,1,1.39,1,.62,0,1.34-.19,1.41-.77s-.7-.68-1.44-1-1.47-.6-1.39-1.36c.09-.94,1.23-1.45,2.31-1.45s2.15.42,2,1.59Z"/>
    <path fill="#662483" d="M242.83,65.28H244l-.26.73h-1.15l-1,3a1.94,1.94,0,0,0-.07.33c0,.27.25.34.54.34a2.18,2.18,0,0,0,.56-.09l-.25.77a5.31,5.31,0,0,1-.68,0c-.71,0-1.29-.17-1.22-.82a2.53,2.53,0,0,1,.1-.42L241.62,66h-1l.26-.73h1l.51-1.49h1Z"/>
    <path fill="#662483" d="M245.05,65.28H246l-1.74,5h-.95Zm1.27-.9h-1l.36-1h1Z"/>
    <path fill="#662483" d="M251.1,69.59a8.73,8.73,0,0,1-.6,1.38,3,3,0,0,1-2.79,1.4c-1.18,0-2.23-.44-2.15-1.56h1c0,.68.64.83,1.37.83,1.48,0,1.88-1,2.29-2l0,0a2.43,2.43,0,0,1-1.89.77,1.75,1.75,0,0,1-2-2.08,3.48,3.48,0,0,1,3.35-3.11c.84,0,1.63.39,1.73,1v0h0l.27-.93h.9Zm-2.55,0a2.61,2.61,0,0,0,2.37-2.41c.08-.79-.35-1.32-1.35-1.32-1.34,0-2.21,1.45-2.31,2.38A1.17,1.17,0,0,0,248.55,69.63Z"/>
    <path fill="#662483" d="M253.27,66.81A2.56,2.56,0,0,1,256,65.17c1.13,0,2.1.26,2,1.28a4.39,4.39,0,0,1-.27.92l-.52,1.38a4.68,4.68,0,0,0-.23.72c0,.23.09.31.25.31a1.16,1.16,0,0,0,.31-.07l-.21.62a2,2,0,0,1-.6.11c-.52,0-.78-.27-.67-.71l0,0a2.56,2.56,0,0,1-2,.73c-1,0-1.73-.39-1.62-1.42.15-1.46,1.81-1.56,3.1-1.66,1-.08,1.48-.13,1.55-.8s-.6-.66-1.1-.66c-.73,0-1.48.25-1.66.91Zm1,2.9a2.09,2.09,0,0,0,1.69-.66,3.7,3.7,0,0,0,.65-1.3h0a6.74,6.74,0,0,1-1.9.34c-.7.09-1.32.28-1.39.92C253.24,69.48,253.72,69.71,254.27,69.71Z"/>
    <path fill="#662483" d="M263.18,66.88c.07-.61-.38-1-1.12-1a2.61,2.61,0,0,0-2.51,2.37,1.16,1.16,0,0,0,1.29,1.44,2,2,0,0,0,1.81-1h1a2.88,2.88,0,0,1-2.84,1.76c-1.58,0-2.31-.82-2.16-2.17a3.44,3.44,0,0,1,3.54-3.1c1.31,0,2.07.54,2,1.71Z"/>
    <path fill="#662483" d="M265.87,65.28h.95l-1.74,5h-.95Zm1.27-.9h-1l.35-1h1Z"/>
    <path fill="#662483" d="M270.66,65.17c1.55,0,2.34.74,2.2,2.08a3.53,3.53,0,0,1-3.55,3.19c-1.53,0-2.36-.89-2.23-2.1A3.54,3.54,0,0,1,270.66,65.17Zm-1.17,4.54a2.66,2.66,0,0,0,2.4-2.39c.09-.79-.33-1.42-1.38-1.42A2.68,2.68,0,0,0,268,68.33C268,69.15,268.44,69.71,269.49,69.71Zm2.22-6.52H273l-1.68,1.4h-.79Z"/>
    <path fill="#662483" d="M274.83,65.28h.92l-.26.76h0a3,3,0,0,1,2.16-.87c1.09,0,1.58.41,1.49,1.29a5,5,0,0,1-.28,1l-1,2.88h-1l1.05-3a4.83,4.83,0,0,0,.22-.78c.05-.48-.38-.7-.94-.7a2.47,2.47,0,0,0-1.5.58,2.23,2.23,0,0,0-.75,1.06l-1,2.79h-1Z"/>
    <path fill="#662483" d="M48,77.88a2.17,2.17,0,0,0-.08.43c-.08.78.6,1.22,1.38,1.22a1.87,1.87,0,0,0,1.77-1h1a2.84,2.84,0,0,1-2.81,1.69c-1.92,0-2.34-1.15-2.25-2A3.49,3.49,0,0,1,50.31,75c1.71,0,2.38.81,2.23,2.23a3,3,0,0,1-.16.66Zm3.58-.74a1.09,1.09,0,0,0-1.21-1.42,2.28,2.28,0,0,0-2.16,1.42Z"/>
    <path fill="#662483" d="M54.4,75.1h.92l-.25.77h0A3,3,0,0,1,57.25,75c1.09,0,1.58.41,1.48,1.29a4.85,4.85,0,0,1-.27,1l-1,2.88h-1l1-3a5.64,5.64,0,0,0,.21-.78c.05-.48-.38-.7-.94-.7a2.52,2.52,0,0,0-1.5.58,2.43,2.43,0,0,0-.75,1.06l-1,2.79h-1Z"/>
    <path fill="#662483" d="M69.87,74h-2.7L65,80.15H64L66.1,74H63.4l.27-.78h6.48Z"/><path fill="#662483" d="M68.84,77.88c0,.14-.06.29-.08.43-.08.78.61,1.22,1.38,1.22a1.88,1.88,0,0,0,1.78-1h1a2.84,2.84,0,0,1-2.81,1.69c-1.93,0-2.35-1.15-2.25-2A3.48,3.48,0,0,1,71.15,75c1.71,0,2.38.81,2.23,2.23a3.75,3.75,0,0,1-.16.66Zm3.59-.74a1.1,1.1,0,0,0-1.21-1.42,2.29,2.29,0,0,0-2.17,1.42Z"/>
    <path fill="#662483" d="M75.9,73.18h.95l-2.41,7h-1Z"/>
    <path fill="#662483" d="M77.38,77.88a2.17,2.17,0,0,0-.08.43c-.09.78.6,1.22,1.38,1.22a1.87,1.87,0,0,0,1.77-1h1a2.84,2.84,0,0,1-2.81,1.69c-1.92,0-2.34-1.15-2.25-2A3.48,3.48,0,0,1,79.68,75c1.71,0,2.38.81,2.23,2.23a3,3,0,0,1-.16.66ZM81,77.14a1.1,1.1,0,0,0-1.21-1.42,2.27,2.27,0,0,0-2.16,1.42Z"/>
    <path fill="#662483" d="M87.11,76.7c.06-.61-.38-1-1.12-1a2.61,2.61,0,0,0-2.51,2.37,1.16,1.16,0,0,0,1.29,1.44,2,2,0,0,0,1.81-1h1a2.88,2.88,0,0,1-2.84,1.76c-1.58,0-2.3-.82-2.17-2.17A3.45,3.45,0,0,1,86.07,75c1.31,0,2.07.54,2,1.71Z"/>
    <path fill="#662483" d="M92.09,75c1.55,0,2.34.74,2.2,2.08a3.52,3.52,0,0,1-3.55,3.19c-1.53,0-2.36-.88-2.23-2.1A3.55,3.55,0,0,1,92.09,75Zm-1.17,4.54a2.68,2.68,0,0,0,2.41-2.39A1.22,1.22,0,0,0,92,75.72a2.68,2.68,0,0,0-2.48,2.43C89.38,79,89.87,79.53,90.92,79.53Z"/>
    <path fill="#662483" d="M96.27,75.1h.89l-.29.76h0A3.13,3.13,0,0,1,99,75c.74,0,1.27.25,1.37.9a3,3,0,0,1,2.09-.9c.82,0,1.58.31,1.5,1.13a4.52,4.52,0,0,1-.28,1l-1,3h-1l1.14-3.2a2,2,0,0,0,.15-.55c0-.39-.31-.68-.82-.68A2.46,2.46,0,0,0,100,77.54l-.91,2.61h-1l1-2.88a5.92,5.92,0,0,0,.27-.89c0-.42-.22-.66-.74-.66a2.49,2.49,0,0,0-1.49.64,2.11,2.11,0,0,0-.74,1.14l-.93,2.65h-.95Z"/>
    <path fill="#662483" d="M108.76,80.15h-.92l.26-.76h0a3,3,0,0,1-2.16.87c-1.09,0-1.58-.41-1.48-1.29a4.27,4.27,0,0,1,.27-1l1-2.88h1l-1,3a5.69,5.69,0,0,0-.22.78c-.05.48.38.7.94.7a2.52,2.52,0,0,0,2.26-1.64l1-2.79h1Z"/>
    <path fill="#662483" d="M112.09,75.1H113l-.26.77h0a3.05,3.05,0,0,1,2.17-.88c1.08,0,1.57.41,1.48,1.29a4.85,4.85,0,0,1-.27,1l-1,2.88h-1l1-3a4.56,4.56,0,0,0,.22-.78c0-.48-.38-.7-.94-.7a2.47,2.47,0,0,0-1.5.58,2.35,2.35,0,0,0-.75,1.06l-1,2.79h-1Z"/>
    <path fill="#662483" d="M118.33,75.1h1l-1.74,5.05h-1Zm1.27-.9h-1l.35-1h1Z"/>
    <path fill="#662483" d="M124.18,76.7c.06-.61-.38-1-1.13-1a2.6,2.6,0,0,0-2.5,2.37,1.16,1.16,0,0,0,1.28,1.44,2,2,0,0,0,1.81-1h1a2.89,2.89,0,0,1-2.84,1.76c-1.59,0-2.31-.82-2.17-2.17a3.45,3.45,0,0,1,3.54-3.1c1.32,0,2.08.54,2,1.71Z"/>
    <path fill="#662483" d="M126.14,76.63A2.55,2.55,0,0,1,128.83,75c1.12,0,2.1.26,2,1.28a4.25,4.25,0,0,1-.28.92L130,78.57a3.37,3.37,0,0,0-.23.72c0,.23.09.31.25.31a1.25,1.25,0,0,0,.31-.07l-.21.62a2.05,2.05,0,0,1-.6.11c-.53,0-.78-.27-.68-.71l0,0a2.56,2.56,0,0,1-2,.73c-1,0-1.74-.39-1.63-1.42.15-1.46,1.82-1.56,3.11-1.66,1-.08,1.47-.13,1.54-.8s-.59-.66-1.1-.66c-.73,0-1.47.25-1.65.91Zm1,2.9a2.12,2.12,0,0,0,1.7-.66,3.68,3.68,0,0,0,.64-1.3h0a6.74,6.74,0,0,1-1.9.34c-.7.09-1.33.28-1.39.92C126.11,79.3,126.59,79.53,127.13,79.53Z"/>
    <path fill="#662483" d="M136.05,76.7c.06-.61-.38-1-1.13-1a2.6,2.6,0,0,0-2.5,2.37,1.16,1.16,0,0,0,1.29,1.44,2,2,0,0,0,1.8-1h1a2.89,2.89,0,0,1-2.84,1.76c-1.58,0-2.31-.82-2.17-2.17A3.45,3.45,0,0,1,135,75c1.32,0,2.08.54,2,1.71Z"/>
    <path fill="#662483" d="M138.73,75.1h1L138,80.15h-1Zm1.28-.9h-1l.35-1h1Z"/>
    <path fill="#662483" d="M143.52,75c1.56,0,2.34.74,2.2,2.08a3.52,3.52,0,0,1-3.55,3.19c-1.52,0-2.35-.88-2.23-2.1A3.55,3.55,0,0,1,143.52,75Zm-1.17,4.54a2.66,2.66,0,0,0,2.41-2.39c.08-.79-.33-1.42-1.38-1.42a2.68,2.68,0,0,0-2.48,2.43C140.82,79,141.31,79.53,142.35,79.53Z"/>
    <path fill="#662483" d="M147.69,75.1h.92l-.25.77h0a3,3,0,0,1,2.16-.88c1.09,0,1.58.41,1.48,1.29a4.85,4.85,0,0,1-.27,1l-1,2.88h-1l1.05-3a5.64,5.64,0,0,0,.21-.78c0-.48-.37-.7-.94-.7a2.52,2.52,0,0,0-1.5.58,2.43,2.43,0,0,0-.75,1.06l-1,2.79h-1Z"/>
    <path fill="#662483" d="M153.59,77.88a2.17,2.17,0,0,0-.08.43c-.08.78.6,1.22,1.38,1.22a1.87,1.87,0,0,0,1.77-1h1a2.84,2.84,0,0,1-2.81,1.69c-1.92,0-2.34-1.15-2.25-2A3.48,3.48,0,0,1,155.89,75c1.71,0,2.38.81,2.23,2.23a3,3,0,0,1-.16.66Zm3.58-.74A1.1,1.1,0,0,0,156,75.72a2.28,2.28,0,0,0-2.16,1.42Z"/>
    <path fill="#662483" d="M162.65,76.58c.1-.57-.45-.86-1.11-.86s-1.26.14-1.31.64.7.66,1.43.93,1.48.62,1.4,1.41c-.12,1.13-1.41,1.56-2.54,1.56-1.32,0-2.31-.42-2.17-1.7h1c-.13.75.58,1,1.38,1,.62,0,1.35-.19,1.41-.77s-.7-.68-1.43-1-1.48-.61-1.4-1.37c.1-.94,1.23-1.45,2.31-1.45s2.15.42,2,1.59Z"/>
    </g>
    </g>
    </svg>
  );
}

export default cendit;
