// @Vendors
import React, { memo } from 'react'
import styled from 'styled-components'

// @Material ui
import {
	Typography,
	Grow
} from '@material-ui/core'

// @Icons
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HouseIcon from '@material-ui/icons/House';

const Steper = styled.div`
	display: felx;
	gap: 5px;
	width: 100%;
	justify-content: center;
	max-width: 100%;
	margin: 0 auto 60px;
	align-items: center;
	flex-flow: row wrap;
`
const StepperCircle = styled.div`
	padding: 15px;
	box-sizing: border-box;
	border-radius: 50px;
	display: flex;
	flex-direction: column;
	place-items: center;
	justify-content: center;
	min-width: 180px;
	gap: 5px;
	transition: .4s linear;
	opacity: .6;
	transform: scale(.8);
	
	&.active {
		opacity: 1;
		transform: scale(1.3);
	}
`

const icons = [
	<SearchIcon color="primary" fontSize="large"/>,
	<PersonIcon color="primary" fontSize="large"/>,
	<HouseIcon color="primary" fontSize="large"/>,
	<WorkIcon color="primary" fontSize="large"/>,
	<MenuBookIcon color="primary" fontSize="large"/>,
	<AccountBoxIcon color="primary" fontSize="large"/>
]


function PreViewTitles({ items = [], actual = 0 }) {
	return (
		<Grow in={true} timeout={650}>
			<Steper>
			 {items.map((view, index) => (
				<StepperCircle key={index} className={index === actual && "active"}>
					<div className="icon">{icons[index]}</div> 
					<div><Typography align="center">{view.title}</Typography></div> 
				</StepperCircle>
			 ))}
			</Steper>
		</Grow>
	)
}

export default memo(PreViewTitles)