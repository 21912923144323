// @Vendors
import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

// @Router
import LoginRoute from './Routes/LoginRoute'
import LoginAdminRoute from './Routes/LoginAdminRoute'
import PublicRoute from './Routes/PublicRoute'
import AdminRoute from 'pages/App/Routes/AdminRoute'
import NotFoundRoute from 'pages/App/Routes/NotFoundRoute'

// @Pages
import Login from 'pages/Login'
import Home from 'pages/Home'
import Recover from 'pages/Recover'
import RegisterUser from 'pages/RegisterUser'
import Cursos from 'pages/Cursos'
import CursoSingle from 'pages/CursoSingle'
import ActivateUser from 'pages/ActivateUser'
import Contacts from 'pages/Contacts'
import PageNotFound from 'pages/PageNotFound'

// @Pages > administrador
import Usuarios from 'pages/Administrador/usuarios'
import UsuariosRoles from 'pages/Administrador/usuariosRoles'
import CursosAdmin from 'pages/Administrador/cursos'
import CategoriasAdmin from 'pages/Administrador/categorias/Index'
import AdminLogin from 'pages/Administrador/login'
import Reportes from 'pages/Administrador/reportes'


export default function PatchRoutes({ user }) {

	const prefixAdmin = "/administracion"
	
	return (
		<Switch>
			{ /* RUTAS ADMINISTRADOR */ }
			<Redirect exact from={prefixAdmin} to={`${prefixAdmin}/reportes`} />

			<AdminRoute exact path={`${prefixAdmin}/roles-de-usuarios`} alias="Roles de usuarios" component={UsuariosRoles} user={user}/>
			
			<AdminRoute exact path={`${prefixAdmin}/usuarios`} alias="Administrar usuarios" component={Usuarios} user={user}/>

			<AdminRoute exact path={`${prefixAdmin}/oferta-formativa`} alias="Administrar oferta formativa" component={CursosAdmin} user={user}/>

			<AdminRoute exact path={`${prefixAdmin}/categorias`} alias="Administrar categorias" component={CategoriasAdmin} user={user}/>

			<AdminRoute exact path={`${prefixAdmin}/reportes`} alias="Administrar reportes" component={Reportes} user={user}/>
			
			<LoginAdminRoute exact path={`${prefixAdmin}/acceder`} alias="Acceder administración" component={AdminLogin} user={user}/> *
		
			{ /* RUTAS USUARIOS */ }
			<LoginRoute exact path="/activar-usuario/:token" alias="Recuperar  cuenta" component={ActivateUser} user={user} />
			
			<LoginRoute exact path="/registro" alias="Registro" component={RegisterUser} user={user}/>
			
			<LoginRoute exact path="/recuperar-cuenta" alias="Recuperar  cuenta" component={Recover} user={user} />

			<LoginRoute exact path="/iniciar-sesion" alias="Iniciar sesión" component={Login} user={user}/>

			<PublicRoute exact path="/oferta-formativa" alias="Oferta Formativa" component={Cursos} user={user}/>

			<PublicRoute exact path="/oferta-formativa/:id" alias="Oferta Formativa" component={CursoSingle} user={user}/>
			
			<PublicRoute exact path="/contacto" alias="Contacto" component={Contacts} user={user}/>
			
			<PublicRoute exact path="/" alias="Mujer, la innovación esta en ti" component={Home} user={user}/>

			<NotFoundRoute alias="Pagina no encotrada" component={PageNotFound}/>
		
		</Switch>
	)
}