// @Vendors
import axios from 'axios';

import { BASE_API } from 'constants/index';
import { getToken } from 'utils/helpers';

export function getCursosService(params) {
  return axios.post(`${BASE_API}/cursos`, { params });
}

export function getCursoService(params) {
  return axios.post(`${BASE_API}/cursos`, { params });
}



export function getProfesoresService(params) {
  return axios.post(`${BASE_API}/profesores`, { params });
}


export function postAsignarService(params) {
  return axios.post(
    `${BASE_API}/cursos/asignar`,
    { params },
    {
      headers: {
        'x-mppct-token': getToken(),
      },
    }
  );
}
export function postCrearCursoService(params) {
  return axios.post(`${BASE_API}/cursos/agregar`, params, {
    headers: {
      'x-mppct-token': getToken(),
    },
  });
}

export function postActualizarCursoService(params) {
  return axios.post(`${BASE_API}/cursos/actualizar`, params, {
    headers: {
      'x-mppct-token': getToken(),
    },
  });
}


export function deleteCursoService(params) {
  return axios.post(`${BASE_API}/cursos/eliminar`, params, {
    headers: {
      'x-mppct-token': getToken(),
    },
  });
}
