// @Material UI
import { createMuiTheme } from '@material-ui/core/styles'




const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#652385"
    },
    secondary:{
      main: "#AA44D8"
    },
    success: {
      main: "#4caf50"
    },
    danger: {
      main: "#b2102f"
    },
    
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    

  }  
})

export {
  MuiTheme
}