import { LOGIN, LOGOUT } from './auth.actionsTypes'

const login = (payload) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN,
      payload
    })
  }
}


const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    })
  }
}

export { login, logout }