import React from 'react'

function ingeneria() {
    return (
<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 200.7 80.8" >
<g id="Capa_1">
	<g id="Layer_1_1_">
	</g>
</g>
<g id="Layer_1">
	<g>
		<g>
			<path fill="#662483" d="M77.7,25.8h-2.2v1.5h2.1v0.9h-2.1v3h-0.9v-6.2h3.1V25.8z"/>
			<path fill="#662483" d="M79.5,27.2v2.3c0,0.7,0.3,1,0.8,1s0.8-0.3,0.8-1v-2.3h0.9v2.3c0,0.3,0,0.6-0.1,0.8
				c-0.1,0.2-0.2,0.4-0.4,0.6c-0.3,0.3-0.7,0.4-1.2,0.4c-0.5,0-0.9-0.1-1.2-0.4c-0.2-0.2-0.3-0.3-0.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.8
				v-2.3H79.5z"/>
			<path fill="#662483" d="M83.1,27.2H84v0.4c0.3-0.3,0.7-0.5,1.1-0.5c0.5,0,0.8,0.1,1.1,0.4c0.2,0.2,0.3,0.6,0.3,1.2v2.4h-0.9v-2.2
				c0-0.4-0.1-0.7-0.2-0.8c-0.1-0.2-0.3-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.2,1v1.9h-0.9V27.2z"/>
			<path fill="#662483" d="M90.5,24.3h0.9v6.8h-0.9v-0.4c-0.4,0.3-0.8,0.5-1.2,0.5c-0.5,0-1-0.2-1.3-0.6c-0.4-0.4-0.5-0.9-0.5-1.5
				c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.3-0.6c0.5,0,0.9,0.2,1.2,0.6V24.3z M88.4,29.1c0,0.4,0.1,0.7,0.3,0.9
				c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4
				c-0.3,0-0.6,0.1-0.8,0.4C88.5,28.5,88.4,28.8,88.4,29.1z"/>
			<path fill="#662483" d="M95.5,27.2h0.9v3.9h-0.9v-0.4c-0.4,0.3-0.8,0.5-1.2,0.5c-0.5,0-1-0.2-1.3-0.6c-0.4-0.4-0.5-0.9-0.5-1.5
				c0-0.6,0.2-1.1,0.5-1.5c0.4-0.4,0.8-0.6,1.3-0.6c0.5,0,0.9,0.2,1.2,0.6V27.2z M93.3,29.1c0,0.4,0.1,0.7,0.3,0.9
				c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4
				c-0.3,0-0.6,0.1-0.8,0.4C93.4,28.5,93.3,28.8,93.3,29.1z"/>
			<path fill="#662483" d="M100.6,27.4v1.2c-0.2-0.3-0.4-0.4-0.6-0.5c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.9,0.4
				c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.3,0.6-0.5v1.2
				c-0.4,0.2-0.7,0.3-1.1,0.3c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
				C99.9,27.1,100.2,27.2,100.6,27.4z"/>
			<path fill="#662483" d="M101.3,25.5c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
				c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
				C101.4,25.9,101.3,25.7,101.3,25.5z M102.4,27.2v3.9h-0.9v-3.9H102.4z"/>
			<path fill="#662483" d="M103.3,29.1c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
				c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6C103.5,30.2,103.3,29.7,103.3,29.1z M104.2,29.1
				c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.3C104.4,28.5,104.2,28.8,104.2,29.1z M106.8,25.2l-1.5,1.3l-0.5-0.3l1.1-1.5
				L106.8,25.2z"/>
			<path fill="#662483" d="M108.5,27.2h0.9v0.4c0.3-0.3,0.7-0.5,1.1-0.5c0.5,0,0.8,0.1,1.1,0.4c0.2,0.2,0.3,0.6,0.3,1.2v2.4h-0.9
				v-2.2c0-0.4-0.1-0.7-0.2-0.8c-0.1-0.2-0.3-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.2,1v1.9h-0.9V27.2z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#662483" d="M73.8,43.5V36c0-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3h0.8v8.3H73.8z"/>
			<path fill="#662483" d="M82.2,43.5h-0.8c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6v-3.3c0-0.4-0.1-0.7-0.4-0.8
				c-0.2-0.2-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v4.1h-1.7v-3.8c0-1.1,0.3-1.8,0.9-2.2
				c0.6-0.4,1.2-0.6,1.9-0.6c0.7,0,1.4,0.2,2,0.6c0.6,0.4,0.9,1.1,0.9,2.2V43.5z"/>
			<path fill="#662483" d="M83.3,41.4H85c0,0.3,0.1,0.6,0.4,0.7c0.2,0.2,0.6,0.2,1,0.2c0.3,0,0.5-0.1,0.7-0.2
				c0.2-0.1,0.3-0.3,0.3-0.5c0-0.3-0.3-0.5-1-0.7c-0.3,0-0.5-0.1-0.7-0.1c-0.9-0.2-1.4-0.5-1.7-0.7c-0.3-0.3-0.5-0.7-0.5-1.2
				c0-0.6,0.2-1.1,0.7-1.5c0.5-0.4,1.1-0.6,1.9-0.6c0.8,0,1.5,0.2,2,0.6c0.5,0.4,0.7,0.9,0.7,1.6h-1c-0.3,0-0.5-0.1-0.7-0.4
				c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0-0.7,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.3,0.4,0.5,1.3,0.7
				c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.2,0c0.8,0.2,1.4,0.5,1.7,0.7c0.3,0.3,0.5,0.7,0.5,1.2c0,0.7-0.3,1.3-0.8,1.6
				c-0.5,0.4-1.2,0.5-2.2,0.5c-0.9,0-1.6-0.2-2.1-0.6C83.6,42.7,83.3,42.1,83.3,41.4L83.3,41.4z"/>
			<path fill="#662483" d="M93.2,43.5c-0.5,0-1.1,0-1.7,0c-0.6,0-0.9-0.4-0.9-1.2v-7.1h0.9c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.2,0.2,0.4,0.2,0.6v1.1h1v0.3c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-0.2v3.6c0,0.3,0.2,0.4,0.5,0.4h0.5V43.5z"
				/>
			<path fill="#662483" d="M94.5,35.2h0.9c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v0.5h-1.7V35.2z M94.5,37.1h0.9
				c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v5.6h-1.7V37.1z"/>
			<path fill="#662483" d="M100.1,43.5c-0.5,0-1.1,0-1.7,0c-0.6,0-0.9-0.4-0.9-1.2v-7.1h0.9c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.2,0.2,0.4,0.2,0.6v1.1h1v0.3c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2h-0.2v3.6c0,0.3,0.2,0.4,0.5,0.4h0.5V43.5z"
				/>
			<path fill="#662483" d="M106.8,37.1v3.8c0,1.1-0.3,1.9-0.9,2.2c-0.6,0.4-1.2,0.5-2,0.5c-0.7,0-1.3-0.2-1.9-0.6
				c-0.6-0.4-0.9-1.1-0.9-2.2v-3.8h1.7v4.1c0,0.4,0.1,0.7,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
				c0.2-0.2,0.4-0.5,0.4-0.8v-3.3c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.2,0.6-0.2H106.8z"/>
			<path fill="#662483" d="M110.9,43.5c-0.5,0-1.1,0-1.7,0c-0.6,0-0.9-0.4-0.9-1.2v-7.1h0.9c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.2,0.2,0.4,0.2,0.6v1.1h1v0.3c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.4,0.2-0.6,0.2H110v3.6c0,0.3,0.2,0.4,0.5,0.4h0.5V43.5z"/>
			<path fill="#662483" d="M112.9,42.9c-0.3-0.3-0.6-0.7-0.7-1.2c-0.1-0.5-0.2-1-0.2-1.5c0-0.4,0.1-0.9,0.2-1.3
				c0.1-0.4,0.3-0.8,0.6-1.1c0.4-0.5,1-0.8,1.6-0.9c0.7-0.1,1.3,0,1.8,0.2c0.6,0.2,1.1,0.7,1.4,1.3c0.3,0.5,0.4,1.2,0.4,1.9
				c0,0.5-0.1,1-0.2,1.3c-0.1,0.4-0.3,0.7-0.4,0.9c-0.4,0.5-0.9,0.9-1.5,1c-0.5,0.1-1.1,0.2-1.6,0C113.6,43.4,113.2,43.2,112.9,42.9
				z M114.1,38.6c-0.3,0.4-0.5,0.9-0.5,1.7c0,0.7,0.2,1.2,0.4,1.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.5,0.1,0.7,0
				c0.2-0.1,0.5-0.3,0.7-0.5c0.2-0.3,0.3-0.8,0.3-1.4c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.4-0.5-0.6-0.6c-0.2-0.1-0.5-0.1-0.7,0
				S114.2,38.5,114.1,38.6z"/>
			<path fill="#662483" d="M127.2,35.1h0.8c0.2,0,0.4,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.6v4.7c0,1.1-0.3,1.9-1,2.3
				c-0.7,0.4-1.4,0.6-2.2,0.6c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.7-0.4-1-0.7c-0.5-0.6-0.7-1.4-0.7-2.4c0-1,0.2-1.8,0.7-2.4
				c0.5-0.6,1.1-0.9,1.9-0.9c0.4,0,0.8,0.1,1.1,0.2c0.3,0.2,0.6,0.4,0.8,0.7V35.1z M124.4,40.2c0,0.6,0.1,1.1,0.4,1.5
				c0.2,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.2,1-0.5c0.2-0.3,0.3-0.8,0.3-1.5c0-0.6-0.1-1-0.4-1.4c-0.3-0.3-0.6-0.5-1-0.5
				c-0.4,0-0.7,0.2-1,0.5C124.5,39.2,124.4,39.6,124.4,40.2z"/>
			<path fill="#662483" d="M134.6,41.5h1.2c-0.2,0.7-0.5,1.2-1,1.5c-0.5,0.4-1.1,0.6-1.9,0.6c-0.9,0-1.6-0.3-2.2-0.9
				c-0.5-0.6-0.8-1.4-0.8-2.5c0-1,0.2-1.8,0.7-2.4c0.5-0.6,1.2-0.9,2.2-0.9c1,0,1.7,0.3,2.2,0.9c0.5,0.6,0.8,1.4,0.8,2.5
				c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2h-4.2c0,0.5,0.1,0.9,0.4,1.1c0.2,0.3,0.5,0.4,0.9,0.4
				c0.3,0,0.5-0.1,0.7-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.2C134.2,41.5,134.4,41.5,134.6,41.5z
				 M131.7,39.6h2.5c0-0.4-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-0.6,0.1-0.9,0.3C131.8,38.9,131.7,39.2,131.7,39.6z"/>
			<path fill="#662483" d="M140.9,43.5V36c0-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3h0.8v8.3H140.9z"/>
			<path fill="#662483" d="M149.3,43.5h-0.8c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6v-3.3c0-0.4-0.1-0.7-0.4-0.8
				c-0.2-0.2-0.5-0.3-0.8-0.3s-0.5,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v4.1h-1.7v-3.8c0-1.1,0.3-1.8,0.9-2.2
				c0.6-0.4,1.2-0.6,1.9-0.6c0.7,0,1.4,0.2,2,0.6c0.6,0.4,0.9,1.1,0.9,2.2V43.5z"/>
			<path fill="#662483" d="M154.9,43.1v-0.4c-0.3,0.5-0.7,0.7-1.2,0.8c-0.5,0.1-1,0.1-1.5-0.1s-0.9-0.6-1.2-1.1
				c-0.3-0.5-0.5-1.2-0.5-2.1c0-1,0.2-1.8,0.7-2.4c0.5-0.6,1.2-0.9,2.2-0.9c0.8,0,1.5,0.2,2.2,0.6c0.7,0.4,1,1.2,1,2.3v3.4
				c0,1.1-0.3,1.9-1,2.3c-0.7,0.4-1.4,0.6-2.2,0.6c-1.5,0-2.4-0.7-2.8-2.1h1.8c0.1,0.2,0.3,0.4,0.6,0.5c0.3,0.1,0.5,0.2,0.8,0.1
				c0.3-0.1,0.5-0.2,0.7-0.5C154.7,44,154.8,43.6,154.9,43.1z M152.1,40.3c0,1.1,0.3,1.7,1,1.8c0.6,0.1,1.1,0,1.4-0.4
				c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.4,0.1-0.8,0-1.1c-0.1-0.4-0.2-0.7-0.4-1c-0.2-0.3-0.5-0.4-0.9-0.4c-0.5,0-0.9,0.2-1.1,0.6
				C152.2,39.3,152.1,39.8,152.1,40.3z"/>
			<path fill="#662483" d="M162.3,41.5h1.2c-0.2,0.7-0.5,1.2-1,1.5c-0.5,0.4-1.1,0.6-1.9,0.6c-0.9,0-1.6-0.3-2.2-0.9
				c-0.5-0.6-0.8-1.4-0.8-2.5c0-1,0.2-1.8,0.7-2.4c0.5-0.6,1.2-0.9,2.2-0.9c1,0,1.7,0.3,2.2,0.9c0.5,0.6,0.8,1.4,0.8,2.5
				c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2h-4.2c0,0.5,0.1,0.9,0.4,1.1c0.2,0.3,0.5,0.4,0.9,0.4
				c0.3,0,0.5-0.1,0.7-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.2C161.9,41.5,162.1,41.5,162.3,41.5z
				 M159.4,39.6h2.5c0-0.4-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-0.6,0.1-0.9,0.3C159.5,38.9,159.4,39.2,159.4,39.6z"/>
			<path fill="#662483" d="M170.4,43.5h-0.8c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.2-0.4-0.2-0.6v-3.3c0-0.4-0.1-0.7-0.4-0.8
				c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v4.1h-1.7v-3.8c0-1.1,0.3-1.8,0.9-2.2
				c0.6-0.4,1.2-0.6,1.9-0.6c0.7,0,1.4,0.2,2,0.6c0.6,0.4,0.9,1.1,0.9,2.2V43.5z"/>
			<path fill="#662483" d="M171.9,35.2h0.9c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v0.5h-1.7V35.2z M171.9,37.1h0.9
				c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v5.6h-1.7V37.1z"/>
			<path fill="#662483" d="M179.1,41.5h1.2c-0.2,0.7-0.5,1.2-1,1.5c-0.5,0.4-1.1,0.6-1.9,0.6c-0.9,0-1.6-0.3-2.2-0.9
				c-0.5-0.6-0.8-1.4-0.8-2.5c0-1,0.2-1.8,0.7-2.4c0.5-0.6,1.2-0.9,2.2-0.9c1,0,1.7,0.3,2.2,0.9c0.5,0.6,0.8,1.4,0.8,2.5
				c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2h-4.2c0,0.5,0.1,0.9,0.4,1.1c0.2,0.3,0.5,0.4,0.9,0.4
				c0.3,0,0.5-0.1,0.7-0.2c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.2C178.7,41.5,178.9,41.5,179.1,41.5z
				 M176.2,39.6h2.5c0-0.4-0.1-0.8-0.3-1c-0.2-0.2-0.5-0.3-0.9-0.3c-0.4,0-0.6,0.1-0.9,0.3C176.3,38.9,176.2,39.2,176.2,39.6z"/>
			<path fill="#662483" d="M181.7,43.5v-3.1c0-0.6,0.1-1.2,0.3-1.6c0.2-0.4,0.4-0.8,0.8-1c0.3-0.2,0.7-0.4,1.1-0.5
				c0.4-0.1,0.8-0.2,1.3-0.2c0,0,0.1,0,0.1,0s0.1,0,0.1,0v1.7h-0.6c-0.5,0-0.8,0.1-1,0.3c-0.2,0.2-0.3,0.6-0.3,1.1v3.3H181.7z"/>
			<path fill="#662483" d="M186.6,37.1h0.9c0.2,0,0.4,0.1,0.6,0.2c0.2,0.2,0.2,0.4,0.2,0.6v5.6h-1.7V37.1z M189.2,35l-1.5,1.5h-0.8
				l0.7-1.2c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1H189.2z"/>
			<path fill="#662483" d="M193.8,43.2c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0-0.7,0.1-1,0.1c-0.6,0-1.1-0.2-1.5-0.5
				c-0.4-0.3-0.7-0.8-0.7-1.6c0-0.7,0.2-1.2,0.6-1.5c0.4-0.3,0.9-0.5,1.5-0.6c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4-0.1
				c0.7-0.1,1.1-0.3,1.1-0.6c0-0.2-0.1-0.4-0.4-0.5c-0.3-0.1-0.5-0.1-0.7-0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.4,0.4h-1.6
				c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.4,1.1-0.6,1.9-0.6c0.9,0,1.6,0.2,2,0.5c0.5,0.3,0.7,0.8,0.7,1.4v2.2c0,0.6-0.1,1.1-0.4,1.5
				C194.4,42.8,194.1,43.1,193.8,43.2z M193.4,40.2c-0.2,0.1-0.5,0.2-0.9,0.3l-0.6,0.1c-0.3,0.1-0.5,0.2-0.6,0.3
				c-0.1,0.2-0.2,0.4-0.2,0.5c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.4,0.2,0.6,0.2c0.5,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.6,0.4-1.1V40.2z"
				/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#662483" d="M75.3,55.9h-0.9v-6.1h0.9v0.4c0.4-0.4,0.8-0.5,1.2-0.5c0.5,0,1,0.2,1.3,0.6c0.4,0.4,0.5,0.9,0.5,1.5
				c0,0.6-0.2,1.1-0.5,1.5c-0.3,0.4-0.8,0.6-1.3,0.6c-0.5,0-0.9-0.2-1.2-0.6V55.9z M77.5,51.9c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4
				c0.3,0,0.6-0.1,0.8-0.4C77.3,52.5,77.5,52.2,77.5,51.9z"/>
			<path fill="#662483" d="M82.2,49.9h0.9v3.9h-0.9v-0.4c-0.4,0.3-0.8,0.5-1.2,0.5c-0.5,0-1-0.2-1.3-0.6c-0.4-0.4-0.5-0.9-0.5-1.5
				c0-0.6,0.2-1.1,0.5-1.5c0.4-0.4,0.8-0.6,1.3-0.6c0.5,0,0.9,0.2,1.2,0.6V49.9z M80.1,51.8c0,0.4,0.1,0.7,0.3,0.9
				c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4
				c-0.3,0-0.6,0.1-0.8,0.4C80.2,51.2,80.1,51.5,80.1,51.8z"/>
			<path fill="#662483" d="M84.3,49.9h0.9v0.4c0.2-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.5,0.1,0.7,0.2l-0.4,0.8
				c-0.2-0.1-0.3-0.2-0.5-0.2c-0.5,0-0.7,0.4-0.7,1.1v2h-0.9V49.9z"/>
			<path fill="#662483" d="M90.3,49.9h0.9v3.9h-0.9v-0.4c-0.4,0.3-0.8,0.5-1.2,0.5c-0.5,0-1-0.2-1.3-0.6c-0.4-0.4-0.5-0.9-0.5-1.5
				c0-0.6,0.2-1.1,0.5-1.5c0.4-0.4,0.8-0.6,1.3-0.6c0.5,0,0.9,0.2,1.2,0.6V49.9z M88.1,51.8c0,0.4,0.1,0.7,0.3,0.9
				c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4
				c-0.3,0-0.6,0.1-0.8,0.4C88.2,51.2,88.1,51.5,88.1,51.8z"/>
			<path fill="#662483" d="M96,47.6v6.2h-0.9v-6.2H96z"/>
			<path fill="#662483" d="M97.3,49.9h0.9v0.4c0.3-0.3,0.7-0.5,1.1-0.5c0.5,0,0.8,0.1,1.1,0.4c0.2,0.2,0.3,0.6,0.3,1.2v2.4h-0.9
				v-2.2c0-0.4-0.1-0.7-0.2-0.8c-0.1-0.2-0.3-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.2,1v1.9h-0.9V49.9z"/>
			<path fill="#662483" d="M102.3,49.9l1,2.2l1-2.2h1l-2,4.2l-2-4.2H102.3z"/>
			<path fill="#662483" d="M109.6,52h-2.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
				c0.1-0.1,0.3-0.3,0.5-0.6l0.8,0.4c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.2
				c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0-1.1-0.2-1.4-0.6c-0.4-0.4-0.5-0.9-0.5-1.5c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.4-0.6
				c0.6,0,1,0.2,1.4,0.6c0.3,0.4,0.5,0.9,0.5,1.5L109.6,52z M108.6,51.3c-0.1-0.5-0.4-0.7-0.9-0.7c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3H108.6z"/>
			<path fill="#662483" d="M112.8,50.5l-0.8,0.4c-0.1-0.2-0.3-0.4-0.4-0.4c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2
				c0,0.2,0.2,0.3,0.5,0.4c0.5,0.2,0.8,0.4,1,0.6c0.2,0.2,0.2,0.4,0.2,0.7c0,0.4-0.1,0.7-0.4,1c-0.3,0.2-0.6,0.4-1,0.4
				c-0.7,0-1.1-0.3-1.4-1l0.8-0.4c0.1,0.2,0.2,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.5-0.1,0.5-0.4c0-0.2-0.1-0.3-0.4-0.5
				c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.4-0.3-0.6-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.6,0.4-0.8
				c0.2-0.2,0.5-0.3,0.9-0.3C112.2,49.8,112.6,50,112.8,50.5z"/>
			<path fill="#662483" d="M114.8,50.7v3.1h-0.9v-3.1h-0.4v-0.8h0.4v-1.4h0.9v1.4h0.7v0.8H114.8z"/>
			<path fill="#662483" d="M116,48.2c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
				c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2s-0.3-0.1-0.4-0.2C116.1,48.5,116,48.4,116,48.2z M117.1,49.9v3.9h-0.9v-3.9
				H117.1z"/>
			<path fill="#662483" d="M122,53.7c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3-0.1,0.4c-0.1,0.3-0.2,0.5-0.4,0.8c-0.4,0.4-0.8,0.6-1.5,0.6
				c-0.5,0-1-0.1-1.3-0.4c-0.3-0.3-0.6-0.7-0.6-1.2h0.9c0,0.2,0.1,0.3,0.2,0.4c0.2,0.2,0.5,0.4,0.8,0.4c0.7,0,1-0.4,1-1.2v-0.5
				c-0.4,0.4-0.8,0.6-1.2,0.6c-0.5,0-1-0.2-1.3-0.6c-0.3-0.4-0.5-0.9-0.5-1.5c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.4-0.6
				c0.5,0,0.9,0.2,1.2,0.6v-0.4h0.9V53.7z M121.2,51.8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.4
				c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4
				C121.1,52.5,121.2,52.2,121.2,51.8z"/>
			<path fill="#662483" d="M126.1,49.9h0.9v3.9h-0.9v-0.4c-0.4,0.3-0.8,0.5-1.2,0.5c-0.5,0-1-0.2-1.3-0.6c-0.4-0.4-0.5-0.9-0.5-1.5
				c0-0.6,0.2-1.1,0.5-1.5c0.4-0.4,0.8-0.6,1.3-0.6c0.5,0,0.9,0.2,1.2,0.6V49.9z M123.9,51.8c0,0.4,0.1,0.7,0.3,0.9
				c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4
				c-0.3,0-0.6,0.1-0.8,0.4C124,51.2,123.9,51.5,123.9,51.8z"/>
			<path fill="#662483" d="M131.2,50v1.2c-0.2-0.3-0.4-0.4-0.6-0.5c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.9,0.4
				c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.3,0.6-0.5v1.2
				c-0.4,0.2-0.7,0.3-1.1,0.3c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
				C130.5,49.8,130.8,49.9,131.2,50z"/>
			<path fill="#662483" d="M131.9,48.2c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
				c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2
				C132,48.5,131.9,48.4,131.9,48.2z M133,49.9v3.9h-0.9v-3.9H133z"/>
			<path fill="#662483" d="M133.9,51.8c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
				c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6C134.1,52.9,133.9,52.4,133.9,51.8z M134.9,51.8
				c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.6-0.1,0.9-0.3s0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.3C135,51.2,134.9,51.5,134.9,51.8z M137.4,47.9l-1.5,1.3l-0.5-0.3l1.1-1.5
				L137.4,47.9z"/>
			<path fill="#662483" d="M139.1,49.9h0.9v0.4c0.3-0.3,0.7-0.5,1.1-0.5c0.5,0,0.8,0.1,1.1,0.4c0.2,0.2,0.3,0.6,0.3,1.2v2.4h-0.9
				v-2.2c0-0.4-0.1-0.7-0.2-0.8c-0.1-0.2-0.3-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.2,1v1.9h-0.9V49.9z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#662483" d="M75.6,63.4l-1.8-3.2h1.1l1.2,2.3l1.1-2.3h1l-3.1,6.1h-1L75.6,63.4z"/>
			<path fill="#662483" d="M81.6,64.1v-6.2h1.3c0.6,0,1.1,0.1,1.5,0.2c0.4,0.1,0.7,0.3,1.1,0.6c0.6,0.6,1,1.4,1,2.3
				c0,1-0.3,1.7-1,2.3c-0.3,0.3-0.7,0.5-1.1,0.6c-0.3,0.1-0.8,0.2-1.5,0.2H81.6z M82.5,63.2H83c0.4,0,0.8,0,1.1-0.1
				c0.3-0.1,0.5-0.2,0.8-0.4c0.5-0.4,0.7-1,0.7-1.6c0-0.7-0.2-1.2-0.7-1.7c-0.4-0.4-1-0.6-1.8-0.6h-0.4V63.2z"/>
			<path fill="#662483" d="M91,62.4h-2.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
				c0.1-0.1,0.3-0.3,0.5-0.6l0.8,0.4c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.2
				c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0-1.1-0.2-1.4-0.6c-0.4-0.4-0.5-0.9-0.5-1.5c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.4-0.6
				c0.6,0,1,0.2,1.4,0.6c0.3,0.4,0.5,0.9,0.5,1.5L91,62.4z M90.1,61.6c-0.1-0.5-0.4-0.7-0.9-0.7c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3H90.1z"/>
			<path fill="#662483" d="M94.3,60.8l-0.8,0.4c-0.1-0.2-0.3-0.4-0.4-0.4c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2
				c0,0.2,0.2,0.3,0.5,0.4c0.5,0.2,0.8,0.4,1,0.6c0.2,0.2,0.3,0.4,0.3,0.7c0,0.4-0.1,0.7-0.4,1c-0.3,0.2-0.6,0.4-1,0.4
				c-0.7,0-1.1-0.3-1.4-1l0.8-0.4c0.1,0.2,0.2,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.3,0,0.5-0.1,0.5-0.4c0-0.2-0.1-0.3-0.4-0.5
				c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.4-0.3-0.6-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0-0.3,0.1-0.6,0.4-0.8
				c0.2-0.2,0.5-0.3,0.9-0.3C93.7,60.1,94,60.3,94.3,60.8z"/>
			<path fill="#662483" d="M98.2,60.2h0.9v3.9h-0.9v-0.4c-0.4,0.3-0.8,0.5-1.2,0.5c-0.5,0-1-0.2-1.3-0.6c-0.4-0.4-0.5-0.9-0.5-1.5
				c0-0.6,0.2-1.1,0.5-1.5c0.4-0.4,0.8-0.6,1.3-0.6c0.5,0,0.9,0.2,1.2,0.6V60.2z M96.1,62.1c0,0.4,0.1,0.7,0.3,0.9
				c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4
				c-0.3,0-0.6,0.1-0.8,0.4C96.2,61.5,96.1,61.8,96.1,62.1z"/>
			<path fill="#662483" d="M100.3,60.2h0.9v0.4c0.2-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.5,0.1,0.7,0.2l-0.4,0.8
				c-0.2-0.1-0.3-0.2-0.5-0.2c-0.5,0-0.7,0.4-0.7,1.1v2h-0.9V60.2z"/>
			<path fill="#662483" d="M103.4,60.2h0.9v0.4c0.2-0.2,0.3-0.3,0.4-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.5,0.1,0.7,0.2l-0.4,0.8
				c-0.2-0.1-0.3-0.2-0.5-0.2c-0.5,0-0.7,0.4-0.7,1.1v2h-0.9V60.2z"/>
			<path fill="#662483" d="M106.3,62.1c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
				c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6C106.5,63.2,106.3,62.7,106.3,62.1z M107.2,62.1
				c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.3C107.3,61.5,107.2,61.8,107.2,62.1z"/>
			<path fill="#662483" d="M112.3,57.3v6.8h-0.9v-6.8H112.3z"/>
			<path fill="#662483" d="M114.4,57.3v6.8h-0.9v-6.8H114.4z"/>
			<path fill="#662483" d="M115.4,62.1c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
				c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6C115.6,63.2,115.4,62.7,115.4,62.1z M116.3,62.1
				c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.3C116.4,61.5,116.3,61.8,116.3,62.1z"/>
			<path fill="#662483" d="M124.9,58.8v5.3H124v-5.3h-1.4v-0.9h3.8v0.9H124.9z"/>
			<path fill="#662483" d="M130.6,62.4h-2.8c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
				c0.1-0.1,0.3-0.3,0.5-0.6l0.8,0.4c-0.1,0.2-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.2
				c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0-1.1-0.2-1.4-0.6c-0.4-0.4-0.5-0.9-0.5-1.5c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.4-0.6
				c0.6,0,1,0.2,1.4,0.6c0.3,0.4,0.5,0.9,0.5,1.5L130.6,62.4z M129.7,61.6c-0.1-0.5-0.4-0.7-0.9-0.7c-0.1,0-0.2,0-0.3,0.1
				c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3H129.7z"/>
			<path fill="#662483" d="M134.7,60.3v1.2c-0.2-0.3-0.4-0.4-0.6-0.5c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.9,0.4
				c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.3,0.6-0.5v1.2
				c-0.4,0.2-0.7,0.3-1.1,0.3c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
				C134,60.1,134.3,60.2,134.7,60.3z"/>
			<path fill="#662483" d="M135.5,60.2h0.9v0.4c0.3-0.3,0.7-0.5,1.1-0.5c0.5,0,0.8,0.1,1.1,0.4c0.2,0.2,0.3,0.6,0.3,1.2v2.4H138
				v-2.2c0-0.4-0.1-0.7-0.2-0.8c-0.1-0.2-0.3-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.6,0.3c-0.1,0.2-0.2,0.5-0.2,1v1.9h-0.9V60.2z"/>
			<path fill="#662483" d="M139.9,62.1c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
				c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6C140.1,63.2,139.9,62.7,139.9,62.1z M140.8,62.1
				c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.3C140.9,61.5,140.8,61.8,140.8,62.1z"/>
			<path fill="#662483" d="M145.9,57.3v6.8H145v-6.8H145.9z"/>
			<path fill="#662483" d="M146.9,62.1c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
				c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6C147.1,63.2,146.9,62.7,146.9,62.1z M147.8,62.1
				c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.3C147.9,61.5,147.8,61.8,147.8,62.1z M150.4,58.2l-1.5,1.3l-0.5-0.3l1.1-1.5
				L150.4,58.2z"/>
			<path fill="#662483" d="M155.8,64c0,0.2,0,0.3,0,0.5c0,0.1,0,0.3-0.1,0.4c-0.1,0.3-0.2,0.5-0.4,0.8c-0.4,0.4-0.8,0.6-1.5,0.6
				c-0.5,0-1-0.1-1.3-0.4c-0.3-0.3-0.6-0.7-0.6-1.2h0.9c0,0.2,0.1,0.3,0.2,0.4c0.2,0.2,0.5,0.4,0.8,0.4c0.7,0,1-0.4,1-1.2v-0.5
				c-0.4,0.4-0.8,0.6-1.2,0.6c-0.5,0-1-0.2-1.3-0.6c-0.3-0.4-0.5-0.9-0.5-1.5c0-0.6,0.2-1.1,0.5-1.5c0.3-0.4,0.8-0.6,1.4-0.6
				c0.5,0,0.9,0.2,1.2,0.6v-0.4h0.9V64z M155,62.2c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.4-0.8-0.4c-0.3,0-0.6,0.1-0.8,0.4
				c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.6,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4C154.9,62.8,155,62.5,155,62.2z"
				/>
			<path fill="#662483" d="M156.9,58.5c0-0.2,0.1-0.3,0.2-0.4s0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
				c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2S156.9,58.7,156.9,58.5z M157.9,60.2v3.9H157v-3.9H157.9
				z"/>
			<path fill="#662483" d="M162.1,60.3v1.2c-0.2-0.3-0.4-0.4-0.6-0.5c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.9,0.4
				c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.3,0.6-0.5v1.2
				c-0.4,0.2-0.7,0.3-1.1,0.3c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6
				C161.4,60.1,161.8,60.2,162.1,60.3z"/>
			<path fill="#662483" d="M162.8,62.1c0-0.6,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
				c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.6,0-1.1-0.2-1.5-0.6C163,63.2,162.8,62.7,162.8,62.1z M163.7,62.1
				c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.6-0.1,0.9-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9
				c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.3C163.8,61.5,163.7,61.8,163.7,62.1z"/>
		</g>
	</g>
	<g>
		<g>
			<path fill="#662483" d="M61.1,22.4c0.8,1.3,2.6,5.7,3.6,8c-1.2-1.9-3.4-5.2-4.1-6.5c-0.8-1.5-3.2-5.5-4.2-7.3
				C57.7,18.1,60.2,21,61.1,22.4"/>
			<polygon fill="#662483" points="41.6,54 56.7,55.9 42.9,65.2 			"/>
			<polygon fill="#662483" points="42.4,65.4 39.4,64.8 26.7,62.4 26.7,62.4 41.3,54.1 			"/>
			<polygon fill="#662483" points="41.6,53.6 41.4,53.6 41.3,53.7 26.3,62.2 23.1,45.6 23.1,45.6 10.2,35.3 24.9,26.1 25,26 
				25.1,25.9 30.8,11.9 44.4,22.1 44.5,22.2 44.7,22.2 59.6,24.3 54.4,38.8 54.3,39 54.4,39.2 57,55.6 			"/>
			<polygon fill="#662483" points="22.9,46.1 26,62.1 13.1,52.1 			"/>
			<polygon fill="#662483" points="13,51.8 12.8,51 10.2,35.7 10.2,35.7 22.7,45.8 			"/>
			<polygon fill="#662483" points="24.6,25.9 10.2,34.9 15.5,20.1 			"/>
			<polygon fill="#662483" points="15.8,19.9 15.8,19.9 24.9,15 30.4,12 30.4,12 24.8,25.7 			"/>
			<polygon fill="#662483" points="47.1,12.7 44.5,21.7 31.3,11.8 36.9,12.1 47.1,12.7 			"/>
			<polygon fill="#662483" points="47.4,12.8 59.3,23.9 44.8,21.9 			"/>
			<polygon fill="#662483" points="54.7,39.2 64,41 57.3,55.3 			"/>
			<polygon fill="#662483" points="54.7,38.9 59.8,24.7 64,40.7 			"/>
			<polygon fill="#662483" points="55.5,16 59.2,23.4 59.2,23.4 48.1,13 			"/>
			<path fill="#662483" d="M53.8,15l-2.1-0.8l-4.4-1.8l0,0l0,0l-4.8-2.2l-2.1-1C47.5,11.6,51.7,13.8,53.8,15"/>
			<polygon fill="#662483" points="37.6,8.2 37.6,8.2 46.5,12.3 31.4,11.5 			"/>
			<polygon fill="#662483" points="36.5,8.4 30.7,11.5 30.3,11.5 21.5,11.6 			"/>
			<polygon fill="#662483" points="19.6,12.3 30,11.8 15.9,19.5 			"/>
			<polygon fill="#662483" points="18.7,13.2 15.4,19.6 15.4,19.7 15.4,19.7 15.4,19.7 15.4,19.7 15.3,19.8 15.3,19.8 15.3,19.8 
				15.2,19.9 8.2,25.2 			"/>
			<polygon fill="#662483" points="15.1,20.3 9.9,34.8 7.2,26.3 			"/>
			<polygon fill="#662483" points="7,27.6 9.8,35.3 5.4,44.9 			"/>
			<polygon fill="#662483" points="9.9,35.8 10.2,37.6 12.6,51.7 12.6,51.7 5.3,45.7 			"/>
			<polygon fill="#662483" points="5.8,46.5 12.6,52.1 12.7,52.2 12.7,52.2 14.5,61.1 			"/>
			<polygon fill="#662483" points="15,61.8 14.5,59.2 13.1,52.5 13.1,52.5 25.7,62.3 19,62 			"/>
			<polygon fill="#662483" points="15.7,62.2 19.7,62.4 26.1,62.7 26.1,62.7 31.8,69.6 31.8,69.6 			"/>
			<polygon fill="#662483" points="32.3,69.2 26.6,62.7 42,65.6 39.1,66.8 			"/>
			<polygon fill="#662483" points="41.4,67.8 33.7,69.3 39.2,67.1 42.8,65.8 42.9,65.8 50.1,66 			"/>
			<polygon fill="#662483" points="51.4,65.6 51.4,65.7 43.1,65.5 56.5,56.5 56.6,56.4 56.7,56.3 			"/>
			<polygon fill="#662483" points="58.7,58.4 52.2,64.9 52.2,64.9 57.1,56.2 57.1,56.2 57.2,56.1 57.2,56.1 63.1,51.3 			"/>
			<polygon fill="#662483" points="63.5,50.5 63.4,50.6 57.6,55.3 64,41.7 			"/>
			<polygon fill="#662483" points="64.1,40.9 64.1,40.9 64.1,40.8 			"/>
			<polygon fill="#662483" points="60.2,25 65.5,32.8 64.2,40.1 			"/>
			<path fill="#662483" d="M64.3,47.5l0.2-6.4l0-0.2l0-0.1l1.2-6.9c0.4,1.9,0.7,3.8,0.7,4.6C66.3,39.3,64.9,44.8,64.3,47.5"/>
		</g>
	</g>
</g>
<g id="svg2">
</g>
<g id="Fondos_Color">
</g>
<g id="Layer_2">
</g>
</svg>

    )
}

export default ingeneria
