// @Vendors
import React, { memo } from "react";
import {useDispatch} from 'react-redux'

// @Routes
import { Route } from "react-router-dom"

//@Actions
import { logout } from 'state/auth/auth.actions'


// @Components
import AppFrame from "components/AppFrame"

function PublicRoute({ component: Component, alias, user, ...rest } = {}) {
    const dispatch = useDispatch()

    const doLogout = React.useCallback(() => {
        dispatch(logout())
    }, [dispatch])

  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <AppFrame title={alias} user={user} onLogout={doLogout}>
          <Component {...routeProps} />
        </AppFrame>
      )}
    />
  );
}

export default memo(PublicRoute);
