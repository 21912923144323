// @Vendors
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

// @Components
import MenuBar from 'components/Menu'
import Footer from 'components/Footer'
import Maintenance from 'pages/Maintenance'

// @Material UI
import useStyles from './useStyles'
import FAVICO from 'statics/images/logos/logoMujer.jpg'

import { MAINTENANCE } from 'constants/index'
import { linksMenu } from 'components/Menu/MenuLinks'

function AppFrame({
  title = 'Not title assigned',
  children,
  user = {},
  onLogout,
}) {
  const { classes } = useStyles()
  const [MenuLinks, setMenu] = React.useState(linksMenu)

  useEffect(() => {
    if(user.roles.length > 0) {
      return setMenu( prev => [...prev, { title: "Administración", to: "administracion"}])
    }
    return setMenu(linksMenu)
  }, [user])

  if(MAINTENANCE) return <Maintenance user={user}/>
  return (
    // ** Caja principal en Grid
    <div className={classes.container}>
      <Helmet>
        <title>MujeresTI | {title}</title>
        <link rel="icon" type="image/jpg" href={FAVICO} sizes="64x64" />
      </Helmet>

      <div>
        <MenuBar
          user={user}
          logout={onLogout}
          position='relative'
          linksMenu={MenuLinks}
        />
      </div>

      <main role='main' className={classes.main}>{children}</main>

      <Footer />
    </div>
  )
}

export default React.memo(AppFrame);
