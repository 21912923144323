// @MaterialUI
import { makeStyles } from "@material-ui/core/styles";

export default function useStyles() {
  const styles = makeStyles((theme) => ({
    /* @Styles */

    nested: {
      paddingLeft: theme.spacing(4),
    },
  root: {
    background: "#f9f9f9",
    padding: "2em 0",
    "& > .MuiContainer-root ": {
      display: "flex",
      alignItems: "center",
    },
  },
  paper: {
    flexGrow: "1",
  },
  imagen: {
    padding:"2.5em",
    "& svg": {
      maxHeight: "400px",
      width: "100%",
    },
  },
  contenido: {
    padding: "2rem",
  },

  titulo: {
    textTransform: "capitalize",
    margin: "10px 0 0 0",
    color: "#444",
    fontWeight: "bolder",
  },
  margen: {
    marginBottom: "20px",
  },

  ente: {
    display: "flex",
    alignItems: "center",
    padding: "0px 14px",
    border: "1px solid",
    borderColor: theme.palette.primary.light,
    borderRadius: "20px",
    maxHeight:"45px",
    "& svg ": {
      marginRight: "10px",
      color: theme.palette.primary.light,
    },
  },
  descripcion: {
    lineHeight: "1.8rem",
  },
  fontWevy: {
    fontWeight: "580",
    color: "#3e3c3c",
  },
  button: {
    fontSize: "16px",
    "&.Mui-disabled": {
      background: "#6c63ff",
      color: "#fff",
    },
  },
  nameEnte: {
    color: "#6c63ff",
  },
  }));

  const classes = styles();

  return {
    classes,
  };
}
