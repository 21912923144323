// @Vendors
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";

//@material-ui
import { Grid, Typography, Card, Button } from "@material-ui/core";

//@components
import ImgCurso from 'components/ImgCurso';

//@ActionsType
import { GET_CURSOS } from "state/cursos/cursos.actionsTypes";

// @Styles
import useStyles from "./useStyles";

function CursosHome() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const cursos = useSelector((state) => state.cursosReducer.get("cursos"));

  useEffect(() => {
    dispatch({
      type: GET_CURSOS,
      payload: {
        limit: 3,
        estatus: 0,
      },
    });
  }, [dispatch]);
  return (
    <section id="cursos-home" className={classes.section}>
      <Typography
        variant="h4"
        color="primary"
        align="center"
        className={classes.titulo}
      >
        Oferta Formativa
      </Typography>
      <Typography
         className={classes.mainText}
         variant="body2"
         align="justify"
      >
        El Ministerio del Poder Popular para Ciencia y Tecnología, pone a disposición sus capacidades formativas en materia de Telecomunicaciones e Informática, tanto en modalidad en línea como presencial, dirigido a la población femenina interesada en conocer herramientas o plataformas tecnológicas que puedan ser de utilidad en su campo laboral o de estudio, así como también garantizar la certificación de dichas experiencias formativas. <p>"Debemos avanzar hacia una explosión masiva del conocimiento, de tecnología, de innovación en función de las necesidades sociales y económicas del país y la soberanía nacional" Hugo Chávez.</p>
      </Typography>

      <br />
      <br />
      <br />
      <br />

      <Grid container spacing={3} justify="center">
        {cursos.rows &&
          cursos.rows.map(({ id, nombre, descripcion, imagen }) => {
            return (
              <Grid key={id} className={classes.rootCard} item sm={4}>
                <Card className={classes.card}>
                  <div className={classes.cursosimg}>
                    {!imagen ? (
                      <ImgCurso imagen={imagen} nombre={nombre} classe="cursoThumbial--home"/>
                    ) : <img alt="Imagen Oferta Academica Actual" src={`data:image/png;base64,${imagen}`} style={{width: '200px' }}/> }
                  </div>
                  <Typography
                    className={classes.titulo}
                    variant="h5"
                    color="primary"
                    align="center"
                  >
                    {nombre}
                  </Typography>
                  <Typography
                    className={classes["texto"]}
                    variant="body2"
                    align="center"
                  >
                    {descripcion.length > 100
                      ? descripcion.substring(0, 100) + "..."
                      : descripcion}
                  </Typography>
                  <p className={classes.centrarBoton}>
                    <Link to={`/oferta-formativa/${id}`}>
                      <Button
                        className={classes.Boton}
                        variant="outlined"
                        color="primary"
                      >
                        Ver más
                      </Button>
                    </Link>
                  </p>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </section>
  );
}

export default CursosHome;
