import React from 'react'

function infocentro() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 101.1 78.1"  >
   <g id="Capa_1">
       <g id="Layer_1_1_">
       </g>
       <g>
           <g>
               <path fill="#662483" d="M63.9,7.6c0,0,0.7-1,2.3-1.1c1.6-0.1,2.2,1,2.4,1.1c0.2,0.2,0.9,1.1,3,0.3c2-0.9,2.2-1.1,2.2-1.1V4.3
                   c0,0-0.1,0.2-2.2,1.1c-2,0.9-2.8-0.1-3-0.3C68.4,5,67.8,3.9,66.2,4c-1.6,0.1-2.3,1.1-2.3,1.1V7.6z"/>
               <path fill="#662483" d="M63.9,12.4c0,0,0.7-1,2.3-1.1c1.6-0.1,2.2,1,2.4,1.1c0.2,0.2,0.9,1.1,3,0.3c2-0.9,2.2-1.1,2.2-1.1V9.1
                   c0,0-0.1,0.2-2.2,1.1c-2,0.9-2.8-0.1-3-0.3c-0.2-0.2-0.8-1.3-2.4-1.1C64.6,9,63.9,10,63.9,10V12.4z"/>
               <rect x="63.3" y="5" fill="#662483" width="0.7" height="9"/>
           </g>
           <g>
               <path fill="#662483" d="M29.5,20.4c0,0,5.4-3.7,6.8-2.2c1.5,1.5,0.7,9.8,0.1,14.7c-0.3,2.9-1.8,7.3-1.8,8.3
                   c0.4,0.9,0.8,0.2,1.6-1.5s3.5-9.6,3.4-15.8s0.4-7.9-1.8-8.8c-2.5-1.1-7.8,3.3-8.3,4.1C29,19.9,28.8,20.1,29.5,20.4z"/>
               <path fill="#662483" d="M39.6,10.2c0.4,2.1-1.4,3.8-3.4,3.4c-1.1-0.2-2.1-1.2-2.3-2.3c-0.4-2.1,1.4-3.8,3.4-3.4
                   C38.4,8.1,39.4,9,39.6,10.2z"/>
               <path fill="#662483" d="M45.1,5.9c-2,2-5.1,5.5-5.9,12.7c-0.9,7.2-0.7,11.9-0.7,11.9s0,4.5,2.7,4.6s6.2-6.7,6.2-6.7
                   s0.2-1.8-1.9,0.9s-4.7,3.5-5.3-0.9s0.5-10.1,1.5-12.9c1-2.8,1.7-4.4,2.2-4.6c0.5-0.2,1.9,0.8,3.2-0.2s1.5-2.4,1-3.6
                   C47.3,5.3,45.6,5.5,45.1,5.9z"/>
           </g>
           <g>
               <path fill="#662483" d="M42.6,21.2c0,0,4.3-2.9,5.5-1.7c1.2,1.2,0.5,7.9,0.1,11.8c-0.3,2.3-1.4,5.9-1.4,6.6
                   c0.3,0.8,0.6,0.2,1.3-1.2c0.6-1.4,2.8-7.7,2.7-12.7c-0.1-5,0.3-6.3-1.4-7.1c-2-0.9-6.3,2.6-6.7,3.3C42.2,20.8,42.1,21,42.6,21.2z
                   "/>
               <path fill="#662483" d="M50.6,12.9c0.4,1.7-1.1,3.3-2.9,2.9c-0.8-0.2-1.5-0.9-1.7-1.7c-0.4-1.7,1.1-3.3,2.9-2.9
                   C49.8,11.4,50.4,12.1,50.6,12.9z"/>
               <path fill="#662483" d="M55.1,9.5c-1.6,1.6-4,4.4-4.8,10.2c-0.7,5.8-0.6,9.5-0.6,9.5s0,3.6,2.1,3.7c2.1,0.1,5-5.4,5-5.4
                   s0.2-1.4-1.5,0.7c-1.7,2.1-3.7,2.8-4.2-0.7c-0.5-3.5,0.4-8.1,1.2-10.3s1.3-3.5,1.7-3.7c0.4-0.2,1.5,0.6,2.5-0.2
                   c1-0.8,1.2-1.9,0.8-2.9C56.9,9.1,55.5,9.2,55.1,9.5z"/>
           </g>
           <g>
               <path fill="#662483" d="M53.8,21.9c0,0,3.4-2.3,4.3-1.4c0.9,0.9,0.4,6.2,0.1,9.3c-0.2,1.8-1.1,4.6-1.1,5.2c0.3,0.6,0.5,0.2,1-0.9
                   c0.5-1.1,2.2-6,2.1-10s0.3-5-1.1-5.6c-1.6-0.7-4.9,2.1-5.3,2.6C53.5,21.6,53.4,21.7,53.8,21.9z"/>
               <path fill="#662483" d="M60.1,15.1c0.6,1.5-0.9,3-2.4,2.4c-0.5-0.2-0.9-0.6-1-1c-0.6-1.5,0.9-3,2.4-2.4
                   C59.5,14.3,59.9,14.7,60.1,15.1z"/>
               <path fill="#662483" d="M63.6,12.7c-1.3,1.3-3.2,3.4-3.8,8s-0.4,7.5-0.4,7.5s0,2.8,1.7,2.9c1.7,0.1,3.9-4.3,3.9-4.3
                   s0.1-1.1-1.2,0.6c-1.3,1.7-2.9,2.2-3.3-0.6s0.3-6.4,0.9-8.1c0.6-1.8,1.1-2.8,1.4-2.9c0.3-0.2,1.2,0.5,2-0.2
                   c0.8-0.7,1-1.5,0.6-2.3C65.1,12.4,64,12.5,63.6,12.7z"/>
           </g>
           <polygon fill="#662483" points="65.4,8.2 65.2,8.1 65,8.3 65,8.1 64.8,8 65,7.8 65,7.6 65.2,7.8 65.4,7.7 65.3,8 		"/>
           <polygon fill="#662483" points="66.5,8 66.2,7.9 66,8 66,7.8 65.8,7.6 66.1,7.6 66.2,7.3 66.3,7.6 66.6,7.6 66.4,7.8 		"/>
           <polygon fill="#662483" points="67.4,8.2 67.2,8.1 67,8.1 67.1,7.9 67,7.7 67.2,7.7 67.4,7.5 67.4,7.7 67.7,7.8 67.4,8 		"/>
           <polygon fill="#662483" points="68.4,8.8 68.3,8.6 68,8.5 68.2,8.3 68.1,8.1 68.4,8.2 68.6,8 68.6,8.3 68.8,8.5 68.5,8.5 		"/>
           <polygon fill="#662483" points="69.6,9.4 69.4,9.3 69.2,9.4 69.2,9.2 69,9 69.3,9 69.3,8.7 69.5,8.9 69.7,9 69.6,9.1 		"/>
           <polygon fill="#662483" points="70.9,9.4 70.6,9.3 70.4,9.5 70.4,9.2 70.2,9.1 70.4,9 70.5,8.7 70.7,8.9 70.9,8.9 70.8,9.1 		"/>
           <polygon fill="#662483" points="72,9 71.7,8.9 71.6,9.1 71.5,8.9 71.3,8.8 71.5,8.7 71.5,8.4 71.7,8.6 71.9,8.5 71.8,8.8 		"/>
           <polygon fill="#662483" points="73,8.6 72.7,8.6 72.6,8.8 72.5,8.5 72.3,8.4 72.5,8.3 72.5,8 72.7,8.2 72.9,8.1 72.8,8.4 		"/>
           <g>
               <path fill="#662483" d="M4.8,47.7c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.3-0.3,0.6-0.4c0.2-0.1,0.4-0.1,0.7-0.1
                   c0.2,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4C7.9,46.5,8,46.7,8.1,47c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.5-0.1,0.7
                   C8,48.6,7.9,48.8,7.8,49c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4
                   c-0.2-0.2-0.3-0.4-0.4-0.6C4.9,48.2,4.8,47.9,4.8,47.7z M8.1,51.2v9.2H5v-9.2H8.1z"/>
               <path fill="#662483" d="M10.2,51.2h3.1v1.2c0.4-0.5,0.8-0.9,1.3-1.1c0.4-0.2,0.9-0.3,1.5-0.3c0.6,0,1.1,0.1,1.6,0.3
                   c0.4,0.2,0.8,0.5,1.1,0.9c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.1,0.8,0.1,1.3v5.8h-3.1v-4.6c0-0.5,0-0.8-0.1-1.1
                   c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.5-0.1c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.4
                   v4.9h-3.1V51.2z"/>
               <path fill="#662483" d="M24.8,53.9v6.5h-3.1v-6.5h-1.1v-2.7h1.1v-1.4c0-0.8,0.1-1.4,0.2-1.8c0.1-0.4,0.3-0.7,0.5-1.1
                   c0.2-0.3,0.5-0.6,0.9-0.9c0.3-0.2,0.7-0.4,1.1-0.6c0.4-0.1,0.8-0.2,1.1-0.2c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.1,0.7,0.3v2.8
                   c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.6
                   c0,0.2-0.1,0.6-0.1,1v1.1H27v2.7H24.8z"/>
               <path fill="#662483" d="M27.9,55.7c0-0.7,0.1-1.3,0.4-1.9c0.3-0.6,0.6-1.1,1.1-1.5c0.5-0.4,1-0.8,1.7-1c0.6-0.3,1.3-0.4,2.1-0.4
                   c0.7,0,1.4,0.1,2.1,0.4c0.6,0.2,1.2,0.6,1.7,1c0.5,0.4,0.8,1,1.1,1.6c0.3,0.6,0.4,1.3,0.4,2c0,0.7-0.1,1.4-0.4,2
                   c-0.3,0.6-0.6,1.1-1.1,1.6c-0.5,0.4-1,0.8-1.7,1c-0.7,0.2-1.4,0.4-2.1,0.4c-0.7,0-1.4-0.1-2.1-0.4c-0.6-0.2-1.2-0.6-1.6-1
                   c-0.5-0.4-0.8-1-1.1-1.6C28.1,57.2,27.9,56.5,27.9,55.7z M31.2,55.8c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.2,0.5,0.4,0.7
                   c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.6-0.4c0.2-0.2,0.3-0.4,0.4-0.7
                   c0.1-0.3,0.2-0.5,0.2-0.8c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.2-0.8-0.2
                   c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.5,0.3-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.7C31.2,55.2,31.2,55.5,31.2,55.8z"/>
               <path fill="#662483" d="M46.7,54.2c-0.5-0.4-1.1-0.6-1.6-0.6c-0.3,0-0.6,0.1-0.8,0.2c-0.3,0.1-0.5,0.3-0.7,0.5
                   c-0.2,0.2-0.3,0.4-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,0.9c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.7,0.5
                   c0.3,0.1,0.5,0.2,0.8,0.2c0.6,0,1.1-0.2,1.6-0.6v2.8c-0.8,0.3-1.5,0.5-2.2,0.5c-0.6,0-1.2-0.1-1.8-0.3c-0.6-0.2-1.1-0.6-1.5-1
                   c-0.4-0.4-0.8-0.9-1-1.5c-0.3-0.6-0.4-1.3-0.4-2c0-0.7,0.1-1.4,0.4-2c0.2-0.6,0.6-1.1,1-1.6c0.4-0.4,0.9-0.8,1.5-1
                   c0.6-0.2,1.2-0.4,1.9-0.4c0.7,0,1.4,0.2,2.1,0.5V54.2z"/>
               <path fill="#662483" d="M51.2,56.4c0,1.4,0.6,2.1,1.9,2.1c0.7,0,1.2-0.3,1.5-0.9h3c-0.6,2.1-2.1,3.2-4.5,3.2
                   c-0.7,0-1.4-0.1-2-0.3c-0.6-0.2-1.1-0.6-1.6-1c-0.4-0.4-0.8-0.9-1-1.5c-0.2-0.6-0.4-1.3-0.4-2c0-0.8,0.1-1.5,0.3-2.1
                   c0.2-0.6,0.5-1.1,1-1.6c0.4-0.4,0.9-0.8,1.5-1c0.6-0.2,1.3-0.3,2-0.3c0.7,0,1.4,0.1,2,0.3c0.6,0.2,1.1,0.6,1.5,1
                   c0.4,0.4,0.7,1,0.9,1.6c0.2,0.6,0.3,1.4,0.3,2.2v0.3H51.2z M54.9,54.5c-0.1-0.5-0.3-0.8-0.6-1.1c-0.3-0.3-0.7-0.4-1.2-0.4
                   c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.6-0.6,1.1H54.9z"/>
               <path fill="#662483" d="M59.5,51.2h3.1v1.2c0.4-0.5,0.8-0.9,1.3-1.1c0.4-0.2,0.9-0.3,1.5-0.3c0.6,0,1.1,0.1,1.6,0.3
                   c0.4,0.2,0.8,0.5,1.1,0.9c0.2,0.3,0.4,0.7,0.5,1c0.1,0.4,0.1,0.8,0.1,1.3v5.8h-3.1v-4.6c0-0.5,0-0.8-0.1-1.1
                   c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.5-0.1c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.4,0.8-0.4,1.4
                   v4.9h-3.1V51.2z"/>
               <path fill="#662483" d="M74.2,53.9v6.5h-3.1v-6.5h-1v-2.7h1v-2.8h3.1v2.8h1.7v2.7H74.2z"/>
               <path fill="#662483" d="M77.1,51.2h3.1v1.5c0.3-0.6,0.7-1,1.2-1.3c0.5-0.3,1-0.4,1.7-0.4c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0
                   v3.1c-0.4-0.2-0.8-0.3-1.3-0.3c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.5,1.1-0.5,2v3.9h-3.1V51.2z"/>
               <path fill="#662483" d="M84.7,55.7c0-0.7,0.1-1.3,0.4-1.9c0.3-0.6,0.6-1.1,1.1-1.5c0.5-0.4,1-0.8,1.7-1c0.6-0.3,1.3-0.4,2.1-0.4
                   c0.7,0,1.4,0.1,2.1,0.4c0.6,0.2,1.2,0.6,1.7,1c0.5,0.4,0.8,1,1.1,1.6c0.3,0.6,0.4,1.3,0.4,2c0,0.7-0.1,1.4-0.4,2
                   c-0.3,0.6-0.6,1.1-1.1,1.6c-0.5,0.4-1,0.8-1.7,1c-0.7,0.2-1.4,0.4-2.1,0.4c-0.7,0-1.4-0.1-2.1-0.4c-0.6-0.2-1.2-0.6-1.6-1
                   c-0.5-0.4-0.8-1-1.1-1.6C84.9,57.2,84.7,56.5,84.7,55.7z M88,55.8c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.2,0.5,0.4,0.7
                   c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.6-0.4c0.2-0.2,0.3-0.4,0.4-0.7
                   c0.1-0.3,0.2-0.5,0.2-0.8c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.3-0.6-0.4s-0.5-0.2-0.8-0.2
                   c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.5,0.3-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.7C88,55.2,88,55.5,88,55.8z"/>
           </g>
           <g>
               <path fill="#662483" d="M5.1,68.2l1.1,2.5l1.2-2.9l1.2,2.9l1.1-2.5h1.1l-2.2,4.7l-1.1-2.8l-1.2,2.8L4,68.2H5.1z"/>
               <path fill="#662483" d="M11.7,68.2l1.1,2.5l1.2-2.9l1.2,2.9l1.1-2.5h1.1l-2.2,4.7L14,70.1l-1.2,2.8l-2.2-4.7H11.7z"/>
               <path fill="#662483" d="M18.4,68.2l1.1,2.5l1.2-2.9l1.2,2.9l1.1-2.5h1.1l-2.2,4.7l-1.1-2.8l-1.2,2.8l-2.2-4.7H18.4z"/>
               <path fill="#662483" d="M24.2,72.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2s0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
                   c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2C24.3,72.4,24.2,72.3,24.2,72.1z"/>
               <path fill="#662483" d="M26.5,66.4c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.4,0.2
                   c0.1,0.1,0.2,0.3,0.2,0.5s-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2C26.6,66.8,26.5,66.6,26.5,66.4z
                    M27.6,68.2v4.4h-1v-4.4H27.6z"/>
               <path fill="#662483" d="M28.8,68.2h1v0.4c0.3-0.3,0.7-0.5,1.1-0.5c0.5,0,0.9,0.2,1.1,0.5c0.2,0.3,0.3,0.7,0.3,1.3v2.7h-1v-2.4
                   c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.7,0.3c-0.1,0.2-0.2,0.6-0.2,1.1v2.1h-1V68.2z"/>
               <path fill="#662483" d="M34.5,69.2v3.4h-1v-3.4h-0.3v-0.9h0.3v-1.7c0-0.5,0.1-0.9,0.3-1.2c0.2-0.3,0.6-0.5,1.1-0.5
                   c0.2,0,0.4,0.1,0.6,0.2v1l-0.1-0.1c-0.2-0.1-0.4-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.3,0.2c-0.1,0.1-0.1,0.4-0.1,0.7v1.4h1v0.9H34.5z"
                   />
               <path fill="#662483" d="M35.9,70.4c0-0.6,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.7,1.6-0.7c0.6,0,1.1,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.6
                   c0,0.7-0.2,1.2-0.6,1.6c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.1-0.2-1.6-0.7C36.1,71.6,35.9,71.1,35.9,70.4z M36.9,70.4
                   c0,0.4,0.1,0.8,0.3,1c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4c0.2-0.3,0.3-0.6,0.3-1c0-0.4-0.1-0.8-0.3-1
                   c-0.2-0.3-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4S36.9,70,36.9,70.4z"/>
               <path fill="#662483" d="M44.5,68.4v1.3c-0.2-0.3-0.4-0.5-0.6-0.6c-0.2-0.1-0.4-0.2-0.6-0.2c-0.4,0-0.7,0.1-0.9,0.4
                   c-0.2,0.3-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.4,0.9,0.4c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.3,0.6-0.6v1.3
                   c-0.4,0.2-0.8,0.3-1.2,0.3c-0.6,0-1.2-0.2-1.6-0.7c-0.4-0.4-0.6-1-0.6-1.6s0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7
                   C43.7,68.1,44.1,68.2,44.5,68.4z"/>
               <path fill="#662483" d="M49.1,70.7h-3c0,0.4,0.1,0.6,0.3,0.9c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
                   c0.2-0.1,0.3-0.3,0.5-0.7l0.8,0.5c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.2
                   c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-1-0.6-1.7c0-0.7,0.2-1.2,0.5-1.7c0.4-0.4,0.8-0.6,1.4-0.6
                   c0.6,0,1.1,0.2,1.4,0.6c0.3,0.4,0.5,1,0.5,1.7L49.1,70.7z M48.1,69.8c-0.1-0.5-0.5-0.8-1-0.8c-0.1,0-0.2,0-0.3,0.1
                   c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3H48.1z"/>
               <path fill="#662483" d="M50.1,68.2h1v0.4c0.3-0.3,0.7-0.5,1.1-0.5c0.5,0,0.9,0.2,1.1,0.5c0.2,0.3,0.3,0.7,0.3,1.3v2.7h-1v-2.4
                   c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.3-0.2-0.6-0.2c-0.3,0-0.5,0.1-0.7,0.3C51.1,69.6,51,70,51,70.5v2.1h-1V68.2z"/>
               <path fill="#662483" d="M55.8,69.2v3.4h-1v-3.4h-0.4v-0.9h0.4v-1.6h1v1.6h0.7v0.9H55.8z"/>
               <path fill="#662483" d="M57.1,68.2h1v0.4c0.2-0.2,0.3-0.3,0.5-0.4c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.8,0.3l-0.4,0.9
                   c-0.2-0.1-0.4-0.2-0.5-0.2c-0.5,0-0.8,0.4-0.8,1.2v2.3h-1V68.2z"/>
               <path fill="#662483" d="M60,70.4c0-0.6,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.7,1.6-0.7c0.6,0,1.1,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.6
                   c0,0.7-0.2,1.2-0.6,1.6c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.1-0.2-1.6-0.7C60.2,71.6,60,71.1,60,70.4z M61,70.4c0,0.4,0.1,0.8,0.3,1
                   c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4c0.2-0.3,0.3-0.6,0.3-1c0-0.4-0.1-0.8-0.3-1c-0.2-0.3-0.5-0.4-0.9-0.4
                   c-0.4,0-0.7,0.1-0.9,0.4C61.1,69.7,61,70,61,70.4z"/>
               <path fill="#662483" d="M65.5,72.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2s0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
                   c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2C65.6,72.4,65.5,72.3,65.5,72.1z"/>
               <path fill="#662483" d="M72,72.5c0,0.2,0,0.4,0,0.5c0,0.2,0,0.3-0.1,0.4c-0.1,0.3-0.2,0.6-0.4,0.8C71.1,74.7,70.6,75,70,75
                   c-0.5,0-1-0.2-1.4-0.5c-0.4-0.3-0.6-0.8-0.6-1.3h1c0,0.2,0.1,0.4,0.2,0.5c0.2,0.3,0.5,0.4,0.9,0.4c0.7,0,1-0.4,1-1.3v-0.6
                   c-0.4,0.4-0.8,0.6-1.3,0.6c-0.6,0-1-0.2-1.4-0.6c-0.4-0.4-0.5-1-0.5-1.6c0-0.6,0.2-1.2,0.5-1.6c0.4-0.5,0.8-0.7,1.4-0.7
                   c0.5,0,1,0.2,1.3,0.6v-0.5h1V72.5z M71.1,70.4c0-0.4-0.1-0.8-0.3-1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.4,0-0.6,0.1-0.9,0.4
                   c-0.2,0.3-0.3,0.6-0.3,1c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4C71,71.1,71.1,70.8,71.1,70.4z"/>
               <path fill="#662483" d="M72.9,70.4c0-0.6,0.2-1.2,0.6-1.6c0.4-0.4,0.9-0.7,1.6-0.7c0.6,0,1.1,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.6
                   c0,0.7-0.2,1.2-0.6,1.6c-0.4,0.4-1,0.7-1.6,0.7c-0.6,0-1.1-0.2-1.6-0.7C73.1,71.6,72.9,71.1,72.9,70.4z M73.9,70.4
                   c0,0.4,0.1,0.8,0.3,1c0.2,0.3,0.5,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4c0.2-0.3,0.3-0.6,0.3-1c0-0.4-0.1-0.8-0.3-1
                   c-0.2-0.3-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4C74,69.7,73.9,70,73.9,70.4z"/>
               <path fill="#662483" d="M79.2,65v3.7c0.4-0.4,0.8-0.6,1.3-0.6c0.6,0,1,0.2,1.4,0.7c0.4,0.4,0.6,1,0.6,1.6c0,0.7-0.2,1.2-0.6,1.7
                   c-0.4,0.4-0.8,0.7-1.4,0.7c-0.5,0-0.9-0.2-1.3-0.6v0.5h-1V65H79.2z M81.5,70.4c0-0.4-0.1-0.8-0.3-1c-0.2-0.3-0.5-0.4-0.8-0.4
                   c-0.3,0-0.6,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1c0,0.4,0.1,0.8,0.3,1c0.2,0.3,0.5,0.4,0.8,0.4c0.3,0,0.6-0.1,0.8-0.4
                   C81.4,71.2,81.5,70.8,81.5,70.4z"/>
               <path fill="#662483" d="M83.6,72.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.2,0.4-0.2s0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
                   c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.2-0.4,0.2c-0.2,0-0.3-0.1-0.4-0.2C83.6,72.4,83.6,72.3,83.6,72.1z"/>
               <path fill="#662483" d="M86.5,68.2l1.1,2.4l1.1-2.4h1.1l-2.1,4.7l-2.1-4.7H86.5z"/>
               <path fill="#662483" d="M93.9,70.7h-3c0,0.4,0.1,0.6,0.3,0.9c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
                   c0.2-0.1,0.3-0.3,0.5-0.7l0.8,0.5c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.3,0.2-0.5,0.2
                   c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-1-0.6-1.7c0-0.7,0.2-1.2,0.5-1.7c0.4-0.4,0.8-0.6,1.4-0.6
                   c0.6,0,1.1,0.2,1.4,0.6c0.3,0.4,0.5,1,0.5,1.7L93.9,70.7z M93,69.8c-0.1-0.5-0.5-0.8-1-0.8c-0.1,0-0.2,0-0.3,0.1
                   c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.1,0.3H93z"/>
           </g>
           <g>
               <path fill="#662483" d="M36.5,42.2L36.4,43h-2.1l-0.2,1.5h2.1l-0.1,0.8h-2.1l-0.3,2.8h-0.9l0.7-6H36.5z"/>
               <path fill="#662483" d="M40.2,44.5l-0.3,2.1c0,0.2,0,0.3-0.1,0.5c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.4,0.4
                   c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.4-0.5-0.4-0.9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3l0.2-1.9h0.9
                   l-0.3,2c0,0.1,0,0.2,0,0.2s0,0.1,0,0.2c0,0.4,0.2,0.6,0.7,0.6c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.7l0.3-2.1H40.2z"/>
               <path fill="#662483" d="M42.2,44.5l0,0.3c0.3-0.2,0.7-0.3,1-0.3c0.4,0,0.6,0.1,0.9,0.3c0.2,0.2,0.3,0.5,0.3,0.8
                   c0,0.2,0,0.4,0,0.6l-0.2,1.9h-0.9l0.3-2.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.4,0.1-0.6,0.2
                   c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.4l-0.2,1.7h-0.9l0.4-3.6H42.2z"/>
               <path fill="#662483" d="M49.7,41.6l-0.8,6.5h-0.8l0-0.4c-0.2,0.2-0.4,0.3-0.5,0.4c-0.2,0-0.3,0-0.6,0c-0.5,0-0.9-0.2-1.2-0.5
                   c-0.3-0.3-0.5-0.7-0.5-1.3c0-0.5,0.2-1,0.5-1.4c0.4-0.4,0.8-0.5,1.3-0.5c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.3,0.6,0.5l0.4-3.5
                   H49.7z M48.3,46.2c0-0.3-0.1-0.6-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.6-0.3,0.9
                   c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.6-0.1,0.8-0.4C48.2,46.9,48.3,46.5,48.3,46.2z"/>
               <path fill="#662483" d="M54.2,44.5l-0.4,3.6h-0.9l0.1-0.4c-0.4,0.3-0.7,0.4-1.1,0.4c-0.5,0-0.9-0.2-1.2-0.5
                   c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.6,0.2-1,0.5-1.4c0.4-0.4,0.8-0.5,1.4-0.5c0.3,0,0.5,0,0.7,0c0.2,0.1,0.4,0.2,0.6,0.4l0.1-0.5H54.2
                   z M53.2,46.2c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.3,0.6-0.3,0.9
                   c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.6-0.1,0.8-0.4C53,46.9,53.2,46.6,53.2,46.2z"/>
               <path fill="#662483" d="M58.1,44.9L58,45.8c-0.2-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.3-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.8,0.3
                   c-0.2,0.2-0.3,0.5-0.3,0.9c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.9-0.4L57.7,48
                   c-0.3,0.1-0.6,0.2-0.9,0.2c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.5,0.2-1,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5
                   C57.3,44.5,57.7,44.6,58.1,44.9z"/>
               <path fill="#662483" d="M59.9,44.5l-0.4,3.6h-0.9l0.4-3.6H59.9z M60.1,42.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1
                   c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
                   C60.1,42.5,60.1,42.6,60.1,42.8z"/>
               <path fill="#662483" d="M64.6,46.3c0,0.5-0.2,1-0.6,1.3c-0.4,0.4-0.9,0.5-1.4,0.5c-0.6,0-1.1-0.2-1.4-0.5
                   c-0.4-0.3-0.6-0.8-0.6-1.3c0-0.5,0.2-1,0.6-1.3c0.4-0.4,0.9-0.5,1.5-0.5c0.6,0,1,0.2,1.4,0.5C64.4,45.3,64.6,45.8,64.6,46.3z
                    M63.7,46.3c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9
                   c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.4C63.6,46.9,63.7,46.6,63.7,46.3z M64.1,42.8l-1.2,1.1
                   l-0.4-0.3l1-1.3L64.1,42.8z"/>
               <path fill="#662483" d="M66.6,44.5l0,0.3c0.3-0.2,0.7-0.3,1-0.3c0.4,0,0.6,0.1,0.9,0.3c0.2,0.2,0.3,0.5,0.3,0.8
                   c0,0.2,0,0.4,0,0.6l-0.2,1.9h-0.9l0.3-2.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-0.4,0.1-0.6,0.2
                   c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.4l-0.2,1.7h-0.9l0.4-3.6H66.6z"/>
           </g>
           <rect x="4" y="62.3" fill="#662483" width="90.8" height="1.6"/>
       </g>
   </g>
   <g id="Layer_1">
   </g>
   <g id="svg2">
   </g>
   <g id="Fondos_Color">
   </g>
   <g id="Layer_2">
   </g>
   </svg>
   
    )
}

export default infocentro
