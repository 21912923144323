const PREFIX = 'CATEGORIAS'

// SETTERS

export const SET_CATEGORIAS = `${PREFIX}/SET_CATEGORIAS`
export const SET_CATEGORIAS_CHECK = `${PREFIX}/SET_CATEGORIAS_CHECK`
//GETTERS

export const GET_CATEGORIAS = `${PREFIX}/GET_CATEGORIAS`
export const GET_CATEGORIAS_CHECK = `${PREFIX}/GET_CATEGORIAS_CHECK`

export const CREAR_CATEGORIAS = `${PREFIX}/CREAR_CATEGORIAS`
export const UPDATE_CATEGORIAS = `${PREFIX}/UPDATE_CATEGORIAS`

export const DELETE_CATEGORIAS = `${PREFIX}/DELETE_CATEGORIAS`

 

  export const  UPDATE_FILTERS = `${PREFIX}/ UPDATE_FILTERS`