// @Vendors
import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

// @Componenets
import FormRecoverPass from './RecoverForm'
import FormRecoverPass2 from './RecoverForm2'

// @Material ui
import  {
  Tooltip,
  Avatar,
  IconButton,
  Typography,
  CircularProgress,
  Grid,
  Grow
} from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

// @ActionsTypes
import { MODAL_CLOSE } from 'state/app/app.actionTypes'

// @Services
import { recoverpassword, recoverpassword2 } from 'state/auth/auth.services'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RecoverPass() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    pregunta: "",
    username: "",
    showForm: true,
    showResponse: false,
    loading: false,
    errors: { name: "", value: null },
    success: null
  })

  const handleRecoverPass = useCallback( async (data1, actions) => {
    setState({...state, loading: true})
    const res = await recoverpassword(data1)
    const {data} = res

    if(data.data.pregunta_seguridad) {
      const {username} = data.data
      const {nombre} = data.data.pregunta_seguridad
      setState({
        ...state,
        showForm: false,
        username: username,
        pregunta: nombre,
        loading: false,
        showResponse: true
      })
    } else {
      setState({
        ...state,
        loading: false,
        errors: {
          name: "form1",
          value:"Correo inválido"
        }
      })
    }
    actions.setSubmitting(false)
  }, [state, setState])

  const handleRecoverPass2 = useCallback( async (data2, actions) => {
    setState({...state, loading: true})
    // username && respuesta
    const {respuesta} = data2
    const {username} = state
    const payload = {username, respuesta}
    
    const res = await recoverpassword2(payload)
    const {data} = res
    if(data.success) {
      setState({
        ...state,
        loading: false,
        showResponse: false,
        showForm: false,
        errors: {
          name: "",
          value:"",
          showResponse: false,
        },
        success: data.message
      })
      setTimeout(() => dispatch({ type: MODAL_CLOSE }), 3000)
    } else {
      setState({
        ...state,
        loading: false,
        errors: {
          name: "form2",
          value: data.message
        },
        success: null
      })
    }
    actions.setSubmitting(false)
  }, [state, dispatch])

  return  (
    <Grow in={true} timeout={1000}>
      <Grid container justify="center" alignItems="center">
        <Grid item md={6} align="center">
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar contraseña
          </Typography>
        </Grid>
        
        <Grid item xs={12}>
          { state.showResponse && <IconButton 
            aria-label="Atrás"
            onClick={() => setState({ 
              pregunta: "",
              username: "",
              showForm: true,
              showResponse: false,
              loading: false,
              errors: { name: "", value: null },
              success: null
            })}
          >
            <Tooltip title="Regresar">
              <ArrowBackIosIcon/>
            </Tooltip>
           </IconButton>
          }
        </Grid>

        <Grid xs={12} align ="center">
          { !state.loading && state.errors.value && <p className="app-title-second" style={{fontSize:"20px",color:"#d65b5b"}}>{state.errors.value}</p> }
          { state.showForm && 
            <FormRecoverPass 
              onSubmit={handleRecoverPass}
              disabledButton={state.loading}
            />
          }
          { state.showResponse &&
            <div>
              <div style={{ display: state.loading && 'none' }}>
                <FormRecoverPass2
                  pregunta={state.pregunta}
                  onSubmit={handleRecoverPass2}
                  disabledButton={state.loading}
                />
              </div>
              <div>{ state.loading && <CircularProgress /> }</div>
            </div>
          }
          { state.success && <h1 style={{textAlign:"center",color: "var(--lightGreen)"}}>{state.success}</h1> }
        </Grid>
      </Grid>
    </Grow>
  )
}