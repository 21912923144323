import React from 'react'

function movilnet() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 146.5 55"  >
   <g id="Capa_1">
       <g id="Layer_1_1_">
       </g>
       <g>
           <path fill="#662483" d="M79.3,14.3c2.3,0,3.5-1.3,3.8-3c0.3-1.7-0.9-3.2-2.9-3.2c-1.9,0-3.4,1-3.7,3
               C76.2,12.8,77.4,14.3,79.3,14.3z"/>
           <polygon fill="#662483" points="78.7,34.8 82.1,15.5 76,15.5 72.6,34.8 		"/>
           <path fill="#662483" d="M47.3,14.8c-0.5,0-1.1,0-1.7,0.1v4.4c0.5-0.1,1-0.2,1.6-0.1c2.4,0.3,3.2,3.2,2.3,6.7l0,0
               c-0.8,2.9-2,4.7-3.9,5.2v4.4c5.5-0.6,9-3.9,10-9.7C56.9,18.8,52.9,14.8,47.3,14.8z M45.7,14.9c-3.9,0.4-9,2.5-10.2,9.5
               c-1.1,6.3,2.2,11.1,8.5,11.1c0.6,0,1.2,0,1.7-0.1V31c-0.5,0.1-1,0.2-1.6,0.1c-2.2-0.2-3.2-3.3-2.2-6.9c0.8-3,2.3-4.5,3.9-4.9V14.9
               z"/>
           <polygon fill="#662483" points="88.4,34.8 93.2,7.7 87.1,7.7 82.4,34.8 		"/>
           <polygon fill="#662483" points="74.9,15.5 69.1,15.5 64.1,27.2 63.4,15.5 57,15.5 59.6,34.8 65.8,34.8 		"/>
           <path fill="#662483" d="M33,17.2c-1.1-1.4-3.4-2.4-6.5-2.4c-2.2,0-4.1,0.3-6,0.9c-1.2-0.6-2.7-0.9-4.5-0.9c-3.1,0-5.8,0.7-8.4,1.8
               L4.5,34.8h6.1l2.7-15.4c0.5-0.2,1.2-0.3,2.1-0.3c0.8,0,1.4,0.2,1.7,0.6c0.3,0.4,0.4,0.9,0.3,1.7L15,34.8h6l2.4-13.4l0.3-2
               c0.5-0.2,1.2-0.3,2.1-0.3c0.8,0,1.4,0.2,1.7,0.6c0.3,0.4,0.4,0.9,0.3,1.7l-2.4,13.5h6.1l2.4-13.4C34.2,19.6,33.8,18.2,33,17.2z"/>
           <path fill="#662483" d="M111,21.4c0.3-1.9-0.1-3.2-0.9-4.2c-1.1-1.4-3.4-2.4-6.5-2.4c-3.2,0-5.8,0.7-8.4,1.8l-3.2,18.2h6.1
               l2.7-15.4c0.5-0.2,1.2-0.3,2.1-0.3c0.8,0,1.4,0.2,1.7,0.6c0.3,0.4,0.4,0.9,0.3,1.7l-2.4,13.5h6.1L111,21.4z"/>
           <path fill="#662483" d="M133.4,33.8c0.5,0.5,3.1,2.4,8.4,0.7l0.7-4.1c-0.8,0.3-2.6,0.8-3.6,0.1c-0.6-0.5-0.7-1.2-0.4-2.9l1.4-7.7
               h3.4l0.8-4.4h-3.4l1-5.6l-6.3,0.8L132.2,28c-0.4,2.1-0.3,3.9,0.6,5.2C133,33.4,133.6,34,133.4,33.8z"/>
           <path fill="#662483" d="M127.5,34.1l0.5-4.1c-1.7,0.9-4.2,1.2-5.9,1.1v4.3C123.7,35.4,125.5,35,127.5,34.1L127.5,34.1z
                M122.1,26.8h8.3c0.2-1.3,0.4-2.6,0.4-3.8v-0.9c0-1.5-0.3-2.9-0.9-4c-1.3-2.4-3.2-3.4-6.3-3.4c-0.5,0-1,0-1.5,0.1v4.2
               c0.2-0.1,0.4-0.1,0.7-0.1l0,0c0.9,0,1.5,0.2,2,0.9c0.5,0.7,0.6,2.1,0.4,3.4h-3.1V26.8z M122.1,31.1c-0.3,0-0.6-0.1-0.9-0.2
               c-1.8-0.6-2.7-1.9-2.8-4.1h3.7v-3.6h-3.2c0.6-2.2,1.7-3.7,3.2-4.1v-4.2c-4.3,0.5-8,3.4-9.4,9.4c-0.1,0.7-0.2,1.3-0.2,1.9v1
               c0.1,2.9,1.3,5.4,3.1,6.6c1.5,1,3.7,1.8,6.5,1.6V31.1z"/>
           <path fill="#662483" d="M140.2,46.7v-1c0.4-0.1,0.8-0.2,1.3-0.6l0.4,0.9c-0.4,0.3-0.7,0.4-1.1,0.6
               C140.6,46.7,140.4,46.7,140.2,46.7L140.2,46.7z M140.2,44.4v-1l0,0c0.2,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2
               c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0v-1c0.1,0,0.3,0,0.4,0
               c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.1,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7c0,0.3-0.1,0.5-0.2,0.7
               c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.5,0.3-0.9,0.4C140.7,44.4,140.5,44.4,140.2,44.4z M140.2,41.1v1c-0.2,0-0.3,0.1-0.4,0.2
               c-0.2,0.2-0.4,0.5-0.5,1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0v1c-0.1,0-0.3,0-0.5,0c-0.2,0-0.3,0-0.5,0
               c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.3,0.1,0.5,0.2,0.7c0.2,0.1,0.4,0.2,0.7,0.2
               c0.1,0,0.2,0,0.2,0v1c-0.2,0-0.4,0-0.6,0c-0.7,0-1.2-0.2-1.5-0.5c-0.4-0.3-0.5-0.8-0.5-1.5c0-0.3,0-0.6,0.1-0.8
               c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.3,0.2-0.5,0.4-0.7c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.3,0.6-0.5,0.9-0.6
               C139.8,41.2,140,41.2,140.2,41.1L140.2,41.1z M119.9,46.3V46l0.5-0.8c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2
               c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.1-0.1-0.2-0.2-0.3
               c-0.1-0.1-0.3-0.1-0.5-0.2l-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.2,0-0.5,0.1-0.7
               c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.7-0.2,1.1-0.2c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.2,0.8,0.4l-0.6,0.9
               c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.2,0.4,0.6,0.5l0.6,0.2
               c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.1,0.1,0.3,0.1,0.5c0,0.3-0.1,0.5-0.2,0.8
               c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.7,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.1-0.2
               C120.5,46.5,120.2,46.4,119.9,46.3L119.9,46.3z M119.9,44c0.1-0.4,0.1-0.7,0.1-1.1c0-0.3,0-0.5-0.1-0.7V44L119.9,44z M131.1,45.2
               c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.1,0.5l-1.2,0.5c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2,0.2-0.4,0.3-0.7,0.5c-0.3,0.1-0.6,0.2-0.9,0.2
               c-0.4,0-0.7-0.1-1-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.1,0-0.2,0-0.4c0-0.1,0-0.3,0.1-0.5l0.7-3.5l1.5-0.3l-0.7,3.5
               c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.3-0.1
               c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3l0.7-3.5l1.4-0.3L131.1,45.2L131.1,45.2z M137.2,42.2
               c0,0.2,0,0.5-0.1,0.8l-0.8,3.7h-1.4l0.7-3.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1
               c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.3-0.6,0.6l-0.7,3.5H132l0.8-3.6c0.1-0.3,0.1-0.6,0.1-0.8c0-0.2,0-0.4-0.1-0.6l1.3-0.5
               c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2
               c0.3,0,0.6,0.1,0.8,0.3C137.1,41.6,137.2,41.8,137.2,42.2z M119.9,42.2V44c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.5-0.3,0.9-0.6,1.2
               c-0.3,0.4-0.6,0.6-1,0.9c-0.2,0.1-0.5,0.2-0.8,0.3v-1.1c0.1,0,0.2-0.1,0.2-0.2c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.2-0.6,0.3-0.9
               c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1v-1.1c0.2,0,0.4-0.1,0.6-0.1
               c0.6,0,1.1,0.2,1.5,0.5C119.7,41.8,119.9,42,119.9,42.2L119.9,42.2z M119.9,46l-0.1,0.2c0,0,0.1,0,0.1,0.1V46z M117.5,41.2v1.1
               c-0.1,0-0.2,0.1-0.2,0.2c-0.2,0.2-0.3,0.5-0.4,0.8c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.3,0.1,0.5,0.2,0.6
               c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1v1.1c-0.2,0-0.4,0.1-0.6,0.1c-0.6,0-1.1-0.2-1.4-0.5c-0.3-0.3-0.5-0.8-0.5-1.4
               c0-0.5,0.1-1,0.2-1.4c0.2-0.4,0.4-0.8,0.6-1.2c0.3-0.3,0.6-0.6,1-0.8C117,41.3,117.3,41.2,117.5,41.2L117.5,41.2z M105.7,46.7V46
               c0.2,0,0.3-0.1,0.4-0.1c0.2-0.1,0.5-0.2,0.7-0.5l0.3,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.2
               C106.1,46.6,105.9,46.7,105.7,46.7C105.7,46.7,105.7,46.7,105.7,46.7L105.7,46.7z M105.7,44.2v-0.6c0.1,0,0.3,0,0.4-0.1
               c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.6-0.2
               c-0.1,0-0.3,0-0.4,0.1v-0.7c0.2,0,0.4-0.1,0.5-0.1c0.5,0,0.9,0.1,1.1,0.4c0.3,0.2,0.4,0.6,0.4,1c0,0.2,0,0.4-0.1,0.6
               c-0.1,0.2-0.2,0.3-0.4,0.5c-0.2,0.2-0.5,0.4-0.9,0.5C106.2,44.2,105.9,44.2,105.7,44.2L105.7,44.2z M114.6,42.2
               c0,0.2,0,0.5-0.1,0.8l-0.8,3.7h-1.4l0.7-3.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1
               c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.3-0.6,0.6l-0.8,3.5h-1.4l0.8-3.6c0.1-0.3,0.1-0.6,0.1-0.8c0-0.2,0-0.4-0.1-0.6l1.3-0.5
               c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2
               c0.3,0,0.6,0.1,0.8,0.3C114.5,41.6,114.6,41.8,114.6,42.2z M105.7,41.3V42c0,0-0.1,0-0.1,0c-0.2,0.1-0.3,0.2-0.5,0.4
               c-0.1,0.2-0.3,0.3-0.4,0.6c-0.1,0.2-0.2,0.5-0.3,0.7c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.6,0l0.1,0v0.6
               c-0.2,0-0.4,0-0.7,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.4,0.1,0.7,0.3,0.8
               c0.2,0.2,0.5,0.3,0.7,0.3c0.1,0,0.3,0,0.4,0v0.7c-0.2,0-0.4,0-0.6,0c-0.2,0-0.5,0-0.7-0.1c-0.2-0.1-0.4-0.2-0.5-0.4
               c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.3,0-0.5,0.1-0.8c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.3,0.2-0.5,0.4-0.7
               c0.1-0.2,0.3-0.4,0.5-0.6C104.8,41.6,105.2,41.4,105.7,41.3L105.7,41.3z M95.8,46.5v-0.7c0,0,0.1-0.1,0.1-0.1
               c0.3-0.2,0.6-0.5,0.9-1l0.6-2.7c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1,0-0.3,0-0.5,0c-0.2,0-0.5,0-0.7,0.1v-0.7c0.2-0.1,0.5-0.1,0.8-0.1
               c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.3,0.1,0.4,0.2c0,0.1-0.1,0.3-0.1,0.5
               c-0.1,0.2-0.1,0.4-0.2,0.7l-1.2,5.8l-0.8,0.2l0.6-3C96.3,46.1,96,46.4,95.8,46.5L95.8,46.5z M102.4,45c0,0.2-0.1,0.4-0.1,0.5
               c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.2,0.2,0.2l-0.6,0.5c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7
               c-0.3,0.4-0.7,0.7-1,0.9c-0.3,0.2-0.7,0.3-1,0.3c-0.4,0-0.6-0.1-0.8-0.2c-0.2-0.2-0.3-0.4-0.3-0.8c0-0.1,0-0.3,0-0.4
               c0-0.1,0-0.3,0.1-0.4l0.8-3.6l0.8-0.2l-0.7,3.5c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0.1,0.5
               c0.1,0.1,0.2,0.1,0.4,0.1c0.3,0,0.6-0.1,0.9-0.3c0.4-0.2,0.7-0.6,1-1l0.7-3.3l0.8-0.1L102.4,45z M95.8,41.3V42
               c-0.2,0.1-0.5,0.2-0.7,0.4c-0.1,0.1-0.2,0.3-0.4,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.5-0.2,0.7c0,0.2-0.1,0.5-0.1,0.6
               c0,0.4,0.1,0.7,0.2,0.8c0.1,0.2,0.3,0.2,0.6,0.2c0.3,0,0.5-0.1,0.8-0.2v0.7c0,0,0,0,0,0c-0.3,0.1-0.6,0.2-1,0.2
               c-0.4,0-0.8-0.2-1-0.5c-0.2-0.3-0.4-0.7-0.4-1.3c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.2-0.5,0.4-0.7
               c0.1-0.2,0.3-0.4,0.5-0.6C94.9,41.7,95.3,41.4,95.8,41.3L95.8,41.3z M86,46.5v-1.1c0,0,0,0,0.1,0c0.1-0.1,0.2-0.2,0.2-0.3
               c0.1-0.1,0.1-0.2,0.2-0.3l0.6-2.5c0,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0-0.5,0.1c0,0-0.1,0-0.1,0.1v-1.1
               c0.3-0.1,0.5-0.1,0.8-0.1c0.3,0,0.6,0,1,0.1c0.3,0.1,0.6,0.2,0.8,0.3c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3-0.1,0.5-0.2,0.7l-0.4,2
               c-0.1,0.2-0.1,0.4,0,0.6c0,0.2,0.1,0.3,0.2,0.5l-1.2,0.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.4
               C86.3,46.3,86.1,46.4,86,46.5L86,46.5z M91.5,38.7c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.4c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3-0.1,0.5
               l-0.9,4c-0.1,0.3-0.1,0.6-0.1,0.7c0,0.2,0,0.3,0,0.3c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.1,0,0.2,0l0.1,0.8c-0.3,0.1-0.6,0.1-0.9,0.1
               c-0.4,0-0.7-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.1,0-0.2,0-0.4c0-0.2,0.1-0.4,0.1-0.6l0.9-4c0.1-0.4,0.1-0.7,0.2-0.9
               c0-0.2,0-0.5,0-0.6L91.5,38.7z M86,41.2v1.1c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.2,0.6
               C85,44,85,44.2,85,44.4c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.3-0.1
               c0.1,0,0.2-0.1,0.3-0.2v1.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.5,0.2-0.8,0.2c-1,0-1.5-0.6-1.5-1.7c0-0.7,0.1-1.3,0.3-1.8
               c0.2-0.5,0.5-1,0.8-1.3c0.3-0.3,0.6-0.5,1-0.6C85.7,41.3,85.8,41.2,86,41.2L86,41.2z M75.7,46.7v-1c0.4-0.1,0.8-0.2,1.3-0.6
               l0.4,0.9c-0.4,0.3-0.7,0.4-1.1,0.6C76,46.7,75.9,46.7,75.7,46.7L75.7,46.7z M75.7,44.4v-1l0,0c0.2,0,0.3-0.1,0.4-0.1
               c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.3,0v-1
               c0.1,0,0.3,0,0.5,0c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.1,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.7
               c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.5,0.3-0.9,0.4C76.2,44.4,76,44.4,75.7,44.4L75.7,44.4z M83.1,42.2
               c0,0.2,0,0.5-0.1,0.8l-0.8,3.7h-1.4l0.7-3.5c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c-0.1,0-0.2-0.1-0.3-0.1
               c-0.2,0-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.3-0.6,0.6l-0.7,3.5H78l0.8-3.6c0.1-0.3,0.1-0.6,0.1-0.8c0-0.2,0-0.4-0.1-0.6l1.3-0.5
               c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2
               c0.3,0,0.6,0.1,0.8,0.3C83,41.6,83.1,41.8,83.1,42.2L83.1,42.2z M83.3,39.8c-0.2,0.2-0.4,0.4-0.7,0.5c-0.2,0.1-0.5,0.2-0.8,0.2
               c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
               c-0.1,0.1-0.2,0.2-0.4,0.3l-0.4-0.8c0.2-0.2,0.4-0.4,0.7-0.5c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
               c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.4-0.3L83.3,39.8z M75.7,41.1v1
               c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.3,0.5-0.5,1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0v1c-0.1,0-0.3,0-0.4,0
               c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.3,0.1,0.5,0.2,0.7
               c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.2,0,0.2,0v1c-0.2,0-0.4,0-0.6,0c-0.7,0-1.2-0.2-1.5-0.5c-0.4-0.3-0.5-0.8-0.5-1.5
               c0-0.3,0-0.6,0.1-0.8c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.3,0.2-0.5,0.4-0.7c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.3,0.6-0.5,0.9-0.6
               C75.3,41.2,75.5,41.2,75.7,41.1L75.7,41.1z M64,46.5v-0.7c0.1,0,0.1-0.1,0.2-0.1c0.3-0.2,0.5-0.5,0.7-0.9l0.6-2.7
               c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.8,0.2v-0.7c0.3-0.1,0.6-0.1,0.9-0.1c0.6,0,1.1,0.1,1.5,0.4c0,0.1-0.1,0.3-0.1,0.5
               c-0.1,0.2-0.1,0.4-0.1,0.6l-0.5,2.2c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.2l-0.7,0.5
               c-0.2-0.2-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7C64.6,46,64.3,46.2,64,46.5L64,46.5L64,46.5z M72.4,42.5
               c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.2,0.2,0.4,0.6,0.5l0.6,0.2
               c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.1,0.1,0.3,0.1,0.5c0,0.3-0.1,0.5-0.2,0.8
               c-0.1,0.2-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.7,0.2-1.2,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.7-0.3-1-0.4
               l0.6-1c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.4-0.1,0.6-0.2
               c0.2-0.1,0.2-0.3,0.2-0.5c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.3-0.1-0.5-0.2l-0.6-0.2c-0.3-0.1-0.5-0.3-0.7-0.5
               c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.2,0-0.5,0.1-0.7c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.5-0.3,0.8-0.4c0.3-0.1,0.7-0.2,1.1-0.2
               c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.2,0.8,0.4L72.4,42.5z M61.3,38.8c0,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.3,0,0.5c0,0.2,0,0.4-0.1,0.5
               c0,0.2-0.1,0.3-0.1,0.5l-1,4.7c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.3,0.2,0.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0l0.1,0.5
               c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.1,0-0.2
               c0-0.1,0-0.2,0-0.2l1-4.9c0.1-0.4,0.1-0.7,0.1-0.9c0-0.3,0-0.5-0.1-0.7L61.3,38.8L61.3,38.8z M64,41.4V42l0,0
               c-0.2,0.1-0.5,0.3-0.7,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0,0.6
               c0,0.3,0.1,0.6,0.2,0.7c0.1,0.1,0.3,0.2,0.6,0.2c0.2,0,0.5-0.1,0.7-0.2v0.7c-0.3,0.2-0.7,0.3-1,0.3c-0.2,0-0.3,0-0.5-0.1
               c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.5,0.1-0.8c0.1-0.3,0.1-0.5,0.2-0.8
               c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.6c0.3-0.3,0.6-0.5,1-0.7C63.8,41.4,63.9,41.4,64,41.4z"/>
       </g>
   </g>
   <g id="Layer_1">
   </g>
   <g id="svg2">
   </g>
   <g id="Fondos_Color">
   </g>
   <g id="Layer_2">
   </g>
   </svg>
   
    )
}

export default movilnet
