// @Vendors
import React, {useState, useCallback}from 'react'
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup'
import { upperFirst } from 'lodash'

// @MateriaUI
import {
	Grid,
	InputAdornment,
	Tooltip,
	IconButton,
	Grow
} from '@material-ui/core'

// @Services
import {getQuestions} from 'utils/helpers'

//@Icons
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

// @Components
import AppField from 'components/Form/AppField'
import SimpleSelect from 'components/Form/SimpleSelect'

// @React ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha"

const initial = {
    username: '',
    password: '',
    passwordRepeat: '',
    pregunta_seguridad_id: '',
    respuesta_seguridad : ''
}

function CrearCuenta({ onSubmit = () => console.log("no submit"), initialValues = initial, ActionsButtons="No buttons" }) {
	
	const [preguntas, setPreguntas] = useState([])
	const [state, setState] = useState({
		showpassword: false,
		showResponse: false
	})

	

	const obtenerPreguntas = React.useCallback( async () => {
		const res = await getQuestions()
		const {data} = res
		const newData = []
	
		for(let i = 0; i < data.data.length; i++) {
			newData.push({ label: upperFirst(data.data[i].nombre.toLowerCase()), value: data.data[i].id})
		}

		setPreguntas(newData)
	},[setPreguntas])

	React.useEffect(() => {
		obtenerPreguntas()
	}, [obtenerPreguntas])

	const handleClickShowpassword = useCallback(() => {
		setState({ ...state, showpassword: !state.showpassword })
	}, [setState, state])
	
	const handleClickShowResponse = useCallback(() => {
		setState({ ...state, showResponse: !state.showResponse })
	},[setState, state])

	const handleMouseDownpassword = useCallback((event) => {
    	event.preventDefault()
  	},[])

	// Expresion regullar para validar solo numeros y algunos caracteres especiales
	const passwordRegex = RegExp("^[a-zA-Z0-9!#$%&/_.,]+$")
	// ESquema para validacion de formulario general
	const formSchemas = Yup.object().shape({
		username: Yup.string().email("¡Ingrese un correo válido!").required("¡Campo requerido!"),
		password: Yup.string().min(6, "¡Mínimo 6 caractéres!").max(255, "¡Máximo 255 caractéres!").required("¡Campo requerido!").matches(passwordRegex, "¡Introduzca una contraseña válida!"),
		passwordRepeat: Yup.string().oneOf([Yup.ref("password"),null], '¡Las contraseñas no coinciden!').required("¡Campo requerido!"),
		pregunta_seguridad_id: Yup.string().required("¡Campo requerido!"),
		respuesta_seguridad: Yup.string().min(3, "¡Debes ingresar 3 o más caractéres!").required("¡Campo requerido!")
	})

	if(!preguntas.length) return

	return (
		<Formik
			initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={formSchemas}
          >
          {({errors, touched, values, handleChange, handleBlur, handleSubmit}) => (
          	<Grow in={true} timeout={1000}>
	          	<form onSubmit={handleSubmit}>
					<Grid container maxwidth="md" spacing={3}>
						{/* username */}
						<Grid item sm={12} md={4}>
							<AppField
								id="username"
								name="username"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.username}
								label="Correo electrónico"
								helperText={<ErrorMessage name="username"/>}
								error={errors.username && touched.username}
							/>
						</Grid>
						{/* password */}
						<Grid item sm={12} md={4}>
							<AppField 
								id="password"
								name="password"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.password}
								label="Contraseña"
								type={state.showpassword ? "text" : "password"}
								InputProps={{
						            endAdornment: 
						             <InputAdornment position="end">
						                <Tooltip title="Se recomienda utilizar una contraseña que contenta letras, números y al menos un caractér especial (!#$%&/_.,)">
							                <IconButton
							                  aria-label="toggle password visibility"
							                  onClick={handleClickShowpassword}
							                  onMouseDown={handleMouseDownpassword}
							                >
							                  {state.showpassword ? <Visibility /> : <VisibilityOff />}
							                 </IconButton>
										</Tooltip>
					              	</InputAdornment>

						        }}
								helperText={<ErrorMessage name="password"/>}
								error={errors.password && touched.password}
							/>
						</Grid>
						{/* password_REPEAT */}
						<Grid item sm={12} md={4}>
							<AppField
								id="passwordRepeat"
								name="passwordRepeat"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.passwordRepeat}
								label="Repetir Contraseña"
								type="password"
								helperText={<ErrorMessage name="passwordRepeat"/>}
								error={errors.passwordRepeat && touched.passwordRepeat}
							/>
						</Grid>
					</Grid>
					<Grid container maxwidth="md" spacing={3}>
						{/* pregunta_seguridad_id */}
						<Grid item sm={12} md={6}>
		                	<SimpleSelect
		                		name="pregunta_seguridad_id"
		                		value={values.pregunta_seguridad_id}
		                		onChange={handleChange}
		                		onBlur={handleBlur}
		                		label="Pregunta de seguridad (*)"
			                    items={preguntas}
		                		helpertext={<ErrorMessage name="pregunta_seguridad_id"/>}
		                		error={errors.pregunta_seguridad_id && touched.pregunta_seguridad_id}
		                		/>
						</Grid>
					{/* RESPUESTA */}
						<Grid item sm={12} md={6}>
							<AppField
								name="respuesta_seguridad"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.respuesta_seguridad}
								label="Respuesta de seguridad"
								type={state.showResponse ? "text" : "password"}
								InputProps={{
						            endAdornment: 
						             <InputAdornment position="end">
						                <IconButton
						                  aria-label="toggle password visibility"
						                  onClick={handleClickShowResponse}
						                  onMouseDown={handleMouseDownpassword}
						                >
						                  {state.showResponse ? <Visibility /> : <VisibilityOff />}
						                </IconButton>
					              	</InputAdornment>

						        }}
								helperText={<ErrorMessage name="respuesta_seguridad"/>}
								error={errors.respuesta_seguridad && touched.respuesta_seguridad}
							/>
						</Grid>
						<Grid item sm={12}>
	                		{ActionsButtons}
	                	</Grid>
	                </Grid>
                <ReCAPTCHA
		              sitekey="6LdieNwZAAAAAKfJtGJxhfx7vx0VN40pVXqGCPjI"
		              size="invisible"
		            />
	            </form>
	        </Grow>
          )}
        </Formik>
	)

}

export default React.memo(CrearCuenta)