import { Map } from 'immutable'
import {
  REQUEST_STARTED,
  REQUEST_FINISHED,
  REQUEST_FAILURE,
  REQUEST_SUCCESS
} from './auth.actionsTypes'

const initialState = Map().merge({
  loading: false,
  serverErrors: null,
  statusError: null,
  serversuccess: null,
})

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REQUEST_STARTED:
      return state.merge({
        loading: true,
      })

    case REQUEST_FINISHED:
      return state.merge({
        loading: false,
      })

    case REQUEST_FAILURE:
      return initialState.merge({
        loading: false,
        serverErrors: payload.serverErrors,
        statusError: payload.statusError,
      })

      case REQUEST_SUCCESS:
        return state.merge({
          serversuccess: payload,
        })
    default:
      return state
  }
}

export default authReducer