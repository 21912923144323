// @Vendors
import React from "react";
import { Helmet } from "react-helmet";

// @Components
import Maintenance from "pages/Maintenance";

// @Material UI
import useStyles from "./useStyles";
import FAVICO from "statics/images/logos/logoMujer.jpg";

import { MAINTENANCE } from "constants/index";

function NotFoundFrame({ title = "Not title assigned", children, user = {} }) {
  const { classes } = useStyles();

  if (MAINTENANCE) return <Maintenance user={user} />;

  return (
    // ** Caja principal en Grid
    <div className={classes.container}>
      <Helmet>
        <title>MujeresTI | {title}</title>
        <link rel="icon" type="image/jpg" href={FAVICO} sizes="64x64" />
      </Helmet>
      <main role="main" >
        {children}
      </main>
    </div>
  );
}

export default React.memo(NotFoundFrame);
