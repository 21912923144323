//  @Vendors
import React from "react";

// @Material UI
import { Grow, Container } from "@material-ui/core";

//@Components
import CursosHome from "./secciones/CursosHome";
import MainHome from "./secciones/MainHome";
import EntesHome from "./secciones/EntesHome";

export default function Inicio() {
  return (
    <Grow in={true} timeout={1000}>
      <Container>
        <MainHome />
        <CursosHome />
        <EntesHome />
      </Container>
    </Grow>
  );
}
