import React from "react";
import CursosLogo from 'statics/images/textures/cursosLogo';


const ImgCurso = ({ imagen, nombre, classe = "" }) => {
  return (
    <>
      {imagen ? (
        <figure className={`cursoThumbial ${classe}`}>
          <img
            src={`http://10.11.11.35:5201/img/${imagen}`}
            alt={`mujerTI curso de ${nombre}`}
          />
        </figure>
      ) : (
        <figure>
          <CursosLogo />
        </figure>
      )}
    </>
  );
};

export default ImgCurso;
