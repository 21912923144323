//  @Vendors
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Lottie from "react-lottie"

// @Material UI
import {
  Avatar,
  Link,
  Typography,
  CircularProgress,
  Grid,
  Grow
} from "@material-ui/core"

import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

// @Components
import AuthForm from "./AuthForm"

// @Stactis
import Animation from "statics/animations/drawkit-grape-animation-10-LOOP.json"

// @ActionsTypes
import { LOGIN } from "state/auth/auth.actionsTypes"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paperBox: {
    padding: "25px",
    boxSizing: "border-box",
    background: "#f9f9f9",
    borderRadius: "10px",
    boxShadow: "0 5px 10px 0 #c3b8b8"
  },
  containerLogin:{
    width:"100%"
  }
}))

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Animation,
  
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
    id: 'some-id',
  },
}

export default function Login() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.authReducer.get("loading"))
  const { push } = useHistory()

  // Function send data from login
  const submit = React.useCallback(
    (values) => {
      dispatch({ type: LOGIN, payload: values })
    },
    [dispatch]
  )


  const href = React.useCallback(
    (url) => {
      push(url)
    },
    [push]
  )

  return (
    <Grow in={true} timeout={1000}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item sm={6}>
          <Lottie id="test" options={defaultOptions} isPaused={true} style={{maxWidth:"600px", margin: '0'}} />
        </Grid>

        <Grid item sm={4} align="center" className={classes.containerLogin}>
          <div className={classes.paperBox}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography align="center" component="h1" variant="h5">
              {loading ? "Iniciando..." :  "Iniciar Sesión"}
            </Typography>
            <div style={{ display: loading ? "none" : "block" }}>
              <AuthForm onSubmit={submit} disabledButton={loading} />
              <br />

              <Grid container spacing={3} justify="space-between">
                <Grid item xs={6} align="left">
                  <Link
                    href="#"
                    component="button"
                    onClick={() => href("/recuperar-cuenta")}
                    variant="body2"
                  >
                    Recuperar contraseña
                  </Link>
                </Grid>
                <Grid item xs={6} align="right">
                  <Link
                    href="#"
                    componet="button"
                    onClick={() => href("/registro")}
                    variant="body2"
                  >
                    Crear cuenta nueva
                  </Link>
                </Grid>
              </Grid>
            </div>
            {loading && (
              <div style={{textAlign: "center"}}>
                <br />
                <CircularProgress />
              </div>
            )}
          </div>
        </Grid>
      </Grid>    
    </Grow>
  )
}
