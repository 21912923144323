// @Vendors
import React from 'react'

//@Material ui
import { Typography } from '@material-ui/core'

//@Styles
import useStyles from '../usuarios/useStyles'

// @Components
import TransferList from './components/TransferList.js'

function UsuariosRoles() {
	const { classes } = useStyles()

	return (
		<div className={classes.paperContent}>
			<Typography variant="h5" color="primary" align="center">
				Grupo de roles y usuarios
	      	</Typography>
	      	<br/><br/>
	      	<TransferList />
	    </div>
	)
}

export default UsuariosRoles