// @Vendors
import React from "react"
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// @Material UI
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import useStyles from "./useStyles"

//@Stactis
import ImgMain from "statics/images/textures/ImgMain"


const MainHome = () => {
  const { classes } = useStyles()
  const { push } = useHistory()
  const { isAuthenticated } = useSelector(state => state.usersReducer.get('user'))
  
  return (
    <section id="main-home" className={classes.section}>
       <Typography
        variant="h4"
        color="primary"
        align="center"
        className={classes.titulo}
      >
        Mujer, la Innovación está en TI 
      </Typography>
      <Grid container className={classes.root} alignItems="flex-end" justify="space-between" >
        <Grid item className={classes.itemInfo} md={5} sm={12}>
          <div className={classes.infoMain}>
            <Typography 
              className={classes.mainText}
              variant="body2"
              align="justify"
            >
              Mujer, la Innovación está en TI (Telecomunicaciones e Informática), es una política de Visibilización e Inclusión de las niñas, las adolescentes y las mujeres, por
              parte del Ministerio del Poder Popular para Ciencia y Tecnología, que trabajan en los
              sectores telecomunicaciones e informática, o que requieren de las herramientas que ofrece este sector, para su formación, emprendimiento o mejorar su calidad de vida, por medio del uso consciente y racional de las tecnologías.

              <p>Es por ello, que desde este portal, se ofrecerá información de actividades dirigidas a las mujeres, de cualquier edad, que deseen conocer más sobre las tecnologías de
              telecomunicaciones e informática, teniendo o no, previamente, conocimientos en el área.</p>
            </Typography>            
          
          { !isAuthenticated &&
            <Button
              className={classes.registerBtn}
              variant="contained"
              color="primary"
              onClick={() => push("/registro")}
            >
              Registro
            </Button>
          }
          </div>
        </Grid>

        <Grid item className={classes.itemInfo}  md={7} sm={12}>
          <div className="svg-main container-svg">
            <ImgMain />
          </div>
        </Grid>
      </Grid>
    </section>
  )
}

export default MainHome
