//@vendors
import React, { useState, memo } from "react";
import {upperFirst} from 'lodash'

//@material-ui
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";

const ListFacilitadores = ({ facilitadores }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <RecordVoiceOverIcon color="primary"/>
        </ListItemIcon>
        <ListItemText primary="Facilitadores" />
        {open & facilitadores.length ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Divider />{" "}
      {facilitadores.length && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {facilitadores.map(( { nombre, apellido }, index) => (
                <div key={`facitador_${index}`}>
                  <ListItem button>
                    <ListItemText 
                      primary={`${upperFirst(nombre)} ${upperFirst(apellido)}`} 
                     />
                  </ListItem>
                  <Divider />
                </div>
              )
            )}
          </List>
        </Collapse>
      )}
    </List>
  );
};

export default memo(ListFacilitadores);
