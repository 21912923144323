//@Vendors
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

//@Material-UI
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom:"35px"
  },
  btn: {
    fontSize: "9px",
    margin: "3px",
    padding: "5px",
    borderRadius: "15px",
  },
}));

const ListCategory = ({ categorias = [] }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography display="inline">
        Categoría:{" "}
      </Typography>
      {categorias.map(({ categoria }, index) => (
          <Button
            key={`categoria_${index}$`}
            variant="outlined"
            color="primary"
            className={classes.btn}
          >
            {categoria.nombre}
          </Button>
       ))}
    </div>
  );
};

export default ListCategory;
