/* eslint-disable no-use-before-define */

// @Vendors
import React, { useCallback } from 'react'
import {Formik, ErrorMessage} from 'formik'
import * as Yup from 'yup'

// @MateriaUI
import {
	Grid,
	Grow
} from '@material-ui/core'

// @Components
import SimpleSelect from 'components/Form/SimpleSelect'
import AppField from 'components/Form/AppField'

// @Services
import  {
	getGrados,
	getEstados,
	getProfesiones
} from 'utils/helpers'

const initial = {
	profesion_id: '',
	grado_id: '',
	area_estudio_id:'',
	plantel: '',
    estado: '',
    municipio: ''
}


function DatosEstudios({ onSubmit = () => console.log("no submit"), initialValues = initial, ActionsButtons="No buttons"}) {
	
	// Establecer valores default de los selects
	const [grados, setGrados] = React.useState([])
	const [estados, setEdos] = React.useState([])
	const [profesiones, setProfesiones] = React.useState([])

	// Expresion regular para validar solo numeros en los selects
	const regexProfesion = RegExp("^[0-9]+$")
	const formSchemas = Yup.object().shape({
		profesion_id: Yup.string().required("¡Campo obligatorio!"),
		grado_id: Yup.string().required("¡Campo obligatorio!").matches(regexProfesion, "Valor de Grado fuera de rango"),
		area_estudio_id: Yup.string().required("¡Campo obligatorio!"),
		// plantel: Yup.string().required("¡Campo obligatorio!")
	})

	const obtenerGrados = React.useCallback( async () => {
		const res = await getGrados()
		const { data } = res
		let nextValues = []

		for(let i = 0; i < data.data.length; i++) {
			nextValues.push({ label: data.data[i].nombre, value: data.data[i].id })
		}
		setGrados(nextValues)
	}, [setGrados])



	const obtenerEstados = useCallback( async () => {
		try {
			const res = await getEstados()
			const { data } = res
			
			let payload = []
			for(let i=0; i < data.data.length; i++) {
				payload.push({ label: data.data[i].nombre, value: data.data[i].id })
			}
			setEdos(payload)
		} catch(err) {
			console.log(err)
		}
	},[])


	const obtenerProfesiones = useCallback( async () => {
		const res = await getProfesiones()
		const { data } = res
		let nextValues = []

		for(let i = 0; i < data.data.length; i++) {
			nextValues.push({ label: data.data[i].nombre, value: data.data[i].id })
		}
		setProfesiones(nextValues)
	},[])

	React.useEffect( () => {
		obtenerEstados()
	}, [obtenerEstados])

	React.useEffect(() => { 
		obtenerGrados()
	}, [obtenerGrados])

	React.useEffect(() => {
		obtenerProfesiones()
	}, [obtenerProfesiones])

	return (
		<Formik
			initialValues={initial}
            onSubmit={onSubmit}
            validationSchema={formSchemas}
          >
          {({errors, touched, values, handleChange, handleBlur, handleSubmit}) => (
          	<form onSubmit={handleSubmit} style={{width:"100%"}}> 
          		<Grow in={true} timeout={1000}>
				<Grid container spacing={3}>
			
					{/* profesion_id */}
		            <Grid item sm={12} md={4}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="profesion_id"
	                		name="profesion_id"
	                		value={values.profesion_id}
							onChange={handleChange}
							onBlur={handleBlur}
	                		label="Profesión(*)"
		                    items={profesiones}
	                		helpertext={<ErrorMessage name="profesion_id"/>}
	                		error={errors.profesion_id && touched.profesion_id} />
		            </Grid>
		    
		            {/* grado_id */}
		            <Grid item sm={12} md={4}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="grado_id"
	                		name="grado_id"
	                		value={values.grado_id}
							onChange={handleChange}
							onBlur={handleBlur}
	                		label="Grado instrucción(*)"
		                    items={grados}
	                		helpertext={<ErrorMessage name="grado_id"/>}
	                		error={errors.grado_id && touched.grado_id} />
		            </Grid>
		    
		            {/* area_estudio_id */}
		            <Grid item sm={12} md={4}>
						<AppField
						id="area_estudio_id"
						name="area_estudio_id"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.area_estudio_id}
						label="Área de estudio (*)"
						helperText={<ErrorMessage name="area_estudio_id"/>}
						error={errors.area_estudio_id && touched.area_estudio_id}
						/>
		            </Grid>

					{/* estado */}
		            {/*<Grid item sm={12} md={3}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="estado"
	                		name="estado"
	                		value={values.estado}
							onChange={(e) => obtenerMunicipios(e,handleChange)}
							onBlur={handleBlur}
	                		label="Estado (*)"
		                    items={estados}
	                		helpertext={<ErrorMessage name="estado"/>}
	                		error={errors.estado && touched.estado} />
		            </Grid>*/}
		    
		            {/* municipio */}
		            {/*<Grid item sm={12} md={3}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="municipio"
	                		name="municipio"
	                		value={values.municipio}
							onChange={(e) => obtenerPlanteles(e, handleChange)}
							onBlur={handleBlur}
	                		label="Municipio (*)"
		                    items={municipios}
	                		helpertext={<ErrorMessage name="municipio"/>}
	                		error={errors.municipio && touched.municipio} />
		            </Grid>*/}

		        	{/* plantel */}
		            {/* <Grid item sm={12} md={6}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="plantel"
	                		name="plantel"
	                		value={values.plantel}
							onChange={handleChange}
							onBlur={handleBlur}
	                		label="Plantel (*)"
		                    items={planteles}
	                		helpertext={<ErrorMessage name="plantel"/>}
	                		error={errors.plantel && touched.plantel} />
		            </Grid> */}
			
			        <Grid item sm={12} md={12}> {ActionsButtons} </Grid>
       			</Grid>
          		</Grow>
          	</form>
          )}
        </Formik>
	)
}

export default React.memo(DatosEstudios)