// @Vendors
import React from 'react'
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup'

// @Moment
import moment from 'moment'
import 'moment/locale/es'

// @MateriaUI
import {
	Grid,
	Grow
} from '@material-ui/core'

// @COmponents
import SimpleSelect from 'components/Form/SimpleSelect'
import AppField from 'components/Form/AppField'

const initial = {
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    fecha_nacimiento: moment().format("YYYY-MM-DD"),
    genero: 'F'
}


function DatosPersonales({ onSubmit = (data) => console.log("no submit"), initialValues = initial, ActionsButtons="No buttons" }) {

	// Expresion regular para validar solo letras y espacios en blanco
	const onlyLetters = RegExp("^[a-zA-Z ]+$")
	const onlyWoman = RegExp("^[F]+$")

	// ESquema para validacion de formulario general
	const formSchemas = Yup.object().shape({
		primer_nombre: Yup.string().required("¡Campo obligatorio!").matches(onlyLetters, "Valor fuera de rango"),
		segundo_nombre: Yup.string().matches(onlyLetters, "Valor fuera de rango"),
		primer_apellido: Yup.string().required("¡Campo obligatorio!").matches(onlyLetters, "Valor fuera de rango"),
		segundo_apellido: Yup.string().required("¡Campo obligatorio!").matches(onlyLetters, "Valor fuera de rango"),
		fecha_nacimiento: Yup.date().required("¡Debes ingresar tu fecha de nacimiento!"),
		genero: Yup.string().required("¡Campo obligatorio!").min(1, "Valor fuera de rango").max(1, "Valor fuera de rango").matches(onlyWoman, "Solo registro femenino")
	})

	return (
		<Formik
			initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={formSchemas}
          >
          {({errors, touched, values, handleChange, handleBlur, handleSubmit}) => (
          	<Grow in={true} timeout={1000}>
	          	<form onSubmit={handleSubmit}> 
					<Grid container maxwidth="lg" spacing={3}>
						{/*  primer_nombre */}
			            <Grid item sm={12} md={4}>
							<AppField
								autoFocus={true}
								name="primer_nombre"
								value={values.primer_nombre}
								onChange={handleChange}
								onBlur={handleBlur}
								label="Primer nombre (*)"
								helperText={<ErrorMessage name="primer_nombre"/>}
								error={errors.primer_nombre && touched.primer_nombre}
							/>
			            </Grid>

			        	{/*  segundo_nombre */}
			            <Grid item sm={12} md={4}>
							<AppField
								name="segundo_nombre"
								value={values.segundo_nombre}
								onChange={handleChange}
								onBlur={handleBlur}
								label="Segundo nombre"
								helperText={<ErrorMessage name="segundo_nombre"/>}
								error={errors.segundo_nombre && touched.segundo_nombre}
							/>
			            </Grid>

			        	{/*  primer_apellido */}
			            <Grid item sm={12} md={4}>
							<AppField
								name="primer_apellido"
								value={values.primer_apellido}
								onChange={handleChange}
								onBlur={handleBlur}
								label="Primer apellido (*)"
								helperText={<ErrorMessage name="primer_apellido"/>}
								error={errors.primer_apellido && touched.primer_apellido}
							/>
			            </Grid>

			        	{/*  segundo_apellido */}
			            <Grid item sm={12} md={4}>
							<AppField
								name="segundo_apellido"
								value={values.segundo_apellido}
								onChange={handleChange}
								onBlur={handleBlur}
								label="Segundo apellido (*)"
								helperText={<ErrorMessage name="segundo_apellido"/>}
								error={errors.segundo_apellido && touched.segundo_apellido}
							/>
			            </Grid>

			        	{/*  fecha_nacimiento */}
			            <Grid item sm={12} md={4}>
							<AppField
								type="date"
								name="fecha_nacimiento"
								value={values.fecha_nacimiento}
								onChange={handleChange}
								onBlur={handleBlur}
								label="Fecha de nacimiento"
								helperText={<ErrorMessage name="fecha_nacimiento"/>}
								error={errors.fecha_nacimiento && touched.fecha_nacimiento}
							/>
			            </Grid>
			            
			            {/*  genero */}
			            <Grid item sm={12} md={4}>
						<SimpleSelect 
	                		style={{minWidth: "160px"}}
	                		id="genero"
	                		name="genero"
	                		value={values.genero}
							onChange={handleChange}
							onBlur={handleBlur}
	                		label="Género (*)"
		                    items={[
		                    	{ value:'F', label: "Femenino" },
		                    	{ value:'M', label: "Masculino" }
		                    ]}
	                		helpertext={<ErrorMessage name="genero"/>}
	                		error={errors.genero && touched.genero} />
	                	</Grid>
	       			</Grid>	       			
	       			<Grid item sm={12} md={12}> {ActionsButtons} </Grid>
	          	</form>
          	</Grow>
          )}
        </Formik>
	)

}

export default React.memo(DatosPersonales)