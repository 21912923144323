const PREFIX = 'APP';

export const CLEAR_SERVER_ERRORS = `${PREFIX}/CLEAR_SERVER_ERRORS`;
export const REQUEST_FAILURE = `${PREFIX}/REQUEST_FAILURE`;
export const REQUEST_FINISHED = `${PREFIX}/REQUEST_FINISHED`;
export const REQUEST_STARTED = `${PREFIX}/REQUEST_STARTED`;

export const REQUEST_SUCCESS = `${PREFIX}/REQUEST_SUCCESS`;
export const CLEAR_SERVER_SUCCESS = `${PREFIX}/CLEAR_SERVER_SUCCESS`;

export const MODAL_OPEN = `${PREFIX}/MODAL_OPEN`;
export const MODAL_CLOSE = `${PREFIX}/MODAL_CLOSE`;

export const DIALOG_CONFIRM_OPEN = `${PREFIX}/DIALOG_CONFIRM_OPEN`;
export const DIALOG_CONFIRM_CLOSE = `${PREFIX}/DIALOG_CONFIRM_CLOSE`;