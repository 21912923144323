// @Vendors
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

// Material iu
import {
	Button,
	Grid,
	Typography,
	Grow
} from '@material-ui/core'

// @Icons
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// @ActionsTypes
import { SET_LABORA } from 'state/users/users.actionsTypes'

// @Hooks
import useSettingsForms from '../hooks/useSettingsForms'
import DatosLaborales from '../forms/DatosLaborales'

function DatosLaboralesView({ initialValues, onSubmit, ActionsButtons }) {
	const dispatch = useDispatch()
	
	const { labora } = useSelector( state => state.usersReducer.get('laborales'))
	const { nextPrev } = useSettingsForms()

	const handleNot = React.useCallback(() => {
		dispatch({ type: SET_LABORA, payload: false})
		nextPrev(4)
	}, [nextPrev, dispatch])

	const handleYes = React.useCallback(() => {
		dispatch({ type: SET_LABORA, payload: true})
	}, [dispatch])

	return (
		<Grow in={true} timeout={1000}>
			<Grid container justify="center" spacing={2} alignItems="center">
				<Grid item xs={12}>
					<Typography align="center" color="primary" variant="h6">
						¿Actualmente usted labora?
					</Typography>
				</Grid>
				<Grid item>
					<Button variant="outlined" color="primary" onClick={handleNot}>No</Button>
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary" onClick={handleYes}>Si</Button>
				</Grid>

				<div style={{ margin: "0px 0px 35px", width: "100%" }} />
				
				{ /* Mostrar formulario si labora, si no labora continuar */ }
				<Grid container justify="center">
					{ labora
						? <DatosLaborales 
							initialValues={initialValues}
							onSubmit={onSubmit}
							ActionsButtons={ActionsButtons} />
						:	<Button 
							align="center"
							type="button"
							variant="outlined"
							color="primary"
							startIcon={<ArrowBackIosIcon />}
							onClick={() => nextPrev(2)}>
								Anterior
							</Button>
					}
				</Grid>
			</Grid>
		</Grow>
	)
}

export default React.memo(DatosLaboralesView)