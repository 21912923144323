// @Vendors
import React from 'react'
import { NavLink } from "react-router-dom"
import clsx from 'clsx'

// @Material ui
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"

// @Icons
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
  item: {
    position: "relative",
    textDecoration: "none",
    color: theme.palette.primary.main,
    display: "grid",
    placeItems: "center",
    padding: "10px",
    boxSizing: "border-box",
    "&  p": {
      margin: "0",
    },
    "&:hover": {
      color: "#652385",
      position: "relative",
    },
    "&:hover::before": {
      position: "absolute",
      content: '""',
      bottom: "-15%",
      height: "6px",
      width: "6px",
      background: theme.palette.primary.main,
      left: "0",
      right: "0",
      margin: " 0 auto",
      borderRadius: "5px",
    },
    "&.active": {
      color: "#652385",
      position: "relative",
    },
    "&.active::before": {
      position: "absolute",
      content: '""',
      bottom: "-15%",
      height: "6px",
      width: "6px",
      background: theme.palette.primary.main,
      left: "0",
      right: "0",
      margin: "auto",
      borderRadius: "5px",
    },
  },
  btnLogin: {
    textDecoration: "none",
    padding:'5px 10px',
    margin: '0px -12px',
    [theme.breakpoints.down('sm')]: {
      margin:'10px auto',
    },
    "& button": {
      borderRadius: "60px",
    },
  },
  btnContainer:{
    [theme.breakpoints.down('sm')]: {
      margin:'10px auto',
    },
  }
}));

function ControlBtnLogin({ auth= false, logout=null }){
	const classes = useStyles()

	return (
		<div className={classes.btnContainer}>
			{
				!auth ? 
				<NavLink className={clsx(classes.btnLogin, classes.item) } to="/iniciar-sesion">
					<Button variant="outlined" color="primary" startIcon={<AccountCircleIcon />}>
					Iniciar Sesión
					</Button>
				</NavLink>
			:
				<Button variant="outlined" color="primary" onClick={logout} startIcon={<ExitToAppIcon />}>
				Cerrar sesión
				</Button>
			}
		</div>
	)
} 
export default  React.memo(ControlBtnLogin)