import React from 'react'

function ipostel() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 114.9 101.1" >
   <g id="Capa_1">
       <g id="Layer_1_1_">
       </g>
   </g>
   <g id="Layer_1">
       <g>
           <path fill="#662483" d="M28.6,74.6c0,0.1-0.1,0.3-0.1,0.4c-0.1,0-0.2,0-0.3,0c-0.1,0.1-0.3,0.3-0.4,0.5c-0.8,1-1.5,2.1-2.1,3.2
               c-0.3,0.6-0.5,1.1-0.6,1.8c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.3-0.2c0-0.1,0-0.2,0-0.3c0,0.1-0.1,0.2-0.1,0.2
               c0.1-0.9,0.3-1.7,0.8-2.5c-0.3-0.1-0.3-0.1-0.3-0.3c0.2-0.2,0.5-0.3,0.8-0.6c-0.2,0-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3
               l0-0.1c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.2,0.2-0.2,0.3-0.5c-0.2-0.1-0.4-0.2-0.7-0.1
               c-0.3,0.1-0.7,0.2-1,0.3c-0.3,0.1-0.7,0.2-1,0.4c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.1,0.9-0.2c-1,0.2-1.9,0.5-2.8,0.9
               c-0.4,0.2-0.7,0.3-1.1,0.5s-0.7,0.4-0.9,0.7c-0.2,0.2-0.2,0.3-0.2,0.5c0.1,0.1,0.2,0.2,0.3,0.1c0,0,0.1,0,0.1,0
               c0.3,0.1,0.7,0.2,1,0.3c0.2,0,0.5,0,0.7,0c0.2-0.2,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0.1c0.1,0,0.2,0.1,0.2,0.2c0,0.3-0.1,0.4-0.4,0.5
               c-0.6,0.1-1.3,0.2-1.9,0.4c-0.3,0.1-0.7,0.2-1,0.3c-0.4,0.1-0.8,0.3-1.1,0.5c-1.3,0.6-2.5,1.5-3.6,2.4c-0.6,0.5-1.1,1.1-1.7,1.6
               c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0l0.8-0.9c0,0-0.1-0.1-0.1-0.1c-0.7,0.8-1.4,1.6-2,2.5c-0.1,0-0.2,0.1-0.2,0.2
               c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.3,0c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2,0.3-0.2,0.7-0.3,1
               c-0.1,0.3,0.2,0.8,0.5,0.9c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.7,0,1-0.1c0,0,0,0,0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0l0.5-0.1
               l2-0.5c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.1,0.4-0.2c1.4-0.4,2.7-1,4-1.5c0.3-0.1,0.7-0.3,1-0.4
               c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.5-0.2c0.2-0.2,0.6-0.3,0.8-0.4c0.1,0,0.2,0,0.2-0.1c0.2-0.1,0.4-0.2,0.6-0.2
               c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2-0.1,0.3-0.2c-0.1,0-0.2,0-0.2,0
               c-0.4,0.2-0.8,0.3-1.2,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c0.7-0.4,1.4-0.7,2.1-1.1c0.1-0.2,0.1-0.4,0.4-0.4l0.3,0.3
               c-0.1,0.3-0.2,0.6-0.6,0.8c-0.3,0.1-0.6,0.4-0.9,0.5c-0.2,0.1-0.4,0.2-0.6,0.3c-1.1,0.4-2,0.9-3.1,1.4c-1.1,0.5-2.2,1-3.4,1.4
               c-1.1,0.4-2.1,0.7-3.3,1c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.6,0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1
               l0,0c-0.1,0-0.2,0-0.2,0c-0.4,0.1-0.9,0.1-1.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.1-0.6-0.4-0.8-0.8c-0.1-0.2-0.1-0.5,0-0.7
               c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.1-0.2,0.1-0.2c0.1-0.3,0.3-0.6,0.5-0.9c0.1-0.1,0.2-0.2,0.2-0.3
               c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.2-0.2c0.4-0.4,0.7-0.8,1.1-1.3
               c0.2-0.2,0.5-0.4,0.7-0.7c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.4-0.3,0.8-0.6,1.2-1
               c0.2-0.2,0.5-0.3,0.7-0.5c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0-0.1,0.2-0.1,0.3-0.2c0.5-0.3,1.1-0.6,1.7-0.9
               c0.2-0.1,0.3-0.2,0.5-0.1c0.1,0,0.1,0,0.2-0.1l-0.1,0.1c0,0,0,0-0.1,0c0.2,0,0.3-0.1,0.8-0.4c0,0,0,0-0.1-0.1
               c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1-0.1-0.3-0.1-0.4-0.3c-0.2-0.3-0.3-0.6,0-0.8l0.1,0.2
               c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.2,0.4c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.3,0.2-0.4c0.2-0.3,0.5-0.4,0.7-0.6c0.5-0.3,1-0.6,1.6-0.8
               c0,0,0,0-0.1-0.1c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.7-0.3,1-0.5c0.4-0.2,0.8-0.3,1.2-0.2c0.4,0,0.7,0,1.1,0c0.1,0,0.2,0,0.3,0.1
               c0.4-0.7,1-1.2,1.5-1.9C28.5,74.4,28.5,74.4,28.6,74.6 M12.6,90.6c0.1,0,0.2,0,0.3,0.1l0.1-0.2h0.5v-0.1c-0.3,0-0.5,0-0.8,0.1
               c0,0,0.1,0,0.1,0.1C12.7,90.6,12.6,90.6,12.6,90.6L12.6,90.6z M15.4,83.5C15.4,83.5,15.4,83.5,15.4,83.5l-0.3,0.1c0,0,0.1,0,0.1,0
               C15.3,83.6,15.3,83.5,15.4,83.5 M17.4,82C17.4,82,17.4,82,17.4,82c-0.5,0.3-0.9,0.6-1.4,1c0,0,0,0,0,0c0,0,0.1,0,0.1,0
               C16.6,82.6,17,82.3,17.4,82C17.4,82,17.4,82,17.4,82 M21.9,77.4c-0.1,0-0.2,0-0.3,0.1C21.7,77.5,21.8,77.5,21.9,77.4"/>
           <path fill="#662483" d="M24.6,84.2c0,0-0.1,0.1-0.1,0.1c0-0.1-0.1-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0-0.1,0-0.1-0.1
               c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.6-1c0.1-0.2,0.3-0.4,0.4-0.7c0,0,0,0,0-0.1c0.1-0.2,0.1-0.4,0.2-0.6
               c0.3-0.6,0.5-1.2,0.8-1.9c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.2-0.5,0.4-1.1,0.5-1.6c0-0.1,0-0.2,0.1-0.3
               c0,0,0-0.1,0-0.1c-0.1-0.3,0-0.4,0.1-0.6c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0.1,0.3,0.3,0.3,0.4c0,0.3,0,0.6-0.1,0.9
               c-0.3,0.9-0.6,1.8-0.9,2.6c-0.2,0.6-0.5,1.2-0.7,1.7c-0.3,0.6-0.5,1.1-0.9,1.7c-0.2,0.4-0.5,0.8-0.7,1.2c0,0.1-0.1,0.1-0.1,0.1
               c0-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.3,0.2-0.5c-0.1,0-0.1,0.1-0.1,0.1c0-0.1,0.1-0.2,0.1-0.3l0.6-1.3
               c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.4-0.4,0.8-0.6,1.2c-0.3,0.5-0.6,1.1-0.9,1.6C24.6,84.3,24.6,84.3,24.6,84.2
                M27.3,79.3L27.3,79.3C27.3,79.3,27.3,79.3,27.3,79.3C27.3,79.3,27.3,79.3,27.3,79.3C27.3,79.3,27.3,79.3,27.3,79.3"/>
           <path fill="#662483" d="M44,78l-0.1,0.2c-0.2,0.1-0.2,0-0.4-0.1c0,0-0.1,0-0.1,0c-0.1-0.1-0.1-0.2-0.2-0.3
               c-0.1-0.1-0.3-0.1-0.5-0.1c-0.4,0-0.9,0-1.3,0.1c-0.7,0.1-1.4,0.4-2.1,0.6c-0.2,0.1-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.2-0.6,0.3
               c-0.4,0.2-0.8,0.5-1.2,0.7c-1.8,1.1-3.5,2.3-4.9,3.9c-0.4,0.5-0.9,1-1.2,1.6c-0.2,0.4-0.4,0.8-0.4,1.2c0,0.3-0.1,0.5,0,0.8
               c0.1,0.2,0.2,0.4,0.4,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.8,0,1.6-0.1,2.3-0.3c0.6-0.2,1.1-0.4,1.7-0.5c0.1,0,0.1,0,0.2-0.1l0.1,0.1
               c0.9-0.4,1.9-0.8,2.7-1.3c-0.4,0.1-0.6,0.3-1,0.4c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0.6-0.3,1.2-0.7,1.8-1.1c0.5-0.3,0.9-0.7,1.4-1
               c0.2-0.1,0.3-0.3,0.5-0.4c0,0,0.1-0.1,0.1-0.1c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.3,0.5-0.5,0.7-0.8c0.1-0.1,0.1-0.2,0.2-0.2
               c-0.1,0-0.2,0-0.2,0L42.5,82c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.3,0.2-0.4,0.4-0.4c0.2,0,0.4,0,0.6,0.2c0.1,0.1,0.1,0.1,0.1,0.2
               c0,0.1,0,0.1,0.1,0.2c0,0.1-0.1,0.3-0.1,0.5c0,0.1,0,0.1-0.1,0.2c-0.2,0.3-0.4,0.5-0.6,0.8c-0.9,0.9-1.9,1.8-3,2.5
               c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.6,0.4-1.2,0.8-1.8,1l-0.8,0.4c-0.1,0.1-0.3,0.1-0.4,0l0.6-0.3
               c-0.1,0-0.1,0-0.2-0.1c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0-0.3,0.2-0.4,0.3c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0.1,0.1
               c-0.1,0-0.1,0.1-0.2,0.1c-0.5,0.2-1,0.3-1.6,0.4c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.3,0.1c-0.8,0.3-1.6,0.1-2.3-0.3
               c-0.2-0.1-0.4-0.3-0.5-0.6c0,0,0,0,0.1,0.1c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.1-0.1-0.1-0.2c-0.2-0.4-0.1-0.8,0-1.2
               c0.1-0.6,0.4-1.1,0.7-1.6c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.3
               c0.2-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.6-0.5l0.1,0.1c0.1-0.2,0.4-0.3,0.5-0.5l-0.1-0.1c1-0.8,1.9-1.5,3-2.1
               c0.5-0.2,0.9-0.4,1.4-0.6c0.1,0,0.1-0.1,0.2-0.1c0.7-0.5,1.5-0.8,2.4-1c0.3-0.1,0.5-0.1,0.8-0.1c0.1,0,0.3-0.1,0.4-0.1
               c0.1,0,0.2-0.1,0.2-0.1c0.4,0,0.7-0.1,1,0c0.4,0.1,0.8,0.5,0.9,0.9L44,78z M33.1,88c0.2,0,0.4,0,0.5-0.1
               C33.4,87.9,33.2,87.9,33.1,88 M33.6,87.9c0.1,0,0.1,0,0.3,0C33.8,87.9,33.7,87.9,33.6,87.9 M34.8,87.8c0.1,0,0.1,0,0.2,0
               c0,0,0,0,0,0C34.9,87.8,34.9,87.8,34.8,87.8L34.8,87.8z M37.8,78.9l-0.1,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c-0.8,0.4-1.6,0.9-2.3,1.4
               c0,0,0.1,0,0.1,0l0.4-0.2c0.3-0.1,0.5-0.3,0.8-0.4c0,0,0.1,0,0.1,0c0.2-0.1,0.4-0.3,0.7-0.4L37.8,78.9z M39.9,85.2
               c0.6-0.3,1.1-0.7,1.5-1.2C40.9,84.4,40.4,84.8,39.9,85.2"/>
           <path fill="#662483" d="M43.7,83.2c0-0.1,0-0.2,0-0.4c0.1-0.4,0.3-0.8,0.5-1.2c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.2,0.3-0.2
               c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3
               c-0.1,0.1,0,0.2,0,0.4c0.1,0.3,0.1,0.6,0.1,0.9c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.3-0.4,0.5-0.7,0.7c-0.2,0-0.4-0.1-0.6,0
               c-0.1,0-0.2,0.1-0.3,0.1C44.1,83.9,43.8,83.5,43.7,83.2 M44.6,82c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2
               c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3l-0.1-0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.5-0.7,0.7
               c-0.2,0.2-0.3,0.5-0.5,0.8C44.2,82.3,44.4,82.2,44.6,82 M44.9,83.4c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.3-0.3,0.4-0.5
               c0,0,0.1,0,0.1,0c0.1-0.2,0.1-0.4,0.1-0.7c-0.1,0.2-0.1,0.3-0.2,0.5c0.1-0.4,0.1-0.8-0.1-1.2l0,0.1c0,0,0,0,0-0.1h0
               c-0.3,0.2-0.5,0.4-0.8,0.7c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.5-0.2,0.8c0.1,0,0.3,0,0.4,0.1c0,0,0,0,0,0h-0.3
               c0.1,0.1,0.2,0.2,0.3,0.1C44.7,83.4,44.8,83.4,44.9,83.4"/>
           <path fill="#662483" d="M49.6,81.3c0,0.2-0.1,0.4-0.3,0.4c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0c0,0-0.1,0-0.1,0
               c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.1-0.5,0.3-0.7,0.5v0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.1,0.2-0.2,0.3
               c-0.1,0.2-0.2,0.4-0.3,0.6l-0.3-0.2c0-0.1-0.1-0.3-0.1-0.4c0.3-0.9,0.8-1.7,1.1-2.7l0.2,0.1c0,0,0,0.1,0,0.2c0.1,0,0.1,0,0.2,0
               c0,0.2-0.1,0.3-0.1,0.5c0.1,0,0.1-0.1,0.1-0.1c0.3-0.2,0.6-0.4,1-0.5c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0.1
               c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0C49.6,81.2,49.6,81.2,49.6,81.3 M47.8,82.3C47.8,82.3,47.8,82.2,47.8,82.3
               c-0.2,0-0.4,0.2-0.5,0.4c0,0,0,0.1-0.1,0.2c0.2-0.2,0.3-0.4,0.5-0.5C47.7,82.3,47.7,82.3,47.8,82.3 M49.2,81
               c-0.1-0.1-0.2-0.1-0.3,0c-0.3,0.1-0.6,0.2-0.9,0.4C48.4,81.3,48.8,81.2,49.2,81"/>
           <path fill="#662483" d="M49.2,83.8c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.4,0.3-0.5c0,0,0-0.1,0.1-0.1c0,0.1-0.1,0.3-0.2,0.4
               c0,0,0,0.1,0,0.2l0.1,0c0.1-0.1,0.1-0.2,0.2-0.3l0.4-1.3c0,0,0-0.1,0-0.2L50,81.8c0-0.2,0.2-0.8,0.3-1c0,0,0.1,0.1,0.1,0.1
               c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0.3-0.3,0.6-0.5,1.1-0.4l-0.5,0.1c-0.2,0-0.3,0.1-0.4,0.2
               c0,0-0.1,0.1-0.1,0.2c0.2-0.2,0.4-0.2,0.6-0.3c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0c0.3,0,0.6,0.1,0.8,0.3
               c0.1,0.1,0.2,0.2,0.1,0.4c-0.1,0.1-0.1,0-0.3,0c-0.1,0-0.3-0.2-0.4-0.2c-0.3-0.1-0.5,0-0.7,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
               c-0.3,0.3-0.5,0.7-0.8,1c-0.1,0.1-0.1,0.3-0.2,0.5c0,0.1-0.1,0.2-0.2,0.3c-0.2,0-0.1-0.2-0.2-0.3c-0.1,0.1-0.1,0.2-0.2,0.3
               C49.3,83.8,49.3,83.8,49.2,83.8 M52.1,81.4c-0.1,0-0.2,0-0.3-0.1s-0.2,0-0.3,0C51.8,81.4,52,81.4,52.1,81.4
               C52.1,81.4,52.1,81.4,52.1,81.4"/>
           <path fill="#662483" d="M52.8,83.6C52.8,83.5,52.8,83.5,52.8,83.6c-0.1-0.2-0.2-0.4-0.4-0.4c0,0-0.1-0.1-0.1-0.1
               c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c-0.1,0-0.3,0.1-0.5-0.1c0-0.1,0-0.1,0-0.2c0.1,0,0.1-0.1,0.2-0.1
               l0,0c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1-0.1c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.4-0.4c0-0.1,0.1-0.1,0.2-0.2
               c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1l0.1-0.1
               c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.3-1.1,0.5c0,0-0.1,0-0.1,0
               c0,0.4,0.2,0.6,0.8,0.6c0,0,0,0-0.1-0.1c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.5-0.2c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0,0.4
               c-0.3,0.2-0.6,0.3-0.9,0.3h-0.3C53.1,83.7,52.9,83.6,52.8,83.6 M52.4,82.6c-0.1,0,0,0.1,0,0.1C52.4,82.7,52.4,82.7,52.4,82.6
                M53.9,81.4L53.9,81.4c-0.3,0.1-0.6,0.3-0.8,0.5C53.4,81.7,53.8,81.5,53.9,81.4"/>
           <path fill="#662483" d="M55.1,83.2c0-0.1,0-0.2,0-0.4c0.1-0.4,0.3-0.8,0.5-1.2c0.1-0.2,0.3-0.3,0.4-0.5c0.1-0.1,0.2-0.2,0.3-0.2
               c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.2,0.3-0.3,0.5-0.3c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3
               c-0.1,0.1,0,0.2,0,0.4c0.1,0.3,0.1,0.6,0.1,0.9c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.3-0.4,0.5-0.7,0.7c-0.2,0-0.4-0.1-0.6,0
               c-0.1,0-0.2,0.1-0.3,0.1C55.4,83.9,55.1,83.5,55.1,83.2 M56,82c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.2,0.2-0.2
               c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3L57,80.9c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.5-0.7,0.7
               c-0.2,0.2-0.3,0.5-0.5,0.8C55.6,82.3,55.8,82.2,56,82 M56.3,83.4c0.2,0,0.4-0.1,0.5-0.3c0.1-0.2,0.3-0.3,0.4-0.5c0,0,0.1,0,0.1,0
               c0.1-0.2,0.1-0.4,0.1-0.7c-0.1,0.2-0.1,0.3-0.2,0.5c0.1-0.4,0.1-0.8-0.1-1.2l0,0.1c0,0,0,0,0-0.1h0c-0.3,0.2-0.5,0.4-0.8,0.7
               c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.5-0.2,0.8c0.1,0,0.3,0,0.4,0.1c0,0,0,0,0,0h-0.3c0.1,0.1,0.2,0.2,0.3,0.1
               C56.1,83.4,56.2,83.4,56.3,83.4"/>
           <path fill="#662483" d="M67.3,77.1c-0.1,0.5-0.4,0.9-0.6,1.3c-0.3,0.6-0.7,1.2-1.1,1.8c0,0,0,0.1-0.1,0.1
               c-0.3,0.8-0.6,1.5-0.9,2.3c0,0,0,0,0,0.1c0.1,0.1,0.2,0.2,0.3,0.3c-0.1,0.3-0.2,0.6-0.4,0.8c-0.1,0.1-0.2,0.2-0.4,0.1
               c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.5,0.2-0.7c0-0.1,0.1-0.2,0.1-0.3
               c0,0-0.1,0.1-0.1,0.1c-0.5,0.5-0.9,1-1.5,1.4c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0-0.6-0.1c-0.1-0.1-0.2-0.2-0.1-0.3
               c0-0.4,0.2-0.8,0.4-1.1c0.5-0.8,1.2-1.3,2-1.7c0.3-0.1,0.5-0.2,0.9-0.2c0.2-0.3,0.5-0.6,0.6-0.9c0.2-0.4,0.4-0.8,0.6-1.2
               c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.1,0.2-0.2c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.2,0.1-0.3,0.3-0.4l0.1,0.1
               C67.2,76.6,67.4,76.8,67.3,77.1 M61.7,83.1L61.7,83.1C61.7,83,61.7,83,61.7,83.1L61.7,83.1C61.7,83.1,61.7,83.1,61.7,83.1
                M61.8,82.7c-0.1,0-0.1,0.1-0.1,0.2l0,0L61.8,82.7z M61.9,82.6C61.9,82.6,61.9,82.6,61.9,82.6L61.9,82.6
               C61.9,82.6,61.9,82.6,61.9,82.6 M63.8,81.4c-0.6,0.2-1.5,1-1.9,1.7C62.6,82.7,63.2,82.1,63.8,81.4 M62.1,82.4
               C62.1,82.3,62.2,82.3,62.1,82.4C62.1,82.3,62.1,82.3,62.1,82.4 M62.2,82.3c0,0,0.1,0,0-0.1L62.2,82.3z M63.9,81.5
               c-0.3,0.3-0.6,0.6-0.8,1l0,0c0.1-0.2,0.3-0.3,0.4-0.5C63.8,81.7,63.9,81.7,63.9,81.5"/>
           <path fill="#662483" d="M66.8,83.6C66.8,83.5,66.9,83.5,66.8,83.6c-0.1-0.2-0.2-0.4-0.4-0.4c0,0-0.1-0.1-0.1-0.1
               c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c-0.1,0-0.3,0.1-0.5-0.1c0-0.1,0-0.1,0-0.2c0.1,0,0.1-0.1,0.2-0.1
               l0,0c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1-0.1c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.4-0.4c0-0.1,0.1-0.1,0.2-0.2
               c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1l0.1-0.1
               c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.3-1.1,0.5c0,0-0.1,0-0.1,0
               c0,0.4,0.2,0.6,0.8,0.6c0,0,0,0-0.1-0.1c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.5-0.2c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0,0.4
               c-0.3,0.2-0.6,0.3-0.9,0.3h-0.3C67.2,83.7,67,83.6,66.8,83.6 M66.5,82.6c-0.1,0,0,0.1,0,0.1C66.5,82.7,66.5,82.7,66.5,82.6
                M68,81.4L68,81.4c-0.3,0.1-0.6,0.3-0.8,0.5C67.5,81.7,67.8,81.5,68,81.4"/>
           <path fill="#662483" d="M81.2,83.6C81.2,83.5,81.2,83.5,81.2,83.6c-0.1-0.2-0.2-0.4-0.4-0.4c0,0-0.1-0.1-0.1-0.1
               c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c-0.1,0-0.3,0.1-0.5-0.1c0-0.1,0-0.1,0-0.2c0.1,0,0.1-0.1,0.2-0.1
               l0,0c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1-0.1c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.4-0.4c0-0.1,0.1-0.1,0.2-0.2
               c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1l0.1-0.1
               c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.3-1.1,0.5c0,0-0.1,0-0.1,0
               c0,0.4,0.2,0.6,0.8,0.6c0,0,0,0-0.1-0.1c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.5-0.2c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0,0.4
               c-0.3,0.2-0.6,0.3-0.9,0.3h-0.3C81.5,83.7,81.4,83.6,81.2,83.6 M80.9,82.6c-0.1,0,0,0.1,0,0.1C80.9,82.7,80.9,82.7,80.9,82.6
                M82.3,81.4L82.3,81.4c-0.3,0.1-0.6,0.3-0.8,0.5C81.8,81.7,82.2,81.5,82.3,81.4"/>
           <path fill="#662483" d="M86.7,82.2c-0.1,0.5-0.3,1.1-0.4,1.6l-0.1,0.4c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3-0.1,0.4
               c-0.1,0-0.2,0.1-0.3,0c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.3l0.2-0.8c0.1-0.2,0.1-0.5,0.2-0.7c0-0.1,0-0.3,0.2-0.4
               c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c-0.1,0-0.2,0-0.3,0.1c-0.6,0.3-1.2,0.7-1.7,1.3c0,0-0.1,0.1-0.2,0.1
               c-0.1,0-0.2,0-0.2-0.1c0-0.2,0.1-0.3,0.1-0.5c0.1-0.3,0.2-0.6,0.4-0.9l0.2-0.4c0,0,0,0-0.1,0c0.1-0.3,0.3-0.5,0.5-0.8
               c0,0,0.1,0.1,0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c0.1,0.1,0.1,0.3,0,0.5c0.1-0.2,0.2-0.3,0.4-0.5c0,0,0,0.1,0,0.1s0,0.1,0,0.1
               c0.1,0.2,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.2-0.1,0.3l0.4-0.2c0,0.1,0,0.1-0.1,0.2c0.1,0,0.2-0.1,0.3-0.1c0,0-0.1,0-0.1-0.1
               c0.3-0.4,0.8-0.3,1,0C86.7,81.7,86.8,81.9,86.7,82.2 M84.6,81.2C84.6,81.2,84.6,81.3,84.6,81.2c-0.1,0.1-0.1,0.1-0.1,0.1
               C84.6,81.3,84.7,81.3,84.6,81.2"/>
           <path fill="#662483" d="M87.8,83.6C87.8,83.5,87.8,83.5,87.8,83.6c-0.1-0.2-0.2-0.4-0.4-0.4c0,0-0.1-0.1-0.1-0.1
               c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c-0.1,0-0.3,0.1-0.5-0.1c0-0.1,0-0.1,0-0.2c0.1,0,0.1-0.1,0.2-0.1
               l0,0c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1-0.1c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.4-0.4c0-0.1,0.1-0.1,0.2-0.2
               c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1l0.1-0.1
               c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.3-1.1,0.5c0,0-0.1,0-0.1,0
               c0,0.4,0.2,0.6,0.8,0.6c0,0,0,0-0.1-0.1c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.5-0.2c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0,0.4
               c-0.3,0.2-0.6,0.3-0.9,0.3h-0.3C88.1,83.7,87.9,83.6,87.8,83.6 M87.4,82.6c-0.1,0,0,0.1,0,0.1C87.4,82.7,87.4,82.7,87.4,82.6
                M88.9,81.4L88.9,81.4c-0.3,0.1-0.6,0.3-0.8,0.5C88.4,81.7,88.8,81.5,88.9,81.4"/>
           <path fill="#662483" d="M92.9,83.4c-0.6-0.1-1.2,0-1.8-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.2-0.1-0.3-0.3-0.4-0.5
               c0.1-0.2,0.3-0.3,0.5-0.5c0.4-0.2,0.7-0.4,1.1-0.6c0,0-0.1,0-0.1,0c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.2,0-0.3,0.2
               c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0.1-0.2,0.2-0.3,0.4-0.3c0.2,0,0.4-0.1,0.6-0.1c0,0,0.1,0,0.1,0
               c0,0,0-0.1,0-0.1c0.2,0,0.4-0.1,0.6,0c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.4
               c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.3-0.7,0.4c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0,0,0
               c0.8,0.1,1.6,0.2,2.4,0.3c0,0.2,0.3,0.4,0.1,0.6C93.5,83.5,93.2,83.4,92.9,83.4 M91.3,82.7c-0.1,0-0.1-0.1-0.2,0H91.3z"/>
           <path fill="#662483" d="M96.7,81.8c-0.1,0.3-0.3,0.6-0.4,0.9c0.1-0.1,0.2-0.3,0.4-0.4l0.1-0.1c0.1,0.1,0.1,0.1,0,0.2
               c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.3-0.3,0.4l-0.1-0.1c0,0,0,0-0.1,0l-0.1-0.1
               c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.1,0.1-0.2c0,0-0.1,0.1-0.1,0.1
               c-0.3,0.3-0.6,0.6-1,0.9c-0.2,0.2-0.5,0.4-0.8,0.5c-0.1,0.1-0.3,0.1-0.4,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.3
               c0-0.3,0.1-0.6,0.3-0.9c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0.2-0.3,0.3-0.5,0.6-0.8
               c0.1,0,0.1,0,0-0.1c0.1-0.3,0.4-0.5,0.6-0.6c0.1-0.1,0.2-0.1,0.3,0.1c-0.2,0.1-0.4,0-0.6,0.2c0,0,0.1,0,0.1,0
               c0.1,0,0.3-0.1,0.5-0.2c0,0,0.1,0,0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0-0.1,0-0.2,0l0.1,0.2c-0.1,0-0.1,0-0.2,0
               c-0.3,0.3-0.6,0.6-0.8,0.9c-0.2,0.3-0.4,0.7-0.6,1.1c0.2-0.1,0.4-0.2,0.6-0.3c-0.1,0-0.1,0-0.1,0c0,0,0,0,0,0
               c0.4-0.3,0.7-0.6,1.1-0.9c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.2,0.3-0.3,0.5-0.4c0.1,0,0.1-0.1,0.2-0.2l0,0c0.1,0,0.1,0,0.2,0
               c0.1,0.1,0.1,0.2,0,0.3C97,81.3,96.9,81.6,96.7,81.8C96.7,81.9,96.7,81.8,96.7,81.8 M94,82.9C94.1,82.9,94.1,82.9,94,82.9
               c0.1,0,0.1-0.1,0.2-0.2l0,0L94,82.9z M94.9,83c-0.1,0-0.3,0.2-0.5,0.4C94.6,83.3,94.7,83.2,94.9,83 M96.4,81.8c0,0-0.1,0-0.1,0
               l-0.5,0.5c0.1,0,0.1,0,0.2-0.1C96.2,82.2,96.3,82,96.4,81.8 M96.2,82.8L96.2,82.8L96.2,82.8C96.2,82.8,96.2,82.8,96.2,82.8
                M96.4,81.7C96.4,81.7,96.4,81.7,96.4,81.7L96.4,81.7C96.4,81.8,96.4,81.8,96.4,81.7C96.4,81.8,96.4,81.7,96.4,81.7"/>
           <path fill="#662483" d="M98.4,83.6C98.4,83.5,98.4,83.5,98.4,83.6c-0.1-0.2-0.2-0.4-0.4-0.4c0,0-0.1-0.1-0.1-0.1
               c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c-0.1,0-0.3,0.1-0.5-0.1c0-0.1,0-0.1,0-0.2c0.1,0,0.1-0.1,0.2-0.1
               l0,0c0.2,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1-0.1c0.1-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.3-0.2,0.4-0.4c0-0.1,0.1-0.1,0.2-0.2
               c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1l0.1-0.1
               c0.2,0.1,0.3,0.3,0.2,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.3,0.3-0.7,0.5-1,0.7c-0.4,0.2-0.7,0.3-1.1,0.5c0,0-0.1,0-0.1,0
               c0,0.4,0.2,0.6,0.8,0.6c0,0,0,0-0.1-0.1c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.5-0.2c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.3,0,0.4
               c-0.3,0.2-0.6,0.3-0.9,0.3h-0.3C98.7,83.7,98.5,83.6,98.4,83.6 M98.1,82.6c-0.1,0,0,0.1,0,0.1C98,82.7,98,82.7,98.1,82.6
                M99.5,81.4L99.5,81.4c-0.3,0.1-0.6,0.3-0.8,0.5C99,81.7,99.4,81.5,99.5,81.4"/>
           <path fill="#662483" d="M100.3,84.2c0,0-0.1,0.1-0.1,0.1c0-0.1-0.1-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0-0.1,0-0.1-0.1
               c0.1-0.1,0.1-0.2,0.2-0.3c0.2-0.3,0.4-0.6,0.6-1c0.1-0.2,0.3-0.4,0.4-0.7c0,0,0,0,0-0.1c0.1-0.2,0.1-0.4,0.2-0.6
               c0.3-0.6,0.5-1.2,0.8-1.9c0.1-0.3,0.2-0.6,0.4-0.9c0.1-0.2,0.2-0.4,0.2-0.6c0.2-0.5,0.4-1.1,0.5-1.6c0-0.1,0-0.2,0.1-0.3
               c0,0,0-0.1,0-0.1c-0.1-0.3,0-0.4,0.1-0.6c0.1,0.1,0.1,0.1,0.2,0.1c0.2,0.1,0.3,0.3,0.3,0.4c0,0.3,0,0.6-0.1,0.9
               c-0.3,0.9-0.6,1.8-0.9,2.6c-0.2,0.6-0.5,1.2-0.7,1.7c-0.3,0.6-0.5,1.1-0.9,1.7c-0.2,0.4-0.5,0.8-0.7,1.2c0,0.1-0.1,0.1-0.1,0.1
               c0-0.2,0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.3,0.2-0.5c-0.1,0-0.1,0.1-0.1,0.1c0-0.1,0.1-0.2,0.1-0.3l0.6-1.3
               c0,0,0-0.1,0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.4-0.4,0.8-0.6,1.2c-0.3,0.5-0.6,1.1-0.9,1.6C100.3,84.3,100.3,84.3,100.3,84.2
                M103,79.3L103,79.3C103,79.3,103,79.3,103,79.3C103,79.3,103,79.3,103,79.3C103,79.3,103,79.3,103,79.3"/>
           <path fill="#662483" d="M102.9,82.9c-0.1,0.1-0.1,0.2-0.2,0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.5-0.4,1-0.8,1.5-1.1l0.4-0.3
               c0,0,0.1,0,0.2-0.1l0.1,0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0.2,0.1,0.4,0,0.6c0,0.1-0.1,0.2-0.2,0.4l-0.7,1.3c0,0-0.1,0.1-0.1,0.1
               c-0.1-0.1,0-0.2,0-0.2c0-0.1,0.1-0.2,0.1-0.3c0.1-0.1,0.2-0.3,0.2-0.5c-0.1,0.1-0.3,0.3-0.2,0.5c-0.1,0-0.1,0.1-0.2,0.2
               c-0.1,0.1-0.1,0.2-0.2,0.4l-0.1-0.1c0,0-0.1,0-0.1,0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.3,0.1-0.4c0,0,0-0.1,0.1-0.1l0.4-0.7
               c0,0,0-0.1,0.1-0.2l-0.7,0.5c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.3-0.1
               c-0.3,0.3-0.7,0.5-1,0.8c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.7,0c-0.1,0-0.2-0.1-0.2-0.3c0-0.3,0.1-0.5,0.2-0.7
               c0.2-0.3,0.3-0.5,0.5-0.7c0.4-0.5,0.9-0.9,1.4-1.3c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1
               c0.2,0,0.3,0.1,0.4,0.2c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0.1,0,0.1,0,0.2,0c0.2,0.2,0.2,0.3,0.1,0.5c-0.2,0.1-0.2,0.1-0.3-0.1
               c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.2,0.1-0.3,0.2-0.5,0.3
               C103.3,82.4,103.1,82.7,102.9,82.9 M102.6,83.6c0,0-0.1,0-0.1,0C102.6,83.7,102.6,83.7,102.6,83.6 M102.7,83.5c0,0,0.1,0,0.1,0
               c0,0,0-0.1,0.1-0.1L102.7,83.5z M103.8,83.1C103.8,83.1,103.8,83.1,103.8,83.1L103.8,83.1L103.8,83.1z"/>
           <path  fill="#662483" d="M18.1,66.9h-5.7c0.4-2.2,0.9-4.8,1.3-7.8c0.4-3,0.8-6.3,1.2-9.8
               h5.7c-0.1,0.4-0.2,0.9-0.3,1.4c-0.1,0.5-0.2,1.3-0.4,2.2l-1.2,8.3c-0.1,0.8-0.2,1.6-0.3,2.6C18.4,64.7,18.2,65.8,18.1,66.9"/>
           <path  fill="#662483" d="M21.5,72.5c0.4-1.6,0.8-4,1.3-7c0.4-3,0.9-6.9,1.5-11.7
               c1.1-0.3,2.3-0.5,3.4-0.7s2.2-0.2,3.3-0.2c2.7,0,4.7,0.6,5.9,1.7c1.2,1.1,1.8,2.9,1.6,5.1c-0.2,2.3-1,4.1-2.2,5.4
               c-1.3,1.4-2.9,2-4.9,2c-0.8,0-1.5-0.1-2.1-0.3c-0.6-0.2-1.1-0.5-1.6-1l-0.4,2.8c-0.1,0.5-0.2,1.1-0.2,1.8c-0.1,0.6-0.2,1.3-0.2,2
               H21.5z M28.9,56.9c-0.1,0.4-0.2,0.8-0.3,1.3c-0.1,0.5-0.2,1.1-0.2,1.8c-0.1,1.2,0,2,0.3,2.6c0.3,0.5,0.9,0.8,1.7,0.8
               c0.8,0,1.4-0.3,1.9-0.9c0.5-0.6,0.8-1.5,0.9-2.7c0.1-1.1,0-1.9-0.4-2.5c-0.4-0.5-1-0.8-1.9-0.8c-0.3,0-0.7,0-1,0.1
               C29.5,56.7,29.2,56.8,28.9,56.9"/>
           <path  fill="#662483" d="M41.3,60.4c0.2-2.3,1.1-4.1,2.6-5.5c1.5-1.3,3.5-2,6-2
               c2.4,0,4.1,0.6,5.3,1.8c1.2,1.2,1.7,2.9,1.5,5.1c-0.2,2.3-1.1,4.2-2.6,5.5c-1.5,1.3-3.5,2-5.9,2c-2.4,0-4.2-0.6-5.3-1.8
               C41.5,64.3,41.1,62.6,41.3,60.4 M49.3,56.6c-0.8,0-1.5,0.3-1.9,0.9c-0.5,0.6-0.8,1.6-0.9,2.8c-0.1,1.1,0,1.9,0.3,2.5
               c0.3,0.5,0.9,0.8,1.7,0.8c0.8,0,1.4-0.3,1.9-0.9c0.5-0.6,0.8-1.5,0.9-2.6c0.1-1.3,0-2.1-0.3-2.7C50.7,56.8,50.1,56.6,49.3,56.6"/>
           <path  fill="#662483" d="M58.7,62.7c1,0.3,1.8,0.5,2.4,0.6c0.6,0.1,1.2,0.2,1.6,0.2
               c0.5,0,0.8-0.1,1.1-0.2c0.3-0.1,0.4-0.3,0.4-0.6c0-0.3-0.4-0.7-1.4-1.4c-0.1-0.1-0.2-0.2-0.3-0.2c-1.3-0.9-2.2-1.7-2.5-2.3
               c-0.3-0.6-0.5-1.2-0.4-2c0.1-1.3,0.7-2.2,1.7-2.9c1-0.7,2.4-1,4.2-1c0.8,0,1.6,0,2.3,0.1c0.7,0.1,1.4,0.2,2,0.4l0,3.7
               c-0.6-0.2-1.2-0.3-1.8-0.4c-0.5-0.1-1.1-0.1-1.6-0.1c-0.4,0-0.7,0.1-0.9,0.2c-0.2,0.1-0.4,0.3-0.4,0.5c0,0.3,0.3,0.7,1.1,1.2
               c0.2,0.1,0.3,0.2,0.4,0.3c1.1,0.8,1.9,1.5,2.3,2.1c0.4,0.6,0.6,1.3,0.5,2.1c-0.1,1.4-0.7,2.4-1.7,3.1c-1,0.7-2.5,1.1-4.4,1.1
               c-0.8,0-1.6-0.1-2.4-0.2c-0.8-0.1-1.5-0.3-2.3-0.5L58.7,62.7z"/>
           <path  fill="#662483" d="M78.5,60.4c0,0,0,0.1,0,0.2c0,0.1,0,0.3-0.1,0.4
               c-0.1,0.9,0,1.5,0.2,1.8c0.2,0.3,0.6,0.5,1.2,0.5c0.1,0,0.4,0,0.8-0.1c0.2,0,0.3,0,0.4-0.1L80.4,67c-0.4,0.1-0.9,0.2-1.4,0.2
               c-0.5,0-1,0.1-1.5,0.1c-1.6,0-2.8-0.4-3.5-1.1c-0.7-0.7-1-1.8-0.9-3.2l0-0.5l0.8-5.7h-2l0.5-3.5h2.2l0.3-2.2l4.9-1.1l-0.5,3.3h3
               L82,56.8h-2.9L78.5,60.4z"/>
           <path  fill="#662483" d="M95.3,66.7c-0.9,0.2-1.7,0.3-2.5,0.4c-0.8,0.1-1.6,0.1-2.3,0.1
               c-2.3,0-4-0.6-5.2-1.7c-1.1-1.1-1.6-2.8-1.4-4.9c0.2-2.4,1.1-4.3,2.5-5.7c1.5-1.4,3.4-2.1,5.8-2.1c2.1,0,3.6,0.5,4.5,1.4
               c0.9,0.9,1.3,2.3,1.1,4.1c0,0.3-0.1,0.7-0.2,1.2c-0.1,0.4-0.2,0.9-0.3,1.4l-8.1,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
               c-0.1,0.8,0.2,1.3,0.7,1.7c0.5,0.4,1.3,0.5,2.5,0.5c0.6,0,1.2,0,1.8-0.1c0.6-0.1,1.2-0.2,1.9-0.3L95.3,66.7z M89.6,58.4h3.5l0-0.1
               c0.1-0.7,0-1.3-0.2-1.7c-0.2-0.4-0.6-0.6-1.1-0.6c-0.5,0-1,0.2-1.4,0.6C90.1,57.1,89.8,57.6,89.6,58.4"/>
           <path  fill="#662483" d="M108,49.2c-0.6,3.6-1.1,6.8-1.6,9.7c-0.4,2.9-0.7,5.4-0.9,7.4
               l0,0.5h-5.4c0.7-4.1,1.2-7.5,1.6-10.3c0.4-2.8,0.7-5.3,0.9-7.4H108z"/>
           <polygon  fill="#662483" points="12.5,68.5 20.9,68.6 20.2,72.5 11.8,72.5 		"/>
           <path fill="#662483" d="M28.5,68.5l-0.5,4h77.6l0.5-4H28.5z M51.7,71.8l-0.3,0.3l-0.1-0.3l-0.4-0.1l0.4-0.2l0-0.3l0.3,0.2l0.4-0.1
               l-0.2,0.3l0.3,0.3L51.7,71.8z M54.5,70.6l-0.3,0.3l-0.1-0.3l-0.4-0.1l0.4-0.2l0-0.3l0.3,0.2l0.4-0.1l-0.2,0.3l0.3,0.3L54.5,70.6z
                M57.5,69.8l-0.3,0.3l-0.1-0.3l-0.4-0.1l0.4-0.2l0-0.4l0.3,0.2l0.4-0.1l-0.2,0.3l0.2,0.3L57.5,69.8z M60.6,69.5l-0.3,0.3l-0.1-0.3
               l-0.4-0.1l0.4-0.2l0-0.3l0.4,0.2l0.4-0.1l-0.1,0.3l0.3,0.3L60.6,69.5z M63.9,69.5l-0.1,0.3l-0.3-0.3l-0.4,0l0.3-0.3L63.2,69
               l0.4,0.1l0.4-0.2l0,0.3l0.4,0.2L63.9,69.5z M67,69.8l-0.1,0.3l-0.3-0.3l-0.4,0l0.2-0.3l-0.2-0.3l0.4,0.1l0.3-0.2l0,0.4l0.4,0.2
               L67,69.8z M70,70.6l-0.1,0.3l-0.3-0.3l-0.4,0l0.3-0.3L69.3,70l0.4,0.1l0.3-0.2l0,0.3l0.4,0.2L70,70.6z M72.9,71.8l-0.1,0.3
               l-0.3-0.3l-0.4,0l0.3-0.3l-0.2-0.3l0.4,0.1l0.3-0.2l0,0.3l0.4,0.2L72.9,71.8z"/>
           <path fill="#662483" d="M41,26c0.9-11,10.4-19.5,21.6-19.3c-0.3,0-0.6-0.1-0.8-0.1c-11.5-0.7-21.6,7.9-22.4,19.2
               c-0.9,11.3,7.8,21,19.3,21.7c0.3,0,0.6,0,0.8,0C48.4,46.5,40.2,37,41,26"/>
           <path fill="#662483" d="M73.7,18.1c-2.9-5.3-8.3-8.7-14.3-8.8h-0.6c-1.1,0-2.1,0.1-3.2,0.4c-6,1.3-10.5,5.6-12.3,11.1
               C54,22,65.1,20.3,73.7,18.1z"/>
           <path fill="#662483" d="M75.6,24.2c0-0.1,0-0.2-0.1-0.3c-0.3-1.3-0.7-2.5-1.2-3.6C60.8,24.5,49,24,43.1,23.2
               c-0.3,1.3-0.5,2.7-0.5,4.1C54.2,28.8,66.4,26.7,75.6,24.2z"/>
           <path fill="#662483" d="M76,30.4c0.2-1.4,0.2-2.8,0-4.2c-14.3,4.7-26.9,4.3-33.1,3.4c0.1,0.8,0.2,1.5,0.3,2.3
               c0.2,0.8,0.4,1.5,0.6,2.2C55.3,35.1,67.1,32.9,76,30.4z"/>
           <path fill="#662483" d="M44.7,36.5c3.2,7,10.8,11.1,18.6,9.4c6.8-1.5,11.7-6.9,12.9-13.4C63,37.1,51.3,37.2,44.7,36.5z"/>
           <path fill="#662483" d="M87.3,14.3c-2.9,1.2-7.7,2.9-13.5,4.4c0.2,0.3,0.3,0.6,0.5,1C78.5,18.4,82.8,16.6,87.3,14.3z"/>
           <path fill="#662483" d="M41.2,21.1c-5.3-1-4-4.3-4-4.3s-2.1,4,2.9,5.4c0.7,0.2,1.6,0.3,2.9,0.5c0.1-0.4,0.2-0.8,0.4-1.2
               C42.6,21.3,41.9,21.2,41.2,21.1z"/>
           <path fill="#662483" d="M74.2,19.7c-0.2-0.3-0.3-0.7-0.5-1C65.1,21,54,22.7,43.4,21.4c-0.1,0.4-0.3,0.8-0.4,1.2
               C48.9,23.5,60.7,23.9,74.2,19.7z"/>
           <path fill="#662483" d="M40.6,27.5c-5.4-1-4.1-4.4-4.1-4.4s-2.1,4.1,3,5.5c0.7,0.2,1.7,0.4,3,0.5c0-0.4,0-0.9,0-1.3
               C41.8,27.7,41.2,27.6,40.6,27.5z"/>
           <path fill="#662483" d="M87.8,20.5c-2.7,1.1-7,2.7-12.3,4.1c0.1,0.3,0.1,0.7,0.2,1C79.6,24.4,83.7,22.7,87.8,20.5z"/>
           <path fill="#662483" d="M75.7,25.7c0-0.3-0.1-0.7-0.2-1c-9.2,2.5-21.3,4.6-33,3.1c0,0.4,0,0.9,0,1.3
               C48.8,29.9,61.4,30.4,75.7,25.7z"/>
           <path fill="#662483" d="M41.1,34.1c-5.4-0.9-4.1-4.4-4.1-4.4s-2.1,4.1,3.1,5.5c1,0.2,2.6,0.5,4.7,0.7c-0.2-0.4-0.4-0.9-0.6-1.4
               C43.1,34.4,42.1,34.3,41.1,34.1z"/>
           <path fill="#662483" d="M76.3,30.8c0,0.4-0.1,0.7-0.2,1.1c3.9-1.3,7.9-3.1,12-5.3C85.6,27.8,81.4,29.4,76.3,30.8z"/>
           <path fill="#662483" d="M44.1,34.5c0.2,0.5,0.4,0.9,0.6,1.4c6.7,0.6,18.4,0.5,31.5-3.9c0.1-0.4,0.1-0.7,0.2-1.1
               C67.4,33.4,55.6,35.6,44.1,34.5z"/>
           <path fill="#662483" d="M75.6,87.9c0,0,0,0.1-0.1,0.1c0,0,0,0-0.1,0c0,0,0-0.1,0-0.1c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.3,0-0.3
               c-0.1-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c-0.1,0.1-0.1,0.1-0.1,0.3c0,0.1,0,0.2,0,0.3c0-0.3-0.2-0.6-0.1-1
               c0,0.1,0.1,0.2,0.1,0.3c0-0.2,0-0.3,0-0.4c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.2-0.7-0.3-1l-0.2-0.6c-0.1,0.2,0.1,0.4,0.1,0.6
               c-0.2-0.6-0.4-1.3-0.6-1.9c-0.1-0.2-0.1-0.4-0.2-0.6c-0.2-0.5-0.4-1.1-0.5-1.6c0-0.1-0.1-0.2-0.1-0.4c0-0.1,0-0.3-0.1-0.4
               c0,0,0-0.1,0-0.1l-0.5-1.4c-0.1-0.2,0-0.5,0.1-0.7c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.4,0.3,0.8,0.4,1.1
               c0.1,0.3,0.2,0.5,0.2,0.8c0,0,0,0,0,0c-0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.8,0.3,1.3
               c0,0.2,0.1,0.4,0.1,0.6c0,0,0,0.1,0,0.1l0.1-0.1c0.3,0.9,0.5,1.9,0.8,2.9c0.2-0.5,0.4-1,0.6-1.4c0-0.1,0.1-0.2,0.2-0.3
               c0.1-0.3,0.3-0.5,0.4-0.8c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0.5-0.9,1-1.7,1.6-2.4
               c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.3,0.4-0.5,0.6-0.7c0.4-0.5,0.8-1,1.3-1.5c0.1-0.1,0.2-0.2,0.3-0.3
               c0.2-0.2,0.3-0.4,0.5-0.5c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2l0.1,0.2c0.2-0.2,0.3-0.4,0.4-0.6c0.2-0.4,0.5-0.7,0.9-1
               c0.1-0.1,0.2-0.2,0.4-0.4c0,0,0,0,0.2,0c0.1-0.2,0.3-0.3,0.5-0.5l1.2-1.1c0.3-0.3,0.6-0.6,0.9-0.8c0.2-0.1,0.3-0.2,0.5-0.3
               c0.2-0.2,0.5-0.2,0.8-0.1l0.1,0c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0-0.7,0.2c0,0,0-0.1,0-0.1
               c0.2-0.1,0.3-0.2,0.5-0.2c0.3-0.1,0.4,0,0.6,0.2c0,0,0,0,0,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0.3,0,0.5-0.3,0.6
               c-0.1,0.1-0.2,0.2-0.4,0.3c0,0-0.1-0.1-0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1-0.1-0.1l0.3-0.2c0,0,0-0.1,0-0.2
               c-0.1,0-0.2,0-0.2,0.1c-0.3,0.1-0.5,0.2-0.7,0.4c-0.3,0.2-0.6,0.5-0.9,0.7c0,0-0.1,0.1-0.1,0.2c0.1,0,0.1,0,0.2-0.1l0,0l-0.1,0.1
               c-1.4,1.2-2.6,2.5-3.8,3.8c-0.2,0.2-0.4,0.4-0.6,0.6c0,0-0.1,0.1-0.1,0.1c-0.2,0.3-0.5,0.6-0.7,0.9c-0.5,0.6-0.9,1.2-1.4,1.8
               c-0.2,0.2-0.3,0.4-0.5,0.6l0-0.1c0,0,0,0,0,0l-0.4,0.5c0,0-0.1,0.1-0.1,0.2l0.3-0.2c0,0,0,0,0,0l-0.1,0.2c-0.5,0.8-1,1.7-1.5,2.6
               c0,0.1-0.2,0.1-0.1,0.2c-0.3,0.6-0.8,2-1,2.6c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.2,0,0.3,0,0.5c0,0,0,0.1-0.1,0.1L75.6,87.9z
                M74.2,83.1c0.1,0.2,0.1,0.4,0.1,0.6C74.4,83.5,74.3,83.3,74.2,83.1 M78.2,81.5c0,0-0.1,0.1-0.1,0.1c-0.2,0.3-0.3,0.5-0.5,0.8
               c-0.1,0.2-0.3,0.5-0.4,0.7c0,0.1-0.1,0.2-0.1,0.2l0.1,0C77.4,82.7,77.9,82.1,78.2,81.5 M81.9,76.4L81.9,76.4L81.9,76.4l-0.1,0.1
               L81.9,76.4z"/>
       </g>
   </g>
   <g id="svg2">
   </g>
   <g id="Fondos_Color">
   </g>
   <g id="Layer_2">
   </g>
   </svg>
   
    )
}

export default ipostel
