//@Vendors
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { buildErrorsObj } from 'utils/helpers';
import clsx from 'clsx';

//@material-ui
import { Grid, Container, Paper, Button, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ImgCurso from 'components/ImgCurso';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventBusyIcon from '@material-ui/icons/EventBusy';

//@Services
import {
  getCursoService,
  postAsignarService,
} from 'state/cursos/cursos.services';

// @actionsTypes
import { GET_CURSOS_REGISTRADOS } from 'state/users/users.actionsTypes';
import { SET_FACILITADORES } from 'state/cursos/cursos.actionsTypes';

import {
  REQUEST_FAILURE,
  /*REQUEST_FINISHED*/ REQUEST_SUCCESS,
} from 'state/app/app.actionTypes';

// @Moment
import moment from 'moment';
import 'moment/locale/es';

import useStyles from './useStyles';
import ListFacilitadores from './components/ListFacilitadores';
import ListCategory from './components/ListCategory';
import Cupons from './components/Cupons';

function Index() {
  const [Registrado, setRegistrado] = useState(false);

  const { isAuthenticated } = useSelector((state) =>
    state.usersReducer.get('user')
  );
  const { usuario_id } = useSelector((state) =>
    state.usersReducer.get('profiles')
  );

  const facilitadores = useSelector((state) =>
    state.cursosReducer.get('facilitadores')
  );

  const cursosRegistrados = useSelector((state) =>
    state.usersReducer.get('cursos')
  );

  const { push } = useHistory();

  const { classes } = useStyles();

  const dispatch = useDispatch();
  const { id: idCurso } = useParams();
  const [curso, setCurso] = useState({});

  const obtenerCurso = useCallback(async () => {
    try {
      const params = {
        id: idCurso,
        limit: 1,
      };
      const response = await getCursoService(params);
      if (response.data.data.count === 0) {
        push('/cursos');
        return;
      }
      const curso = response.data.data.rows[0];
      delete curso.version;
      delete curso.creado_por;
      setCurso(curso);

      //Hago una desturtaracion del array de facilitadores en cursos
      const payload = curso.Facilitadores.map(({ usuario }) => {
        const {
          primer_apellido: apellido,
          primer_nombre: nombre,
        } = usuario.usuarios_perfil;
        return {
          apellido,
          nombre,
        };
      });
      dispatch({
        type: SET_FACILITADORES,
        payload,
      });
    } catch (err) {
      //console.log(err);
    }
  }, [push, idCurso, dispatch]);

  useEffect(() => {
    if (!parseInt(idCurso)) {
      push('/cursos');
      return;
    }
    obtenerCurso();
  }, [idCurso, obtenerCurso]);

  useEffect(() => {
    if (!isUndefined(usuario_id)) {
      dispatch({
        type: GET_CURSOS_REGISTRADOS,
        payload: { id: usuario_id },
      });
    }
  }, [idCurso, usuario_id, dispatch]);

  const {
    imagen,
    nombre,
    descripcion,
    fecha_inicio,
    fecha_fin,
    fecha_inicio_clases,
    fecha_fin_clases,
    ente,
    capacidad,
    Categorias: categorias,
    Usuarios_Inscritos: usuarios_inscriptos = [],
    modalidad
  } = curso;

  useEffect(() => {
    if (cursosRegistrados.some(({ curso_id }) => curso_id === idCurso)) {
      setRegistrado(true);
    }
  }, [cursosRegistrados, setRegistrado, idCurso]);


  useEffect(() => {
    obtenerCurso();
  }, [idCurso, obtenerCurso]);

  useEffect(() => {
    if (!isUndefined(usuario_id)) {
      dispatch({
        type: GET_CURSOS_REGISTRADOS,
        payload: { id: usuario_id },
      });
    }
  }, [idCurso, usuario_id, dispatch]);

  const { nombre: nombreEnte } = ente || {};

  const handleSumbit = useCallback(async () => {
    try {
      const error = {
        message: 'Necesista iniciar sesión para la preinscripcion de curso',
      };
      if (!isAuthenticated) {
        push('/iniciar-sesion');
        dispatch({
          type: REQUEST_FAILURE,
          payload: buildErrorsObj(error, error.message),
        });
        return;
      }

      if (!Registrado) {
        const params = {
          id: usuario_id,
          curso_id: idCurso,
        };
        const response = await postAsignarService(params);
        const { message, success } = response.data;

        if (success) {
          dispatch({
            type: REQUEST_SUCCESS,
            payload: message,
          });
          setRegistrado(true);
        } else {
          dispatch({
            type: REQUEST_FAILURE,
            payload: buildErrorsObj('error en el registro'),
          });
        }
        dispatch({
          type: REQUEST_SUCCESS,
          payload: 'Registrado al curso',
        });
        setRegistrado(true);
      }
    } catch (err) {
      dispatch({
        type: REQUEST_FAILURE,
        payload: buildErrorsObj(err),
      });
    }
  }, [isAuthenticated, dispatch, idCurso, usuario_id, Registrado, push]);

  return (
    <section className={classes.root}>
      <Container>
        <Paper className={classes.paper} elevation={1}>
          <Grid container>
            <Grid className={classes.imagen} item sm={12}>
              {!imagen ? (
                <ImgCurso imagen={imagen} nombre={nombre} classe="cursoThumbial--home"/>
              ) : <img alt="Imagen Oferta Academica Actual" src={`data:image/png;base64,${imagen}`}/> }
            </Grid>
            <Grid container className={classes.contenido}>
              <Grid item xs={12}>
                <Typography
                  className={classes.titulo}
                  align='left'
                  gutterBottom
                  variant='h4'>
                  {nombre ? (
                    nombre
                  ) : (
                    <Skeleton variant='text' width={300} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ListCategory categorias={categorias} />
              </Grid>
              <Grid container justify='space-between'>
                <Grid item className={clsx(classes['ente'], classes['margen'])}>
                  <ApartmentRoundedIcon />
                  <Typography color='primary' variant='h6'>
                    {ente ? nombreEnte : <Skeleton variant='text' width={75} />}
                  </Typography>
                </Grid>
                {facilitadores.length > 0 && (
                  <Grid item>
                    <ListFacilitadores facilitadores={facilitadores} />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className={clsx(classes['descripcion'], classes['margen'])}
                  gutterBottom
                  align='justify'
                  variant='body2'>
                  {descripcion ? (
                    descripcion
                  ) : (
                    <Skeleton variant='rect' height={150} />
                  )}
                </Typography>
              </Grid>
              <br />
              <Grid
                justifyContent='space-between'
                container
                className={classes.margen}>
                <Grid item xs={12} sm={4} alignItems='center'>
                  {fecha_inicio ? (
                    <Typography align='left'>
                      <strong
                        className={classes.fontWevy}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}>
                        <DateRangeIcon />
                        Fecha de inicio de preinscripción:
                        <span> {moment(fecha_inicio).format('l')}</span>
                      </strong>
                    </Typography>
                  ) : (
                    <Skeleton variant='text' height={35} />
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {fecha_fin ? (
                    <Typography align='right'>
                      <strong
                        className={classes.fontWevy}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: '10px',
                        }}>
                        <EventBusyIcon /> &nbsp; Fecha de cierre de preinscripción:
                        <span> {moment(fecha_fin).format('l')}</span>
                      </strong>
                    </Typography>
                  ) : (
                    <Skeleton variant='text' height={35} />
                  )}
                </Grid>
              </Grid>
              <Grid
                justifyContent='space-between'
                container
                className={classes.margen}>
                <Grid item xs={12} sm={4} alignItems='center'>
                  {fecha_inicio_clases ? (
                    <Typography align='left'>
                      <strong
                        className={classes.fontWevy}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}>
                        <DateRangeIcon />
                        Fecha de inicio del curso:
                        <span> {moment(fecha_inicio_clases).format('l')}</span>
                      </strong>
                    </Typography>
                  ) : (
                    <Skeleton variant='text' height={35} />
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {fecha_fin_clases ? (
                    <Typography align='right'>
                      <strong
                        className={classes.fontWevy}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: '10px',
                        }}>
                        <EventBusyIcon /> &nbsp; Fecha de cierre del curso:
                        <span> {moment(fecha_fin_clases).format('l')}</span>
                      </strong>
                    </Typography>
                  ) : (
                    <Skeleton variant='text' height={35} />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                direction='column'
                alignItems='center'
                justify='center'>
                <Cupons
                  cupons={capacidad}
                  disponibilidad={usuarios_inscriptos}
                />
                <div>
                  <Typography
              gutterBottom
              variant="body2"
              className={classes.nameEnte}
            >
               Modalidad: {(modalidad === 0) ? 'No definido' : (modalidad === 1 ? 'Presencial' : 'En linea')}
            </Typography>
                </div>
                <Button
                  onClick={handleSumbit}
                  className={classes.button}
                  variant='outlined'
                  disabled={Registrado ? true : false}
                  color='primary'>
                  {!Registrado ? 'Preinscribirse' : 'Preinscrito'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </section>
  );
}

export default Index;
