// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/Roboto/Roboto-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* ## @Imports ## */\n:root {\n  --gray: #C5C4C5;\n  --lightBlue: #479BD5;\n  --purpple: #AE5EA1;\n  --lightGreen: #B2CB08;\n  --orange: #F29100;\n  --darkBlue: #2C395E; }\n\n/*-----------------------------------------CURSOS--------------------------------------------------*/\n.banner {\n  grid-column: 1 / 13;\n  margin: 0 calc((-100vw + 1280px - 48px) / 2); }\n  .banner img {\n    width: 100%;\n    float: left; }\n\n/* ## Fuentes ##  */\n@font-face {\n  font-family: \"Roboto\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\"); }\n\n* {\n  box-sizing: border-box; }\n\nbody {\n  margin: 0;\n  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background: #fff; }\n\nh1 {\n  font-size: 40px; }\n\nh1, h2, h3, h4, h5, h6, p, i, th, td {\n  color: #777; }\n\n.anim_form-data {\n  animation: anim_form-data 0.7s cubic-bezier(0.68, -0.55, 0.265, 1.55);\n  animation-fill-mode: forwards;\n  position: relative;\n  left: 10%;\n  opacity: 0; }\n\n@keyframes anim_form-data {\n  from {\n    opacity: 0;\n    left: 10%; }\n  to {\n    opacity: 1;\n    left: 0%; } }\n\n.MuiCircularProgress-colorPrimary {\n  color: #B2CB08; }\n\n.MuiGrid-grid-sm-12, .MuiFormControl-root {\n  width: 100% !important; }\n\n.cursoThumbial {\n  margin: 0 auto;\n  max-height: 350px;\n  overflow: hidden; }\n  .cursoThumbial img {\n    object-position: top;\n    height: 100%; }\n\n.cursoThumbial--home {\n  height: 200px; }\n\n.cursoThumbial--listCursos {\n  width: 80%; }\n", ""]);
// Exports
module.exports = exports;
