// @Vendors
import React, { useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

// @Material UI
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// @Icons
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleIcon from '@material-ui/icons/People';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const useStyles = makeStyles(() => ({
  listItems: {
    '& * ': {
      color: '#fff',
    },
  },
}));

function ListItemsMenu({ prefixpatch = '/administracion' }) {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { push } = useHistory();

  const ITEMS_MENU = useMemo(
    () => [
      {
        label: 'Reportes',
        icon: <AssessmentIcon />,
        path: `${prefixpatch}/reportes`,
        to: () => push(`${prefixpatch}/reportes`),
      },
      {
        label: 'Usuarios',
        icon: <AccountBoxIcon />,
        path: `${prefixpatch}/usuarios`,
        to: () => push(`${prefixpatch}/usuarios`),
      },
      {
        label: 'Oferta Formativa',
        icon: <DescriptionIcon />,
        path: `${prefixpatch}/oferta-formativa`,
        to: () => push(`${prefixpatch}/oferta-formativa`),
      },
      {
        label: 'Categorias',
        icon: <LocalOfferIcon />,
        path: `${prefixpatch}/categorias`,
        to: () => push(`${prefixpatch}/categorias`),
      },
      {
        label: 'Roles de usuarios',
        icon: <PeopleIcon />,
        path: `${prefixpatch}/roles-de-usuarios`,
        to: () => push(`${prefixpatch}/roles-de-usuarios`),
      },
    ],
    [push, prefixpatch]
  );

  return (
    <List>
      {ITEMS_MENU.map((item, index) => (
        <ListItem
          button
          selected={item.path && path === item.path}
          key={index}
          onClick={item.to}
          className={classes.listItems}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.label} />
        </ListItem>
      ))}
      <Divider />
    </List>
  );
}

export default ListItemsMenu;
