// @Vendors
import { get } from 'lodash';
import jsonwebtoken from 'jsonwebtoken';
import axios from 'axios';
import { API_KEY, BASE_API } from 'constants/index';
import moment from 'moment';
import 'moment/locale/es';

import { AUTH_TOKEN } from 'state/auth/auth.actionsTypes';

export function getToken({ key = AUTH_TOKEN, recorted = false } = {}) {
  if (recorted) {
    const TOKEN = JSON.parse(localStorage.getItem(key)) || false;
    if (TOKEN) {
      try {
        const tokenDecode = jsonwebtoken.decode(TOKEN);
        const { exp } = tokenDecode;
        const {
          username,
          id,
          borrado,
          creado_por,
          actualizado_por,
        } = tokenDecode.user;

        const payload = {
          id,
          username,
          exp,
          creado_por,
          actualizado_por,
          borrado,
        };
        const tokenRecorted = jsonwebtoken.sign(payload, API_KEY);
        return tokenRecorted;
      } catch (err) {
        /* Silenc */
      }
    }
  }

  return JSON.parse(localStorage.getItem(key)) || false;
}

export function setToken(jwt = '', key = AUTH_TOKEN) {
  localStorage.setItem(key, JSON.stringify(jwt));
}

export function removeToken(key = AUTH_TOKEN) {
  if (getToken(key)) {
    localStorage.removeItem(key);
    return true;
  }
  return false;
}

export function verifyToken(tokenName = AUTH_TOKEN, recorted = false) {
  const token = getToken({ key: tokenName, recorted });
  if (token) {
    const { exp } = jsonwebtoken.decode(token) || 0;
    if (exp > Math.floor(Date.now() / 1000)) {
      return token;
    }
    return false;
  }
  return false;
}

// Mapear mensajes de errores del servidor
export const buildErrorsObj = (
  err,
  msg = '¡Ocurrió un error en el servidor!'
) => {
  let serverErrors = msg;
  const errNro = get(err, 'errNro', '');

  if (errNro !== '') {
    serverErrors = `${errNro} - ${serverErrors}`;
  }

  return {
    serverErrors,
    statusError: err ? get(err, 'status', '') : 502,
  };
};

// Obtener Estados, Municipios y Parroquias de Venezuela.
export function getEstados(id_estado) {
  return axios.post(`${BASE_API}/estados`, { params: { id_estado } });
}

export function getMunicipios(id_estado) {
  return axios.post(`${BASE_API}/municipios`, { params: { id_estado } });
}

export function getParroquias(id_municipio) {
  return axios.post(`${BASE_API}/parroquias`, { params: { id_municipio } });
}

export function getProfesiones() {
  return axios.post(`${BASE_API}/profesiones`, { params: {} });
}

export function getGrados() {
  return axios.post(`${BASE_API}/grados`, { params: {} });
}

export function getQuestions() {
  return axios.post(`${BASE_API}/preguntas`, { params: {} });
}

export function getEntes() {
  return axios.post(`${BASE_API}/entes`);
}

export function getFacilitadores() {
  return axios.post(
    `${BASE_API}/facilitadores`,
    { params: { limit: 100 } },
    {
      headers: {
        'x-mppct-token': getToken(),
      },
    }
  );
}

export function getPlanteles(municipio_id) {
  return axios.post(`${BASE_API}/planteles`, { params: { municipio_id } });
}

export function compareAge(date, edadCompare) {
  let diff = moment(new Date()).diff(date);
  let years = moment.duration(diff).years();

  return years <= edadCompare ? years : false;
}

export function userCan(rolName, rolId) {
  try {
    const rolesList = [
      ['ADMINISTRADOR', 3],
      ['ROOT', 2],
      ['ANALISTA', 1],
    ];
    for (let i = 0; i < rolesList.length; i++) {
      if (
        rolesList[i][0] === rolName.toUpperCase() &&
        rolesList[i][1] === Number(rolId)
      ) {
        return true;
      }
    }
    return false;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export function arrayChecked(array, cat = true) {
  var newArray = [];
  array.forEach((e) => {
    newArray.push(cat ? e.id_categoria : e.id_usuario);
  });

  return newArray;
}

export function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

export function union(a, b) {
  return [...a, ...not(b, a)];
}
