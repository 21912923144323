// @Vendors
import React from 'react'
import {Route, Redirect} from 'react-router-dom'

// @Utils
import {verifyToken} from 'utils/helpers'

// @Components
import AdminLoginFrame from 'components/AdminFrame/AdminLoginFrame'

function LoginAdminRoute({ component: Component, alias="Not title assigned", user, ...rest }) {
    return (
        !verifyToken()
        ? <Route {...rest} render={
                (props) => 
                <AdminLoginFrame title={alias} user={user}>
                    <Component {...props}/>
                </AdminLoginFrame>
            } />
        : <Redirect to="/administracion/reportes"/>
    )
}

export default React.memo(LoginAdminRoute)