import React from 'react';

//@Material-UI
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { Skeleton } from '@material-ui/lab';

const Cupons = ({ cupons = 0, disponibilidad = [] }) => {
  const [isHaveCupons, setIsHaveCupons] = React.useState(null);

  function verifyCupons() {
    if (Number(cupons - disponibilidad.length) < 1) {
      setIsHaveCupons(true);
    }
  }

  React.useEffect(() => {
    return verifyCupons();
  }, [verifyCupons]);

  return (
    <div>
      <ListItem style={{ margin: '0 3px', fontColor: '8px' }}>
        {isHaveCupons ? (
          <AssignmentIcon color='primary' />
        ) : (
          <AssignmentLateIcon color='primary' />
        )}

        <ListItemText style={{ margin: '0 3px', fontColor: '8px' }}>
          {cupons ? (
            `Cupos disponibles:  ${cupons - disponibilidad.length}`
          ) : (
            <Skeleton variant='text' width={160} height={30} />
          )}
        </ListItemText>
      </ListItem>
    </div>
  );
};

export default Cupons;
