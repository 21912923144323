import React from 'react'

function suscerte() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 184.1 53" >
<g id="Capa_1">
	<g id="Layer_1_1_">
	</g>
	<g>
		<path fill="#662483" d="M133.8,41.5c0.5,0,1,0.1,1.4,0.1c6.2,0.2,12-2,13.7-2.7c0.3-0.1,0.5-0.2,0.5-0.2c0,0,0,0,0,0
			c0,0.3-0.1,1.8-0.2,2.8c0,0.1,0,0.2,0,0.3c0,0.4-0.1,0.7-0.1,0.7c0.4-0.4,0.8-0.8,1.2-1.2c1.7-1.7,3-3.3,3.9-4.6
			c2.7-3.7,3.1-5.7,3.1-5.7c-6,2.1-13.8,1.5-13.8,1.5c1.4,1.1,2.9,2.1,2.9,2.1c-22.5,9.1-17.2-2-17.2-2c-0.6,1-1.8,3.9-0.7,6.1
			C129.3,40.1,130.8,41.2,133.8,41.5z"/>
		<path fill="#662483" d="M90.9,25c0.2,0.3,0.4,0.6,0.7,0.9c0.2,0.3,0.4,0.6,0.7,0.8c0.2,0.3,0.5,0.6,0.7,0.8
			c0.2,0.3,0.5,0.6,0.7,0.8c0.2,0.3,0.5,0.5,0.7,0.8c-0.1,0-0.1,0.1-0.2,0.1c-1,0-2,0-3,0c-0.1,0-0.1,0-0.2,0
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.2-1.9-2.3-2.8-3.5c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.5,0-0.7,0
			c-0.8,0-1.5,0-2.3,0c-0.1,0-0.3,0-0.4,0c-0.1,0.1-0.1,0.3-0.1,0.4c0,1,0,2.1,0,3.1c0,0.2,0,0.3,0,0.5c-0.5,0.1-0.9,0-1.4,0
			c-0.5,0-0.9,0-1.4,0c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.9,0-3.8,0-5.7c0-1.2,0-2.5,0-3.7c0-0.1,0-0.3,0-0.5c0.1,0,0.2,0,0.3,0
			c1.8,0,3.5,0,5.3,0c1.1,0,2.2,0,3.3,0c0.6,0,1.2,0.1,1.7,0.3c1.2,0.5,1.9,1.4,2,2.6c0.1,0.7-0.1,1.4-0.6,1.9
			c-0.4,0.4-0.9,0.7-1.4,0.9C91.4,24.8,91.1,24.9,90.9,25z M84.1,23.3c0.2,0,0.3,0,0.4,0c1,0,2.1,0,3.1,0c0.5,0,1.1,0,1.6,0
			c0.3,0,0.6-0.1,0.9-0.3c0.3-0.2,0.5-0.4,0.5-0.8c0.1-0.5-0.1-0.9-0.5-1.2c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6-0.1-0.9-0.1
			c-1.4,0-2.7,0-4.1,0c-0.1,0-0.2,0-0.3,0c0,0.1,0,0.1,0,0.2c0,0.7,0,1.4,0,2.1C84.1,23.2,84.1,23.2,84.1,23.3z"/>
		<path fill="#662483" d="M9,29.3c-0.1-0.5,0-1.4,0-1.7c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0c2.5,0,5,0,7.5,0c0.3,0,0.5,0,0.8-0.1
			c0.7-0.2,1-0.7,1-1.3c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3,0-0.6-0.1-0.9-0.1c-1.6,0-3.3,0-4.9,0
			c-0.6,0-1.3-0.1-1.9-0.3c-0.3-0.1-0.6-0.2-0.8-0.4c-0.6-0.4-1-1-1.1-1.8c0-0.3,0-0.6,0-0.9c0.1-0.8,0.5-1.4,1.1-1.9
			c0.5-0.4,1.1-0.6,1.7-0.7c0.3-0.1,0.7-0.1,1-0.1c2.9,0,5.8,0,8.7,0c0.1,0,0.2,0,0.3,0c0.1,0.3,0.1,0.6,0.1,0.8c0,0.3,0,0.5,0,0.8
			c-0.1,0-0.2,0.1-0.3,0.1c-0.3,0-0.6,0-0.9,0c-2.2,0-4.4,0-6.6,0c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7,0.2-1,0.4
			c-0.7,0.6-0.5,1.6,0.4,1.9c0.3,0.1,0.6,0.1,0.8,0.1c0.9,0,1.8,0,2.7,0c0.9,0,1.8,0,2.8,0.1c0.5,0,1.1,0.1,1.6,0.2
			c1.2,0.4,1.9,1.2,2.1,2.4c0.1,0.8-0.1,1.5-0.7,2.1c-0.3,0.3-0.6,0.5-0.9,0.7c-0.5,0.3-1,0.4-1.6,0.5c-0.4,0.1-0.8,0.1-1.2,0.1
			c-2.7,0-5.4,0-8,0C9.4,29.3,9.2,29.3,9,29.3z"/>
		<path fill="#662483" d="M39.7,29.3c0-0.1-0.1-0.1-0.1-0.1c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0
			c0.1,0,0.2,0,0.4,0c2.5,0,5,0,7.5,0c0.3,0,0.6,0,0.9-0.1c0.7-0.3,0.9-0.9,0.9-1.3c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.1-0.4-0.2-0.6-0.2
			C48.3,25.1,48,25,47.7,25c-1.6,0-3.3,0-4.9,0c-0.6,0-1.3-0.1-1.9-0.3c-0.3-0.1-0.5-0.2-0.8-0.4c-0.7-0.5-1.1-1.1-1.1-2
			c0-0.3,0-0.6,0-0.9c0.1-0.7,0.5-1.3,1.1-1.7c0.5-0.4,1.1-0.5,1.7-0.7c0.4-0.1,0.7-0.1,1.1-0.1c2.9,0,5.7,0,8.6,0
			c0.1,0,0.3,0,0.4,0c0.1,0.3,0.1,1.3,0,1.7c-0.1,0.1-0.3,0.1-0.4,0.1c-1.1,0-2.2,0-3.3,0c-1.4,0-2.8,0-4.2,0c-0.4,0-0.8,0-1.2,0.2
			c-0.4,0.2-0.6,0.4-0.8,0.8c-0.1,0.2-0.1,0.3,0,0.5c0.1,0.5,0.4,0.8,0.9,0.9c0.2,0.1,0.5,0.1,0.7,0.1c1.8,0,3.5,0.1,5.3,0.1
			c0.6,0,1.1,0.1,1.7,0.2c1.2,0.4,1.9,1.2,2.1,2.6c0,0.2,0,0.5-0.1,0.8c-0.2,0.7-0.6,1.2-1.2,1.6C51,28.8,50.6,29,50,29.1
			c-0.5,0.1-1,0.2-1.6,0.2c-2.8,0-5.5,0-8.3,0C40,29.3,39.9,29.3,39.7,29.3z"/>
		<path fill="#662483" d="M121.4,23.4c0.1,0.3,0.1,1,0,1.5c-0.1,0-0.2,0-0.3,0c-0.9,0-1.9,0-2.8,0c-1.7,0-3.3,0-5,0
			c-0.1,0-0.3,0-0.4,0.1c-0.1,0.2,0,0.4,0.1,0.6c0.1,0.4,0.2,0.7,0.5,1c0.5,0.6,1.1,0.8,1.8,0.9c0.4,0,0.7,0,1.1,0
			c1.6,0,3.2,0,4.8,0c0.1,0,0.2,0,0.3,0c0.1,0.3,0.1,1.2,0,1.6c-0.1,0.1-0.2,0.1-0.3,0.1c-0.5,0-1,0-1.5,0c-1.4,0-2.9,0-4.3,0
			c-1,0-1.9-0.2-2.8-0.5c-1.1-0.4-1.8-1.1-2.4-2.1c-0.7-1.2-0.8-2.6-0.5-3.9c0.1-0.5,0.3-1,0.5-1.4c0.4-0.8,1.1-1.4,1.9-1.8
			c0.7-0.3,1.4-0.5,2.2-0.6c0.5-0.1,1-0.1,1.6-0.1c1.7,0,3.4,0,5.1-0.1c0.2,0,0.3,0,0.5,0c0,0.1,0.1,0.1,0.1,0.2c0,0.5,0,1,0,1.4
			c0,0,0,0.1,0,0.1c-0.1,0.1-0.3,0.1-0.4,0.1c-0.9,0-1.8,0-2.7,0c-0.8,0-1.7,0-2.5,0c-0.6,0-1.1,0.1-1.6,0.4
			c-0.5,0.2-0.9,0.6-1.2,1.1c-0.2,0.3-0.3,0.6-0.3,1c0.1,0.1,0.3,0.1,0.4,0.1c0.7,0,1.3,0,2,0c1.9,0,3.8,0,5.7,0
			C121.1,23.3,121.3,23.3,121.4,23.4z"/>
		<path fill="#662483" d="M79.3,18.9c0.1,0.6,0,1.4,0,1.8c-0.1,0-0.3,0-0.4,0c-1.7,0-3.4,0-5.1,0c-0.5,0-1,0.1-1.5,0.2
			c-0.9,0.3-1.4,0.9-1.6,1.8c0,0.2-0.1,0.3-0.1,0.5c0.1,0.1,0.3,0,0.5,0.1c0.8,0,1.5,0,2.3,0c1.7,0,3.5,0,5.2,0c0.2,0,0.4,0,0.5,0
			c0.1,0.3,0.1,0.9,0,1.5c-0.1,0.1-0.2,0.1-0.3,0.1c-1.8,0-3.6,0-5.4,0.1c-0.8,0-1.6,0-2.4,0c-0.1,0-0.2,0-0.4,0c0,0.1,0,0.2,0,0.3
			c0.1,0.3,0.1,0.5,0.2,0.8c0.3,0.8,0.9,1.3,1.7,1.4c0.3,0,0.5,0.1,0.8,0.1c1.8,0,3.6,0,5.4,0.1c0.2,0,0.4,0,0.5,0
			c0.1,0.3,0.1,1.3,0,1.6c-0.1,0.1-0.3,0-0.4,0c-1.4,0-2.8,0-4.2,0c-0.5,0-1,0-1.4,0c-1,0-1.9-0.2-2.8-0.5c-1.1-0.4-1.9-1.1-2.4-2.1
			c-0.9-1.8-0.8-3.6,0.1-5.4c0.4-0.7,1-1.2,1.7-1.6c0.6-0.3,1.2-0.4,1.8-0.6c0.6-0.1,1.3-0.2,2-0.2c1.7,0,3.5,0,5.2-0.1
			C78.9,18.9,79.1,18.9,79.3,18.9z"/>
		<path fill="#662483" d="M33.8,19c0.4-0.1,2.6-0.1,3,0c0.1,0.1,0,0.3,0,0.4c0,2.1,0,4.2,0,6.3c0,0.3,0,0.5-0.1,0.8
			c-0.1,0.6-0.4,1.2-0.8,1.7c-0.1,0.1-0.2,0.2-0.4,0.3c-0.7,0.5-1.5,0.8-2.3,0.9c-0.6,0.1-1.1,0.1-1.7,0.1c-0.8,0-1.7,0-2.5,0
			c-0.7,0-1.4-0.1-2.1-0.3c-0.5-0.2-1-0.4-1.4-0.6c-0.4-0.2-0.7-0.6-0.9-1c-0.3-0.6-0.5-1.3-0.4-1.9c0-2.1,0.1-4.2,0.1-6.3
			c0-0.1,0-0.2,0-0.3c0.1,0,0.1-0.1,0.2-0.1c0.8,0,1.5,0,2.3,0c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.2c0,0.1,0,0.3,0,0.4
			c0,2,0,3.9,0,5.9c0,0.9,0.4,1.4,1.1,1.8c0.3,0.2,0.6,0.3,0.9,0.3c0.9,0.2,1.9,0.2,2.8,0c0.3-0.1,0.7-0.2,0.9-0.4
			c0.6-0.4,0.9-1,0.9-1.8c0-2,0-4,0-6C33.8,19.4,33.8,19.2,33.8,19z"/>
		<path fill="#662483" d="M66.2,18.9c0,0.6,0,1.1,0,1.7c-0.2,0.1-0.3,0.1-0.4,0.1c-0.7,0-1.4,0-2.1,0c-1,0-2.1,0-3.1,0
			c-0.5,0-0.9,0.1-1.4,0.2c-1,0.3-1.5,1-1.7,1.9c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.6,0,1.2,0.2,1.8c0.1,0.4,0.3,0.9,0.6,1.2
			c0.3,0.4,0.8,0.6,1.2,0.7c0.3,0,0.7,0.1,1,0.1c1.5,0,3,0,4.5,0c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.3,0c0.1,0.4,0.1,1.3,0,1.6
			c-0.1,0-0.2,0-0.3,0c-0.4,0-0.7,0-1.1,0c-1.5,0-3.1,0-4.6,0c-1,0-1.9-0.1-2.8-0.4c-1.1-0.4-1.9-1.1-2.5-2.2
			c-0.7-1.4-0.8-2.8-0.3-4.2c0.1-0.2,0.1-0.4,0.2-0.6c0.5-1.1,1.2-1.9,2.4-2.3c0.6-0.2,1.2-0.4,1.8-0.5c0.7-0.1,1.3-0.1,2-0.1
			c1.6,0,3.3,0,4.9-0.1C66,18.9,66.1,18.9,66.2,18.9z"/>
		<path fill="#662483" d="M100.4,29.2c0-0.6,0-1.1,0-1.7c0-0.6,0-1.1,0-1.7c0-0.5,0-1.1,0-1.6c0-0.6,0-1.1,0-1.7c0-0.6,0-1.1,0-1.7
			c-0.1-0.1-0.3-0.1-0.4-0.1c-0.9,0-1.7,0-2.6,0c-0.4,0-0.8,0-1.2,0c-0.1,0-0.2,0-0.4,0c-0.1-0.2,0-0.4,0-0.6c0-0.2,0-0.4,0-0.5
			c0-0.2,0-0.4,0-0.6c0.2-0.1,0.3-0.1,0.4-0.1c2.6,0,5.2,0,7.8,0c1.3,0,2.5,0,3.8,0c0.1,0,0.3,0,0.4,0c0.1,0.3,0.1,1.1,0,1.6
			c-0.1,0.1-0.3,0.1-0.4,0.1c-1.2,0-2.3,0-3.5,0c-0.3,0-0.5,0-0.8,0c0,0.1,0,0.2,0,0.3c0,0.4,0,0.8,0,1.2c0,2,0,4,0,5.9
			c0,0.3,0,0.5,0,0.8c0,0.1,0,0.2,0,0.3c-0.1,0-0.1,0-0.2,0c-0.9,0-1.7,0-2.6-0.1C100.5,29.3,100.4,29.2,100.4,29.2z"/>
		<g>
			<path fill="#662483" d="M8.7,33.6l0.4,0c0,0.2,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.5,0.1
				c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
				c-0.1,0-0.3-0.1-0.6-0.2C9.6,33,9.3,33,9.2,32.9c-0.2-0.1-0.3-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.4
				c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.2,0.5l-0.4,0
				c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.4,0-0.6,0.1c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1,0,0.2,0.1,0.3
				c0.1,0.1,0.3,0.1,0.6,0.2c0.3,0.1,0.6,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3-0.1,0.5
				c-0.1,0.1-0.2,0.3-0.4,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.2-0.5-0.4C8.7,34,8.7,33.8,8.7,33.6z"
				/>
			<path fill="#662483" d="M13.2,34.6v-0.3c-0.2,0.3-0.4,0.4-0.7,0.4c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
				c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.4v-1.4h0.4v1.3c0,0.2,0,0.3,0,0.4c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1
				s0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0.1-0.3,0.1-0.4v-1.2h0.4v2.3H13.2z"/>
			<path fill="#662483" d="M14.1,35.4v-3.1h0.4v0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.4,0.1,0.5,0.2
				s0.3,0.2,0.3,0.4c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.3,0.1-0.5,0.1
				c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.1-0.3-0.2v1.1H14.1z M14.5,33.4c0,0.3,0.1,0.5,0.2,0.6c0.1,0.1,0.3,0.2,0.4,0.2
				c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
				S14.5,33.1,14.5,33.4z"/>
			<path fill="#662483" d="M18.1,33.8l0.4,0c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9
				c0,0,0,0.1,0,0.1h-1.7c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1S18.1,34,18.1,33.8z M16.9,33.2h1.3
				c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.2C16.9,32.8,16.9,33,16.9,33.2z"/>
			<path fill="#662483" d="M19,34.6v-2.3h0.3v0.3c0.1-0.2,0.2-0.3,0.2-0.3c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.3,0,0.4,0.1l-0.1,0.4
				c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.5v1.2H19z"/>
			<path fill="#662483" d="M20.5,31.9v-0.4h0.4v0.4H20.5z M20.5,34.6v-2.3h0.4v2.3H20.5z"/>
			<path fill="#662483" d="M21.4,34.6v-2.3h0.3v0.3c0.2-0.3,0.4-0.4,0.7-0.4c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.2
				c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4v1.4h-0.4v-1.4c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.1-0.2-0.2s-0.2-0.1-0.3-0.1
				c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.6v1.2H21.4z"/>
			<path fill="#662483" d="M24.7,34.2l0.1,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.2c0-0.1,0-0.2,0-0.5
				v-1.3h-0.3v-0.3H24v-0.6l0.4-0.2v0.8h0.4v0.3h-0.4v1.3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1s0.1,0,0.1,0
				C24.6,34.2,24.7,34.2,24.7,34.2z"/>
			<path fill="#662483" d="M26.7,33.8l0.4,0c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9
				c0,0,0,0.1,0,0.1h-1.7c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1C26.5,34.1,26.6,34,26.7,33.8z
				 M25.4,33.2h1.3c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.2C25.5,32.8,25.4,33,25.4,33.2z"/>
			<path fill="#662483" d="M27.6,34.6v-2.3h0.3v0.3c0.2-0.3,0.4-0.4,0.7-0.4c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.2
				c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4v1.4H29v-1.4c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.1-0.2-0.2s-0.2-0.1-0.3-0.1
				c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.6v1.2H27.6z"/>
			<path fill="#662483" d="M31.5,34.6v-0.3c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
				c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0.1
				c0.1,0.1,0.2,0.1,0.3,0.2v-1.1h0.4v3.1H31.5z M30.2,33.4c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
				c0.1-0.1,0.2-0.3,0.2-0.6c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2C30.3,32.9,30.2,33.1,30.2,33.4
				z"/>
			<path fill="#662483" d="M34,33.8l0.4,0c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9
				c0,0,0,0.1,0,0.1h-1.7c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1S33.9,34,34,33.8z M32.7,33.2H34
				c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.2C32.8,32.8,32.7,33,32.7,33.2z"/>
			<path fill="#662483" d="M34.9,34.6v-2.3h0.3v0.3c0.2-0.3,0.4-0.4,0.7-0.4c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.2
				c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4v1.4h-0.4v-1.4c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1
				c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.6v1.2H34.9z"/>
			<path fill="#662483" d="M38.8,33.7l0.4,0c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.2,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.1,0.3,0.3,0.3,0.5L38.8,33c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.4-0.2,0.7
				c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1C38.7,34.1,38.8,33.9,38.8,33.7z"/>
			<path fill="#662483" d="M39.5,31.9v-0.4h0.4v0.4H39.5z M39.5,34.6v-2.3h0.4v2.3H39.5z"/>
			<path fill="#662483" d="M42,34.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.1-0.6-0.2
				c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3-0.1
				c0.3,0,0.5-0.1,0.7-0.1c0-0.1,0-0.1,0-0.1c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
				c-0.1,0.1-0.2,0.2-0.2,0.3l-0.4-0.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1
				c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4v0.5c0,0.4,0,0.6,0,0.7c0,0.1,0,0.2,0.1,0.3H42
				C42,34.5,42,34.4,42,34.3z M41.9,33.4c-0.1,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.3,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1
				c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3
				c0-0.1,0.1-0.2,0.1-0.4V33.4z"/>
			<path fill="#662483" d="M45.6,34.6v-0.3c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
				c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0.1
				c0.1,0.1,0.2,0.1,0.3,0.2v-1.1H46v3.1H45.6z M44.4,33.4c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
				c0.1-0.1,0.2-0.3,0.2-0.6c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2C44.5,32.9,44.4,33.1,44.4,33.4
				z"/>
			<path fill="#662483" d="M48.1,33.8l0.4,0c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9
				c0,0,0,0.1,0,0.1h-1.7c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1S48.1,34,48.1,33.8z M46.9,33.2h1.3
				c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.2C47,32.8,46.9,33,46.9,33.2z"/>
			<path fill="#662483" d="M50.2,33.6l0.4,0c0,0.2,0.1,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.5,0.1
				c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
				c-0.1,0-0.3-0.1-0.6-0.2C51,33,50.8,33,50.7,32.9c-0.2-0.1-0.3-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.3,0.1-0.4
				c0.1-0.1,0.2-0.2,0.4-0.3c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.2,0.5l-0.4,0
				c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.3,0-0.4,0-0.6,0.1s-0.2,0.2-0.2,0.3c0,0.1,0,0.2,0.1,0.3
				c0.1,0.1,0.3,0.1,0.6,0.2c0.3,0.1,0.6,0.1,0.7,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3-0.1,0.5
				c-0.1,0.1-0.2,0.3-0.4,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.5,0-0.7-0.1c-0.2-0.1-0.3-0.2-0.5-0.4C50.2,34,50.2,33.8,50.2,33.6
				z"/>
			<path fill="#662483" d="M54.7,33.8l0.4,0c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9
				c0,0,0,0.1,0,0.1h-1.7c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1S54.7,34,54.7,33.8z M53.5,33.2h1.3
				c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.2C53.6,32.8,53.5,33,53.5,33.2z"/>
			<path fill="#662483" d="M55.6,34.6v-2.3H56v0.3c0.1-0.2,0.2-0.3,0.2-0.3c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.3,0,0.4,0.1l-0.1,0.4
				c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.5v1.2H55.6z"/>
			<path fill="#662483" d="M57.7,34.6l-0.9-2.3h0.4l0.5,1.4c0.1,0.1,0.1,0.3,0.1,0.5c0-0.1,0.1-0.3,0.1-0.4l0.5-1.4h0.4l-0.9,2.3
				H57.7z"/>
			<path fill="#662483" d="M59.3,31.9v-0.4h0.4v0.4H59.3z M59.3,34.6v-2.3h0.4v2.3H59.3z"/>
			<path fill="#662483" d="M61.7,33.7l0.4,0c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.2,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.1,0.3,0.3,0.3,0.5L61.7,33c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.4-0.2,0.7
				c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1C61.6,34.1,61.7,33.9,61.7,33.7z"/>
			<path fill="#662483" d="M62.4,31.9v-0.4h0.4v0.4H62.4z M62.4,34.6v-2.3h0.4v2.3H62.4z"/>
			<path fill="#662483" d="M63.3,33.4c0-0.4,0.1-0.7,0.4-0.9c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.1,0.8,0.3
				c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.8-0.3
				C63.4,34.1,63.3,33.8,63.3,33.4z M63.7,33.4c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2
				c0.1-0.1,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2C63.7,32.9,63.7,33.1,63.7,33.4
				z"/>
			<path fill="#662483" d="M65.7,33.9l0.4-0.1c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.3,0,0.4-0.1
				c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2-0.1-0.4-0.1c-0.3-0.1-0.5-0.1-0.6-0.2c-0.1-0.1-0.2-0.1-0.2-0.2
				c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1s0.2,0,0.3,0c0.2,0,0.3,0,0.5,0.1
				c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.4l-0.4,0.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
				c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.2,0,0.3,0.1
				c0.3,0.1,0.5,0.1,0.6,0.2c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3
				c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.7-0.2C65.9,34.3,65.8,34.1,65.7,33.9z"/>
			<path fill="#662483" d="M70.8,34.6v-0.3c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4
				c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.3,0,0.4,0.1
				c0.1,0.1,0.2,0.1,0.3,0.2v-1.1h0.4v3.1H70.8z M69.5,33.4c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2
				c0.1-0.1,0.2-0.3,0.2-0.6c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2C69.6,32.9,69.5,33.1,69.5,33.4
				z"/>
			<path fill="#662483" d="M73.3,33.8l0.4,0c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9
				c0,0,0,0.1,0,0.1H72c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1S73.2,34,73.3,33.8z M72,33.2h1.3
				c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.2C72.1,32.8,72,33,72,33.2z"/>
			<path fill="#662483" d="M77.7,33.5l0.4,0.1c-0.1,0.3-0.2,0.6-0.5,0.8c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.2
				c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3-0.2-0.5-0.2-0.8c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.8-0.2
				c0.3,0,0.6,0.1,0.8,0.2c0.2,0.2,0.4,0.4,0.4,0.7l-0.4,0.1c-0.1-0.2-0.2-0.4-0.3-0.5s-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.6,0.2
				c-0.2,0.1-0.3,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.3,0.1,0.5,0.1
				c0.2,0,0.4-0.1,0.6-0.2C77.5,33.9,77.6,33.7,77.7,33.5z"/>
			<path fill="#662483" d="M80.1,33.8l0.4,0c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9
				c0,0,0,0.1,0,0.1h-1.7c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1S80.1,34,80.1,33.8z M78.8,33.2h1.3
				c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.2C78.9,32.8,78.9,33,78.8,33.2z"/>
			<path fill="#662483" d="M81,34.6v-2.3h0.3v0.3c0.1-0.2,0.2-0.3,0.2-0.3c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.3,0,0.4,0.1l-0.1,0.4
				c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.5v1.2H81z"/>
			<path fill="#662483" d="M83.3,34.2l0.1,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.2c0-0.1,0-0.2,0-0.5
				v-1.3h-0.3v-0.3h0.3v-0.6l0.4-0.2v0.8h0.4v0.3h-0.4v1.3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1s0.1,0,0.1,0
				C83.2,34.2,83.2,34.2,83.3,34.2z"/>
			<path fill="#662483" d="M83.7,31.9v-0.4h0.4v0.4H83.7z M83.7,34.6v-2.3h0.4v2.3H83.7z"/>
			<path fill="#662483" d="M84.7,34.6v-2h-0.3v-0.3h0.3V32c0-0.2,0-0.3,0-0.3c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.4-0.1
				c0.1,0,0.2,0,0.4,0l-0.1,0.3c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0.1c0,0.1-0.1,0.1-0.1,0.3v0.2h0.4v0.3h-0.4v2H84.7z"/>
			<path fill="#662483" d="M85.9,31.9v-0.4h0.4v0.4H85.9z M85.9,34.6v-2.3h0.4v2.3H85.9z"/>
			<path fill="#662483" d="M88.3,33.7l0.4,0c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.2,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.1,0.3,0.3,0.3,0.5L88.3,33c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				c-0.1,0.1-0.2,0.4-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1C88.2,34.1,88.3,33.9,88.3,33.7z"
				/>
			<path fill="#662483" d="M90.5,34.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.1-0.6-0.2
				c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3-0.1
				c0.3,0,0.5-0.1,0.7-0.1c0-0.1,0-0.1,0-0.1c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
				c-0.1,0.1-0.2,0.2-0.2,0.3L89,32.9c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1
				c0.1,0,0.2,0.1,0.3,0.2s0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4v0.5c0,0.4,0,0.6,0,0.7c0,0.1,0,0.2,0.1,0.3h-0.4
				C90.6,34.5,90.5,34.4,90.5,34.3z M90.5,33.4c-0.1,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.3,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1
				c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3
				c0-0.1,0.1-0.2,0.1-0.4V33.4z"/>
			<path fill="#662483" d="M93,33.7l0.4,0c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.2,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.1,0.3,0.3,0.3,0.5L92.9,33c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				c-0.1,0.1-0.2,0.4-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1C92.9,34.1,92.9,33.9,93,33.7z"/>
			<path fill="#662483" d="M93.7,31.9v-0.4h0.4v0.4H93.7z M93.7,34.6v-2.3h0.4v2.3H93.7z"/>
			<path fill="#662483" d="M94.5,33.4c0-0.4,0.1-0.7,0.4-0.9c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.1,0.8,0.3
				c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.8-0.3
				C94.6,34.1,94.5,33.8,94.5,33.4z M94.9,33.4c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2
				c0.1-0.1,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2C95,32.9,94.9,33.1,94.9,33.4z
				 M95.4,32l0.3-0.6h0.5L95.7,32H95.4z"/>
			<path fill="#662483" d="M97.1,34.6v-2.3h0.3v0.3c0.2-0.3,0.4-0.4,0.7-0.4c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.2
				c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4v1.4h-0.4v-1.4c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1
				c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.6v1.2H97.1z"/>
			<path fill="#662483" d="M100.8,34.6v-3.1h2.3v0.4h-1.9v1h1.7v0.4h-1.7v1.1h1.9v0.4H100.8z"/>
			<path fill="#662483" d="M103.7,34.6v-3.1h0.4v3.1H103.7z"/>
			<path fill="#662483" d="M106.2,33.8l0.4,0c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.1-0.4,0.2-0.7,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.7,0.3c0.2,0.2,0.3,0.5,0.3,0.9
				c0,0,0,0.1,0,0.1h-1.7c0,0.3,0.1,0.4,0.2,0.6c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.3,0,0.4-0.1S106.2,34,106.2,33.8z M104.9,33.2h1.3
				c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0.1-0.4,0.2C105,32.8,105,33,104.9,33.2z"/>
			<path fill="#662483" d="M108.6,33.7l0.4,0c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.2,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.1,0.3,0.3,0.3,0.5l-0.4,0.1c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2
				c-0.1,0.1-0.2,0.4-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1C108.5,34.1,108.6,33.9,108.6,33.7
				z"/>
			<path fill="#662483" d="M110.1,34.2l0.1,0.3c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.2c0-0.1,0-0.2,0-0.5
				v-1.3h-0.3v-0.3h0.3v-0.6l0.4-0.2v0.8h0.4v0.3h-0.4v1.3c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1s0.1,0,0.1,0
				C110,34.2,110.1,34.2,110.1,34.2z"/>
			<path fill="#662483" d="M110.5,34.6v-2.3h0.3v0.3c0.1-0.2,0.2-0.3,0.2-0.3c0.1-0.1,0.2-0.1,0.2-0.1c0.1,0,0.3,0,0.4,0.1l-0.1,0.4
				c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.3-0.1,0.5v1.2H110.5z"/>
			<path fill="#662483" d="M111.8,33.4c0-0.4,0.1-0.7,0.4-0.9c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.1,0.8,0.3
				c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.8-0.3
				S111.8,33.8,111.8,33.4z M112.2,33.4c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2
				c0.1-0.1,0.2-0.4,0.2-0.7c0-0.3-0.1-0.5-0.2-0.6c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2
				C112.3,32.9,112.2,33.1,112.2,33.4z M112.7,32l0.3-0.6h0.5L113,32H112.7z"/>
			<path fill="#662483" d="M114.4,34.6v-2.3h0.3v0.3c0.2-0.3,0.4-0.4,0.7-0.4c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.3,0.2
				c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4v1.4h-0.4v-1.4c0-0.2,0-0.3,0-0.4c0-0.1-0.1-0.1-0.2-0.2s-0.2-0.1-0.3-0.1
				c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.6v1.2H114.4z"/>
			<path fill="#662483" d="M116.9,31.9v-0.4h0.4v0.4H116.9z M116.9,34.6v-2.3h0.4v2.3H116.9z"/>
			<path fill="#662483" d="M119.3,33.7l0.4,0c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.3,0-0.6-0.1-0.8-0.3
				c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.2,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0.1,0.6,0.2
				c0.2,0.1,0.3,0.3,0.3,0.5l-0.4,0.1c0-0.2-0.1-0.3-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4,0.1-0.5,0.2s-0.2,0.4-0.2,0.7
				c0,0.3,0.1,0.5,0.2,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1C119.2,34.1,119.3,33.9,119.3,33.7z"/>
			<path fill="#662483" d="M121.5,34.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.3,0-0.4-0.1-0.6-0.2
				c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3-0.1
				c0.3,0,0.5-0.1,0.7-0.1c0-0.1,0-0.1,0-0.1c0-0.2,0-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1c-0.2,0-0.3,0-0.4,0.1
				c-0.1,0.1-0.2,0.2-0.2,0.3l-0.4-0.1c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1
				c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.4v0.5c0,0.4,0,0.6,0,0.7c0,0.1,0,0.2,0.1,0.3h-0.4
				C121.6,34.5,121.5,34.4,121.5,34.3z M121.5,33.4c-0.1,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.3,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1
				c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3s0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0-0.1,0.1-0.2,0.1-0.4
				V33.4z"/>
		</g>
		<g>
			<path fill="#662483" d="M149.4,17.1c-1.2,0-2.2,1.2-2.2,2.6c0,1.4,1,2.6,2.2,2.6c1.2,0,2.2-1.2,2.2-2.6
				C151.6,18.3,150.6,17.1,149.4,17.1z M149.8,21.4c-0.8,0.1-1.5-0.5-1.6-1.5s0.4-1.8,1.1-1.9c0.8-0.1,1.5,0.5,1.6,1.5
				C151,20.5,150.5,21.3,149.8,21.4z"/>
			<path fill="#662483" d="M142.8,18.7c-1.5,0-2.6,1.3-2.6,2.8s1.2,2.8,2.6,2.8c1.5,0,2.6-1.3,2.6-2.8S144.3,18.7,142.8,18.7z
				 M142.8,23.4c-0.9,0-1.6-0.8-1.6-1.9c0-1,0.7-1.9,1.6-1.9c0.9,0,1.6,0.8,1.6,1.9C144.4,22.5,143.7,23.4,142.8,23.4z"/>
			<path fill="#662483" d="M135.6,20.5c-1.4,0-2.5,1.2-2.5,2.7c0,1.5,1.1,2.7,2.5,2.7c1.4,0,2.5-1.2,2.5-2.7
				C138.1,21.7,137,20.5,135.6,20.5z M136,25.2c-0.8,0.1-1.6-0.6-1.8-1.6c-0.2-1.1,0.3-2,1.1-2.2s1.6,0.6,1.8,1.6
				C137.3,24,136.8,25,136,25.2z"/>
			<path fill="#662483" d="M127.5,24.9c0.1,1.3,1,2.3,2.1,2.3c1.1-0.1,1.9-1.2,1.8-2.5c-0.1-1.3-1-2.3-2.1-2.3
				C128.2,22.5,127.4,23.6,127.5,24.9z M130.7,24.5c0.2,0.9-0.2,1.7-0.9,1.9c-0.6,0.1-1.3-0.5-1.5-1.4c-0.2-0.9,0.2-1.7,0.9-1.9
				C129.8,22.9,130.5,23.6,130.7,24.5z"/>
			<path fill="#662483" d="M126.3,25.4c-0.3-1-1-1.8-1.6-1.6c-0.6,0.2-0.9,1.2-0.6,2.2c0.3,1,1,1.8,1.6,1.6
				C126.4,27.4,126.6,26.4,126.3,25.4z M125.6,27c-0.4,0.1-0.8-0.4-1-1.2c-0.2-0.7-0.1-1.4,0.3-1.5c0.4-0.1,0.8,0.4,1,1.2
				C126.1,26.2,126,26.9,125.6,27z"/>
			<path fill="#662483" d="M127.9,29.4c-0.4-0.8-1.1-1.3-1.6-1c-0.5,0.2-0.5,1.1-0.2,1.9c0.4,0.8,1.1,1.3,1.6,1
				C128.2,31.1,128.3,30.2,127.9,29.4z M127.6,30.8c-0.3,0.1-0.7-0.2-1-0.7c-0.3-0.5-0.3-1.1,0-1.2c0.3-0.1,0.7,0.2,1,0.7
				S127.8,30.7,127.6,30.8z"/>
			<path fill="#662483" d="M124.7,23.1c0.5,0,0.9-0.8,0.8-1.7c-0.1-0.9-0.6-1.6-1.1-1.5c-0.5,0-0.9,0.8-0.8,1.7
				C123.7,22.4,124.2,23.1,124.7,23.1z M124.4,20.3c0.3,0,0.6,0.4,0.7,1c0.1,0.6-0.1,1.2-0.4,1.2c-0.3,0-0.6-0.4-0.7-1.1
				C124,20.9,124.1,20.4,124.4,20.3z"/>
			<path fill="#662483" d="M131.1,31c0.7-0.2,1-1.2,0.7-2.2c-0.3-1-1.1-1.6-1.8-1.4s-1,1.2-0.7,2.2C129.6,30.6,130.4,31.2,131.1,31z
				 M130.3,28c0.5-0.1,1,0.3,1.2,1c0.2,0.7,0,1.3-0.5,1.5c-0.5,0.1-1-0.3-1.2-1C129.6,28.8,129.8,28.2,130.3,28z"/>
			<path fill="#662483" d="M136.2,30.5c0.8-0.4,1.1-1.6,0.6-2.6c-0.5-1-1.6-1.5-2.4-1.1c-0.8,0.4-1.1,1.6-0.6,2.6
				C134.3,30.4,135.4,30.9,136.2,30.5z M134.7,27.2c0.5-0.2,1.2,0.2,1.6,1c0.3,0.8,0.2,1.6-0.4,1.8c-0.5,0.2-1.2-0.2-1.6-1
				C134,28.3,134.2,27.5,134.7,27.2z"/>
			<path fill="#662483" d="M128,18.3c-0.8,0-1.5,0.8-1.5,1.9c0,1,0.7,1.9,1.5,1.9c0.8,0,1.5-0.8,1.5-1.9
				C129.5,19.1,128.8,18.3,128,18.3z M128.2,21.4c-0.5,0.1-0.9-0.5-1-1.2c-0.1-0.7,0.2-1.4,0.7-1.4c0.5-0.1,0.9,0.5,1,1.2
				C129,20.7,128.7,21.4,128.2,21.4z"/>
			<path fill="#662483" d="M124.8,19.3c0.5,0,0.9-0.7,1-1.5c0-0.9-0.4-1.6-0.9-1.6c-0.5,0-0.9,0.7-1,1.5
				C123.9,18.6,124.3,19.3,124.8,19.3z M124.9,16.7c0.3,0,0.6,0.5,0.6,1.1c0,0.6-0.3,1.1-0.6,1.1c-0.3,0-0.6-0.5-0.6-1.1
				C124.3,17.1,124.6,16.7,124.9,16.7z"/>
			<path fill="#662483" d="M143.9,17.9c1.1,0,2-1,2-2.2c0-1.2-0.9-2.2-2-2.2c-1.1,0-2,1-2,2.2C141.9,16.9,142.8,17.9,143.9,17.9z
				 M143.4,14.3c0.6-0.2,1.3,0.3,1.6,1.1c0.2,0.8-0.1,1.6-0.7,1.8c-0.6,0.2-1.3-0.3-1.6-1.1C142.5,15.2,142.8,14.5,143.4,14.3z"/>
			<path fill="#662483" d="M140.2,17.2c0-1.2-0.9-2.2-2-2.2c-1.1,0-2,1-2,2.2c0,1.2,0.9,2.2,2,2.2C139.3,19.3,140.2,18.4,140.2,17.2
				z M137,17.5c-0.2-0.7,0.1-1.5,0.7-1.7c0.6-0.2,1.3,0.2,1.6,1c0.2,0.7-0.1,1.5-0.7,1.7C138,18.7,137.3,18.2,137,17.5z"/>
			<path fill="#662483" d="M134.5,18.7c0-1.2-0.8-2.1-1.9-2.1s-1.9,1-1.9,2.1c0,1.2,0.8,2.1,1.9,2.1S134.5,19.9,134.5,18.7z
				 M131.6,18.7c0-0.8,0.5-1.4,1.1-1.4s1.1,0.6,1.1,1.4c0,0.8-0.5,1.4-1.1,1.4S131.6,19.5,131.6,18.7z"/>
			<path fill="#662483" d="M128.1,17.8c0.8,0,1.4-0.7,1.4-1.6c0-0.9-0.6-1.6-1.4-1.6s-1.4,0.7-1.4,1.6
				C126.8,17,127.4,17.8,128.1,17.8z M128.1,15.1c0.5,0,0.8,0.5,0.8,1.1c0,0.6-0.4,1.1-0.8,1.1c-0.5,0-0.8-0.5-0.8-1.1
				C127.3,15.6,127.7,15.1,128.1,15.1z"/>
			<path fill="#662483" d="M130.8,15.8c0.6,0.6,1.7,0.6,2.5-0.1c0.7-0.7,0.9-1.8,0.3-2.4c-0.6-0.6-1.7-0.6-2.5,0.1
				C130.3,14.1,130.2,15.2,130.8,15.8z M132.2,13.3c0.6,0,1,0.5,1,1.2c0,0.7-0.5,1.2-1,1.2s-1-0.5-1-1.2
				C131.1,13.9,131.6,13.3,132.2,13.3z"/>
			<path fill="#662483" d="M135.3,12.8c-0.1,1.1,0.5,2,1.4,2.1c0.9,0.1,1.8-0.6,1.9-1.7c0.1-1.1-0.5-2-1.4-2.1
				C136.3,11,135.4,11.8,135.3,12.8z M138.1,12.9c0.1,0.7-0.3,1.3-0.9,1.4c-0.6,0.1-1.2-0.4-1.2-1.1c-0.1-0.7,0.3-1.3,0.9-1.4
				C137.4,11.7,138,12.2,138.1,12.9z"/>
			<path fill="#662483" d="M139.1,9.8c0.3,0.6,1.2,0.8,1.9,0.5c0.7-0.4,1.1-1.2,0.8-1.8s-1.2-0.8-1.9-0.5
				C139.1,8.3,138.8,9.1,139.1,9.8z M141,8.3c0.4,0.3,0.4,0.8,0.1,1.2c-0.3,0.4-0.9,0.5-1.2,0.2c-0.4-0.3-0.4-0.8-0.1-1.2
				C140.1,8.2,140.6,8.1,141,8.3z"/>
			<path fill="#662483" d="M125.9,15.4c0.4,0.1,0.8-0.4,1-1c0.2-0.7,0-1.3-0.4-1.4c-0.4-0.1-0.8,0.4-1,1
				C125.4,14.7,125.5,15.3,125.9,15.4z M125.8,14.1c0.1-0.5,0.4-0.8,0.6-0.7c0.2,0.1,0.3,0.5,0.2,0.9c-0.1,0.5-0.4,0.8-0.6,0.7
				C125.8,15,125.7,14.5,125.8,14.1z"/>
			<path fill="#662483" d="M128.1,31.6c-0.3,0.3-0.1,1,0.5,1.5c0.6,0.5,1.3,0.7,1.6,0.4c0.3-0.3,0.1-1-0.5-1.5
				C129.1,31.4,128.4,31.2,128.1,31.6z M129.7,33.1c-0.2,0.2-0.7,0.1-1-0.3c-0.4-0.4-0.5-0.8-0.3-1s0.7-0.1,1,0.3
				C129.8,32.5,129.9,32.9,129.7,33.1z"/>
			<path fill="#662483" d="M128,11.7c0.3,0,0.6-0.2,0.6-0.6c0-0.4-0.2-0.7-0.5-0.7c-0.3,0-0.6,0.2-0.6,0.6
				C127.5,11.4,127.7,11.7,128,11.7z M127.8,11c0.1-0.2,0.2-0.4,0.4-0.3c0.2,0,0.2,0.3,0.2,0.5c-0.1,0.2-0.2,0.4-0.4,0.3
				C127.9,11.5,127.8,11.3,127.8,11z"/>
			<path fill="#662483" d="M128.4,11.9c-0.5,0.5-0.5,1.3,0,1.7c0.5,0.4,1.2,0.3,1.7-0.2c0.5-0.5,0.5-1.3,0-1.7
				C129.6,11.3,128.9,11.4,128.4,11.9z M129.1,13.5c-0.4,0-0.7-0.5-0.6-0.9c0-0.5,0.4-0.8,0.8-0.8c0.4,0,0.7,0.5,0.6,0.9
				C129.9,13.2,129.5,13.5,129.1,13.5z"/>
			<path fill="#662483" d="M130.8,10.4c0.4-0.3,0.5-0.7,0.3-1c-0.2-0.3-0.7-0.3-1,0c-0.4,0.3-0.5,0.7-0.3,1
				C130,10.7,130.5,10.7,130.8,10.4z M130.2,9.7c0.2-0.2,0.5-0.2,0.6-0.1c0.1,0.1,0.1,0.4-0.1,0.6s-0.5,0.2-0.6,0.1
				C130,10.2,130,9.9,130.2,9.7z"/>
			<path fill="#662483" d="M135.9,9c-0.7,0.3-1,1-0.7,1.6c0.3,0.5,1,0.7,1.7,0.4c0.7-0.3,1-1,0.7-1.6C137.3,8.8,136.6,8.6,135.9,9z
				 M136.9,10.4c-0.3,0.3-0.8,0.4-1.1,0.1c-0.3-0.3-0.3-0.7,0-1.1c0.3-0.3,0.8-0.4,1.1-0.1C137.2,9.6,137.2,10.1,136.9,10.4z"/>
			<path fill="#662483" d="M131.9,33.6c0.7,0.5,1.5,0.4,1.9-0.2c0.4-0.5,0.1-1.4-0.6-1.8c-0.7-0.5-1.5-0.4-1.9,0.2
				C131,32.3,131.2,33.1,131.9,33.6z M131.7,32.1c0.2-0.4,0.7-0.4,1.3-0.2c0.5,0.3,0.8,0.8,0.6,1.1c-0.2,0.4-0.7,0.4-1.3,0.2
				C131.8,33,131.5,32.5,131.7,32.1z"/>
			<path fill="#662483" d="M132.4,34.4c-0.5-0.4-1.1-0.4-1.3-0.1c-0.2,0.3,0.1,0.8,0.6,1.2c0.5,0.4,1.1,0.4,1.3,0.1
				C133.3,35.3,133,34.7,132.4,34.4z M132.7,35.3c-0.1,0.2-0.5,0.1-0.9-0.1c-0.4-0.2-0.6-0.6-0.5-0.8s0.5-0.1,0.9,0.1
				C132.6,34.8,132.8,35.2,132.7,35.3z"/>
			<path fill="#662483" d="M135,35.6c0.6,0.3,1.2,0.1,1.4-0.2c0.2-0.4-0.2-0.9-0.7-1.2c-0.6-0.3-1.2-0.1-1.4,0.2
				C134.1,34.8,134.4,35.4,135,35.6z M134.6,34.6c0.1-0.2,0.5-0.3,0.9-0.1c0.4,0.2,0.7,0.5,0.6,0.7c-0.1,0.2-0.5,0.3-0.9,0.1
				C134.7,35.1,134.5,34.8,134.6,34.6z"/>
			<path fill="#662483" d="M138.3,33.2c0.4-0.6,0.1-1.6-0.7-2.1c-0.8-0.5-1.8-0.5-2.2,0.1c-0.4,0.6-0.1,1.6,0.7,2.1
				C136.9,33.9,137.9,33.8,138.3,33.2z M137.7,32.8c-0.3,0.4-0.9,0.4-1.5,0c-0.6-0.4-0.8-1-0.5-1.4c0.3-0.4,0.9-0.4,1.5,0
				C137.8,31.8,138,32.5,137.7,32.8z"/>
			<path fill="#662483" d="M138.5,35.4c0.6,0.2,1.2,0,1.4-0.4c0.1-0.4-0.2-1-0.8-1.2c-0.6-0.2-1.2,0-1.4,0.4
				C137.6,34.7,137.9,35.2,138.5,35.4z M138.9,34.1c0.4,0.1,0.7,0.4,0.7,0.7c-0.1,0.3-0.5,0.4-0.9,0.3c-0.4-0.1-0.7-0.4-0.7-0.7
				C138.1,34.1,138.5,34,138.9,34.1z"/>
			<path fill="#662483" d="M142.4,33c-0.7,0.1-1.1,0.7-1,1.2c0.1,0.5,0.7,0.8,1.4,0.7c0.7-0.1,1.1-0.7,1-1.2
				C143.7,33.1,143.1,32.8,142.4,33z M142.7,34.4c-0.5,0.1-0.9-0.1-1-0.4c-0.1-0.3,0.3-0.6,0.7-0.7c0.5-0.1,0.9,0.1,1,0.4
				C143.5,34,143.2,34.3,142.7,34.4z"/>
			<path fill="#662483" d="M143.4,31.5c0-0.8-0.7-1.4-1.7-1.5c-1-0.1-1.8,0.5-1.8,1.3c0,0.8,0.7,1.4,1.7,1.5
				C142.5,32.8,143.3,32.2,143.4,31.5z M140.5,31.2c0.1-0.5,0.7-0.7,1.3-0.6c0.7,0.1,1.1,0.6,1,1c-0.1,0.5-0.7,0.7-1.3,0.6
				C140.8,32.1,140.4,31.6,140.5,31.2z"/>
			<path fill="#662483" d="M133.1,12.2c0.7-0.3,1-1,0.7-1.6c-0.3-0.5-1-0.7-1.7-0.4c-0.7,0.3-1,1-0.7,1.6
				C131.7,12.4,132.4,12.5,133.1,12.2z M132.1,10.7c0.3-0.3,0.8-0.4,1.1-0.1c0.3,0.3,0.3,0.7,0,1.1c-0.3,0.3-0.8,0.4-1.1,0.1
				C131.8,11.5,131.8,11.1,132.1,10.7z"/>
			<path fill="#662483" d="M133.4,9.4c0.4-0.3,0.5-0.8,0.3-1.1c-0.2-0.3-0.7-0.2-1.1,0.1c-0.4,0.3-0.5,0.8-0.3,1.1
				C132.5,9.8,133,9.8,133.4,9.4z M132.7,8.7c0.2-0.2,0.5-0.3,0.6-0.1c0.1,0.1,0.1,0.4-0.1,0.6c-0.2,0.2-0.5,0.3-0.6,0.1
				C132.5,9.2,132.5,8.9,132.7,8.7z"/>
			<path fill="#662483" d="M135.2,7.5c-0.4,0.4-0.6,0.9-0.3,1.1c0.2,0.3,0.8,0.2,1.2-0.2c0.4-0.4,0.6-0.9,0.3-1.1
				C136.2,7.1,135.7,7.2,135.2,7.5z M136,8.3c-0.2,0.2-0.6,0.3-0.7,0.1c-0.2-0.2-0.1-0.5,0.1-0.7s0.6-0.3,0.7-0.1
				C136.3,7.7,136.2,8.1,136,8.3z"/>
			<path fill="#662483" d="M130.9,8.8c0.3-0.2,0.4-0.4,0.3-0.6c-0.1-0.2-0.4-0.2-0.7,0c-0.3,0.2-0.4,0.4-0.3,0.6
				C130.3,8.9,130.7,8.9,130.9,8.8z M130.7,8.3c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0,0.2-0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0
				C130.4,8.5,130.5,8.4,130.7,8.3z"/>
			<path fill="#662483" d="M135.3,36c-0.4-0.1-0.8,0.1-0.8,0.3c0,0.2,0.3,0.5,0.7,0.5c0.4,0.1,0.8-0.1,0.8-0.3
				C136.1,36.2,135.7,36,135.3,36z M135.2,36.7c-0.3,0-0.5-0.2-0.5-0.3c0-0.1,0.3-0.2,0.5-0.2c0.3,0,0.5,0.2,0.5,0.3
				C135.7,36.6,135.5,36.7,135.2,36.7z"/>
			<path fill="#662483" d="M137.7,35.9c-0.5-0.1-0.9,0.1-0.9,0.4s0.3,0.5,0.8,0.6c0.5,0.1,0.9-0.1,0.9-0.4
				C138.5,36.2,138.2,36,137.7,35.9z M137.6,36.6c-0.3,0-0.6-0.2-0.5-0.3s0.3-0.2,0.6-0.2c0.3,0,0.6,0.2,0.5,0.3
				C138.2,36.6,137.9,36.7,137.6,36.6z"/>
			<path fill="#662483" d="M132.3,8.3c0.3-0.1,0.5-0.3,0.5-0.4c0-0.2-0.3-0.3-0.6-0.2c-0.3,0.1-0.5,0.3-0.5,0.4
				C131.7,8.2,132,8.3,132.3,8.3z M131.9,8c0-0.1,0.1-0.2,0.3-0.3c0.2,0,0.4,0,0.4,0.1c0,0.1-0.1,0.2-0.3,0.3
				C132.1,8.2,132,8.1,131.9,8z"/>
			<path fill="#662483" d="M131.9,7.5c0.2-0.1,0.4-0.3,0.3-0.4c-0.1-0.1-0.3-0.1-0.5,0c-0.2,0.1-0.4,0.3-0.3,0.4
				C131.5,7.6,131.7,7.6,131.9,7.5z M131.7,7.2c0.1-0.1,0.3-0.1,0.3,0c0,0.1,0,0.2-0.2,0.3c-0.1,0.1-0.3,0.1-0.3,0
				C131.5,7.3,131.6,7.2,131.7,7.2z"/>
			<path fill="#662483" d="M133.9,7.7c0.3-0.1,0.5-0.3,0.5-0.4c0-0.2-0.3-0.3-0.6-0.3c-0.3,0.1-0.5,0.3-0.5,0.4
				C133.3,7.7,133.6,7.8,133.9,7.7z M133.8,7.2c0.2,0,0.4,0,0.4,0.2c0,0.1-0.1,0.2-0.3,0.3c-0.2,0-0.4,0-0.4-0.2
				C133.4,7.3,133.6,7.2,133.8,7.2z"/>
			<path fill="#662483" d="M133,6.9c0.2-0.1,0.4-0.3,0.3-0.4c-0.1-0.1-0.3-0.1-0.5,0c-0.2,0.1-0.4,0.3-0.3,0.4
				C132.6,7,132.8,7,133,6.9z M132.9,6.5c0.1-0.1,0.3-0.1,0.3,0s0,0.2-0.2,0.3c-0.1,0.1-0.3,0.1-0.3,0
				C132.6,6.7,132.7,6.6,132.9,6.5z"/>
			<path fill="#662483" d="M135.4,7.2c0.3-0.1,0.5-0.2,0.5-0.4s-0.3-0.3-0.6-0.2c-0.3,0.1-0.5,0.2-0.5,0.4
				C134.8,7.1,135.1,7.2,135.4,7.2z M135.2,6.6c0.2,0,0.4,0,0.4,0.2c0,0.1-0.1,0.2-0.3,0.3c-0.2,0-0.4,0-0.4-0.2
				C134.9,6.8,135.1,6.7,135.2,6.6z"/>
			<path fill="#662483" d="M136.7,6.1c-0.3,0.1-0.5,0.2-0.5,0.4c0,0.2,0.3,0.3,0.6,0.2c0.3-0.1,0.5-0.2,0.5-0.4
				C137.2,6.1,137,6,136.7,6.1z M136.8,6.6c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.3-0.3c0.2,0,0.4,0,0.4,0.2
				C137.1,6.5,137,6.6,136.8,6.6z"/>
			<path fill="#662483" d="M137.7,6.2c0,0.2,0.3,0.3,0.6,0.2c0.3-0.1,0.5-0.2,0.5-0.4c0-0.2-0.3-0.3-0.6-0.2
				C137.9,5.9,137.6,6.1,137.7,6.2z M138.2,5.9c0.2,0,0.4,0,0.4,0.2c0,0.1-0.1,0.2-0.3,0.3c-0.2,0-0.4,0-0.4-0.2
				C137.9,6.1,138,6,138.2,5.9z"/>
			<path fill="#662483" d="M139.7,6.2c0.3-0.1,0.5-0.2,0.5-0.4c0-0.2-0.3-0.3-0.6-0.2c-0.3,0.1-0.5,0.2-0.5,0.4S139.4,6.3,139.7,6.2
				z M139.6,5.7c0.2,0,0.4,0,0.4,0.2c0,0.1-0.1,0.2-0.3,0.3c-0.2,0-0.4,0-0.4-0.2C139.2,5.8,139.4,5.7,139.6,5.7z"/>
			<path fill="#662483" d="M137.8,5.7c0.2-0.1,0.4-0.3,0.3-0.4c0-0.1-0.3-0.2-0.5-0.1c-0.2,0.1-0.4,0.3-0.3,0.4S137.6,5.8,137.8,5.7
				z M137.7,5.3c0.1-0.1,0.3,0,0.3,0c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0-0.3,0C137.4,5.5,137.5,5.4,137.7,5.3z"/>
			<path fill="#662483" d="M134.2,6.5c0.2-0.1,0.4-0.3,0.3-0.4c0-0.1-0.3-0.2-0.5-0.1c-0.2,0.1-0.4,0.3-0.3,0.4
				C133.7,6.5,134,6.6,134.2,6.5z M134.1,6.1c0.1-0.1,0.3,0,0.3,0c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0-0.3,0
				C133.8,6.3,133.9,6.2,134.1,6.1z"/>
			<path fill="#662483" d="M135.4,6.2c0.2-0.1,0.4-0.3,0.3-0.4c0-0.1-0.3-0.2-0.5-0.1c-0.2,0.1-0.4,0.3-0.3,0.4
				C135,6.2,135.2,6.3,135.4,6.2z M135.3,5.8c0.1-0.1,0.3,0,0.3,0c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0-0.3,0
				C135.1,6,135.1,5.8,135.3,5.8z"/>
			<path fill="#662483" d="M136.6,5.9c0.2-0.1,0.4-0.2,0.3-0.4c0-0.1-0.3-0.2-0.5-0.1c-0.2,0.1-0.4,0.2-0.3,0.4
				C136.2,6,136.4,6,136.6,5.9z M136.5,5.5c0.1-0.1,0.3,0,0.3,0c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0-0.3,0
				C136.3,5.7,136.3,5.6,136.5,5.5z"/>
			<polygon fill="#662483" points="135.2,15.9 134.6,16.1 135.6,19.9 136.2,19.7 			"/>
			<polygon fill="#662483" points="134.6,15.5 135.2,15.1 134.5,12.2 133.8,12.5 			"/>
			<polygon fill="#662483" points="145.3,18.3 146.6,23.1 147.2,22.7 146.1,18.1 			"/>
			<polygon fill="#662483" points="138.2,20.2 139.4,24.9 140.2,24.6 139,20 			"/>
			<polygon fill="#662483" points="140.2,14.6 141.4,18.4 142,18.1 140.9,14.4 			"/>
			<polygon fill="#662483" points="130.8,21.1 130.2,17.7 129.5,17.8 130.2,21.3 			"/>
			<polygon fill="#662483" points="133.2,26.3 132.1,21.7 131.3,21.8 132.5,26.6 			"/>
			<polygon fill="#662483" points="138.5,29.9 139,29.8 137.8,26.4 137.2,26.7 			"/>
			<polygon fill="#662483" points="138.9,30.7 138.5,30.9 139.6,33 140,32.9 			"/>
			<polygon fill="#662483" points="140.9,35.1 141.1,35 140.4,33.4 140.2,33.5 			"/>
			<polygon fill="#662483" points="143.2,29.5 144.5,32.1 144.9,31.9 143.6,29.3 			"/>
			<polygon fill="#662483" points="136.5,34 137.3,35.5 137.5,35.5 136.7,33.8 			"/>
			<polygon fill="#662483" points="133,34.2 133.9,35.8 134.1,35.7 133.3,34.1 			"/>
			<polygon fill="#662483" points="135.9,36 136.5,37 136.7,36.9 136.1,35.9 			"/>
			<polygon fill="#662483" points="138.4,35.7 138.9,36.5 139.1,36.4 138.6,35.6 			"/>
			<polygon fill="#662483" points="135.4,33.5 134.4,31.2 134,31.5 135.1,33.7 			"/>
			<polygon fill="#662483" points="131.4,33.6 130.2,31.4 129.8,31.6 131.1,33.7 			"/>
			<polygon fill="#662483" points="129.2,31 128.2,28.6 127.9,28.8 128.9,31.1 			"/>
			<polygon fill="#662483" points="133.7,30.6 132.5,27.3 131.9,27.6 133.1,30.7 			"/>
			<polygon fill="#662483" points="128,27.3 126.8,23.3 126.2,23.4 127.4,27.5 			"/>
			<polygon fill="#662483" points="141.1,35.3 141.4,36 141.6,35.9 141.3,35.2 			"/>
			<polygon fill="#662483" points="126,22.6 126.5,22.5 125.9,19.3 125.4,19.3 			"/>
			<polygon fill="#662483" points="125.8,15.7 126.1,18.6 126.6,18.4 126.4,15.7 			"/>
			<polygon fill="#662483" points="130.4,16.8 130,13.9 129.4,14.1 129.8,17 			"/>
			<polygon fill="#662483" points="127.7,12.3 127.2,12.4 127.2,14.4 127.7,14.4 			"/>
			<polygon fill="#662483" points="130.9,10.9 130.5,11 130.8,13 131.2,12.9 			"/>
			<polygon fill="#662483" points="129.2,9.9 128.9,10 128.9,11 129.2,11 			"/>
			<polygon fill="#662483" points="131.6,10 131.9,9.9 131.8,8.9 131.5,9 			"/>
			<polygon fill="#662483" points="134.4,11.6 134.8,11.4 134.5,9.5 134.1,9.6 			"/>
			<polygon fill="#662483" points="138.9,10.4 138.3,8.5 137.9,8.7 138.4,10.6 			"/>
			<polygon fill="#662483" points="134.4,7.9 134.1,8 134.2,9.1 134.5,9 			"/>
			<polygon fill="#662483" points="131.6,8.4 131.6,7.8 131.4,7.8 131.4,8.5 			"/>
			<polygon fill="#662483" points="133.1,7.3 132.9,7.3 133,8 133.2,7.9 			"/>
			<polygon fill="#662483" points="132.4,7.2 132.4,6.8 132.3,6.8 132.3,7.2 			"/>
			<polygon fill="#662483" points="134.5,7.4 134.6,7.4 134.6,6.7 134.4,6.8 			"/>
			<polygon fill="#662483" points="133.6,6.6 133.6,6.2 133.4,6.2 133.4,6.7 			"/>
			<polygon fill="#662483" points="136,6.9 136.1,6.9 136.1,6.3 135.9,6.3 			"/>
			<polygon fill="#662483" points="134.8,6.3 134.7,5.8 134.6,5.9 134.6,6.3 			"/>
			<polygon fill="#662483" points="136,6 136,5.5 135.8,5.6 135.9,6 			"/>
			<polygon fill="#662483" points="137.5,5.9 137.3,6 137.4,6.6 137.6,6.6 			"/>
			<polygon fill="#662483" points="137.2,5.7 137.2,5.3 137,5.3 137.1,5.8 			"/>
			<polygon fill="#662483" points="139,6.3 138.9,5.7 138.8,5.8 138.9,6.3 			"/>
			<path fill="#662483" d="M140.3,35.6c-0.5-0.1-0.9,0.1-0.9,0.5c0,0.3,0.3,0.6,0.8,0.7c0.5,0.1,0.9-0.1,0.9-0.5
				C141.1,35.9,140.8,35.6,140.3,35.6z M140.2,36.5c-0.3,0-0.5-0.2-0.5-0.4c0-0.2,0.3-0.3,0.5-0.3c0.3,0,0.5,0.2,0.5,0.4
				C140.7,36.4,140.5,36.5,140.2,36.5z"/>
		</g>
		<path fill="#662483" d="M149.5,38.7c0,0-0.2,0.1-0.5,0.2C149.1,38.9,149.3,38.8,149.5,38.7C149.5,38.7,149.5,38.7,149.5,38.7z"/>
		<path fill="#662483" d="M147.2,42c0.7,0,1.3-0.1,2.1-0.1c0-0.1,0-0.2,0-0.3C148.6,41.7,147.9,41.9,147.2,42z"/>
		<path fill="#662483" d="M149.8,44.1c0,0,0-0.3,0.1-0.7c-0.7,0.1-1.4,0.1-2.1,0.1c-8.3,1.4-13.1-0.3-13.1-0.3c0.4,0,0.7,0,1.1,0
			c-0.5,0-1,0-1.4-0.1c-3-0.3-4.5-1.4-5.1-2.8c2.5,7.8,22.2,6.8,22.2,6.8l-1.8,2c8.9-1.7,16.6-6.2,16.6-6.2c-5.8,0-9.6-0.4-12.2-0.9
			c-1,0.3-2,0.6-3,0.8C150.6,43.3,150.2,43.7,149.8,44.1z"/>
		<path fill="#662483" d="M171.8,26.7c0,0-5.1,2.6-10.6,3.5c0,0,3.8,1,4.1,1c0.2,0-3.8,3.3-10.2,6.3c-1,1.3-2.3,2.9-3.9,4.6
			c1-0.2,2-0.5,3-0.8c4.2-1.3,9-3.4,14.4-6.6c0,0,0,2.7-0.4,3.9C168.3,38.7,173.7,33,171.8,26.7z"/>
		<path fill="#662483" d="M148.6,39.7c-0.2,0.1-0.3,0.1-0.5,0.2c-1.7,0.7-7.6,2.9-13.7,2.7c-0.4,0-0.7,0-1.1,0c0,0,4.7,1.7,13.1,0.3
			c0.7-0.1,1.4-0.2,2.1-0.4C148.5,41.4,148.6,39.9,148.6,39.7z"/>
	</g>
</g>
<g id="Layer_1">
</g>
<g id="svg2">
</g>
<g id="Fondos_Color">
</g>
<g id="Layer_2">
</g>
</svg>

    )
}

export default suscerte
