// @Vendors
import { combineReducers } from 'redux'

// @Reducers
import appReducer from './app/app.reducer'
import usersReducer from './users/users.reducer'
import cursosReducer from './cursos/cursos.reducer'
import authReducer from './auth/auth.reducer'
import categoriasReducer from './categorias/categorias.reducer'


export default combineReducers({
  appReducer,
  usersReducer,
  cursosReducer,
  authReducer,
  categoriasReducer
})
