// @Vendors
import React, { memo } from 'react'
import { Helmet } from "react-helmet"

// @Material ui
import Container from "@material-ui/core/Container"

// @Components
import MenuBar from 'components/Menu'
import Footer from 'components/Footer'

// @Styles
import useStyles from 'components/AppFrame/useStyles'
import FAVICO from 'statics/images/logos/logoMujer.jpg'

function AdminLoginFrame({ children: Content, user, title="" }) {
	const { classes } = useStyles()
	
	return (
		<div className={classes.container}>
			<Helmet>
				<title>MujeresTI | {title}</title>
				<link rel="icon" type="image/jpg" href={FAVICO} sizes="64x64" />
			</Helmet>

			<div>
				<MenuBar
				user={user}
				logout={() => console.log("")}
				position="relative"
				linksMenu={[]}
				/>
			</div>

			<main role="main" className={classes.main}>
				<Container maxWidth="lg">
					{Content}
				</Container>
			</main>
			<Footer />
		</div>
	)
}

export default memo(AdminLoginFrame)