const PREFIX = 'USERS'

// SETTERS
export const SET_ONE_USER = `${PREFIX}/SET_ONE_USER`
export const SET_PROFILES = `${PREFIX}/SET_PROFILES`
export const SET_DOMICILIO = `${PREFIX}/SET_DOMICILIO`
export const SET_REPRESENTANTE = `${PREFIX}/SET_REPRESENTANTE`
export const SET_FORM_STEP = `${PREFIX}/SET_FORM_STEP`
export const SET_CURSOS_REGISTRADOS = `${PREFIX}/SET_CURSOS_REGISTRADOS`
export const GET_CURSOS_REGISTRADOS = `${PREFIX}/GET_CURSOS_REGISTRADOS`
export const SET_LABORA = `${PREFIX}/SET_LABORA`
export const SET_LABORALES = `${PREFIX}/SET_LABORALES`
export const SET_ESTUDIA = `${PREFIX}/SET_ESTUDIA`
export const SET_ESTUDIOS = `${PREFIX}/SET_ESTUDIOS`
export const SET_CEDULADO = `${PREFIX}/SET_CEDULADO`
export const SET_USERS = `${PREFIX}/SET_USERS`
export const UPDATE_USER = `${PREFIX}/UPDATE_USER`

// SEND
export const REGISTER_NEW_USER = `${PREFIX}/REGISTER_NEW_USER`
export const SEARCH_SAIME = `${PREFIX}/SEARCH_SAIME`

// CLEANERS
export const CLEAR_PROFILES = `${PREFIX}/CLEAR_PROFILES`
export const CLEAR_ESTUDIOS = `${PREFIX}/CLEAR_ESTUDIOS`
export const CLEAR_DOMICILIO = `${PREFIX}/CLEAR_DOMICILIO`
export const CLEAR_USER = `${PREFIX}/CLEAR_USER`
export const DELETE_USER = `${PREFIX}/DELETE_USER`

// GETTERS
export const GET_USERS = `${PREFIX}/GET_USERS`

// FILTERS
export const UPDATE_FILTERS = `${PREFIX}/UPDATE_FILTERS`

// USUARIOS ROLES
export const ASSIGN_ROLE = `${PREFIX}/ASSIGN_ROLE`
export const SET_ASSIGNEDS = `${PREFIX}/SET_ASSIGNEDS`
export const REVOQUE_ROLE = `${PREFIX}/REVOQUE_ROLE`
export const GET_USERS_ASIGNED = `${PREFIX}/GET_USERS_ASIGNED`

export const SEND_EMAIL_CONTACT = `${PREFIX}/SEND_EMAIL_CONTACT`