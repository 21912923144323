// @Vendors
import React from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { Helmet } from "react-helmet"

// @Material UI
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
// @Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from "@material-ui/icons/Menu"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

// @Components
import useStyles from './useStyles'
import ListItemsMenu from './ListItemsMenu'
import LogoMujer from "components/LogoMujer"
import FAVICO from 'statics/images/logos/logoMujer.jpg'

function AdminFrame({
  title = "Not title assigned",
  children,
  user = {},
  onLogout
} = {}) {
  const { push } = useHistory()
  const [drawerWidth, setDrawer] = React.useState(240)
  const [open, setOpen] = React.useState(true)
  const { classes } = useStyles({ drawerWidth })

  const handleDrawer = () => {
    setOpen(prev => !prev)
    setDrawer( prev =>  prev === 240 ? 90 : 240)
  }

  React.useEffect(() => {
    if(user.roles.length === 0){
      return push("/")
    }
  }, [user, push])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>MujeresTI | {title}</title>
        <link rel="icon" type="image/jpg" href={FAVICO} sizes="64x64" />
      </Helmet>
      <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
      >
        
        <Toolbar>
        { !open && 
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            <MenuIcon />
          </IconButton>
        }
          <div className={classes.toolbarHeader}>
            <LogoMujer className={classes.logo}/>
            <Button
              startIcon={<ExitToAppIcon />}
              variant="outlined"
              onClick={onLogout}
              color="primary"
              className={classes.btnCerrarSesion}
            >
              Cerrar sesión
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >

        <div className={classes.toolbarIcon}>
          <IconButton 
            onClick={handleDrawer}
            style={{ color : "#f9f9f9"}}>
            { 
              !open 
              ? <MenuIcon color="inherit" />
              : <ChevronLeftIcon/>
            }
          </IconButton>
        </div>

        { open &&
          <div className={classes.toolbarInnerProfle}>
            <div className={classes.toolbarInnerProfle_icon}><PermIdentityIcon size="large"/></div>  
            <Typography align="center" className={classes.toolbarInnerProfle_profile}>
                <span className={classes.activeBar}></span>
                &nbsp;&nbsp;
                <span>{ user.isAuthenticated ? user.username.toLowerCase() : "Cargando..."}</span>
            </Typography>
          </div>
        }

        <Divider />

        { /* Items*/}
        <ListItemsMenu />
      </Drawer>

      <main className={classes.content} role="main">
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {children}
        </Container>
      </main>
    </div>
  );
}

export default React.memo(AdminFrame)