// @Vendors
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

// @Components
import AdminFrame from 'components/AdminFrame'

// @Actions
import { logout } from 'state/auth/auth.actions'
import { verifyToken } from 'utils/helpers'

function AdminRoute({ component: Component, alias, user, ...rest } = {}) {
    const dispatch = useDispatch()

    const doLogout = useCallback(() => {
        dispatch(logout())
    }, [dispatch])

    return verifyToken()
    ? <Route {...rest} render={ (routeProps) =>
        <AdminFrame title={alias} user={user} onLogout={doLogout}>
            <Component {...routeProps}/>
        </AdminFrame>
       } />
    : <Redirect to="/administracion/acceder"/>
}

export default React.memo(AdminRoute)
