import React from 'react'

function tgc() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 139.7 87.9"  >
   <g id="Capa_1">
       <g id="Layer_1_1_">
       </g>
       <g>
           <path fill="#662483" d="M132.7,57.9c0.8,0,2.1-0.3,2.1-1.5c0-1.6-2.5-1.4-2.5-2.2c0-0.2,0.2-0.5,0.7-0.5c0.5,0,0.9,0.1,1.3,0.2
               l0.2-0.9c-0.4-0.1-0.9-0.3-1.5-0.3c-1,0-1.9,0.5-1.9,1.5c0,1.7,2.5,1.4,2.5,2.2c0,0.3-0.2,0.5-1,0.5c-0.4,0-0.9-0.1-1.4-0.2
               l-0.1,0.9C131.5,57.8,132,57.9,132.7,57.9L132.7,57.9z M127.1,54.9c0.1-0.8,0.5-1.3,1.1-1.3c0.6,0,0.9,0.5,0.9,1.3H127.1
               L127.1,54.9z M128.4,57.9c0.7,0,1.3-0.1,1.7-0.2l-0.2-0.9c-0.4,0.1-1,0.2-1.3,0.2c-0.9,0-1.5-0.2-1.5-1.3h3.2v-0.4
               c0-1.5-0.6-2.5-2-2.5c-1.4,0-2.4,0.6-2.4,2.7C125.9,57.4,126.9,57.9,128.4,57.9L128.4,57.9z M120.3,57.8h1.2v-3.6
               c0.3-0.2,0.8-0.4,1.3-0.4c0.7,0,0.8,0.3,0.8,1v3h1.2v-3.1c0-1-0.1-1.9-1.6-1.9c-0.7,0-1.3,0.4-1.6,0.6v-0.5h-1.2V57.8L120.3,57.8z
                M116.8,56.9c-0.9,0-1.2-0.7-1.2-1.6c0-0.8,0.3-1.6,1.2-1.6c1,0,1.2,0.8,1.2,1.6C118,56.2,117.8,56.9,116.8,56.9L116.8,56.9z
                M116.8,57.9c1.4,0,2.5-0.9,2.5-2.6c0-1.6-0.8-2.6-2.4-2.6c-1.5,0-2.5,1-2.5,2.6C114.4,57,115.2,57.9,116.8,57.9L116.8,57.9z
                M113.3,52.4l0-1.2l-1.2,0l0,1.2L113.3,52.4L113.3,52.4z M112.1,57.8h1.2v-4.9h-1.2V57.8L112.1,57.8z M109.8,57.9
               c0.5,0,0.9-0.1,1.3-0.2l-0.2-0.9c-0.3,0.1-0.6,0.1-1,0.1c-1.3,0-1.6-0.5-1.6-1.6c0-1.1,0.6-1.6,1.4-1.6c0.5,0,0.8,0.1,1.1,0.2
               l0.3-0.9c-0.3-0.2-0.8-0.3-1.5-0.3c-1.6,0-2.5,1-2.5,2.6C107.1,57.1,107.9,57.9,109.8,57.9L109.8,57.9z M103.4,56.9
               c-0.4,0-0.7-0.2-0.7-0.6c0-0.4,0.4-0.6,0.9-0.6l0.9,0v1C104.3,56.7,103.9,56.9,103.4,56.9L103.4,56.9z M103.2,57.9
               c0.7,0,1.2-0.4,1.5-0.6c0.1,0.4,0.7,0.6,1.2,0.6h0.3l0.1-0.9c-0.3,0-0.5-0.1-0.5-0.3v-2.4c0-1.1-0.6-1.5-2-1.5
               c-0.8,0-1.4,0.2-1.8,0.4l0.2,0.9c0.4-0.1,0.9-0.2,1.4-0.2c0.7,0,1,0.1,1,0.7v0.4l-1,0c-0.9,0-2,0.3-2,1.5
               C101.6,57.4,102.2,57.9,103.2,57.9L103.2,57.9z M99.7,57.9c0.5,0,0.9-0.1,1.3-0.2l-0.2-0.9c-0.3,0.1-0.6,0.1-1,0.1
               c-1.3,0-1.6-0.5-1.6-1.6c0-1.1,0.6-1.6,1.4-1.6c0.5,0,0.8,0.1,1.1,0.2l0.3-0.9c-0.3-0.2-0.8-0.3-1.5-0.3c-1.6,0-2.5,1-2.5,2.6
               C96.9,57.1,97.7,57.9,99.7,57.9L99.7,57.9z M95.8,52.4l0-1.2l-1.2,0l0,1.2L95.8,52.4L95.8,52.4z M94.6,57.8h1.2v-4.9h-1.2V57.8
               L94.6,57.8z M88.8,57.8H90v-3.6c0.3-0.2,0.8-0.4,1.3-0.4c0.7,0,0.8,0.3,0.8,1v3h1.2v-3.1c0-1-0.1-1.9-1.6-1.9
               c-0.7,0-1.3,0.4-1.6,0.6v-0.5h-1.2V57.8L88.8,57.8z M84.5,57.9c0.7,0,1.3-0.4,1.7-0.6v0.5h1.2v-4.9h-1.2v3.6
               c-0.2,0.1-0.8,0.4-1.3,0.4c-0.6,0-0.8-0.3-0.8-1v-3H83V56C83,57,83.1,57.9,84.5,57.9L84.5,57.9z M74.2,57.8h1.2v-3.6
               c0.3-0.2,0.8-0.4,1.2-0.4c0.6,0,0.8,0.3,0.8,1v3h1.2v-3.1c0-0.2,0-0.3,0-0.5c0.3-0.2,0.8-0.4,1.2-0.4c0.6,0,0.8,0.4,0.8,1v3h1.2
               v-3.1c0-0.8,0-1.9-1.6-1.9c-0.7,0-1.3,0.4-1.7,0.7c-0.2-0.4-0.6-0.7-1.4-0.7c-0.6,0-1.2,0.3-1.6,0.7v-0.5h-1.2V57.8L74.2,57.8z
                M70.6,56.9c-0.9,0-1.2-0.7-1.2-1.6c0-0.8,0.3-1.6,1.2-1.6c1,0,1.2,0.8,1.2,1.6C71.8,56.2,71.6,56.9,70.6,56.9L70.6,56.9z
                M70.6,57.9c1.4,0,2.5-0.9,2.5-2.6c0-1.6-0.8-2.6-2.4-2.6c-1.5,0-2.5,1-2.5,2.6C68.2,57,69.1,57.9,70.6,57.9L70.6,57.9z
                M66.2,57.9c0.5,0,0.9-0.1,1.3-0.2l-0.2-0.9c-0.3,0.1-0.6,0.1-1,0.1c-1.3,0-1.6-0.5-1.6-1.6c0-1.1,0.6-1.6,1.4-1.6
               c0.5,0,0.8,0.1,1.1,0.2l0.3-0.9c-0.3-0.2-0.8-0.3-1.5-0.3c-1.6,0-2.5,1-2.5,2.6C63.5,57.1,64.3,57.9,66.2,57.9L66.2,57.9z
                M59.5,54.9c0.1-0.8,0.5-1.3,1.1-1.3c0.6,0,0.9,0.5,0.9,1.3H59.5L59.5,54.9z M60.8,57.9c0.7,0,1.3-0.1,1.7-0.2l-0.2-0.9
               c-0.4,0.1-1,0.2-1.3,0.2c-0.9,0-1.6-0.2-1.6-1.3h3.2v-0.4c0-1.5-0.6-2.5-2-2.5c-1.4,0-2.4,0.6-2.4,2.7
               C58.3,57.4,59.3,57.9,60.8,57.9L60.8,57.9z M56,57.8h1.2V51H56V57.8L56,57.8z M51.7,54.9c0.1-0.8,0.5-1.3,1.1-1.3
               c0.6,0,0.9,0.5,0.9,1.3H51.7L51.7,54.9z M53,57.9c0.7,0,1.3-0.1,1.7-0.2l-0.2-0.9c-0.4,0.1-1,0.2-1.3,0.2c-0.9,0-1.6-0.2-1.6-1.3
               h3.2v-0.4c0-1.5-0.6-2.5-2-2.5c-1.4,0-2.4,0.6-2.4,2.7C50.5,57.4,51.5,57.9,53,57.9L53,57.9z M47.2,57.8h1.2v-5.4h2.1v-1h-5.4v1
               h2.1V57.8L47.2,57.8z"/>
           <path fill="#662483" d="M128.7,71h6.1v-1.4h-4.3V67h3.7v-1.3h-3.7v-2.5h4v-1.4h-5.8V71L128.7,71z M121.7,65.6v-2.5h0.9
               c1.2,0,1.8,0.3,1.8,1.3c0,0.9-0.5,1.2-1.7,1.2H121.7L121.7,65.6z M121.7,69.6v-2.7h1.1c1.5,0,2.3,0.3,2.3,1.4c0,1-0.8,1.3-2,1.3
               H121.7L121.7,69.6z M119.9,71h3.4c2.6,0,3.6-1.1,3.6-2.8c0-1.5-1.3-2.1-1.7-2.2v0c0.3-0.2,1.1-0.7,1.1-1.8c0-1.6-1.1-2.5-3.3-2.5
               h-3V71L119.9,71z M115.7,71h1.8v-9.3h-1.8V71L115.7,71z M107.9,66.3v-3.2h1.3c1.3,0,1.8,0.4,1.8,1.6c0,1.1-0.7,1.6-2.1,1.6H107.9
               L107.9,66.3z M114,71.2l0.4-1.4c-0.8-0.2-1.4-0.5-2-1.2l-1.1-1.4c1.1-0.5,1.6-1.5,1.6-2.5c0-2-1.4-2.9-3.4-2.9h-3.4V71h1.8v-3.4
               h0.9c0.2,0,0.5,0,0.6,0l1.3,1.7C111.7,70.4,112.7,71.1,114,71.2L114,71.2z M99,66.7l1.3-3.8l1.1,3.8H99L99,66.7z M95.6,71h1.8
               l1.1-3h3.3l1,3h1.9l-3.2-9.3h-2.3L95.6,71L95.6,71z M92.3,71.1c1.3,0,2.1-0.2,2.8-0.4l-0.4-1.4c-0.6,0.2-1.3,0.3-2.2,0.3
               c-2,0-3.4-1.1-3.4-3.4c0-2.2,1.4-3.2,3.2-3.2c1.1,0,1.6,0.2,2.3,0.4l0.5-1.3c-0.5-0.2-1.3-0.5-2.8-0.5c-3.2,0-5,1.7-5,4.8
               C87.2,69.4,89,71.1,92.3,71.1L92.3,71.1z M74.3,71h1.8v-5.9L76.1,64l4,7h2.3v-9.3h-1.8v6l0.1,1.1l-4.1-7.1h-2.2V71L74.3,71z
                M67.2,66.7l1.3-3.8l1.1,3.8H67.2L67.2,66.7z M63.8,71h1.8l1.1-3h3.3l1,3H73l-3.2-9.3h-2.3L63.8,71L63.8,71z M57,66.3v-3.2h1.3
               c1.3,0,1.8,0.4,1.8,1.6c0,1.1-0.7,1.6-2.1,1.6H57L57,66.3z M63.1,71.2l0.4-1.4c-0.8-0.2-1.4-0.5-2-1.2l-1.1-1.4
               c1.1-0.5,1.6-1.5,1.6-2.5c0-2-1.4-2.9-3.4-2.9h-3.4V71H57v-3.4h0.9c0.2,0,0.5,0,0.6,0l1.3,1.7C60.8,70.4,61.8,71.1,63.1,71.2
               L63.1,71.2z M50.1,71.1c1.2,0,2-0.2,3-0.5v-4.2h-1.8v3.1c-0.2,0-0.6,0.1-0.9,0.1c-1.9,0-3.4-1-3.4-3.4c0-2.3,1.4-3.2,3.2-3.2
               c1.1,0,1.6,0.2,2.3,0.4l0.5-1.3c-0.6-0.2-1.3-0.5-2.7-0.5c-3.2,0-5,1.6-5,4.8C45.1,69.4,47,71.1,50.1,71.1L50.1,71.1z"/>
           <path fill="#662483" d="M23.8,32.6L23.8,32.6L23.8,32.6L23.8,32.6z M23.8,32.6C23.8,32.5,23.8,32.5,23.8,32.6
               C23.8,32.5,23.8,32.5,23.8,32.6L23.8,32.6z M23.8,32.5c0,0,0-0.1,0-0.1C23.7,32.5,23.7,32.5,23.8,32.5L23.8,32.5z M22.6,26.9
               L22.6,26.9C22.6,26.9,22.6,26.9,22.6,26.9C22.6,26.9,22.6,26.9,22.6,26.9L22.6,26.9z M22.6,26.9C22.6,26.9,22.6,26.8,22.6,26.9
               C22.6,26.8,22.6,26.9,22.6,26.9L22.6,26.9z M22.6,26.8C22.6,26.8,22.6,26.8,22.6,26.8C22.6,26.8,22.6,26.8,22.6,26.8L22.6,26.8z
                M22.6,26.8c0-1.9,0.4-3.8,1.1-5.5c0.7-1.8,1.8-3.4,3.2-4.7c0.7-0.7,1.4-1.3,2.2-1.8c1.4,1.9,2.1,4.3,2.1,6.8
               c0,0.8-0.1,1.6-0.2,2.4c-0.5,0-1-0.1-1.5-0.1c-2.5,0-4.8,0.6-6.8,1.6C22.6,26,22.6,26.4,22.6,26.8L22.6,26.8z"/>
           <path fill="#662483" d="M15.6,33.2c-5.1-1.5-8.8-6.1-8.8-11.6c0-6.6,5.4-12,12.2-12c3.6,0,6.9,1.6,9.1,4c-1,0.6-1.9,1.3-2.7,2.1
               c-1.5,1.5-2.7,3.2-3.5,5.1c-0.8,1.8-1.2,3.9-1.2,6c0,0,0-0.1,0-0.1C18.4,28.3,16.6,30.6,15.6,33.2L15.6,33.2z M20.5,26.9
               C20.5,26.9,20.5,26.9,20.5,26.9C20.5,26.9,20.5,26.9,20.5,26.9L20.5,26.9z M20.5,26.8C20.5,26.8,20.5,26.8,20.5,26.8
               C20.5,26.8,20.5,26.8,20.5,26.8L20.5,26.8z M20.5,26.8C20.5,26.8,20.5,26.8,20.5,26.8C20.5,26.8,20.5,26.8,20.5,26.8L20.5,26.8z"
               />
           <path fill="#662483" d="M29.9,12.6c-0.5-4.3,2.7-8.1,7-8.2h0.4c3.5,0.1,6.4,2.6,7,6c-0.8,0.4-1.5,0.9-1.9,1.7
               c0.1,0,0.2,0.1,0.3,0.1c-1.7-0.7-3.5-1.1-5.4-1.1c-2.2,0-4.4,0.4-6.5,1.2c-0.3,0.1-0.6,0.3-0.9,0.4C29.9,12.7,29.9,12.7,29.9,12.6
               L29.9,12.6z"/>
           <path fill="#662483" d="M37.1,18.9c-3.2,0-5.9-2-6.9-4.8c0.5-0.3,0.9-0.5,1.4-0.7c1.7-0.7,3.6-1.1,5.6-1.1c1.6,0,3.2,0.3,4.6,0.7
               c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.9,0.3,1.7,0.7,2.3C41.1,18,39.2,18.9,37.1,18.9L37.1,18.9z M42.6,13.3
               C42.6,13.3,42.6,13.3,42.6,13.3C42.6,13.3,42.6,13.3,42.6,13.3L42.6,13.3z M42.5,13.3C42.5,13.3,42.5,13.3,42.5,13.3
               C42.5,13.3,42.5,13.3,42.5,13.3L42.5,13.3z M42.5,13.3C42.5,13.3,42.5,13.3,42.5,13.3C42.5,13.3,42.5,13.3,42.5,13.3L42.5,13.3z"
               />
           <path fill="#662483" d="M49.6,17.2c-0.3-0.4-0.7-0.8-1-1.1c-1.2-1.2-2.5-2.2-4-3c0,0,0,0,0,0c0.1-0.4,0.1-0.9,0.1-1.3
               c0-0.4,0-0.9-0.1-1.3c0.5-0.2,1.1-0.4,1.8-0.4c2.4,0,4.3,1.9,4.3,4.3C50.7,15.4,50.3,16.4,49.6,17.2L49.6,17.2z"/>
           <path fill="#662483" d="M44.4,13C44.4,13,44.4,13,44.4,13c-0.4-0.2-0.8-0.4-1.2-0.6l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
               c-0.2-0.1-0.4-0.2-0.6-0.3c0.5-0.7,1.1-1.3,1.9-1.7c0.1,0.4,0.1,0.8,0.1,1.3C44.5,12.2,44.5,12.6,44.4,13L44.4,13L44.4,13z"/>
           <path fill="#662483" d="M47.9,16.7c0.4,0.3,0.7,0.7,1,1.1c-0.7,0.5-1.6,0.9-2.6,0.9l0,0c-1.5,0-2.9-0.8-3.6-2
               c0.7-0.7,1.3-1.6,1.6-2.6C44.3,14.1,45.3,14.3,47.9,16.7z M43.1,13.6L43.1,13.6L43.1,13.6L43.1,13.6z M42.8,13.5
               C42.8,13.5,42.8,13.5,42.8,13.5C42.8,13.5,42.8,13.5,42.8,13.5L42.8,13.5z"/>
           <path fill="#662483" d="M42.6,16.7c-0.4-0.7-0.7-1.5-0.7-2.3c0-0.4,0.1-0.8,0.2-1.2c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0,0,0,0
               c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0
               c0.4,0.2,0.7,0.3,1.1,0.5C43.8,15,43.3,15.9,42.6,16.7L42.6,16.7L42.6,16.7z"/>
           <path fill="#662483" d="M37.5,42.2c-1.9,0-3.8-0.4-5.5-1.2c-1.9-0.8-3.5-2-4.9-3.4c-1.3-1.3-2.4-2.9-3.1-4.6c0,0.1,0,0.1,0.1,0.2
               c3.6-1.5,6.4-4.8,7.1-8.7c7.5,0.7,13.4,7,13.4,14.7c0,0.3,0,0.7,0,1c-0.5,0.3-1.1,0.6-1.6,0.8C41.3,41.8,39.4,42.2,37.5,42.2
               L37.5,42.2z M23.9,33.1C23.9,33.1,23.9,33.1,23.9,33.1C23.9,33.1,23.9,33.1,23.9,33.1L23.9,33.1z M23.9,33.1
               C23.9,33.1,23.9,33.1,23.9,33.1C23.9,33.1,23.9,33.1,23.9,33.1L23.9,33.1z M22.8,27.4C22.8,27.4,22.8,27.4,22.8,27.4L22.8,27.4z"
               />
           <path fill="#662483" d="M23.8,33c0-0.1,0-0.1-0.1-0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1l0,0
               c-0.7-1.7-1.1-3.6-1.1-5.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.4,0-0.8,0.1-1.2
               c2-1,4.4-1.6,6.8-1.6c0.5,0,1,0,1.5,0.1C30.2,28.2,27.5,31.5,23.8,33L23.8,33L23.8,33z"/>
           <path fill="#662483" d="M29.5,54.1c-8.2,0-14.9-6.6-14.9-14.8c0-1.9,0.4-3.8,1.1-5.5c1.1,0.3,2.2,0.5,3.4,0.5
               c1.2,0,2.3-0.2,3.4-0.5c0.9,1.8,2.2,3.4,3.6,4.8c1.4,1.4,3.1,2.5,5,3.2c1.9,0.7,4,1.1,6.2,1.1c2.2,0,4.3-0.4,6.2-1.1
               c0.2-0.1,0.5-0.2,0.7-0.3C43.2,48.6,37,54.1,29.5,54.1L29.5,54.1z M20.8,27.5L20.8,27.5C20.8,27.5,20.8,27.4,20.8,27.5
               C20.8,27.4,20.8,27.5,20.8,27.5L20.8,27.5z M20.8,27.4C20.8,27.4,20.8,27.4,20.8,27.4C20.8,27.4,20.8,27.4,20.8,27.4L20.8,27.4z
                M20.8,27.4C20.8,27.4,20.8,27.4,20.8,27.4C20.8,27.4,20.8,27.4,20.8,27.4L20.8,27.4z M20.8,27.4C20.8,27.4,20.8,27.4,20.8,27.4
               C20.8,27.4,20.8,27.4,20.8,27.4L20.8,27.4z"/>
           <path fill="#662483" d="M19.1,34c-1.2,0-2.3-0.2-3.4-0.5c1.1-2.6,2.9-4.9,5.2-6.5c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0
               c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0c0,2,0.5,4,1.4,5.8c0.1,0.2,0.2,0.4,0.3,0.6
               C21.4,33.8,20.2,34,19.1,34L19.1,34L19.1,34z"/>
           <path fill="#662483" d="M31,24.3c-0.8,3.9-3.5,7.2-7.1,8.7c0-0.1-0.1-0.2-0.1-0.3c-0.7-1.7-1.1-3.6-1.1-5.6c0-0.4,0-0.9,0.1-1.3
               c2-1,4.4-1.6,6.8-1.6C30,24.2,30.5,24.3,31,24.3L31,24.3z"/>
           <path fill="#662483" d="M116.3,38.8c0.7,0,1.4,0.3,1.9,0.7h0c0.5,0.4,0.8,1.1,0.8,1.7c0,0.5-0.2,1.1-0.5,1.5
               c-0.3,0.3-0.6,0.6-1,0.8l0,0l-0.3,0.1c-1.2,0.4-2.3,0.7-3.5,0.9c-1.2,0.2-2.5,0.3-4.1,0.3c-4.6,0-8-1-10.4-3c-2.4-2-3.6-5-3.6-8.9
               c0-4.1,1.2-7.1,3.5-9.2c2.3-2.1,5.8-3.1,10.3-3.1c1.7,0,3.1,0.1,4.3,0.4c1.2,0.2,2.2,0.5,3.1,0.9l0,0c0,0,0.1,0,0.1,0
               c0,0,0.1,0,0.1,0.1l0,0c0.4,0.2,0.8,0.5,1.1,0.9c0.2,0.4,0.4,0.8,0.4,1.3c0,0.7-0.3,1.3-0.8,1.7l0,0c-0.5,0.4-1.1,0.7-1.9,0.7
               c-0.1,0-0.3,0-0.4,0l0,0c-0.1,0-0.3,0-0.4-0.1l0,0c-1-0.3-1.8-0.4-2.6-0.6c-0.8-0.1-1.7-0.2-3-0.2h-0.2c-2,0-3.8,0.5-5,1.6
               c-1.2,1.1-1.9,2.7-2,4.9v0.2c0,2.3,0.7,4,2.1,5.2c1.4,1.2,3.4,1.9,5.8,1.9c1.2,0,2.1,0,3-0.1c0.9-0.1,1.7-0.3,2.6-0.5l0,0
               C116,38.8,116.2,38.8,116.3,38.8L116.3,38.8L116.3,38.8L116.3,38.8z"/>
           <path fill="#662483" d="M89.3,30.1c0.9,0,1.7,0.3,2.3,0.8c0.6,0.5,1,1.3,1,2.1v0l0,9.7c-0.1,0.5-0.3,0.9-0.9,1.1
               c-1.2,0.4-2.3,0.6-3.4,0.8C87,44.9,85.6,45,84.1,45c-4.4,0-8-1-10.4-3.1c-2.3-2-3.7-4.9-3.7-8.8c0-4.2,1.3-7.3,3.7-9.3
               c2.4-2,6-2.9,10.7-2.9c1.8,0,3.2,0.2,4.4,0.4c1.2,0.3,2.2,0.6,3.2,1l0.3,0.1l0,0.1c0.3,0.2,0.6,0.5,0.8,0.8
               c0.2,0.4,0.4,0.8,0.4,1.3c0,0.7-0.3,1.3-0.8,1.7l0,0c-0.5,0.4-1.2,0.7-1.9,0.7c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.4-0.1-0.5-0.2
               c-1-0.2-1.8-0.4-2.6-0.5c-0.8-0.1-1.6-0.2-2.8-0.2c-2.3,0-4.2,0.5-5.5,1.7c-1.2,1.1-1.9,2.7-1.9,5c0,2.3,0.8,4,2.1,5.1
               c1.4,1.3,3.5,1.9,5.8,1.9c0.1,0,1.2-0.1,1.2-1.2l0-5.5v0c0-0.8,0.4-1.5,1-2.1C87.6,30.4,88.4,30.1,89.3,30.1L89.3,30.1z"/>
           <path fill="#662483" d="M46.8,26.1c-0.5-0.1-1-0.3-1.4-0.7v0c-0.4-0.4-0.7-1.1-0.7-1.7c0-0.7,0.3-1.3,0.7-1.7l0,0l0,0
               c0.4-0.4,1.1-0.7,1.7-0.7v0l19.8,0l0.3,0c0.8,0,1.3,0.6,1.5,0.7l0,0c0.4,0.4,0.7,1.1,0.7,1.8c0,0.7-0.3,1.3-0.7,1.7l0,0
               c-0.1,0.1-0.9,0.7-1.5,0.7H67c-1.9,0-3.8,0-5.6,0c-0.7,0.1-1.2,0.4-1.2,1.2v15.2h-0.1c-0.1,0.7-0.4,1.3-0.9,1.8
               c-0.6,0.5-1.3,0.8-2.1,0.8c-0.8,0-1.6-0.3-2.1-0.8c-0.5-0.5-0.8-1.1-0.9-1.8h0v-0.3c0-5,0-9.9,0-14.9c0-0.7-0.3-1.1-1.2-1.2
               L46.8,26.1L46.8,26.1L46.8,26.1z"/>
           <path fill="#662483" d="M133.9,80.7l-0.2-1.3l-0.1-0.9h0l-0.2,0.9l-0.5,1.3H133.9L133.9,80.7z M134.8,82.9h-0.5l-0.3-1.8h-1.2
               l-0.6,1.8h-0.5l1.7-4.7h0.7L134.8,82.9L134.8,82.9z M131.2,81.4c0,0.8-0.4,1-0.8,1h-0.5l0.1-1.8h0.4
               C130.7,80.7,131.2,80.8,131.2,81.4L131.2,81.4z M131.1,79.3c0,0.7-0.3,1-0.7,1H130l0.1-1.7h0.4C130.8,78.6,131.1,78.7,131.1,79.3
               L131.1,79.3z M131.7,81.4c0-0.5-0.3-0.8-0.6-1v0c0.4-0.1,0.6-0.7,0.6-1.1c0-0.8-0.5-1.1-1-1.1h-0.9l-0.4,4.7h1.1
               C131.2,82.9,131.7,82.4,131.7,81.4L131.7,81.4z M128.9,82.4h-1.5l0.3-4.3h-0.5l-0.4,4.7h1.9L128.9,82.4L128.9,82.4z M125.7,80.7
               h-1l0.5-1.3l0.2-0.9h0l0.1,0.9L125.7,80.7L125.7,80.7z M126.6,82.9l-0.7-4.7h-0.7l-1.7,4.7h0.5l0.6-1.8h1.2l0.3,1.8H126.6
               L126.6,82.9z M122.5,77.9H122l-0.4,5h0.5L122.5,77.9L122.5,77.9z M120.9,80c0,0.2-0.1,0.4-0.4,0.6l-0.6,0.4
               c0.1-0.6,0.3-1.3,0.8-1.3C120.8,79.6,120.9,79.7,120.9,80L120.9,80z M121.4,80c0-0.5-0.2-0.8-0.7-0.8c-0.9,0-1.3,1.2-1.3,2.5
               c0,0.7,0.1,1.3,0.7,1.3c0.4,0,0.7-0.2,1.1-0.5l-0.1-0.4c-0.3,0.2-0.6,0.4-0.9,0.4c-0.2,0-0.2-0.4-0.2-0.9c0,0,0-0.2,0-0.2l0.8-0.4
               C121,80.8,121.4,80.5,121.4,80L121.4,80z M118.5,79.7l-0.2,2c-0.1,0.2-0.6,0.8-0.8,0.8c-0.1,0-0.2-0.1-0.2-0.7c0-1.1,0.5-2,1-2
               C118.5,79.7,118.5,79.7,118.5,79.7L118.5,79.7z M119.1,77.8h-0.5l-0.1,1.4c-1.3,0-1.7,1.7-1.7,2.6c0,0.7,0.1,1.1,0.6,1.1
               c0.2,0,0.6-0.4,0.9-0.8l-0.1,0.7h0.5L119.1,77.8L119.1,77.8z M115.5,79.4c-0.2-0.1-0.5-0.2-0.7-0.2c-0.5,0-0.9,0.3-0.9,1
               c0,0.9,1,1.1,1,1.8c0,0.4-0.3,0.5-0.5,0.5c-0.2,0-0.4,0-0.7-0.2l-0.1,0.4c0.3,0.2,0.5,0.2,0.8,0.2c0.4,0,1-0.3,1-1
               c0-1-1-1.1-1-1.8c0-0.3,0.2-0.5,0.4-0.5c0.2,0,0.4,0,0.7,0.2L115.5,79.4L115.5,79.4z M113,80c0,0.2-0.1,0.4-0.4,0.6l-0.6,0.4
               c0.1-0.6,0.3-1.3,0.8-1.3C112.9,79.6,113,79.7,113,80L113,80z M113.5,80c0-0.5-0.2-0.8-0.7-0.8c-0.9,0-1.3,1.2-1.3,2.5
               c0,0.7,0.1,1.3,0.7,1.3c0.4,0,0.7-0.2,1.1-0.5l-0.1-0.4c-0.3,0.2-0.6,0.4-0.9,0.4c-0.2,0-0.2-0.4-0.2-0.9c0,0,0-0.2,0-0.2l0.8-0.4
               C113.1,80.8,113.5,80.5,113.5,80L113.5,80z M111.1,79.8c0-0.4-0.1-0.6-0.5-0.6c-0.3,0-0.6,0.3-0.9,0.6l0-0.5h-0.5l-0.3,3.6h0.5
               l0.2-2.6c0.2-0.3,0.6-0.6,0.8-0.6c0.2,0,0.2,0.1,0.2,0.2c0,0.1,0,0.3,0,0.4l-0.2,2.6h0.5l0.2-2.6C111,80.1,111.1,79.9,111.1,79.8
               L111.1,79.8z M108.1,80.5c0,1.2-0.4,2-0.8,2c-0.4,0-0.4-0.5-0.4-0.9c0-1.2,0.4-2,0.8-2C108,79.6,108.1,79.8,108.1,80.5L108.1,80.5
               z M108.6,80.4c0-0.7-0.2-1.3-0.8-1.3c-0.8,0-1.3,1.1-1.3,2.5c0,0.8,0.3,1.3,0.8,1.3C108.2,82.9,108.6,81.5,108.6,80.4L108.6,80.4z
                M106.2,78l-0.4-0.4l-0.4,0.4l0.4,0.4L106.2,78L106.2,78z M106,79.2h-0.5l-0.3,3.6h0.5L106,79.2L106,79.2z M105.1,79.4
               c-0.1-0.1-0.3-0.2-0.7-0.2c-0.9,0-1.3,1.2-1.3,2.5c0,0.8,0.2,1.3,0.7,1.3c0.4,0,0.8-0.3,1.1-0.5l-0.2-0.4
               c-0.3,0.2-0.7,0.4-0.9,0.4c-0.1,0-0.2-0.3-0.2-0.9c0-0.8,0.2-2,0.8-2c0.3,0,0.5,0.1,0.5,0.2L105.1,79.4L105.1,79.4z M102.1,79.7
               l-0.2,2c-0.1,0.2-0.6,0.8-0.8,0.8c-0.1,0-0.2-0.1-0.2-0.6c0-1.1,0.4-2.1,1-2.2C102.1,79.7,102.1,79.7,102.1,79.7L102.1,79.7z
                M102.6,79.3c-0.2,0-0.4-0.1-0.5-0.1c-1,0-1.6,1.3-1.6,2.6c0,0.8,0.1,1.1,0.6,1.1c0.3,0,0.6-0.4,0.9-0.8l-0.1,0.7h0.5L102.6,79.3
               L102.6,79.3z M100.4,79.4c-0.1-0.1-0.3-0.2-0.7-0.2c-0.9,0-1.3,1.2-1.3,2.5c0,0.8,0.2,1.3,0.7,1.3c0.4,0,0.8-0.3,1.1-0.5L100,82
               c-0.3,0.2-0.7,0.4-0.9,0.4c-0.1,0-0.2-0.3-0.2-0.9c0-0.8,0.2-2,0.8-2c0.3,0,0.5,0.1,0.5,0.2L100.4,79.4L100.4,79.4z M98.2,78
               l-0.4-0.4L97.4,78l0.4,0.4L98.2,78L98.2,78z M98,79.2h-0.5l-0.3,3.6h0.5L98,79.2L98,79.2z M96.7,79.8c0-0.4-0.1-0.6-0.5-0.6
               c-0.3,0-0.6,0.3-0.9,0.6l0-0.5h-0.5l-0.3,3.6h0.5l0.2-2.6c0.2-0.3,0.6-0.6,0.8-0.6c0.2,0,0.2,0.1,0.2,0.2c0,0.1,0,0.3,0,0.4
               L96,82.9h0.5l0.2-2.6C96.7,80.1,96.7,79.9,96.7,79.8L96.7,79.8z M94.1,79.2h-0.5l-0.2,2.6c-0.1,0.1-0.6,0.7-0.8,0.7
               c-0.2,0-0.2-0.1-0.2-0.2c0-0.1,0-0.3,0-0.4l0.2-2.6h-0.5L92,81.8c0,0.2,0,0.3,0,0.5c0,0.4,0.1,0.6,0.5,0.6c0.3,0,0.7-0.4,0.9-0.7
               l0,0.6h0.5L94.1,79.2L94.1,79.2z M91.4,80.3c0-0.2,0-0.3,0-0.5c0-0.4-0.1-0.7-0.5-0.7c-0.3,0-0.6,0.4-0.8,0.6
               c0-0.4-0.1-0.6-0.5-0.6c-0.3,0-0.6,0.4-0.8,0.6l0-0.5h-0.5L88,82.9h0.5l0.2-2.6c0.1-0.1,0.5-0.6,0.7-0.6c0.1,0,0.2,0,0.2,0.1
               c0,0.1,0,0.4,0,0.5l-0.2,2.6h0.5l0.2-2.6c0.1-0.1,0.5-0.6,0.7-0.6c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.4,0,0.5l-0.2,2.6h0.5L91.4,80.3
               L91.4,80.3z M87.2,80.5c0,1.2-0.4,2-0.8,2C86,82.5,86,82,86,81.6c0-1.2,0.4-2,0.8-2C87,79.6,87.2,79.8,87.2,80.5L87.2,80.5z
                M87.6,80.4c0-0.7-0.2-1.3-0.8-1.3c-0.8,0-1.3,1.1-1.3,2.5c0,0.8,0.3,1.3,0.8,1.3C87.3,82.9,87.6,81.5,87.6,80.4L87.6,80.4z
                M85.4,79.4c-0.1-0.1-0.3-0.2-0.7-0.2c-0.9,0-1.3,1.2-1.3,2.5c0,0.8,0.2,1.3,0.7,1.3c0.4,0,0.8-0.3,1.1-0.5L85,82
               c-0.3,0.2-0.7,0.4-0.9,0.4c-0.1,0-0.2-0.3-0.2-0.9c0-0.8,0.2-2,0.8-2c0.3,0,0.5,0.1,0.5,0.2L85.4,79.4L85.4,79.4z M82.8,80
               c0,0.2-0.1,0.4-0.4,0.6l-0.6,0.4c0.1-0.6,0.3-1.3,0.8-1.3C82.7,79.6,82.8,79.7,82.8,80L82.8,80z M83.2,80c0-0.5-0.2-0.8-0.7-0.8
               c-0.9,0-1.3,1.2-1.3,2.5c0,0.7,0.1,1.3,0.7,1.3c0.4,0,0.7-0.2,1.1-0.5l-0.1-0.4c-0.3,0.2-0.6,0.4-0.9,0.4c-0.2,0-0.2-0.4-0.2-0.9
               c0,0,0-0.2,0-0.2l0.8-0.4C82.9,80.8,83.2,80.5,83.2,80L83.2,80z M80.9,77.9h-0.5l-0.4,5h0.5L80.9,77.9L80.9,77.9z M79.4,80
               c0-0.3-0.1-0.4-0.3-0.4c-0.5,0-0.7,0.7-0.8,1.3l0.6-0.4C79.2,80.4,79.4,80.2,79.4,80L79.4,80z M79.8,80c0,0.6-0.4,0.8-0.7,1
               l-0.8,0.4c0,0,0,0.2,0,0.2c0,0.5,0,0.9,0.2,0.9c0.3,0,0.5-0.1,0.9-0.4l0.1,0.4c-0.3,0.3-0.6,0.5-1.1,0.5c-0.6,0-0.7-0.6-0.7-1.3
               c0-1.2,0.4-2.5,1.3-2.5C79.6,79.1,79.8,79.5,79.8,80L79.8,80z M78.2,78.2h-2.8l0,0.5h1.1l-0.3,4.3h0.5l0.3-4.3h1.1L78.2,78.2
               L78.2,78.2z M74.2,79.4c-0.2-0.1-0.5-0.2-0.7-0.2c-0.5,0-0.9,0.3-0.9,1c0,0.9,1,1.1,1,1.8c0,0.4-0.3,0.5-0.5,0.5
               c-0.2,0-0.4,0-0.7-0.2l-0.1,0.4c0.3,0.2,0.5,0.2,0.8,0.2c0.4,0,1-0.3,1-1c0-1-1-1.1-1-1.8c0-0.3,0.2-0.5,0.4-0.5
               c0.2,0,0.4,0,0.7,0.2L74.2,79.4L74.2,79.4z M71.4,79.7l-0.2,2c-0.1,0.2-0.6,0.8-0.8,0.8c-0.1,0-0.2-0.1-0.2-0.6
               c0-1.1,0.4-2.1,1-2.2C71.3,79.7,71.4,79.7,71.4,79.7L71.4,79.7z M71.9,79.3c-0.2,0-0.4-0.1-0.5-0.1c-1,0-1.6,1.3-1.6,2.6
               c0,0.8,0.1,1.1,0.6,1.1c0.3,0,0.6-0.4,0.9-0.8l-0.1,0.7h0.5L71.9,79.3L71.9,79.3z M69.4,77.9H69l-0.4,5h0.5L69.4,77.9L69.4,77.9z
                M66.5,80.5c0,1.2-0.4,2-0.8,2c-0.4,0-0.4-0.5-0.4-0.9c0-1.2,0.4-2,0.8-2C66.4,79.6,66.5,79.8,66.5,80.5L66.5,80.5z M67,80.4
               c0-0.7-0.2-1.3-0.8-1.3c-0.8,0-1.3,1.1-1.3,2.5c0,0.8,0.3,1.3,0.8,1.3C66.7,82.9,67,81.5,67,80.4L67,80.4z M64,79.7l-0.2,2
               c-0.1,0.2-0.6,0.8-0.8,0.8c-0.1,0-0.2-0.1-0.2-0.7c0-1.1,0.5-2,1-2C63.9,79.7,63.9,79.7,64,79.7L64,79.7z M64.6,77.8h-0.5L64,79.2
               c-1.3,0-1.6,1.7-1.6,2.6c0,0.7,0.1,1.1,0.6,1.1c0.2,0,0.6-0.4,0.9-0.8l-0.1,0.7h0.5L64.6,77.8L64.6,77.8z M61.9,79.8
               c0-0.4-0.1-0.6-0.5-0.6c-0.3,0-0.6,0.3-0.9,0.6l0-0.5h-0.5l-0.3,3.6h0.5l0.2-2.6c0.2-0.3,0.6-0.6,0.8-0.6c0.2,0,0.2,0.1,0.2,0.2
               c0,0.1,0,0.3,0,0.4l-0.2,2.6h0.5l0.2-2.6C61.9,80.1,61.9,79.9,61.9,79.8L61.9,79.8z M58.8,79.7L58.8,79.7c0,0-0.1,0-0.1,0
               c-0.6,0-1,1.1-1,2.2c0,0.5,0.1,0.6,0.2,0.6c0.2,0,0.7-0.6,0.8-0.8L58.8,79.7L58.8,79.7z M59.3,79.3L59,82.9h-0.5l0.1-0.7
               C58.3,82.6,58,83,57.8,83c-0.4,0-0.6-0.3-0.6-1.1c0-1.4,0.6-2.6,1.6-2.6C58.9,79.2,59.1,79.2,59.3,79.3L59.3,79.3z M57.3,79.2
               c0,0-0.1,0-0.2,0c-0.3,0-0.6,0.4-0.8,0.7h0l0-0.6h-0.5l-0.3,3.6h0.5l0.2-2.5c0.1-0.2,0.5-0.6,0.7-0.6c0.1,0,0.1,0,0.2,0L57.3,79.2
               L57.3,79.2z M54.6,79.7c0,0-0.1,0-0.1,0c-0.6,0-1,1-1,2.1c0,0.6,0.1,0.7,0.2,0.7c0.2,0,0.7-0.6,0.8-0.8L54.6,79.7L54.6,79.7z
                M55.1,79.3l-0.3,3.8c-0.1,1-0.7,1.3-1.2,1.3c-0.4,0-0.8-0.1-0.9-0.2l0.1-0.4c0.3,0.1,0.5,0.1,0.7,0.1c0.5,0,0.7-0.2,0.8-0.9
               l0.1-0.8c-0.3,0.4-0.6,0.8-0.9,0.8c-0.4,0-0.6-0.3-0.6-1.2c0-1.4,0.7-2.6,1.6-2.6C54.7,79.2,54.9,79.2,55.1,79.3L55.1,79.3z
                M52.4,80c0,0.2-0.1,0.4-0.4,0.6l-0.6,0.4c0.1-0.6,0.3-1.3,0.8-1.3C52.3,79.6,52.4,79.7,52.4,80L52.4,80z M52.8,80
               c0-0.5-0.2-0.8-0.7-0.8c-0.9,0-1.3,1.2-1.3,2.5c0,0.7,0.1,1.3,0.7,1.3c0.4,0,0.7-0.2,1.1-0.5l-0.1-0.4c-0.3,0.2-0.6,0.4-0.9,0.4
               c-0.2,0-0.2-0.4-0.2-0.9c0,0,0-0.2,0-0.2l0.8-0.4C52.5,80.8,52.8,80.5,52.8,80L52.8,80z M50.7,79.2h-0.8l0.1-0.7h-0.5l-0.1,0.7H49
               l0,0.4h0.4l-0.2,2.3c0,0.1,0,0.2,0,0.4c0,0.4,0.1,0.7,0.6,0.7c0.3,0,0.6-0.2,0.8-0.3l-0.1-0.4c-0.2,0.1-0.4,0.2-0.6,0.2
               c-0.1,0-0.1,0-0.1-0.3c0-0.1,0-0.2,0-0.3l0.2-2.3h0.8L50.7,79.2L50.7,79.2z M48.6,79.8c0-0.4-0.1-0.6-0.5-0.6
               c-0.3,0-0.6,0.3-0.9,0.6l0-0.5h-0.5l-0.3,3.6H47l0.2-2.6c0.2-0.3,0.6-0.6,0.8-0.6c0.2,0,0.2,0.1,0.2,0.2c0,0.1,0,0.3,0,0.4
               l-0.2,2.6h0.5l0.2-2.6C48.6,80.1,48.6,79.9,48.6,79.8L48.6,79.8z M46,78.2h-0.5l-0.4,4.7h0.5L46,78.2L46,78.2z"/>
       </g>
   </g>
   <g id="Layer_1">
   </g>
   <g id="svg2">
   </g>
   <g id="Fondos_Color">
   </g>
   <g id="Layer_2">
   </g>
   </svg>
   
    )
}

export default tgc
