// @Vendors
import axios from "axios";
import { BASE_API } from "constants/index";
import { getToken } from "utils/helpers";

export const getReportTotal = () => {
  return axios.post(`${BASE_API}/reportes`, {}, {
    headers: {
      "x-mppct-token": getToken()
    }
  })
};

export const getReportEstados = () => {
    return axios.post(
      `${BASE_API}/reportes/estados`, {}, {
        headers: {
          "x-mppct-token": getToken(),
        },
      }
    );
};

export const getReportMunicipios = () => {
  return axios.post(
    `${BASE_API}/reportes/municipios`, {}, {
      headers: {
        "x-mppct-token": getToken(),
      },
    }
  );
};

export const getReportParroquia = () => {
  return axios.post(
    `${BASE_API}/reportes/parroquias`, {}, {
      headers: {
        "x-mppct-token": getToken(),
      },
    }
  );
};