// @MaterialUI
import { makeStyles } from '@material-ui/core/styles'

export default function useStyles ({ drawerWidth }) {

    const styles = makeStyles((theme) => ({
        root: {
          display: 'flex',
        },
        toolbar: {
          paddingRight: 24, // keep right padding when drawer closed
        },
        toolbarIcon: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '0 8px',
          ...theme.mixins.toolbar,
        },
        appBar: {
          background: "#fff",
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        },
        appBarShift: {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
        menuButton: {
          marginRight: 36,
          color: theme.palette.primary.main
        },
        title: {
          flexGrow: 1,
        },
        drawerPaper: {
          position: 'relative',
          whiteSpace: 'nowrap',
          width: drawerWidth,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          background: "linear-gradient(70deg,#52375e,#47344f)"
        },
        drawerPaperClose: {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        },
        btnCerrarSesion:{
          [theme.breakpoints.down("sm")]: {
            fontSize:"9px"
          },
        },

        appBarSpacer: theme.mixins.toolbar,
        content: {
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          backgroundColor: "#e9e9e9"
        },
        container: {
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
        },
        paper: {
          padding: theme.spacing(2),
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
        },
        fixedHeight: {
          height: 240,
        },
        logo: {
          width: "150px",
          height: "60px",
          position: "relative",
          " & img ": {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0"
          }
        },
        toolbarHeader: {
          display: "flex",
          justifyContent:"space-between",
          alignItems: "center",
          flex: "1"
        },
        toolbarInnerProfle: {
          [theme.breakpoints.down('sm')]: {
            display: "none"
          },
          display: "grid",
          gap: "15px",
          margin: "0 0 30px",
          transition: theme.transitions.create('display', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),          
        },
        toolbarInnerProfle_icon: {
          color: "#fff",
          background: "#685271",
          margin: "auto",
          borderRadius: "50%",
          width: "100px",
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& svg": {
            fontSize: "5em"
          }
        },
        toolbarInnerProfle_profile: {
          color:"#fff"
        },
        activeBar: {
          display: "inline-block",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: "#43a047"
        }
      }))

    const classes = styles()

    return {
        classes
    }
}