// @MaterialUI
import { makeStyles } from '@material-ui/core/styles'

export default function useStyles () {

	const styles = makeStyles((theme) => ({
	  container: {
	    display: 'grid',
	    gridTemplateRows: 'auto 1fr auto',
	    minHeight: '100vh',
	 
	  },
	  main: {
	  	marginTop: "90px",
	    paddingBottom: "1em"
	  },
	  leftMaintenance: {
	  	margin: "4pc 0 0 0",
	  	display: "grid",
	  	gap: "30px",
	  	justifyContent:"center",
	  	justifyItems: "center"
	  }
	}))

    const classes = styles()

    return {
        classes
    }
}