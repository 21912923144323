//@Vendors
import React from "react";
import { useHistory } from "react-router-dom";

//@Material-UI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

//@Static
import Mujer404 from "statics/images/textures/Mujer404";

const useStyles = makeStyles((theme) => ({
    root:{
        padding:'0px 15px',
    },
  containerSvg: {
    maxHeight: "70vh",
    margin: "10px auto",
    "& svg": {
      height: "100%",
      width: "100%"
    },
  },
  
  btnStyle: {
    display:'block',
    margin: "10px auto",
  },
}));

const Index = () => {
  const classes = useStyles();
  const { push } = useHistory();

  return (
    <Grid container justify="center" className={classes.root}>
      <div className={classes.containerSvg}>
        <Mujer404 />
      </div>
      <div>
        <Typography variant="h4" component="h1" align="center">
          <span role="img" aria-label="emoji">Lo sentimos, 😢 página no encontrada</span>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => push("/")}
          className={classes.btnStyle}
        >
          Ir a inicio
        </Button>
      </div>
    </Grid>
  );
};

export default Index;
