import React from "react";

function canaima() {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 157.5 41.4"
     
    >
      <g id="Capa_1">
        <g id="Layer_1_1_"></g>
        <g>
          <g>
            <path
              fill="#662483"
              d="M80.1,24.1l2.1,2.5c2.8,3.6,4,3.6,10-1s9.8-3.6,13.5,0.8s5,2.1,10.7-2.2c5.7-4.3,10.1-0.8,11.5,1
               s3,3.1,3,3.1c0.1-0.4,0.5-2.4,2.9-4.7c2.3-2.3,6.1-2,7.4-1.3c1.3,0.7,4.2,4,4.9,5c0.7,1,0.3,1.7-0.1,2s-1.1,0.2-1.6-0.3
               s-1.4-1.3-1.4-1.3s-1.2,1.2-4.7,2.9c-3.4,1.7-7.5,0.8-8.6,0.1c-1.1-0.6-0.9-0.7-4.1-4.1c-3.4-3.7-7.1-0.4-7.1-0.4s-3,2.3-6.3,4.7
               c-3.3,2.4-6-0.1-6-0.1s-2-1.8-4.2-4.3c-2.5-2.8-6.1-0.1-6.1-0.1s-4.7,3.5-7.8,5.2c-3,1.7-5.4-0.5-5.4-0.5s-3.2-3.3-4.1-4.4
               c-0.9-1.1-0.1-2.4-0.1-2.4C79.4,23.2,80.1,24.1,80.1,24.1z M141.2,25.3c0,0-1.4-1.6-3.7-0.5c-2.1,1-3.6,2.3-3.4,3
               c0.2,0.6,2.2,0.4,3.8-0.3C139.4,26.8,141,25.7,141.2,25.3z"
            />
            <path
              fill="#662483"
              d="M9.1,27c-0.3,0.3-2.1,3.2,1.5,3.8c4.9,0.8,14.5-5.4,18.1-6.8s6,1.3,6.3,1.8c0.7,0.9,2.3,4,4.3,4.4
               c2.5,0.4,4.3-1.6,5.9-2.7c3.5-2.5,6.2-4,7.6-4.2c1.8-0.3,4,0.5,6,3.1c2.1,2.6,4,3.5,4,3.5c-0.2-2.5,2.4-4.9,5.2-6.4
               s5.4-0.4,6.2,0.4c0.5,0.5,3,3.5,3.3,4c0.3,0.4,1.2,1.7,0.2,2.5C77,30.9,76.1,30,76.1,30l-1.6-1.4c-4.8,3.5-8.1,4.2-11.4,4.1
               c-2.6-0.1-5-3.3-5.4-3.8C57,28,56,26.2,53.7,26c-2.7-0.3-7.5,4-9,5.2c-1.5,1.1-3.1,2.2-5.3,2.1c-2.8-0.1-5.1-3.6-5.1-3.6
               c-2.6,2.1-6,3.5-8.9,3.6c-2.9,0.1-3-2.9-3-2.9s-3.2,1.4-5.2,2s-4.1,1.5-6.4,1.4c-4.3-0.2-5.3-3.2-5.2-4.6c0.1-2,1.3-3.9,2.8-5.2
               s2.2-0.2,2.2-0.2C11.4,25.2,10.2,25.8,9.1,27z M72.9,26.5c0,0-1.4-1.6-3.7-0.5c-2.1,1-3.6,2.3-3.4,3c0.2,0.6,2.2,0.4,3.8-0.3
               C71.2,28,72.8,26.9,72.9,26.5z M32.9,27.3c0,0-1.4-1.6-3.7-0.5c-2.1,1-3.6,2.3-3.4,3c0.2,0.6,2.2,0.4,3.8-0.3
               C31.1,28.8,32.8,27.7,32.9,27.3z"
            />
            <path
              fill="#662483"
              d="M75.4,20.5c0,0,1.1-0.7,1.9,0.4c0.8,1.1,0.6,1.9,0.3,2.4c-0.4,0.5-1.3,0.4-1.9-0.3
               C75.2,22.3,74.5,21.4,75.4,20.5z"
            />
          </g>
          <g>
            <path fill="#662483" d="M51.1,9v7.4h-1.7V9H51.1z" />
            <path
              fill="#662483"
              d="M52.6,16.4V9h2l1.5,2.7c0.4,0.8,0.9,1.7,1.2,2.5h0c-0.1-1-0.1-2-0.1-3.1V9h1.5v7.4h-1.8l-1.6-2.9
               c-0.4-0.8-0.9-1.7-1.3-2.6l0,0c0,1,0.1,2,0.1,3.2v2.2H52.6z"
            />
            <path
              fill="#662483"
              d="M60.1,9.1C60.8,9,61.6,9,62.4,9c1.4,0,2.3,0.3,3,0.8c0.8,0.6,1.3,1.5,1.3,2.8c0,1.4-0.5,2.4-1.2,3
               c-0.8,0.6-2,1-3.4,1c-0.9,0-1.5-0.1-1.9-0.1V9.1z M61.8,15.2c0.1,0,0.4,0,0.6,0c1.5,0,2.5-0.8,2.5-2.6c0-1.5-0.9-2.4-2.3-2.4
               c-0.4,0-0.6,0-0.8,0.1V15.2z"
            />
            <path
              fill="#662483"
              d="M69.5,9v4.3c0,1.3,0.5,1.9,1.3,1.9c0.9,0,1.4-0.6,1.4-1.9V9h1.7v4.2c0,2.3-1.2,3.4-3.1,3.4
               c-1.9,0-3-1-3-3.4V9H69.5z"
            />
            <path
              fill="#662483"
              d="M75.3,14.7c0.5,0.2,1.1,0.5,1.9,0.5c0.8,0,1.2-0.3,1.2-0.8c0-0.5-0.4-0.7-1.2-1c-1.2-0.4-2-1.1-2-2.2
               c0-1.3,1.1-2.2,2.8-2.2c0.8,0,1.5,0.2,1.9,0.4l-0.4,1.4c-0.3-0.1-0.8-0.4-1.5-0.4c-0.7,0-1.1,0.3-1.1,0.7c0,0.5,0.4,0.7,1.4,1
               c1.3,0.5,1.9,1.2,1.9,2.2c0,1.2-1,2.3-3,2.3c-0.8,0-1.7-0.2-2.1-0.5L75.3,14.7z"
            />
            <path fill="#662483" d="M82.6,10.4h-2V9h5.7v1.4h-2v6h-1.7V10.4z" />
            <path
              fill="#662483"
              d="M87.2,9.1C87.8,9,88.6,9,89.5,9c1.1,0,1.9,0.2,2.4,0.6c0.4,0.4,0.7,0.9,0.7,1.5c0,0.9-0.7,1.6-1.3,1.8v0
               c0.5,0.2,0.8,0.7,1,1.4c0.2,0.8,0.5,1.8,0.6,2.1h-1.7c-0.1-0.2-0.3-0.8-0.5-1.7c-0.2-0.9-0.5-1.2-1.2-1.2h-0.5v2.9h-1.7V9.1z
                M88.9,12.3h0.7c0.8,0,1.3-0.4,1.3-1.1c0-0.7-0.5-1-1.2-1c-0.4,0-0.6,0-0.8,0.1V12.3z"
            />
            <path fill="#662483" d="M95.4,9v7.4h-1.7V9H95.4z" />
            <path
              fill="#662483"
              d="M98.6,14.5l-0.5,1.9h-1.7L98.6,9h2.2l2.3,7.4h-1.8l-0.6-1.9H98.6z M100.5,13.3l-0.5-1.6
               c-0.1-0.4-0.3-1-0.4-1.4h0c-0.1,0.4-0.2,1-0.3,1.4l-0.4,1.6H100.5z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_1"></g>
      <g id="svg2"></g>
      <g id="Fondos_Color"></g>
      <g id="Layer_2"></g>
    </svg>
  );
}

export default canaima;
