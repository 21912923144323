import { Map } from "immutable";

import {  SET_CATEGORIAS, SET_CATEGORIAS_CHECK, UPDATE_FILTERS } from "./categorias.actionsTypes";

const initialState = Map().merge({
  categorias: {
    count: 0,
    rows: [],
  },
  categoriasCheck:[],
  

  filters: {
    page: 0,
    limit: 2,
    title: "",
    entes_id: 0,
    search: {},
    rowsPerPage: 5,
    order: 'asc',
    orderBy: 'nombre',
    selected: []
  },
});

const cursosReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  
    case SET_CATEGORIAS:
      return state.merge({
        categorias:payload
    })
    case SET_CATEGORIAS_CHECK:
      return state.merge({
        categoriasCheck:payload
    })
    case UPDATE_FILTERS:
      return state.merge({
        filters: payload
    })

    default:
      return state;
  }
};

export default cursosReducer;
