// @VENDORS
import React from 'react'
import { Helmet } from "react-helmet"

// @MATERIAL UI
import Container from "@material-ui/core/Container"

// @Components
import MenuBar from 'components/Menu'
import Footer from 'components/Footer'
import Maintenance from 'pages/Maintenance'

import { MAINTENANCE } from 'constants/index'

// @Styles
import useStyles from './useStyles'
import FAVICO from 'statics/images/logos/logoMujer.jpg'
import { linksMenu } from 'components/Menu/MenuLinks'

 function LoginFrame({
  title = "",
  children: Content,
  user,
  ...rest
} = {}) {

  const { classes } = useStyles()
  if(MAINTENANCE) return <Maintenance user={user}/>
    
  return (
    // ** Caja principal en Grid
    <div className={classes.container}>
      <Helmet>
        <title>MujeresTI | {title}</title>
        <link rel="icon" type="image/jpg" href={FAVICO} sizes="64x64" />
      </Helmet>
      
      <div>
        <MenuBar
          user={user}
          logout={() => console.log("")}
          position="relative"
          linksMenu={linksMenu}
         />
      </div>

      <main role="main" className={classes.main}>
        <Container maxWidth="lg">
          {Content}
        </Container>
      </main>

      <Footer />
    </div>
  )
}
export default React.memo(LoginFrame)