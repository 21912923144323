const PREFIX = 'CURSOS'

// SETTERS
export const SET_CURSOS = `${PREFIX}/SET_CURSOS`

export const SET_PROFESORES = `${PREFIX}/SET_PROFESORES`
//GETTERS
export const GET_CURSO = `${PREFIX}/GET_CURSO`
export const GET_CURSOS = `${PREFIX}/GET_CURSOS`

export const GET_PROFESORES = `${PREFIX}/GET_PROFESORES`

export const CREATE_CURSO = `${PREFIX}/CREATE_CURSO`
export const DELETE_CURSO = `${PREFIX}/DELETE_CURSO`

export const UPDATE_CURSO = `${PREFIX}/UPDATE_CURSO`

export const ASIGNAR_CURSOS = `${PREFIX}/ASIGNAR_CURSOS`
export const UPDATE_FILTERS = `${PREFIX}/UPDATE_FILTERS`

export const LOAD_CURSOS = `${PREFIX}/GET_CURSOS`

export const SET_FACILITADORES = `${PREFIX}/SET_FACILITADORES`