const PREFIX = 'AUTH'

export const LOGIN = `${PREFIX}/LOGIN`
export const LOGIN_SUCCESS = `${PREFIX}/LOGIN_SUCCESS`
export const LOGOUT = `${PREFIX}/LOGOUT`
export const CHECK_AUTH = `${PREFIX}/CHECK_AUTH`
export const VALIDATE_ACCOUNT = `${PREFIX}/VALIDATE_ACCOUNT`
export const AUTH_TOKEN = `x-mppct-mujeres-token`
export const RECOVER = `${PREFIX}/RECOVER`

export const REQUEST_FAILURE = `${PREFIX}/REQUEST_FAILURE`;
export const REQUEST_FINISHED = `${PREFIX}/REQUEST_FINISHED`;
export const REQUEST_STARTED = `${PREFIX}/REQUEST_STARTED`;

export const REQUEST_SUCCESS = `${PREFIX}/REQUEST_SUCCESS`;