// @Vendors
import { put, takeLatest, call } from 'redux-saga/effects';
import { remove, reverse } from 'lodash';

// @services
import {
  searchSaimeService,
  registerNewUserService,
  listaCursosService,
  getUsersService,
  updateUserService,
  deleteUserService,
  assignRoleService,
  rolesAsignadosServies,
  revoqueRoleService,
  checkUserService
} from './users.services'

import { buildErrorsObj } from 'utils/helpers';

// @ActionsTypes
import {
  SEARCH_SAIME,
  SET_PROFILES,
  SET_FORM_STEP,
  REGISTER_NEW_USER,
  SET_CURSOS_REGISTRADOS,
  GET_CURSOS_REGISTRADOS,
  GET_USERS,
  DELETE_USER,
  SET_USERS,
  UPDATE_USER,
  ASSIGN_ROLE,
  SET_ASSIGNEDS,
  REVOQUE_ROLE,
  GET_USERS_ASIGNED,
  SEND_EMAIL_CONTACT,
} from 'state/users/users.actionsTypes';

import {
  REQUEST_STARTED,
  REQUEST_FINISHED,
  REQUEST_FAILURE,
  REQUEST_SUCCESS,
  MODAL_CLOSE,
} from 'state/app/app.actionTypes';
import { setEmailProviders } from 'providers/setEmail.providers';

function* searchSaimeWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED });

    const res = yield call(searchSaimeService, payload);
    const { data } = res;

    if (data.success && data.data.length) {
      const { origen, cedula, fecha_nacimiento, primer_nombre, segundo_nombre, primer_apellido, segundo_apellido, sexo } = data.data[0]

      if (sexo === "F") {

        const res2 = yield call(checkUserService, {
          nacionalidad: origen,
          cedula
        })

        const { success, message } = res2.data

        if(!success) {
          const payload = {
            cedulado: true,
            nacionalidad: origen,
            cedula,
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,
            fecha_nacimiento,
            genero: sexo
          }

          yield put({ type: SET_PROFILES, payload })
          yield put({ type: SET_FORM_STEP, payload: 1 })          

        } else {
          yield put({
            type: REQUEST_FAILURE,
            payload: { serverErrors: message }
          })          
        }
      } else {
        yield put({
          type: REQUEST_FAILURE,
          payload: { serverErrors: 'Solo se permite el registro femenino' },
        });
      }
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: 'Cédula inválida o no encontrada' },
      });
    }

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* registerNewUserWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED });
    const res = yield call(registerNewUserService, payload);
    const { data } = res;

    if (data.success) {
      yield put({ type: REQUEST_SUCCESS, payload: data.message });
      setTimeout(() => {
        window.location = '/iniciar-sesion';
      }, 3500);
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: {
          serverErrors: data.message,
        },
      });
    }

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* listaUsuarioCursosWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED });

    const response = yield call(listaCursosService, payload);

    yield put({
      type: SET_CURSOS_REGISTRADOS,
      payload: response.data.data.curs,
    });

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* getUsuariosWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED });
    const response = yield call(getUsersService, payload);
    yield put({ type: SET_USERS, payload: response.data.data });
    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* deleteUsuarioWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED });
    const { id, users, version } = payload;

    const response = yield call(deleteUserService, { id, version });
    const { data } = response;

    if (data.success) {
      yield put({ type: REQUEST_SUCCESS, payload: data.message });

      const newUsers = {
        count: users.count - 1,
        rows: users.rows.filter((user) => user.id !== id),
      };
      yield put({ type: SET_USERS, payload: newUsers });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* updateUsuarioWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED });

    const res = yield call(updateUserService, payload);
    const { data } = res;

    if (data.success) {
      yield put({ type: REQUEST_SUCCESS, payload: data.message });
      yield put({ type: MODAL_CLOSE, payload: '' });
      yield put({ type: GET_USERS });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: data.message },
      });
    }

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* assignRoleWorker({ payload }) {
  try {
    const res = yield call(assignRoleService, payload);
    const { success, message } = res.data;

    if (success) {
      yield put({ type: REQUEST_SUCCESS, payload: message });
      yield put({ type: GET_USERS_ASIGNED });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: message },
      });
    }
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* revoqueRoleWorker({ payload }) {
  try {
    const res = yield call(revoqueRoleService, payload);
    const { success, message } = res.data;

    if (success) {
      yield put({ type: REQUEST_SUCCESS, payload: message });
      yield put({ type: GET_USERS_ASIGNED });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: message },
      });
    }
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* getUsersAssignedWorker() {
  try {
    const res2 = yield call(rolesAsignadosServies);
    const { data } = res2.data;

    const asignados = data.rows.reduce((acc, el) => {
      const { usuario_id } = el;
      const { rol_id } = el;
      const userExist = acc.filter((e) => e['usuario_id'] === usuario_id);

      if (userExist.length) {
        const roles = [...userExist[0]['roles'], rol_id];
        if (remove(acc, (ex) => ex['usuario_id'] === usuario_id)) {
          return acc.concat([{ usuario_id, roles }]);
        }
        return acc;
      }
      return acc.concat({ usuario_id, roles: [rol_id] });
    }, []);

    yield put({ type: SET_ASSIGNEDS, payload: reverse(asignados) });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

function* sendEmailContactWorker({ payload }) {
  try {
    delete payload.lastname;
    delete payload.name;
    const res = yield call(setEmailProviders, payload);
    const { success, message } = res.data;

    if (success) {
      yield put({ type: REQUEST_SUCCESS, payload: message });
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: message },
      });
    }
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err),
    });
  }
}

// @Whatcher
function* requestWatcher() {
  yield takeLatest(SEARCH_SAIME, searchSaimeWorker);
  yield takeLatest(REGISTER_NEW_USER, registerNewUserWorker);
  yield takeLatest(GET_CURSOS_REGISTRADOS, listaUsuarioCursosWorker);
  yield takeLatest(GET_USERS, getUsuariosWorker);
  yield takeLatest(DELETE_USER, deleteUsuarioWorker);
  yield takeLatest(UPDATE_USER, updateUsuarioWorker);
  yield takeLatest(ASSIGN_ROLE, assignRoleWorker);
  yield takeLatest(REVOQUE_ROLE, revoqueRoleWorker);
  yield takeLatest(GET_USERS_ASIGNED, getUsersAssignedWorker);
  yield takeLatest(SEND_EMAIL_CONTACT, sendEmailContactWorker);
}

export default { requestWatcher };
