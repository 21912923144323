import { Map } from 'immutable'


import {
  SET_ONE_USER,
  SET_PROFILES,
  SET_FORM_STEP,
  SET_CURSOS_REGISTRADOS,

  SET_LABORA,
  SET_LABORALES,
  SET_ESTUDIA,
  SET_ESTUDIOS,
  SET_CEDULADO,
  SET_USERS,
  // CLEANERS
  CLEAR_PROFILES,
  SET_DOMICILIO,
  CLEAR_USER,
  CLEAR_DOMICILIO,
  SET_ASSIGNEDS
} from './users.actionsTypes'

const initialState = Map().merge({
  user: {
    isAuthenticated: false,
    username: '',
    password: '',
    roles:{}
  },
  
  profiles: {
    nacionalidad: 'V',
    cedula: '',
    primer_nombre: '',
    segundo_nombre: '',
    primer_apellido: '',
    segundo_apellido: '',
    fecha_nacimiento: '',
    genero: '',
    cedulado: false
  },
  
  domicilio: {
    direccion_habitacional: '',
    estado: '',
    municipio: '',
    parroquia_id:''
  },
  
  laborales: {
    labora: false,
    tipo_sector: '',
    sector_productivo: '',
    nombre_organismo: '',
    direccion_organismo: '',
    parroquia_organismo: '',
    telefono_organismo: ''
  },

  educativo: {
    estudia: false,
    profesion_id: '',
    grado_id: '',
    area_estudio_id:'',
    plantel: ''
  },

  registerFormStep: {
    actualVisible: 0
  },

  cursos:[],

  users: {
    count: 0,
    rows: [],
  },

  asignaciones: [],

  filters: {
    limit: 10,
    title: "",
    page: 0,
    search: {},
    rowsPerPage: 5,
    order: 'asc',
    orderBy: 'username',
    selected: []
  },
})

const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ONE_USER:
      return state.merge({
        user: payload
      })

    case SET_PROFILES:{
      return state.merge({
        profiles: {
          ...state.profiles,
          ...payload
        }
      })
    }
    case SET_CEDULADO: 
    return state.merge({
      profiles: { cedulado: payload}
    })

    case SET_LABORA: 
    return state.merge({
      laborales: { labora: payload }
    })

    case SET_LABORALES:
    return state.merge({
      laborales: {...state.laborales, ...payload}
    })

    case SET_ESTUDIA: 
    return state.merge({
      educativo: { estudia: payload }
    })

    case SET_ESTUDIOS:
    return state.merge({
      educativo: payload
    })

    case SET_DOMICILIO: 
    return state.merge({
      domicilio: payload
    })

    case SET_FORM_STEP:
      return state.merge({
        registerFormStep: { actualVisible: payload }
    })
    

    case SET_CURSOS_REGISTRADOS:
      return state.merge({
        cursos:payload
      })

    // CLEANERS
    case CLEAR_USER: 
      return state.merge({
        user: {
          isAuthenticated: false,
          username: '',
          password: '',
          roles: []
        }
    })

    case CLEAR_PROFILES: 
      return state.merge({
        profiles: {
          nacionalidad: '',
          cedula: '',
          primer_nombre: '',
          segundo_nombre: '',
          primer_apellido: '',
          segundo_apellido: '',
          fecha_nacimiento: '',
          genero: ''
        }
    })

    case CLEAR_DOMICILIO: 
      return state.merge({
        ubication: {
          direccion_habitacional: '',
          parroquia_id: ''
        }
    })

    case SET_USERS:
      return state.merge({
        users: payload
      })

      case SET_ASSIGNEDS:
      return state.merge({
        asignaciones: payload
      })

    default:
      return state
  }
}

export default usersReducer