// @Vendors
import React from "react"
import "pure-react-carousel/dist/react-carousel.es.css"
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel"

// @Material UI
import { Grid, Typography } from "@material-ui/core"

// @Statics > logos de los entes
import Canaima from "statics/entes/canaima"
import Cantv from "statics/entes/cantv"
import Conati from "statics/entes/conati"
import Infocentro from "statics/entes/infocentro"
import Ingeneria from "statics/entes/ingeneria"
import Ipostel from "statics/entes/ipostel"
import Movilnet from "statics/entes/movilnet"
import Suscerte from "statics/entes/suscerte"
import Telcom from "statics/entes/telcom"
import Tgc from "statics/entes/tgc"

import Cnti from "statics/entes/cnti"
import Cendit from "statics/entes/cendit"

import Fundacite from "statics/entes/fundacite"
import Cenditel from "statics/entes/cenditel"
import Abae from "statics/entes/abae"

// @Styles
import useStyles from './useStyles'

export default function EntesHome() {
  const { classes } = useStyles()
  const totalSlide = [0, 1, 2, 3,4] 

  return (
    <section id="entes-carousel">
      <Typography
        variant="h4"
        color="primary"
        align="center"
        className={classes.titulo}
      >
        Con la fuerza de
      </Typography>
      <CarouselProvider
        isPlaying={true}
        infinite
        naturalSlideWidth={200}
        naturalSlideHeight={40}
        totalSlides={totalSlide.length}
        className={classes.carousel}
      >
        <Slider>
          <Slide index={0}>
            <Grid
              container
              className={classes.containerEntes}
              alignItems="center"
              spacing={3}
            >
              <Grid className={classes.entes} item xs={4}>
                <Canaima />
              </Grid>

              <Grid className={classes.entes} item xs={4}>
                <Cantv />
              </Grid>

              <Grid className={classes.entes} item xs={4}>
                <Conati />
              </Grid>
            </Grid>
          </Slide>
          <Slide index={1}>
            <Grid
              container
              className={classes.containerEntes}
              alignItems="center"
              spacing={3}
            >
              <Grid className={classes.entes} item xs={4}>
                <Infocentro />
              </Grid>

              <Grid className={classes.entes} item xs={4}>
                <Ingeneria />
              </Grid>

              <Grid className={classes.entes} item xs={4}>
                <Ipostel />
              </Grid>
            </Grid>
          </Slide>
          <Slide index={2}>
            <Grid
              container
              className={classes.containerEntes}
              alignItems="center"
              spacing={3}
            >
              <Grid className={classes.entes} item xs={4}>
                <Movilnet />
              </Grid>

              <Grid className={classes.entes} item xs={4}>
                <Suscerte />
              </Grid>

              <Grid className={classes.entes} item xs={4}>
                <Telcom />
              </Grid>
            </Grid>
          </Slide>
          <Slide index={3}>
            <Grid
              container
              className={classes.containerEntes}
              alignItems="center"
              spacing={3}
            >
              <Grid className={classes.entes} item xs={4}>
                <Tgc />
              </Grid>

              <Grid className={classes.entes} item xs={4}>
                <Cnti />
              </Grid>

              <Grid className={classes.entes} item xs={4}>
                <Cendit />
              </Grid>
            </Grid>
          </Slide>
          <Slide index={4}>
            <Grid
              container
              className={classes.containerEntes}
              alignItems="center"
              spacing={3}
            >
              <Grid className={classes.entes} item xs={4}>
                <Fundacite />
              </Grid>
              <Grid className={classes.entes} item xs={4}>
                <Cenditel />
              </Grid>
              <Grid className={classes.entes} item xs={4}>
                <Abae />
              </Grid>
            </Grid>
          </Slide>
        </Slider>
        <div className={classes.dots}>
          {totalSlide.map((slide, i) => (
            <Dot key={i} className={classes.dot} slide={slide}></Dot>
          ))}
        </div>
      </CarouselProvider>
    </section>      
  )
}
