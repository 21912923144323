// @Vendors
import React from 'react'
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup'
import { makeStyles } from "@material-ui/core/styles";

// @Components
import { 
  TextField,
  Button,
  Typography
} from '@material-ui/core';

// @React ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha"

const useStyles = makeStyles((theme) => ({
  mainText: {
  lineHeight: "190%",
  margin: "15px 0",
  [theme.breakpoints.down("sm")]: {
    textAlign: "justify",
  },
  }
}))

function FormRecoverPass2({pregunta, onSubmit, disabledButton=false}) {
  const classes = useStyles()
  const initialValues = {
    respuesta: ''
  }

  const RecoverSchema = Yup.object().shape({
    respuesta: Yup.string()
    .required('¡Campo requerido!')
  })
  
  return (
    <>
    <Typography 
        className={classes.mainText}
        variant="body2"
        align="center"
      >
        ¡Porfavor complete el siguiente campo con su respuesta de seguridad para continuar con el proceso de recuperación de contraseña!
      </Typography>           
      <p className="app--text-second" style={{magin: "0"}}>
        <span style={{textTransform: "capitalize", color: "var(--darkBlue)"}}>{`* ${pregunta.toLowerCase()} *`}</span>
      </p>
      <br/>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={RecoverSchema}
      >
        {
          ({errors, touched, values, handleChange, handleBlur, handleSubmit}) => (
            <form onSubmit={handleSubmit}>
             <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="respuesta"
              label="Respuesta seguridad"
              name="respuesta"
              autoComplete="respuesta"
              autoFocus
              helperText={<ErrorMessage name="respuesta"/>}
              error={errors.respuesta && touched.respuesta}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.respuesta}
            />
              <p style={{textAlign: "center"}}>
                <Button type="submit" variant="contained" color="primary" disabled={disabledButton}>
                  RECUPERAR CONTRASEÑA
                </Button>
              </p>
              <ReCAPTCHA
                sitekey="6LdieNwZAAAAAKfJtGJxhfx7vx0VN40pVXqGCPjI"
                size="invisible"
              />
            </form>
          )
        }
      </Formik>
    </>
  )
}

export default React.memo(FormRecoverPass2)