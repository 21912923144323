// @Vendors
import React, { memo } from 'react' 
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/es'

// @Material UI
import {
	Grid,
	Typography,
	Grow
}
from '@material-ui/core'

// @Components
import DatosPersonales from '../forms/DatosPersonales'
import ActionsButtons from 'components/ActionsButtons'
import useSettingsForms from '../hooks/useSettingsForms'

function DatosPersonalesView({ ActionsButtons: Buttons, onSubmit }){
	const profiles = useSelector(state => state.usersReducer.get("profiles"))
	const { cedulado } = profiles
	const { nextPrev } = useSettingsForms()

	const {
	    primer_nombre,
	    segundo_nombre,
	    primer_apellido,
	    segundo_apellido,
	    fecha_nacimiento,
	    genero
	} = profiles

	const handleNext = React.useCallback((e) => {
		nextPrev(2)
		e.preventDefault()
	}, [nextPrev])

	const handleBack = React.useCallback(() => {
		nextPrev(0)
	}, [nextPrev])
	
	return (
		<Grow in={true} timeout={1000}>
			<div>
				{ 
					cedulado ?
						<Grid container spacing={4}>
							<Grid item xs={12} sm={3}>
								<Typography align="center" color="secondary" variant="h5">Nombre(s)</Typography>
								<Typography align="center">{primer_nombre} {segundo_nombre}</Typography>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Typography align="center" color="secondary" variant="h5">Apellidos</Typography>
								<Typography align="center">{primer_apellido} {segundo_apellido}</Typography>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Typography align="center" color="secondary" variant="h5">Fecha de nacimiento</Typography>
								<Typography align="center">{moment(fecha_nacimiento,"YYYYMMDD").format("ll")}</Typography>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Typography align="center" color="secondary" variant="h5">Género</Typography>
								<Typography align="center">{genero === "F" ? "Femenino" : "Masculino"}</Typography>
							</Grid>
							<Grid item xs={12}>
							<form onSubmit={handleNext}>
								<ActionsButtons actualVisible={1} totalForms={5} onPrev={handleBack}/>
							</form>
							</Grid>
						</Grid>
					:
					<DatosPersonales ActionsButtons={Buttons} onSubmit={onSubmit}/>
				}
			</div>
		</Grow>
	)
}

export default memo(DatosPersonalesView)