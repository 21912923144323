// @Vendors
import {put, takeLatest, call} from 'redux-saga/effects';
import jsonwebtoken from 'jsonwebtoken'

// @Utils
import {verifyToken, setToken, removeToken} from 'utils/helpers';

// @services
import {postLoginService, activateAccountService, recoverpassword} from './auth.services';

// @ActionsTypes
import {
  REQUEST_STARTED,
  REQUEST_FINISHED,
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  VALIDATE_ACCOUNT,
  RECOVER
} from './auth.actionsTypes'
import {
  REQUEST_FAILURE,
  REQUEST_SUCCESS
} from 'state/app/app.actionTypes'

import {
  CLEAR_USER,
  SET_PROFILES,
  SET_DOMICILIO,
  // CLEANERS
  SET_ONE_USER,  

} from 'state/users/users.actionsTypes'

import { buildErrorsObj } from 'utils/helpers'

function* loginWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED })
    const res = yield call(postLoginService, payload)
    const {data} = res
    
    const { success, message, data: datos } = data

    if(success && datos) {
      const { token } = datos
      yield call(setToken, token)
      yield call(checkAuthenticationWorker)
    
    } else {
      yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: message }
      })
    }

    yield put({ type: REQUEST_FINISHED })
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err)
    })
  }
}

function* logoutWorker() {
  const isRemove = yield call(removeToken)

  if(isRemove) {
    yield put({ type: CLEAR_USER })
  }
}

function* checkAuthenticationWorker() {
  try {
    const _TOKEN_ = yield call(verifyToken)
    if(_TOKEN_) {
      const { user } = jsonwebtoken.decode(_TOKEN_)
  
      // Desglosar informacion del usuario y repartirla
      const {
        Roles,
        usuarios_domicilio: _UBICATION,
        usuarios_perfil: _PROFILES,
        username
      } = user

      const roles = Roles.reduce( (acc, { rol_id, role }) => {
        const { nombre: name } = role
        return acc.concat([{ rol_id, name }])
      }, [])

      // Agregar valores al estado
      const _USER = { isAuthenticated: true, username, roles }
      yield put({ type: SET_ONE_USER, payload: _USER })
      yield put({ type: SET_PROFILES, payload: _PROFILES })
      yield put({ type: SET_DOMICILIO, payload: _UBICATION })
    } else {
      yield put({ type: LOGOUT })
    }
  } catch(err) {
    // silenct
    //console.log(err)
  }
}

function* activateAccountWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED })
    
    const res = yield call(activateAccountService, payload)
    const {data} = res

    if(data.alert.type === "success"){
      yield put({ type: REQUEST_SUCCESS, payload: data.alert.message})
      setTimeout(() => window.location="/acceder", 3000)
    } else {
    yield put({
        type: REQUEST_FAILURE,
        payload: {
          serverErrors: "Ocurrió un error con el token de validación", 
          statusError: 502
        }
      })
    }

    yield put({ type: REQUEST_FINISHED })
  } catch(err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err)
    })
  }
}

function* recoverWorker({ payload }) {
  try {
    yield put({ type: REQUEST_STARTED });
     const res = yield call(recoverpassword, payload);

     if (!res.data.success) {
        yield put({
        type: REQUEST_FAILURE,
        payload: { serverErrors: res.data.message}
      })
     }

    yield put({ type: REQUEST_FINISHED });
  } catch (err) {
    yield put({
      type: REQUEST_FAILURE,
      payload: buildErrorsObj(err)
    })
  }
}

// @Whatcher
function* requestWatcher() {
  yield takeLatest(LOGIN, loginWorker)
  yield takeLatest(LOGOUT, logoutWorker)
  yield takeLatest(VALIDATE_ACCOUNT, activateAccountWorker)
  yield takeLatest(CHECK_AUTH, checkAuthenticationWorker)
  yield takeLatest(RECOVER, recoverWorker)
}

export default {requestWatcher}