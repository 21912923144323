import React from "react";

function fundacite() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.34 28.11">
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
            <path fill="#662483" d="M0,27.73V.76H18.31V6.23H6.23V12h10v5.05H6.23V27.73Z"/>
            <path fill="#662483" d="M20.09,20.63V8h6.08V19.28A4.53,4.53,0,0,0,26.89,22a2.44,2.44,0,0,0,2.05.95A4.52,4.52,0,0,0,32,21.8a6,6,0,0,0,1.29-1.62V8h6.08V21a2.09,2.09,0,0,0,.28,1.26,1.47,1.47,0,0,0,1,.43v5.05c-.56.1-1,.18-1.46.23A9.45,9.45,0,0,1,38.1,28a4.4,4.4,0,0,1-2.55-.66,2.78,2.78,0,0,1-1.17-1.85l-.12-1.21a8.25,8.25,0,0,1-3.4,2.88,10.93,10.93,0,0,1-4.54.92,5.68,5.68,0,0,1-4.63-1.92A8.44,8.44,0,0,1,20.09,20.63Z"/>
            <path fill="#662483" d="M63,27.73H56.94v-11a4.34,4.34,0,0,0-.74-2.81,2.46,2.46,0,0,0-2-.87,3.69,3.69,0,0,0-1.71.44A5.3,5.3,0,0,0,51,14.71a5.6,5.6,0,0,0-1.09,1.76V27.73H43.8V8h5.46v3.37a7.49,7.49,0,0,1,1.9-2,8.82,8.82,0,0,1,2.62-1.25A11.47,11.47,0,0,1,57,7.71a6.27,6.27,0,0,1,3.14.69A4.89,4.89,0,0,1,62,10.22a7.28,7.28,0,0,1,.84,2.41,16.9,16.9,0,0,1,.2,2.53Z"/>
            <path fill="#662483" d="M65.6,17.89a11.5,11.5,0,0,1,1.14-5.18,9.35,9.35,0,0,1,3.11-3.65,7.9,7.9,0,0,1,4.56-1.35,7.37,7.37,0,0,1,3.8,1,6.67,6.67,0,0,1,2.58,2.64V0h6.08V21a2,2,0,0,0,.3,1.27,1.46,1.46,0,0,0,1,.44v5.05a14.56,14.56,0,0,1-2.58.3,4.35,4.35,0,0,1-2.53-.68,2.9,2.9,0,0,1-1.19-1.9l-.12-1a6.79,6.79,0,0,1-2.89,2.78,8.53,8.53,0,0,1-3.83.91,9.22,9.22,0,0,1-3.78-.77,9,9,0,0,1-3-2.15,9.66,9.66,0,0,1-2-3.26A11.77,11.77,0,0,1,65.6,17.89Zm15.23,2.4V16.23a5.06,5.06,0,0,0-1.08-1.73,5.58,5.58,0,0,0-1.6-1.18,3.92,3.92,0,0,0-1.8-.44,4,4,0,0,0-1.81.4,4.41,4.41,0,0,0-1.42,1.13,4.92,4.92,0,0,0-.93,1.64A5.7,5.7,0,0,0,71.87,18a5.32,5.32,0,0,0,.36,2,4.89,4.89,0,0,0,1,1.58,4.32,4.32,0,0,0,1.52,1.05,4.77,4.77,0,0,0,1.9.37,4,4,0,0,0,1.25-.2,5.85,5.85,0,0,0,1.16-.54,4.22,4.22,0,0,0,1-.84A5.76,5.76,0,0,0,80.83,20.29Z"/>
            <path fill="#662483" d="M90,21.86a5.54,5.54,0,0,1,1.1-3.41,7.47,7.47,0,0,1,3-2.3,11.07,11.07,0,0,1,4.45-.84,13.61,13.61,0,0,1,2.39.21,8.89,8.89,0,0,1,2,.59v-.88a3,3,0,0,0-1-2.42,4.55,4.55,0,0,0-3-.85,8.53,8.53,0,0,0-3.13.57,17.06,17.06,0,0,0-3.1,1.64L91,10.29a15.9,15.9,0,0,1,4.12-1.93,15.67,15.67,0,0,1,4.54-.65,10.49,10.49,0,0,1,7,2.1,7.55,7.55,0,0,1,2.49,6.09V21a2.06,2.06,0,0,0,.28,1.25,1.47,1.47,0,0,0,1,.43v5.05c-.51.1-1,.18-1.43.23a10.32,10.32,0,0,1-1.15.07,4.3,4.3,0,0,1-2.61-.68,3,3,0,0,1-1.12-1.86l-.11-.88a9,9,0,0,1-3.17,2.61,8.84,8.84,0,0,1-3.86.89,7.67,7.67,0,0,1-3.55-.82A6.45,6.45,0,0,1,90.93,25,5.84,5.84,0,0,1,90,21.86Zm12.08.71a2.91,2.91,0,0,0,.68-.73,1.41,1.41,0,0,0,.27-.77V19.35a8.85,8.85,0,0,0-1.69-.46,9.94,9.94,0,0,0-1.73-.16,5,5,0,0,0-2.77.71,2.15,2.15,0,0,0-1.1,1.88,2,2,0,0,0,.38,1.21,2.65,2.65,0,0,0,1,.88,3.51,3.51,0,0,0,1.58.33,5.31,5.31,0,0,0,1.82-.33A5,5,0,0,0,102.1,22.57Z"/>
            <path fill="#662483" d="M122.76,28.11a11.87,11.87,0,0,1-4.52-.82A10.12,10.12,0,0,1,114.88,25a9.74,9.74,0,0,1-2.11-3.26,10.54,10.54,0,0,1-.72-3.89,10.3,10.3,0,0,1,1.27-5A9.78,9.78,0,0,1,117,9.13a11.17,11.17,0,0,1,5.77-1.42,10.93,10.93,0,0,1,5.8,1.46A9.43,9.43,0,0,1,132.11,13l-5.93,1.79a3.92,3.92,0,0,0-3.45-1.9,4.24,4.24,0,0,0-2.26.62,4.41,4.41,0,0,0-1.6,1.74,5.58,5.58,0,0,0-.59,2.65,5.55,5.55,0,0,0,.61,2.66,4.86,4.86,0,0,0,1.59,1.79,4,4,0,0,0,2.25.64,3.93,3.93,0,0,0,1.42-.25,4.25,4.25,0,0,0,1.22-.7,3.53,3.53,0,0,0,.85-1l5.93,1.82a8.67,8.67,0,0,1-2.07,2.71,9.88,9.88,0,0,1-3.18,1.88A11.59,11.59,0,0,1,122.76,28.11Z"/>
            <path fill="#662483" d="M171.34,26.66c-.55.23-1.17.46-1.84.69a15.15,15.15,0,0,1-2.11.55,11.45,11.45,0,0,1-2.16.21,7.37,7.37,0,0,1-2.79-.51,4.16,4.16,0,0,1-2-1.66,5.69,5.69,0,0,1-.74-3.07V12.61h-2.54V8h2.54V1.67h6.08V8h4.06v4.6h-4.06v8.13a1.76,1.76,0,0,0,.49,1.4,1.88,1.88,0,0,0,1.26.42,4.43,4.43,0,0,0,1.38-.23,8,8,0,0,0,1.28-.49Z"/>
            <path fill="#662483" d="M182.7,28.11a11.83,11.83,0,0,1-4.52-.81,10,10,0,0,1-3.36-2.2,9.2,9.2,0,0,1-2.11-3.21,10.24,10.24,0,0,1-.72-3.81,10.62,10.62,0,0,1,1.27-5.17,9.81,9.81,0,0,1,3.67-3.76,12.32,12.32,0,0,1,11.52,0,9.66,9.66,0,0,1,3.63,3.72,10.25,10.25,0,0,1,1.26,5,8.64,8.64,0,0,1-.06,1c0,.34-.07.63-.1.88H178.45a4.29,4.29,0,0,0,.74,2.18,4,4,0,0,0,1.61,1.35,4.86,4.86,0,0,0,2.09.46,5.18,5.18,0,0,0,2.56-.65,3.53,3.53,0,0,0,1.66-1.74l5.16,1.44a8.57,8.57,0,0,1-2.14,2.75,10.33,10.33,0,0,1-3.27,1.88A12,12,0,0,1,182.7,28.11ZM178.29,16H187a4.9,4.9,0,0,0-.72-2.08,3.82,3.82,0,0,0-1.52-1.38,4.44,4.44,0,0,0-2.09-.49,4.4,4.4,0,0,0-2.1.49A3.9,3.9,0,0,0,179,14,4.91,4.91,0,0,0,178.29,16Z"/>
            <circle fill="#662483" cx="141.46" cy="9.33" r="2.27"/>
            <path fill="#662483" d="M147.94,10l.06-.12,0-.06,0-.06a.9.9,0,0,1,1.59.81,1,1,0,0,1-.23.36h0l0,0-.08.07-.1.06,0,0a.43.43,0,0,1-.12.05h0a.92.92,0,0,1-.52,0,.91.91,0,0,1-.59-1.14h0Z"/>
            <path fill="#662483" d="M143.87,26a1.46,1.46,0,0,1-1.09,1h0a1.41,1.41,0,0,1-.76,0,1.48,1.48,0,0,1-1-1.26,1.29,1.29,0,0,1,.07-.58,1.46,1.46,0,1,1,2.78.89Z"/>
            <path fill="#662483" d="M156.21,4.49h0a.08.08,0,0,1,0,0,.08.08,0,0,1,0,0l0,0a.59.59,0,0,1-.53.09.56.56,0,0,1-.34-.28h-8a.67.67,0,0,1-.24.26h0a.6.6,0,0,1-.44,0,.58.58,0,0,1-.41-.51.58.58,0,0,1,0-.23.6.6,0,0,1,.75-.39.6.6,0,0,1,.35.31h8a.56.56,0,0,1,.05-.08v0a.39.39,0,0,1,.08-.1h0a.58.58,0,0,1,.58-.14.6.6,0,0,1,.38.75.54.54,0,0,1-.1.18Z"/>
            <path fill="#662483" d="M153.92,8.47l-.07,0-.09.06,0,0-.13.05h0a.84.84,0,0,1-.52,0,.91.91,0,0,1-.59-1.14h0v0a.61.61,0,0,1,.05-.12l0-.06.05-.07,0-.06v0l.18-.14h0a.87.87,0,0,1,.74-.09.89.89,0,0,1,.33,1.52h0Z"/><path fill="#662483" d="M150,8.78h0a.5.5,0,0,1,.05-.13l0-.06,0-.06.12-.13h0a.9.9,0,0,1,1.47.94,1,1,0,0,1-.22.36h0l0,.05-.08.06-.09.06,0,0-.13.05h0a.87.87,0,0,1-.53,0A.91.91,0,0,1,150,8.78Z"/>
            <path fill="#662483" d="M146.83,6a1,1,0,0,1,0-.23.36.36,0,0,1,0-.09h0a.35.35,0,0,1,0-.08h0a.58.58,0,0,1,.65-.21.54.54,0,0,1,.36.33l6.29.27a.37.37,0,0,1,.07-.14v0a.59.59,0,0,1,.66-.22.59.59,0,0,1,.38.74.63.63,0,0,1-.16.26h0l-.1.08h0l-.12,0-.07,0h-.18l-.08,0a.6.6,0,0,1-.32-.24l-6.37-.27a.56.56,0,0,1-.21.22h0l0,0-.07,0h0a.58.58,0,0,1-.74-.52Z"/>
            <path fill="#662483" d="M148.18,8.67a.92.92,0,0,1-.62-.79.86.86,0,0,1,0-.35.9.9,0,1,1,1.15,1.13h0l0,0-.14,0h0A1.06,1.06,0,0,1,148.18,8.67Z"/>
            <path fill="#662483" d="M151,13.21h0a1.13,1.13,0,0,1-.48-.05,1.21,1.21,0,0,1-.84-1.05,1.31,1.31,0,0,1,.05-.48,1.22,1.22,0,0,1,2.32.74,1.23,1.23,0,0,1-.85.81h0A.76.76,0,0,1,151,13.21Z"/>
            <path fill="#662483" d="M139,26.13h0a1,1,0,0,1-.48-.05,1.21,1.21,0,0,1-.84-1,1.31,1.31,0,0,1,0-.48,1.24,1.24,0,0,1,1.54-.79,1.21,1.21,0,0,1,.78,1.53,1.23,1.23,0,0,1-.85.81h0Z"/>
            <path fill="#662483" d="M145.68,26.54a1.72,1.72,0,0,1-1.18-1.48,1.55,1.55,0,0,1,.08-.67,1.7,1.7,0,1,1,1.93,2.2h0A1.72,1.72,0,0,1,145.68,26.54Z"/>
            <path fill="#662483" d="M146.77,14.35l3.8,1.22a1.45,1.45,0,0,0,1,1,1.51,1.51,0,0,0,.76,0h0a1.46,1.46,0,0,0,1.08-1,1.46,1.46,0,0,0-2.77-.92L147,13.53a1.6,1.6,0,0,1-.05.21C146.87,14,146.82,14.15,146.77,14.35Z"/>
            <path fill="#662483" d="M152.39,17.43a1.7,1.7,0,0,0-2.09,1l-4.62-1.48c-.15.25-.31.5-.47.73l5,1.59a1.7,1.7,0,0,0,1.17,1.44,1.77,1.77,0,0,0,.85.05h0a1.7,1.7,0,0,0,.2-3.3Z"/>
            <path fill="#662483" d="M148.41,22.51a1.63,1.63,0,0,0,0,.58,2,2,0,0,0,1.37,1.72,2.33,2.33,0,0,0,.58.09h.34A2,2,0,0,0,151,21a2,2,0,0,0-2.2.72l-5.45-2c-.24.22-.48.43-.73.63Z"/>
            <path fill="#662483" d="M141.22,2.49S146,7.42,144,10.87a2.62,2.62,0,0,1-.24.37,2.67,2.67,0,0,1-1.2.88c-1.06.43-2.56.51-4.88,1.38a10,10,0,0,0-5.12,4.69,28.8,28.8,0,0,1,5.8-3.52,4.42,4.42,0,0,1,2.18-.28c.91.13,1.23.45,1.36.83.19.59.07.88-.56,1.74l-.23.3c-.34.42-.78.94-1.38,1.63l-.23.26c-2,2.22-3.24,2.64-4.26,4.53a6.9,6.9,0,0,0-1,3.47,11.73,11.73,0,0,1,3.14-4.21c1-.77,3-1.72,5-3.25.25-.19.49-.4.73-.62A12.21,12.21,0,0,0,145,17c.16-.23.32-.48.47-.73a10.63,10.63,0,0,0,1.09-2.57c.05-.2.1-.4.14-.61s0-.14.05-.21C148.45,5.32,141.22,2.49,141.22,2.49Z"/>
            <path fill="#662483" d="M146.92,6.27a.6.6,0,0,1,.85-.84.43.43,0,0,1,.11.12.61.61,0,0,1-.85.84A.25.25,0,0,1,146.92,6.27Z"/>
            <path fill="#662483" d="M146.23,4.53a.6.6,0,0,1,.85-.84l.11.11a.6.6,0,0,1-.85.84A.23.23,0,0,1,146.23,4.53Z"/>
            <path fill="#662483" d="M154.33,6.49a.6.6,0,0,1,.85-.84.38.38,0,0,1,.11.11.6.6,0,0,1-.85.84A.29.29,0,0,1,154.33,6.49Z"/>
            <path fill="#662483" d="M155.23,4.5a.6.6,0,0,1,.85-.84.29.29,0,0,1,.11.11.6.6,0,0,1-.85.84A.38.38,0,0,1,155.23,4.5Z"/>
            <circle fill="#662483" cx="151.95" cy="15.23" r="1.53"/>
            <circle fill="#662483" cx="151.88" cy="19.02" r="1.76"/>
            <circle fill="#662483" cx="150.34" cy="22.9" r="2"/>
        </g>
        </g>
    </svg>
  );
}

export default fundacite;
