// @Vendors
import React from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

// @Material UI - COMPONENTS
import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";

// @React ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";

//@Components
import AppField from "components/Form/AppField";


const initial = {
  correo: "",
  name: "",
  lastName: "",
  asunto: "",
  descripcion: "",
};

function ContactForm({
  initialValues = initial,
  onSubmit,
  disabledButton = false,
}) {

  const constactSchema = Yup.object().shape({
    correo: Yup.string()
      .email("¡Ingrese un correo válido!")
      .required("¡Campo requerido!"),
    asunto: Yup.string()
      .min(5, "¡La asunto debe tener como mínimo 5 carácteres!")
      .max(100, "¡La contraseña tener como máximo 100 carácteres!")
      .required("¡Campo requerido!"),
    descripcion: Yup.string()
      .min(30, "La descripción debe tener minimo 30 carácteres")
      .max(255, "La descripción del problema debe tener mínimo 255 carácteres")
      .required("¡Campo requerido!"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={constactSchema}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} justify="space-between">
            <Grid item xs={12}>
              <AppField
                id="correo"
                name="correo"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.correo}
                label="Correo electrónico"
                helperText={<ErrorMessage name="correo" />}
                error={errors.correo && touched.correo}
              />
            </Grid>
  
            <Grid item xs={12}>
              <AppField
                id="asunto"
                name="asunto"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.asunto}
                label="Asunto"
                helperText={<ErrorMessage name="asunto" />}
                error={errors.asunto && touched.asunto}
              />
            </Grid>
            <Grid item xs={12}>
              <AppField
                id="descripcion"
                name="descripcion"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.descripcion}
                label="Descripción"
                helperText={<ErrorMessage name="descripcion" />}
                error={errors.descripcion && touched.descripcion}
                multiline
                rows={4}
              />
            </Grid>
            <p style={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={disabledButton}
              >
                ACEPTAR
              </Button>
            </p>

            <ReCAPTCHA
              sitekey="6LdieNwZAAAAAKfJtGJxhfx7vx0VN40pVXqGCPjI"
              size="invisible"
            />
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default React.memo(ContactForm);
