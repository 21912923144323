import React from 'react';
import { Grid } from '@material-ui/core';
import Curso from './Curso';

function ListaCursos({ cursos }) {
  return (
    <Grid container>
      {cursos.count > 0 ? (
        <Grid item xs={12}>
          {cursos.rows.map((curso) => (
            <Curso key={curso.id} curso={curso} />
          ))}
        </Grid>
      ) : (
        <p>No hay cursos</p>
      )}
    </Grid>
  );
}

export default ListaCursos;
