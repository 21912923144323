// @Vendors
import React from 'react'
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup'

// @Components
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';

// @React ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha"

function FormRecoverPass({onSubmit, disabledButton=false}) {
  const initialValues = { username: '' }

  const RecoverSchema = Yup.object().shape({
    username: Yup.string()
    .email('¡Introduzca un correo válido!')
    .required('¡Campo requerido!')
  })
  
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={RecoverSchema}
    >
      {
        ({errors, touched, values, handleChange, handleBlur, handleSubmit}) => (
          <form onSubmit={handleSubmit}>
           <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Correo electrónico"
              name="username"
              autoComplete="username"
              autoFocus
              helperText={<ErrorMessage name="username"/>}
              error={errors.username && touched.username}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
            />
            <p style={{textAlign: "center"}}>
              <Button type="submit" variant="contained" color="primary" disabled={disabledButton}>
                RECUPERAR CONTRASEÑA
              </Button>
            </p>
            <ReCAPTCHA
              sitekey="6LdieNwZAAAAAKfJtGJxhfx7vx0VN40pVXqGCPjI"
              size="invisible"
            />
          </form>
        )
      }
    </Formik>
  )
}

export default React.memo(FormRecoverPass)