import React from 'react'

function cantv() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 174 77.8" >
   <g id="Capa_1">
       <g id="Layer_1_1_">
       </g>
   </g>
   <g id="Layer_1">
   </g>
   <g id="svg2">
   </g>
   <g id="Fondos_Color">
       <g>
           <g>
               <path fill="#662483" d="M38.6,36.4c0,0-3.2,1.6-6.1,1.6c-2.8,0-5.7-2-5.7-6.1
                   c0-4,2.8-10.8,8.7-10.8c0,0,3.5,0.1,5.3,1.7l2-7c0,0-2.5-1.1-7.6-1.1c-5.1,0-17.2,1.8-17.2,17.9c0,0-0.6,12,12.4,12
                   c0,0,3.6,0.2,7.3-1.1L38.6,36.4z M40.2,37c0,12.9,21.7,6.6,23,5.8l3.5-19.3c0,0,1.6-8.7-10.2-8.7c0,0-6.7,0.1-12,1.9l0.9,6.2
                   c0,0,4.8-2,8.9-2c4.9,0,3.5,4.2,3.5,4.2S40.2,24,40.2,37z M55.2,39c0,0-6.4,2.1-6.4-3.3c0-5.4,7.9-5.9,7.9-5.9L55.2,39z
                    M167,51.7c-6.5-1.9-12.9-3.4-19.4-4.6c6.7,0.3,13.4,0.9,20,1.6c-13.4-4.5-27.8-7.1-42.6-7.8l12-25.2h-8.6l-7.2,16.8l-1-16.8
                   h-9.5l3.7,25.1c-2.4,0-4.8,0.1-7.3,0.3l0.8-3.8c0,0-0.6,0.8-3.6,0.8c-3.1,0-2.4-3.4-2.4-3.4l1.9-12.4h5l1.1-6.6h-4.8l1.2-8.1
                   l-8.9,1.1c-5.2,24.7-4.8,29.1-4.8,29.1c0,2.2,0.8,3.6,2,4.5c-1.8,0.2-3.6,0.5-5.4,0.8c0.5-2.6,2.8-15.9,3.4-20.3
                   c0.6-4.9-3.5-8.2-10.7-8.2c-7.2,0-12.4,2.8-12.4,2.8l-4.6,26.2h8.8l4-22.1c0,0,1-0.6,3.4-0.5c2.4,0.2,2.5,2.3,2.5,2.3l-3.4,20.4
                   l6.4,0C68.9,47,51.2,53.3,34.8,62.8c8.3-2.8,16.6-5.2,24.8-7.2c-8.1,3.1-16,6.9-23.7,11.3c8.9-3,17.8-5.6,26.6-7.8
                   c-9.1,3.2-17.9,7.3-26.2,12.2c33.4-11.4,66.2-16.7,99.3-16.7c10.2,0,20.5,0.5,30.8,1.5c-10-3-20.6-5-31.4-6.1c0.1,0,0.3,0,0.4,0
                   C145.9,50.1,156.4,50.6,167,51.7z M42.7,64c10.8-5.6,22.1-10,33.7-13.2c13.7-3.7,27.9-5.6,42.2-5.6c0,0,0,0,0,0
                   c13.7,0,27.4,1.7,41,5.1c-8.1-0.6-16.2-1-24.1-1C104,49.4,73.6,54.2,42.7,64z M110.5,47.6l-0.6-1.1l-0.2,1.2l-1.2,0.2l1.1,0.6
                   l-0.2,1.2l0.9-0.9l1.1,0.6l-0.6-1.1l0.9-0.9L110.5,47.6z M73,53.6l-0.6-1.1l-0.2,1.2L71,53.9l1.1,0.6l-0.2,1.2l0.9-0.9l1.1,0.6
                   l-0.6-1.1l0.9-0.9L73,53.6z M117.8,47.3l-0.6-1.1l-0.2,1.2l-1.2,0.2l1.1,0.6l-0.2,1.2l0.9-0.9l1.1,0.6l-0.6-1.1l0.9-0.9
                   L117.8,47.3z M125.1,47.3l-0.6-1.1l-0.2,1.2l-1.2,0.2l1.1,0.6l-0.2,1.2l0.9-0.9l1.1,0.6l-0.6-1.1l0.9-0.9L125.1,47.3z
                    M103.2,48.1l-0.6-1.1l-0.2,1.2l-1.2,0.2l1.1,0.6l-0.2,1.2l0.9-0.9l1.1,0.6l-0.6-1.1l0.9-0.9L103.2,48.1z M81,51.6l-0.6-1.1
                   l-0.2,1.2L79,51.9l1.1,0.6l-0.2,1.2l0.9-0.9l1.1,0.6l-0.6-1.1l0.9-0.9L81,51.6z M95.6,49.1l-0.6-1.1l-0.2,1.2l-1.2,0.2l1.1,0.6
                   l-0.2,1.2l0.9-0.9l1.1,0.6L96,49.8l0.9-0.9L95.6,49.1z M88.1,50.2l-0.6-1.1l-0.2,1.2l-1.2,0.2l1.1,0.6L87,52.3l0.9-0.9L89,52
                   l-0.6-1.1l0.9-0.9L88.1,50.2z"/>
           </g>
       </g>
   </g>
   <g id="Layer_2">
   </g>
   </svg>
   
    )
}

export default cantv
