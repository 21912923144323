// @Vendors
import React from 'react'
import {Route, Redirect} from 'react-router-dom'

// @Utils
import {verifyToken} from 'utils/helpers'

// @Components
import LoginFrame from 'components/AppFrame/LoginFrame'

function LoginRoute({ component: Component, alias="Not title assigned", user, ...rest }) {
    return (
        !verifyToken()
        ? <Route {...rest} render={
                (props) => 
                <LoginFrame title={alias} user={user}>
                    <Component {...props}/>
                </LoginFrame>
            } />
        : <Redirect to="/"/>
    )
}

export default React.memo(LoginRoute)