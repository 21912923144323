import React from 'react'

function conati() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 170.3 119.5"  >
   <g id="Capa_1">
       <g id="Layer_1_1_">
       </g>
       <g>
           <g>
               <path  fill="#662483" d="M95.8,90.8c0.5-0.7,0.9-1.2,1.2-1.7c3.5-5,6.9-10,10.5-14.9
                   c2.5-3.3,5.5-4,9.5-2.6c3.5,1.2,5.9,4.4,5.9,8c0,7.2,0,14.4,0,21.7c0,2.1-1.2,3.3-3.1,3.3c-2.1,0-3.5-1.3-3.5-3.4c0-2,0-4,0-5.9
                   c0-0.9-0.3-1.2-1.2-1.2c-1.7,0.1-3.3,0.1-5,0c-1.6,0-2.6-1-2.8-2.7c-0.2-1.8,0.7-3.4,2.3-3.5c1.8-0.2,3.6-0.2,5.5-0.2
                   c0.9,0,1.3-0.2,1.2-1.1c0-2.4,0-4.7,0-7.1c0-1.2-0.6-1.7-1.8-1.7c-1.2,0-2.1,0.4-2.8,1.4c-5.2,7.4-10.4,14.8-15.6,22.2
                   c-1.1,1.6-2.4,2.7-4.5,2.5c-1.7-0.1-2.5-0.9-2.5-2.6c0-2.5,0-5.1,0-7.6c0-4,0-8.1,0-12.1c0-2.2-0.6-3.2-2.7-3.7
                   c-2.9-0.8-5.9-1.1-8.9-0.1c-2.6,0.8-3.3,1.9-3.2,4.2c0.1,6.4,0,12.8,0,19.3c0,1.2-0.4,2.2-1.5,2.7c-1.2,0.6-2.3,0.4-3.4-0.2
                   c-1.1-0.7-1.3-1.8-1.3-2.9c0-7.5-0.1-15,0-22.6c0-3.8,3.1-7.1,7-7.2c4.6-0.2,9.2-0.2,13.8,0c2.7,0.1,5.8,2.2,6.5,5.3
                   c0.1,0.5,0.1,1,0.1,1.5c0,4.1,0,8.2,0,12.3C95.7,90.3,95.8,90.4,95.8,90.8z"/>
               <path  fill="#662483" d="M37.5,87.5c0-2.9,0.1-5.9,0-8.8c-0.1-4.4,3.4-8,7.9-7.8
                   c4.5,0.2,9.1-0.1,13.7,0.1c4.6,0.1,7.4,3.1,7.5,7.6c0.1,5.4,0.2,10.7,0.2,16.1c0,1.5-0.2,3.1-0.6,4.5c-1.1,3.2-3.5,5.1-6.9,5.1
                   c-5,0.1-10,0.1-15,0c-4.3-0.1-6.5-3.9-6.6-7.3C37.4,93.8,37.5,90.7,37.5,87.5z M44.1,87.7c0,1,0,2.1,0,3.1c0,1,0,2.1,0,3.1
                   c0.1,2.6,1.7,4.3,4.2,4.4c2.4,0.1,4.7,0.1,7.1,0c2.9-0.1,4.6-1.6,4.7-4.5c0.1-4.1,0.1-8.3-0.1-12.4c-0.1-2.4-1.7-3.9-4.2-3.9
                   c-2.5-0.1-4.9,0-7.4,0c-2.7,0-4.3,1.7-4.3,4.4C44.1,83.7,44.1,85.7,44.1,87.7z"/>
               <path  fill="#662483" d="M39.3,104.2c-0.3,0-0.6,0.1-0.9,0.1c-6.8,0-13.7,0-20.5,0
                   c-2.1,0-4.1-0.4-5.7-1.8c-1.4-1.2-2.2-2.6-2.2-4.5c-0.1-6.3-0.3-12.6-0.3-18.9c0-5.1,3.4-8.2,8.5-8.1c4.2,0.1,8.4,0.1,12.6,0.2
                   c0.5,0,1.1,0,1.6,0.1c1.7,0.2,2.7,1.6,2.6,3.3c-0.1,1.5-1.5,2.8-3,2.9c-3.9,0-7.8,0-11.7,0c-3.2,0.1-4.3,1.3-4.2,4.2
                   c0.1,4,0,8,0,12c0,0.9,0.1,1.8,0.4,2.6c0.5,1.2,1.6,1.8,2.9,1.8c5.2,0.1,10.4,0.2,15.6,0.2c1.1,0,1.7,0.3,1.8,1.4
                   c0.2,1.8,1.2,3.2,2.6,4.4C39.4,104.1,39.4,104.2,39.3,104.2z"/>
               <path  fill="#662483" d="M133.8,89.7c0-3.6,0-7.3,0-10.9c0-1-0.3-1.4-1.3-1.3
                   c-1.9,0.1-3.7,0-5.6,0c-2.4,0-3.5-1-3.5-3.1c0-2.1,1.1-3.2,3.6-3.2c6.5,0,13,0,19.5,0.1c0.8,0,1.5,0,2.2,0.2
                   c1.6,0.4,2.5,1.9,2.3,3.5c-0.2,1.5-1.4,2.6-3,2.6c-2,0-4,0-6,0c-1.6,0-1.6,0-1.6,1.5c0,7,0,14,0,21.1c0,0.4,0,0.8,0,1.1
                   c-0.3,2.1-1.4,3.1-3.1,3.1c-1.9-0.1-3.4-1.5-3.4-3.4C133.7,97.1,133.7,93.4,133.8,89.7C133.7,89.7,133.7,89.7,133.8,89.7z"/>
               <path  fill="#662483" d="M152.6,87.6c0-4.6,0-9.1,0-13.7c0-1.8,1.2-3,3.1-3
                   c1.8-0.1,3,1.1,3.1,2.9c0.1,1.7,0.1,3.4,0.1,5.1c0,7.3,0,14.5-0.1,21.8c0,0.8-0.2,1.7-0.6,2.4c-0.6,1.2-2.2,1.7-3.6,1.4
                   c-1.1-0.3-2-1.6-2.1-3c0-3.9,0-7.8-0.1-11.6C152.6,89.2,152.6,88.4,152.6,87.6C152.6,87.6,152.6,87.6,152.6,87.6z"/>
               <g>
                   <path fill="#662483" d="M13.5,113.4c-0.2,0.1-0.6,0.2-1.2,0.2c-1.5,0-2.4-0.9-2.4-2.3c0-1.5,1.1-2.5,2.5-2.5
                       c0.6,0,1,0.1,1.1,0.2l-0.2,0.7c-0.2-0.1-0.5-0.2-0.9-0.2c-0.9,0-1.6,0.6-1.6,1.7c0,1,0.6,1.7,1.6,1.7c0.3,0,0.7-0.1,0.9-0.2
                       L13.5,113.4z"/>
                   <path fill="#662483" d="M17.1,111.8c0,1.2-0.9,1.8-1.7,1.8c-1,0-1.7-0.7-1.7-1.7c0-1.1,0.7-1.8,1.7-1.8
                       C16.5,110.1,17.1,110.8,17.1,111.8z M14.6,111.8c0,0.6,0.3,1.1,0.8,1.1c0.5,0,0.8-0.5,0.8-1.2c0-0.5-0.2-1.1-0.8-1.1
                       C14.8,110.7,14.6,111.3,14.6,111.8z"/>
                   <path fill="#662483" d="M17.7,111.1c0-0.4,0-0.7,0-1h0.7l0,0.5h0c0.2-0.3,0.5-0.6,1-0.6c0.4,0,0.8,0.2,0.9,0.6h0
                       c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.6,0,1.1,0.4,1.1,1.5v2h-0.8v-1.9c0-0.6-0.2-0.9-0.6-0.9
                       c-0.3,0-0.5,0.2-0.6,0.4c0,0.1,0,0.2,0,0.3v2h-0.8v-1.9c0-0.5-0.2-0.8-0.6-0.8c-0.3,0-0.5,0.2-0.6,0.5c0,0.1,0,0.2,0,0.3v2h-0.8
                       V111.1z"/>
                   <path fill="#662483" d="M24.3,109.2c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
                       C24.1,108.7,24.3,108.9,24.3,109.2z M23.4,113.5v-3.4h0.9v3.4H23.4z"/>
                   <path fill="#662483" d="M25,112.7c0.2,0.1,0.6,0.2,0.9,0.2c0.4,0,0.5-0.2,0.5-0.4c0-0.2-0.1-0.3-0.6-0.5c-0.7-0.2-0.9-0.6-0.9-1
                       c0-0.6,0.5-1.1,1.3-1.1c0.4,0,0.7,0.1,0.9,0.2l-0.2,0.6c-0.1-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.5,0.4c0,0.2,0.2,0.3,0.6,0.5
                       c0.6,0.2,0.9,0.5,0.9,1c0,0.6-0.5,1-1.4,1c-0.4,0-0.8-0.1-1-0.2L25,112.7z"/>
                   <path fill="#662483" d="M28.7,109.2c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
                       C28.5,108.7,28.6,108.9,28.7,109.2z M27.7,113.5v-3.4h0.9v3.4H27.7z"/>
                   <path fill="#662483" d="M32.6,111.8c0,1.2-0.9,1.8-1.7,1.8c-1,0-1.7-0.7-1.7-1.7c0-1.1,0.7-1.8,1.7-1.8
                       C31.9,110.1,32.6,110.8,32.6,111.8z M30,111.8c0,0.6,0.3,1.1,0.8,1.1c0.5,0,0.8-0.5,0.8-1.2c0-0.5-0.2-1.1-0.8-1.1
                       C30.3,110.7,30,111.3,30,111.8z M31.9,108.7l-0.8,1h-0.6l0.6-1H31.9z"/>
                   <path fill="#662483" d="M33.1,111.1c0-0.4,0-0.7,0-1h0.7l0,0.5h0c0.1-0.3,0.5-0.6,1.1-0.6c0.6,0,1.2,0.4,1.2,1.4v2h-0.8v-1.9
                       c0-0.5-0.2-0.8-0.6-0.8c-0.3,0-0.6,0.2-0.7,0.5c0,0.1,0,0.2,0,0.3v2h-0.9V111.1z"/>
                   <path fill="#662483" d="M38.2,113.5v-4.7h1l1.2,2c0.3,0.5,0.6,1.1,0.8,1.6h0c-0.1-0.6-0.1-1.2-0.1-1.9v-1.6h0.8v4.7H41l-1.2-2
                       c-0.3-0.5-0.6-1.1-0.8-1.6l0,0c0,0.6,0,1.2,0,2v1.7H38.2z"/>
                   <path fill="#662483" d="M44.6,113.5l-0.1-0.4h0c-0.2,0.3-0.6,0.4-1,0.4c-0.7,0-1.1-0.5-1.1-1c0-0.8,0.8-1.3,2-1.3v-0.1
                       c0-0.2-0.1-0.6-0.7-0.6c-0.3,0-0.7,0.1-0.9,0.2l-0.2-0.6c0.2-0.2,0.7-0.3,1.2-0.3c1.1,0,1.4,0.7,1.4,1.4v1.2c0,0.3,0,0.6,0,0.8
                       H44.6z M44.5,111.9c-0.6,0-1.2,0.1-1.2,0.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.6-0.2,0.7-0.5c0-0.1,0-0.1,0-0.2V111.9z"/>
                   <path fill="#662483" d="M48.6,113.4c-0.2,0.1-0.5,0.2-1,0.2c-1,0-1.7-0.7-1.7-1.7c0-1,0.7-1.8,1.9-1.8c0.3,0,0.6,0.1,0.8,0.2
                       l-0.2,0.6c-0.1-0.1-0.3-0.1-0.6-0.1c-0.6,0-1,0.5-1,1.1c0,0.7,0.4,1.1,1,1.1c0.3,0,0.5-0.1,0.7-0.1L48.6,113.4z"/>
                   <path fill="#662483" d="M49.9,109.2c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
                       C49.8,108.7,49.9,108.9,49.9,109.2z M49,113.5v-3.4h0.9v3.4H49z"/>
                   <path fill="#662483" d="M53.8,111.8c0,1.2-0.9,1.8-1.7,1.8c-1,0-1.7-0.7-1.7-1.7c0-1.1,0.7-1.8,1.7-1.8
                       C53.2,110.1,53.8,110.8,53.8,111.8z M51.3,111.8c0,0.6,0.3,1.1,0.8,1.1c0.5,0,0.8-0.5,0.8-1.2c0-0.5-0.2-1.1-0.8-1.1
                       C51.6,110.7,51.3,111.3,51.3,111.8z"/>
                   <path fill="#662483" d="M54.4,111.1c0-0.4,0-0.7,0-1h0.7l0,0.5h0c0.1-0.3,0.5-0.6,1.1-0.6c0.6,0,1.2,0.4,1.2,1.4v2h-0.8v-1.9
                       c0-0.5-0.2-0.8-0.6-0.8c-0.3,0-0.6,0.2-0.7,0.5c0,0.1,0,0.2,0,0.3v2h-0.9V111.1z"/>
                   <path fill="#662483" d="M60.1,113.5l-0.1-0.4h0c-0.2,0.3-0.6,0.4-1,0.4c-0.7,0-1.1-0.5-1.1-1c0-0.8,0.8-1.3,2-1.3v-0.1
                       c0-0.2-0.1-0.6-0.7-0.6c-0.3,0-0.7,0.1-0.9,0.2l-0.2-0.6c0.2-0.2,0.7-0.3,1.2-0.3c1.1,0,1.4,0.7,1.4,1.4v1.2c0,0.3,0,0.6,0,0.8
                       H60.1z M60,111.9c-0.6,0-1.2,0.1-1.2,0.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.6-0.2,0.7-0.5c0-0.1,0-0.1,0-0.2V111.9z"/>
                   <path fill="#662483" d="M61.6,108.6h0.9v4.9h-0.9V108.6z"/>
                   <path fill="#662483" d="M67.6,108.6v4c0,0.3,0,0.7,0,0.9h-0.8l0-0.5h0c-0.2,0.4-0.6,0.6-1.1,0.6c-0.8,0-1.4-0.7-1.4-1.7
                       c0-1.1,0.7-1.8,1.5-1.8c0.5,0,0.8,0.2,0.9,0.4h0v-1.9H67.6z M66.7,111.5c0-0.1,0-0.2,0-0.2c-0.1-0.3-0.3-0.6-0.7-0.6
                       c-0.5,0-0.8,0.5-0.8,1.1c0,0.6,0.3,1.1,0.8,1.1c0.3,0,0.6-0.2,0.7-0.6c0-0.1,0-0.2,0-0.2V111.5z"/>
                   <path fill="#662483" d="M68.9,112.1c0,0.6,0.5,0.9,1,0.9c0.4,0,0.7-0.1,0.9-0.2l0.1,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
                       c-1.1,0-1.7-0.7-1.7-1.7c0-0.9,0.6-1.8,1.6-1.8c1.1,0,1.4,0.9,1.4,1.6c0,0.2,0,0.3,0,0.4H68.9z M70.4,111.5
                       c0-0.3-0.1-0.8-0.7-0.8c-0.5,0-0.7,0.5-0.8,0.8H70.4z"/>
                   <path fill="#662483" d="M73,108.6h0.9v4.9H73V108.6z"/>
                   <path fill="#662483" d="M76.6,113.5l-0.1-0.4h0c-0.2,0.3-0.6,0.4-1,0.4c-0.7,0-1.1-0.5-1.1-1c0-0.8,0.8-1.3,2-1.3v-0.1
                       c0-0.2-0.1-0.6-0.7-0.6c-0.3,0-0.7,0.1-0.9,0.2l-0.2-0.6c0.2-0.2,0.7-0.3,1.2-0.3c1.1,0,1.4,0.7,1.4,1.4v1.2c0,0.3,0,0.6,0,0.8
                       H76.6z M76.4,111.9c-0.6,0-1.2,0.1-1.2,0.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.6-0.2,0.7-0.5c0-0.1,0-0.1,0-0.2V111.9z"/>
                   <path fill="#662483" d="M78,112.7c0.2,0.1,0.6,0.2,0.9,0.2c0.4,0,0.5-0.2,0.5-0.4c0-0.2-0.1-0.3-0.6-0.5c-0.7-0.2-0.9-0.6-0.9-1
                       c0-0.6,0.5-1.1,1.3-1.1c0.4,0,0.7,0.1,0.9,0.2l-0.2,0.6c-0.1-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.5,0.4c0,0.2,0.2,0.3,0.6,0.5
                       c0.6,0.2,0.9,0.5,0.9,1c0,0.6-0.5,1-1.4,1c-0.4,0-0.8-0.1-1-0.2L78,112.7z"/>
                   <path fill="#662483" d="M82.7,109.6h-1.3v-0.7h3.5v0.7h-1.3v3.9h-0.8V109.6z"/>
                   <path fill="#662483" d="M85.3,112.1c0,0.6,0.5,0.9,1,0.9c0.4,0,0.7-0.1,0.9-0.2l0.1,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
                       c-1.1,0-1.7-0.7-1.7-1.7c0-0.9,0.6-1.8,1.6-1.8c1.1,0,1.4,0.9,1.4,1.6c0,0.2,0,0.3,0,0.4H85.3z M86.8,111.5
                       c0-0.3-0.1-0.8-0.7-0.8c-0.5,0-0.7,0.5-0.8,0.8H86.8z"/>
                   <path fill="#662483" d="M90.6,113.4c-0.2,0.1-0.5,0.2-1,0.2c-1,0-1.7-0.7-1.7-1.7c0-1,0.7-1.8,1.9-1.8c0.3,0,0.6,0.1,0.8,0.2
                       l-0.2,0.6c-0.1-0.1-0.3-0.1-0.6-0.1c-0.6,0-1,0.5-1,1.1c0,0.7,0.4,1.1,1,1.1c0.3,0,0.5-0.1,0.7-0.1L90.6,113.4z"/>
                   <path fill="#662483" d="M91.1,111.1c0-0.4,0-0.7,0-1h0.7l0,0.5h0c0.1-0.3,0.5-0.6,1.1-0.6c0.6,0,1.2,0.4,1.2,1.4v2h-0.8v-1.9
                       c0-0.5-0.2-0.8-0.6-0.8c-0.3,0-0.6,0.2-0.7,0.5c0,0.1,0,0.2,0,0.3v2h-0.9V111.1z"/>
                   <path fill="#662483" d="M98.1,111.8c0,1.2-0.9,1.8-1.7,1.8c-1,0-1.7-0.7-1.7-1.7c0-1.1,0.7-1.8,1.7-1.8
                       C97.4,110.1,98.1,110.8,98.1,111.8z M95.5,111.8c0,0.6,0.3,1.1,0.8,1.1c0.5,0,0.8-0.5,0.8-1.2c0-0.5-0.2-1.1-0.8-1.1
                       C95.8,110.7,95.5,111.3,95.5,111.8z"/>
                   <path fill="#662483" d="M98.6,108.6h0.9v4.9h-0.9V108.6z"/>
                   <path fill="#662483" d="M103.4,111.8c0,1.2-0.9,1.8-1.7,1.8c-1,0-1.7-0.7-1.7-1.7c0-1.1,0.7-1.8,1.7-1.8
                       C102.8,110.1,103.4,110.8,103.4,111.8z M100.9,111.8c0,0.6,0.3,1.1,0.8,1.1c0.5,0,0.8-0.5,0.8-1.2c0-0.5-0.2-1.1-0.8-1.1
                       C101.2,110.7,100.9,111.3,100.9,111.8z"/>
                   <path fill="#662483" d="M107.1,110.1c0,0.2,0,0.5,0,1v1.9c0,0.7-0.1,1.2-0.5,1.5c-0.4,0.3-0.8,0.4-1.3,0.4
                       c-0.4,0-0.9-0.1-1.2-0.3l0.2-0.6c0.2,0.1,0.6,0.3,1,0.3c0.6,0,1-0.3,1-1V113h0c-0.2,0.3-0.5,0.5-1,0.5c-0.8,0-1.4-0.7-1.4-1.6
                       c0-1.1,0.7-1.8,1.5-1.8c0.5,0,0.8,0.2,1,0.5h0l0-0.4H107.1z M106.2,111.5c0-0.1,0-0.2,0-0.2c-0.1-0.3-0.3-0.5-0.7-0.5
                       c-0.5,0-0.8,0.4-0.8,1.1c0,0.6,0.3,1,0.8,1c0.3,0,0.6-0.2,0.7-0.5c0-0.1,0-0.2,0-0.3V111.5z"/>
                   <path fill="#662483" d="M107.8,113.5v-3.4h0.9v3.4H107.8z M109.2,108.7l-0.8,1h-0.6l0.6-1H109.2z"/>
                   <path fill="#662483" d="M111.3,113.5l-0.1-0.4h0c-0.2,0.3-0.6,0.4-1,0.4c-0.7,0-1.1-0.5-1.1-1c0-0.8,0.8-1.3,2-1.3v-0.1
                       c0-0.2-0.1-0.6-0.7-0.6c-0.3,0-0.7,0.1-0.9,0.2l-0.2-0.6c0.2-0.2,0.7-0.3,1.2-0.3c1.1,0,1.4,0.7,1.4,1.4v1.2c0,0.3,0,0.6,0,0.8
                       H111.3z M111.2,111.9c-0.6,0-1.2,0.1-1.2,0.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.6-0.2,0.7-0.5c0-0.1,0-0.1,0-0.2V111.9z"/>
                   <path fill="#662483" d="M112.7,112.7c0.2,0.1,0.6,0.2,0.9,0.2c0.4,0,0.5-0.2,0.5-0.4c0-0.2-0.1-0.3-0.6-0.5
                       c-0.7-0.2-0.9-0.6-0.9-1c0-0.6,0.5-1.1,1.3-1.1c0.4,0,0.7,0.1,0.9,0.2l-0.2,0.6c-0.1-0.1-0.4-0.2-0.7-0.2
                       c-0.3,0-0.5,0.1-0.5,0.4c0,0.2,0.2,0.3,0.6,0.5c0.6,0.2,0.9,0.5,0.9,1c0,0.6-0.5,1-1.4,1c-0.4,0-0.8-0.1-1-0.2L112.7,112.7z"/>
                   <path fill="#662483" d="M119.9,108.6v4c0,0.3,0,0.7,0,0.9h-0.8l0-0.5h0c-0.2,0.4-0.6,0.6-1.1,0.6c-0.8,0-1.4-0.7-1.4-1.7
                       c0-1.1,0.7-1.8,1.5-1.8c0.5,0,0.8,0.2,0.9,0.4h0v-1.9H119.9z M119,111.5c0-0.1,0-0.2,0-0.2c-0.1-0.3-0.3-0.6-0.7-0.6
                       c-0.5,0-0.8,0.5-0.8,1.1c0,0.6,0.3,1.1,0.8,1.1c0.3,0,0.6-0.2,0.7-0.6c0-0.1,0-0.2,0-0.2V111.5z"/>
                   <path fill="#662483" d="M121.2,112.1c0,0.6,0.5,0.9,1,0.9c0.4,0,0.7-0.1,0.9-0.2l0.1,0.6c-0.3,0.1-0.7,0.2-1.2,0.2
                       c-1.1,0-1.7-0.7-1.7-1.7c0-0.9,0.6-1.8,1.6-1.8c1.1,0,1.4,0.9,1.4,1.6c0,0.2,0,0.3,0,0.4H121.2z M122.7,111.5
                       c0-0.3-0.1-0.8-0.7-0.8c-0.5,0-0.7,0.5-0.8,0.8H122.7z"/>
                   <path fill="#662483" d="M126.2,108.8v4.7h-0.8v-4.7H126.2z"/>
                   <path fill="#662483" d="M127.1,111.1c0-0.4,0-0.7,0-1h0.7l0,0.5h0c0.1-0.3,0.5-0.6,1.1-0.6c0.6,0,1.2,0.4,1.2,1.4v2h-0.8v-1.9
                       c0-0.5-0.2-0.8-0.6-0.8c-0.3,0-0.6,0.2-0.7,0.5c0,0.1,0,0.2,0,0.3v2h-0.9V111.1z"/>
                   <path fill="#662483" d="M131,113.5v-2.7h-0.5v-0.6h0.5V110c0-0.4,0.1-0.9,0.4-1.1c0.2-0.2,0.6-0.3,0.9-0.3c0.2,0,0.4,0,0.5,0.1
                       l-0.1,0.7c-0.1,0-0.2-0.1-0.4-0.1c-0.4,0-0.6,0.4-0.6,0.8v0.2h0.7v0.6h-0.7v2.7H131z"/>
                   <path fill="#662483" d="M136,111.8c0,1.2-0.9,1.8-1.7,1.8c-1,0-1.7-0.7-1.7-1.7c0-1.1,0.7-1.8,1.7-1.8
                       C135.4,110.1,136,110.8,136,111.8z M133.5,111.8c0,0.6,0.3,1.1,0.8,1.1c0.5,0,0.8-0.5,0.8-1.2c0-0.5-0.2-1.1-0.8-1.1
                       C133.7,110.7,133.5,111.3,133.5,111.8z"/>
                   <path fill="#662483" d="M136.6,111.2c0-0.5,0-0.8,0-1.1h0.7l0,0.6h0c0.2-0.5,0.6-0.7,0.9-0.7c0.1,0,0.1,0,0.2,0v0.8
                       c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.3-0.8,0.6c0,0.1,0,0.2,0,0.3v1.7h-0.8V111.2z"/>
                   <path fill="#662483" d="M138.9,111.1c0-0.4,0-0.7,0-1h0.7l0,0.5h0c0.2-0.3,0.5-0.6,1-0.6c0.4,0,0.8,0.2,0.9,0.6h0
                       c0.1-0.2,0.3-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.7-0.2c0.6,0,1.1,0.4,1.1,1.5v2H143v-1.9c0-0.6-0.2-0.9-0.6-0.9
                       c-0.3,0-0.5,0.2-0.6,0.4c0,0.1,0,0.2,0,0.3v2H141v-1.9c0-0.5-0.2-0.8-0.6-0.8c-0.3,0-0.5,0.2-0.6,0.5c0,0.1,0,0.2,0,0.3v2h-0.8
                       V111.1z"/>
                   <path fill="#662483" d="M146.5,113.5l-0.1-0.4h0c-0.2,0.3-0.6,0.4-1,0.4c-0.7,0-1.1-0.5-1.1-1c0-0.8,0.8-1.3,2-1.3v-0.1
                       c0-0.2-0.1-0.6-0.7-0.6c-0.3,0-0.7,0.1-0.9,0.2l-0.2-0.6c0.2-0.2,0.7-0.3,1.2-0.3c1.1,0,1.4,0.7,1.4,1.4v1.2c0,0.3,0,0.6,0,0.8
                       H146.5z M146.4,111.9c-0.6,0-1.2,0.1-1.2,0.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.6-0.2,0.7-0.5c0-0.1,0-0.1,0-0.2V111.9z"/>
                   <path fill="#662483" d="M150.4,113.4c-0.2,0.1-0.5,0.2-1,0.2c-1,0-1.7-0.7-1.7-1.7c0-1,0.7-1.8,1.9-1.8c0.3,0,0.6,0.1,0.8,0.2
                       l-0.2,0.6c-0.1-0.1-0.3-0.1-0.6-0.1c-0.6,0-1,0.5-1,1.1c0,0.7,0.4,1.1,1,1.1c0.3,0,0.5-0.1,0.7-0.1L150.4,113.4z"/>
                   <path fill="#662483" d="M151.8,109.2c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5
                       C151.6,108.7,151.8,108.9,151.8,109.2z M150.9,113.5v-3.4h0.9v3.4H150.9z"/>
                   <path fill="#662483" d="M155.7,111.8c0,1.2-0.9,1.8-1.7,1.8c-1,0-1.7-0.7-1.7-1.7c0-1.1,0.7-1.8,1.7-1.8
                       C155.1,110.1,155.7,110.8,155.7,111.8z M153.2,111.8c0,0.6,0.3,1.1,0.8,1.1c0.5,0,0.8-0.5,0.8-1.2c0-0.5-0.2-1.1-0.8-1.1
                       C153.4,110.7,153.2,111.3,153.2,111.8z M155,108.7l-0.8,1h-0.6l0.6-1H155z"/>
                   <path fill="#662483" d="M156.3,111.1c0-0.4,0-0.7,0-1h0.7l0,0.5h0c0.1-0.3,0.5-0.6,1.1-0.6c0.6,0,1.2,0.4,1.2,1.4v2h-0.8v-1.9
                       c0-0.5-0.2-0.8-0.6-0.8c-0.3,0-0.6,0.2-0.7,0.5c0,0.1,0,0.2,0,0.3v2h-0.9V111.1z"/>
               </g>
               <path  fill="#662483" d="M95.7,61.2C91,63.7,85.6,65,80.4,64.8c-6-0.2-11.7-2.4-16.3-6.4
                   c-6.3-5.4-13.2-15-9.9-30.4c2.1-9.9,7.6-14.3,10.7-16.1c0.5,0.4,1.2,0.6,1.9,0.6c1.9,0,3.4-1.5,3.4-3.4c0-1.9-1.5-3.4-3.4-3.4
                   c-1.9,0-3.4,1.5-3.4,3.4c0,0.2,0,0.4,0.1,0.6c-3.6,2.1-9.6,7.1-11.9,17.8c-1.7,7.9-1,15.2,2,21.7c2,4.3,5,8.1,8.8,11.4
                   c5.1,4.3,11.4,6.8,18,7c0.4,0,0.8,0,1.1,0c5.3,0,10.8-1.4,15.6-3.9L95.7,61.2z"/>
               <path  fill="#662483" d="M81.7,7.6c-1.5,0-2.9,1-3.3,2.4c-3.4,0.1-9.6,1.3-15.3,8
                   c-9.4,11-7.2,21.9-5.1,27.5C62.3,56.9,73.2,61,81.2,61.1l0-2.7c-7.1-0.1-16.9-3.8-20.7-13.9c-2.3-6.1-3.4-15.3,4.6-24.7
                   c5.3-6.1,10.8-7,13.6-7.1c0.6,1,1.7,1.7,3,1.7c1.9,0,3.4-1.5,3.4-3.4C85.1,9.1,83.6,7.6,81.7,7.6z"/>
               <path  fill="#662483" d="M90.9,15.7c-1.1,0-2,0.5-2.6,1.2c-1.9-1-6.6-2.8-13.6,0.1
                   c-9.5,4-12,12.5-12,16.8c0,8.6,3.2,15.1,8.8,18.5l1.4-2.4c-4.8-2.8-7.5-8.6-7.5-16.1c0-3.7,2.2-10.9,10.4-14.3
                   c7.4-3.1,11.6-0.1,11.7,0.1l0,0c0.2,1.7,1.7,3,3.4,3c1.9,0,3.4-1.5,3.4-3.4C94.4,17.2,92.8,15.7,90.9,15.7z"/>
           </g>
       </g>
   </g>
   <g id="Layer_1">
   </g>
   <g id="svg2">
   </g>
   <g id="Fondos_Color">
   </g>
   <g id="Layer_2">
   </g>
   </svg>
   
    )
}

export default conati
