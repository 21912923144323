// @Vendors
import React, { useState, useCallback } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";

// @Material UI - COMPONENTS
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

// @React ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";

const initial = {
  username: "",
  password: "",
};

function AuthForm({
  initialValues = initial,
  onSubmit,
  disabledButton = false,
}) {
  const [state, setState] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = useCallback(() => {
    setState((prev) => ({ showPassword: !prev.showPassword }));
  }, [setState]);

  const handleMouseDownPassword = useCallback((event) => {
    event.preventDefault();
  }, []);

  const passwordRegex = RegExp("^[a-zA-Z0-9!#$%&/_.,]+$");
  const SignupSchema = Yup.object().shape({
    username: Yup.string()
      .email("¡Introduzca un correo válido!")
      .required("¡Campo requerido!"),
    password: Yup.string()
      .min(5, "¡La contraseña debe tener como mínimo 5 carácteres!")
      .max(255, "¡La contraseña tener como máximo 255 carácteres!")
      .required("¡Campo requerido!")
      .matches(passwordRegex, "¡Introduzca una contraseña válidá!"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={SignupSchema}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Correo electrónico"
            name="username"
            autoComplete="username"
            autoFocus
            helperText={<ErrorMessage name="username" />}
            error={errors.username && touched.username}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            helperText={<ErrorMessage name="password" />}
            error={errors.password && touched.password}
            type={!state.showPassword ? "password" : "text"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <p style={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={disabledButton}
            >
              ACEPTAR
            </Button>
          </p>

          <ReCAPTCHA
            sitekey="6LdieNwZAAAAAKfJtGJxhfx7vx0VN40pVXqGCPjI"
            size="invisible"
          />
        </form>
      )}
    </Formik>
  );
}

export default React.memo(AuthForm);
