import React from 'react'

function telcom() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        viewBox="0 0 188.1 54.9"  >
   <g id="Capa_1">
       <g id="Layer_1_1_">
       </g>
   </g>
   <g id="Layer_1">
   </g>
   <g id="svg2">
   </g>
   <g id="Fondos_Color">
   </g>
   <g id="Layer_2">
       <path fill="#662483" d="M24.7,16.3v-4.8l1,4.1c0,0,12-6.5,28.8-7.6c-4.4-3.8-10-6-16.2-6c-13.7,0-24.9,11.2-25.1,25
           C13.7,23.6,24.7,16.3,24.7,16.3z"/>
       <path fill="#662483" d="M18,40.2C17.9,36,25.4,25,25.4,25s1.5,22.1,10.8,26.6c0,0-11-0.5-18.1-11.2L18,40.2z"/>
       <path fill="#662483" d="M54.9,8.5c-24.3,6.2-27.8,15.6-27.8,15.6c4.1,16.6,21.5,26.8,21.5,26.8C75.5,32.1,54.9,8.5,54.9,8.5z
            M51.6,27.6L53,29l2-0.3l-0.9,1.8l1,1.8l-2-0.3l-1.4,1.5l-0.3-2l-1.8-0.9l1.8-0.9L51.6,27.6z M30.8,24.8l-0.6,0.6l-0.1-0.9
           l-0.8-0.4l0.8-0.4l0.1-0.9l0.6,0.6l0.9-0.2l-0.4,0.8l0.4,0.8L30.8,24.8z M35,23.2l-0.9,0.9l-0.2-1.3l-1.1-0.6l1.1-0.6l0.2-1.3
           l0.9,0.9l1.2-0.2l-0.6,1.1l0.6,1.1L35,23.2z M40.9,23.4l-1.1,1.1L39.6,23l-1.4-0.7l1.4-0.7l0.2-1.5l1.1,1.1l1.5-0.3l-0.7,1.4
           l0.7,1.4L40.9,23.4z M46.6,25.1l-1.8-0.9l1.8-0.9l0.3-2l1.4,1.5l2-0.3l-0.9,1.8l1,1.8l-2-0.3l-1.4,1.5L46.6,25.1z M49.8,48.1
           l-0.6,0.6L49,47.8l-0.8-0.4L49,47l0.1-0.9l0.6,0.6l0.9-0.2l-0.4,0.8l0.4,0.8L49.8,48.1z M52.2,44.3l-0.9,0.9l-0.2-1.3L50,43.4
           l1.1-0.6l0.2-1.3l0.9,0.9l1.2-0.2l-0.6,1.1l0.6,1.1L52.2,44.3z M53,39.8l-0.2-1.5l-1.4-0.7l1.4-0.7l0.2-1.5l1.1,1.1l1.5-0.3
           l-0.7,1.4l0.7,1.4l-1.5-0.3L53,39.8z"/>
       <g>
           <path fill="#662483" d="M73.8,38.8l-2.1-5.9h1.3l1.5,4.4l1.4-4.4h1.2L75,38.8H73.8z"/>
           <path fill="#662483" d="M84.2,38.8v-5.9h4.3v1h-3.1v1.3h2.9v1h-2.9v1.6h3.3v1H84.2z"/>
           <path fill="#662483" d="M96.2,38.8v-5.9h1.1l2.4,3.9v-3.9h1.1v5.9h-1.2l-2.4-3.9v3.9H96.2z"/>
           <path fill="#662483" d="M108.6,38.8v-5.9h4.3v1h-3.1v1.3h2.9v1h-2.9v1.6h3.3v1H108.6z"/>
           <path fill="#662483" d="M120,38.8v-1.1l3.1-3.8h-2.7v-1h4.3v0.9l-3.2,4h3.3v1H120z"/>
           <path fill="#662483" d="M132,32.9h1.2v3.2c0,0.5,0,0.8,0,1c0,0.2,0.2,0.4,0.4,0.6c0.2,0.1,0.4,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2
               c0.2-0.1,0.3-0.3,0.3-0.5c0-0.2,0.1-0.5,0.1-1v-3.3h1.2V36c0,0.7,0,1.2-0.1,1.5c-0.1,0.3-0.2,0.5-0.4,0.7
               c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.7,0.2-1.1,0.2c-0.6,0-1-0.1-1.3-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.4-0.3-0.7
               c-0.1-0.3-0.1-0.8-0.1-1.5V32.9z"/>
           <path fill="#662483" d="M144.4,38.8v-5.9h4.3v1h-3.1v1.3h2.9v1h-2.9v1.6h3.3v1H144.4z"/>
           <path fill="#662483" d="M156.4,38.8v-5.9h1.2v4.9h2.9v1H156.4z"/>
           <path fill="#662483" d="M173.2,38.8h-1.3l-0.5-1.3H169l-0.5,1.3h-1.2l2.3-5.9h1.2L173.2,38.8z M171,36.5l-0.8-2.2l-0.8,2.2H171z"
               />
       </g>
       <g>
           <polygon fill="#662483" points="69.7,19.2 82.6,19.2 82.6,21.8 77.8,21.8 77.8,30.4 74.4,30.4 74.4,21.8 69.7,21.8 		"/>
           <path fill="#662483" d="M89.4,21.8H97v-2.6h-9.8c0,0-2.8,0.1-3,2.6v2.6v0.2v0.5v0.2v2.6c0.3,2.5,3,2.6,3,2.6H97v-2.6h-7.6
               c-2,0-1.9-1.7-1.9-1.7H97v-0.9v-0.2v-0.5v-0.2v-0.9h-9.5C87.5,23.5,87.4,21.8,89.4,21.8z"/>
           <path fill="#662483" d="M118.1,21.8h7.6v-2.6h-9.8c0,0-2.8,0.1-3,2.6v2.6v0.2v0.5v0.2v2.6c0.3,2.5,3,2.6,3,2.6h9.8v-2.6h-7.6
               c-2,0-1.9-1.7-1.9-1.7h9.5v-0.9v-0.2v-0.5v-0.2v-0.9h-9.5C116.2,23.5,116.1,21.8,118.1,21.8z"/>
           <path fill="#662483" d="M98.5,19.2h3.4v7.1c0,0-0.1,1.5,1.6,1.5h7.8v2.7H102c0,0-3.5,0-3.5-2.8V19.2z"/>
           <path fill="#662483" d="M151.8,19.2h-7.6c-1.4,0-2.6,1-2.6,2.3V28c0,1.3,1.1,2.3,2.6,2.3h7.6c1.4,0,2.6-1,2.6-2.3v-6.5
               C154.4,20.2,153.2,19.2,151.8,19.2z M151,26.5c0,0.7-0.5,1.3-1.2,1.3h-3.6c-0.7,0-1.2-0.6-1.2-1.3v-3.6c0-0.7,0.5-1.3,1.2-1.3h3.6
               c0.7,0,1.2,0.6,1.2,1.3V26.5z"/>
           <path fill="#662483" d="M136.6,26v0.5c0,0.7-0.5,1.3-1.2,1.3h-3.6c-0.7,0-1.2-0.6-1.2-1.3v-3.6c0-0.7,0.5-1.3,1.2-1.3h3.6
               c0.7,0,1.2,0.6,1.2,1.3v0.5h3.3v-2c0-1.3-1.1-2.3-2.6-2.3h-7.6c-1.4,0-2.6,1-2.6,2.3V28c0,1.3,1.1,2.3,2.6,2.3h7.6
               c1.4,0,2.6-1,2.6-2.3v-2H136.6z"/>
           <path fill="#662483" d="M169.2,19.2h-4h-0.8h-0.5h-0.7h-4.1c-3.1,0-3.2,2.5-3.2,2.5v8.6h3.5v-7.2c0-1.3,1.3-1.5,1.3-1.5h1.8v8.7
               h0.8h0.7h0.5h0.8h0.7v-8.7h1.8c0,0,1.3,0.3,1.3,1.5v7.2h3.5v-8.6C172.4,21.7,172.4,19.2,169.2,19.2z"/>
       </g>
   </g>
   </svg>
   
    )
}

export default telcom
