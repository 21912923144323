//  @Vendors
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiTheme } from './MuiTheme'

// @Routes Path
import PatchRoutes from './PatchRoutes'

// @ActionTypes
import {
  MODAL_CLOSE,
  CLEAR_SERVER_ERRORS,
  CLEAR_SERVER_SUCCESS,
  DIALOG_CONFIRM_CLOSE
} from 'state/app/app.actionTypes'
import { CHECK_AUTH } from 'state/auth/auth.actionsTypes'

// @Selectors
import {
  selectServerErrors,
  selectServerSuccess,
  selectModal
} from 'state/app/app.selectors'

// @Styles
import 'statics/styles/App.scss'

// @Components
import Snackbars from 'components/Snackbars'
import SnackbarsSuccess from 'components/SnackbarsSuccess'
import MaterialModal from 'components/MaterialModal'
import DialogConfirm from 'components/DialogConfirm'

function App(){
	
  const dispatch = useDispatch()
  const serverErrors = useSelector(state => selectServerErrors(state))
	const serverSuccess = useSelector(state => selectServerSuccess(state))
	const modal = useSelector(state => selectModal(state))
  const dialogConfirm = useSelector(state => state.appReducer.get("dialogConfirm"))
  const user = useSelector(state => state.usersReducer.get('user'))

  React.useEffect(() => {
    dispatch({ type: CHECK_AUTH })
  }, [dispatch])
  
  return (
    <ThemeProvider theme={MuiTheme}>      
			<Snackbars onClose={() => dispatch({ type: CLEAR_SERVER_ERRORS })} message={serverErrors} open={!!serverErrors} />
			<SnackbarsSuccess  onClose={() => dispatch({ type: CLEAR_SERVER_SUCCESS })} message={serverSuccess} open={!!serverSuccess} />

      <PatchRoutes user={user} />

      <MaterialModal open={modal.open} handleClose={() => dispatch({ type: MODAL_CLOSE })} >
      	{modal.description}
      </MaterialModal>

      <DialogConfirm
          dialogTitle={dialogConfirm.title}
          dialogText={dialogConfirm.description}
          open={dialogConfirm.open}
          onClose={() => dispatch({ type: DIALOG_CONFIRM_CLOSE })}
          onConfirm={() => dialogConfirm.onConfirm()}
      />
    </ThemeProvider>
  )
}
export default App