import { Map } from "immutable";

import {
  SET_CURSOS,
  LOAD_CURSOS,
  UPDATE_FILTERS,
  SET_PROFESORES,
  SET_FACILITADORES,
} from "./cursos.actionsTypes";

const initialState = Map().merge({
  cursos: {
    count: 0,
    rows: [],
  },

  facilitadores: [],

  profesores: [],

  filters: {
    page: 0,
    limit: 10,
    title: "",
    entes_id: 0,
    search: {},
    rowsPerPage: 5,
    order: "asc",
    orderBy: "nombre",
    selected: [],
  },
  firstLoad: false,
});

const cursosReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURSOS:
      return state.merge({
        cursos: payload,
      });

    case SET_PROFESORES:
      return state.merge({
        profesores: payload,
      });

    case LOAD_CURSOS:
      return state.merge({
        firstLoad: true,
      });

    case UPDATE_FILTERS:
      return state.merge({
        filters: payload,
      });

    case SET_FACILITADORES:

      return state.merge({
        facilitadores: payload,
      });

    default:
      return state;
  }
};

export default cursosReducer;
