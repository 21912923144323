// @Vendors
import React, { useEffect, useState, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce'

import {
  Grid,
  Container,
  Button,
  Grow,
  IconButton,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TuneIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';

// @Statics
import BannerCurso from 'statics/images/textures/mujerCursos';

// @Components
import ListaCurso from './componentes/ListaCursos';
import SimpleSelect from 'components/Form/SimpleSelect';
import AppField from 'components/Form/AppField';

// @Utils
import { getEntes } from 'utils/helpers';

//@hooks
import useNearScreen from 'hooks/useNearScreen'
import { useCursos } from './useCursos';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#f9f9f9',
  },
  filtroRoot: {
    marginTop: '20px',
    background: '#fff',
    padding: '15px',
    borderRadius: '10px',
    boxShadow: '4px 4px 9px 0px #ddd',
  },
  buttonFilter: {
    height: '100%',
  },
  filtro: {
    marginLeft: '15px',
  },
  thumBailnImg: {
    minHeight: 'calc(100vh - 85px - 20px)',
    display: 'flex',
    placeItems: 'center',
    '& svg': {
      float: 'left',
      width: '100%',
    },
  },
  circularLoader: {
    textAlign: "center",
    margin: "10px auto"
  },
  mainText: {
    lineHeight: "190%",
    margin: "15px 0",
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify",
    },
  },
  titulo: {
    marginTop: '1em',
    fontWeight: "700",
    letterSpacing: "-1px",
    marginBottom: "10px",
  },
}));

function Index() {
  const classes = useStyles();

  const { loading, loadingNextPage, cursos, setPage } = useCursos()
  
  const loadCursosRef = useRef(null);
  const { isNearScreen } = useNearScreen({ 
    externalRef: loading ? null : loadCursosRef,
    once: false, distance: '300px' 
  });
  
  const debounceHandleNextPage = useCallback(debounce(
    () => setPage(prevPage => prevPage + 1), 300
  ), [setPage]);

  useEffect(function () {
    if (isNearScreen) debounceHandleNextPage()
  }, [debounceHandleNextPage, isNearScreen]);

  const  [filtrar, setFiltrar] = useState(false);
  const [state, setState] = useState({
    ente: '',
    entes: []
  });

  const handleFiltrar = useCallback( () => {
    setFiltrar((prev) => !prev);
  }, [setFiltrar]);
  

  const obtenerEntes = useCallback(async () => {
    try {
      const result = await getEntes();

      const entes = result.data.data.map( ente => ({
        label: ente.nombre,
        value: ente.id
      }));

      setState((prev) => ({ ...prev, entes }));
    } catch (err) {
      console.log(err);
    }
  }, [])

  useEffect(
    () => {
      obtenerEntes();
    },
    [obtenerEntes]
  )
  
  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grow in={true} timeout={1000}>
            <Grid item xs={12}>
              <div className={classes.thumBailnImg}>
                <BannerCurso />
              </div>
              <Typography
                variant="h4"
                color="primary"
                align="center"
                className={classes.titulo}
              >
                Oferta Formativa
              </Typography>
              <Typography 
                className={classes.mainText}
                variant="body2"
                align="justify"
              >
                El Ministerio del Poder Popular para Ciencia y Tecnología, pone a disposición sus capacidades formativas en materia de Telecomunicaciones e Informática, tanto en modalidad en línea como presencial, dirigido a la población femenina interesada en conocer herramientas o plataformas tecnológicas que puedan ser de utilidad en su campo laboral o de estudio, así como también garantizar la certificación de dichas experiencias formativas. "Debemos avanzar hacia una explosión masiva del conocimiento, de tecnología, de innovación en función de las necesidades sociales y económicas del país y la soberanía nacional" Hugo Chávez.
              </Typography>     
            </Grid>
          </Grow>

          <Grid className={classes.filtroRoot} container spacing={1}>
            <Grid item>
              <Button
                className={classes.buttonFilter}
                onClick={handleFiltrar}
                size='large'
                startIcon={<TuneIcon />}
                variant='outlined'
                color='primary'>
                Filtro
              </Button>
            </Grid>

            <Grid item sm={10}>
              <Grow in={filtrar}>
                <Grid container alignItems='center' spacing={1}>
                  <Grid item className={classes.filtro} xs={2}>
                    <SimpleSelect
                      style={{ minWidth: '160px' }}
                      id='entes'
                      name='entes'
                      value={state.ente}
                      label='Entes'
                      items={state.entes}
                      onChange={(e) =>
                        setState((prev) => ({ ...prev, ente: e.target.value }))
                      }
                    />
                  </Grid>
                  <Grid item className={classes.filtro} xs={5}>
                    <AppField id='buscar' name='buscar' label='Buscar' />
                  </Grid>
                  
                  <Grid item>
                    <IconButton color='primary'>
                      <SearchIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grow>
            </Grid>
          </Grid>
          <ListaCurso cursos={cursos} />
        </Grid>
        
        <div ref={loadCursosRef} className={classes.circularLoader}>
          { loadingNextPage && <CircularProgress color="primary"/> }
        </div>
      </Container>
    </div>
  );
}

export default Index;
