// @Vendors
import axios from 'axios';

import { BASE_API } from 'constants/index';
import { getToken } from 'utils/helpers';



export function getCategoriasService(params) {
  return axios.post(`${BASE_API}/categorias`, { params });
}

export function crearCategoriasService(params) {
  return axios.post(`${BASE_API}/categorias/agregar`, { params },  {
    headers: {
      'x-mppct-token': getToken(),
    },
  });
}
export function updateCategoriasService(params) {
  return axios.post(`${BASE_API}/categorias/actualizar`, { params },  {
    headers: {
      'x-mppct-token': getToken(),
    },
  });
}
export function deleteCategoriasService(params) {


  return axios.post(`${BASE_API}/categorias/eliminar`, { ...params },  {
    headers: {
      'x-mppct-token': getToken(),
    },
  });
}


