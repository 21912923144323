// @Vendors
import React from 'react'
import {Formik, ErrorMessage} from 'formik';
import * as Yup from 'yup'

// @MateriaUI
import {
	TextField,
	Grid,
	Grow 
} from '@material-ui/core'
import SimpleSelect from 'components/Form/SimpleSelect'

// @React ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha"

const initial = {
	nacionalidad: '',
	cedula: ''
}

function SearchSaime({ initialValues = initial, onSubmit, ActionsButtons="", nextPrev }) {
	const cedulaRegex = RegExp("^[0-9]+$")

	const SignupSchema = Yup.object().shape({
	  nacionalidad: Yup.string().required("¡Campo obligatorio!"),
	  cedula: Yup.string().required("¡Campo obligatorio!").matches(cedulaRegex, "Cédula inválida")
	})

	return (
		<Formik
			initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={SignupSchema}
          >
          {({errors, touched, values, handleChange, handleBlur, handleSubmit}) => (
          	<Grow in={true} timeout={1000}>
          	<form onSubmit={handleSubmit}> 
				<Grid container spacing={2}>
		            <Grid item sm={2}>
						<SimpleSelect							
	                		style={{minWidth: "160px"}}
	                		id="nacionalidad"
	                		name="nacionalidad"
	                		value={values.nacionalidad}
							onChange={handleChange}
							onBlur={handleBlur}
	                		label="Nacionalidad (*)"
		                    items={[
		                    	{value: 'V', label:"V"},
		                    	{value: 'E', label:"E"}
		                    ]}
	                		helpertext={<ErrorMessage name="nacionalidad"/>}
	                		error={errors.nacionalidad && touched.nacionalidad} />
		            </Grid>

		            <Grid item sm={10}>
			            <TextField
			            	autoFocus
			              variant="outlined"
			              required
			              fullWidth
			              id="cedula"
			              label="Cédula"
			              name="cedula"
			              autoComplete="cedula"
			              helperText={<ErrorMessage name="cedula"/>}
			              error={errors.cedula && touched.cedula}
			              onChange={handleChange}
			              onBlur={handleBlur}
			              value={values.cedula}
			            />
			        </Grid>
			        <Grid item sm={12}> {ActionsButtons} </Grid>
       			</Grid>
       			<ReCAPTCHA
              sitekey="6LdieNwZAAAAAKfJtGJxhfx7vx0VN40pVXqGCPjI"
              size="invisible"
            />
          	</form>
          	</Grow>
          )}
        </Formik>
	)
}

export default React.memo(SearchSaime)