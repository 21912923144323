import { useEffect, useState } from 'react'
import { getCursosService } from 'state/cursos/cursos.services'

const INITIAL_PAGE = 0
const LIMIT = 2

export function useCursos () {
  const [loading, setLoading] = useState(false)
  const [loadingNextPage, setLoadingNextPage] = useState(false)
  const [stopLoad, setStopLoad] = useState(false)

  const [page, setPage] = useState(INITIAL_PAGE)

  const [cursos, setCursos] = useState([{
    count: 0,
    rows: []
  }])

  useEffect( function () {
    setLoading(true)

    getCursosService({ limit: LIMIT, offset: (INITIAL_PAGE * LIMIT) }).then( res => {
        const { data } = res.data
        setCursos(data)
        setLoading(false)
    } ).catch( err => console.log("ocurrió un error"))
  }, [])
  

  useEffect(function () {
    if (page === INITIAL_PAGE) return
    if(stopLoad) return

    setLoadingNextPage(true)
    getCursosService({ limit: LIMIT, offset: (page * LIMIT) }).then( res => {
        const { data } = res.data
        const { rows } = data
        
        setCursos( prev => {
          const newState = {...prev, rows: prev.rows.concat(rows)}

          if(prev.count === newState.rows.length) setStopLoad(true)
          return newState
        })

        setLoadingNextPage(false)
    }).catch(err => console.log("Error al cargar mas cursos"))
  }, [page, stopLoad]);

  return { loading, loadingNextPage, cursos, setPage };
}