// @Vendors
import axios from 'axios'
import { BASE_API } from "constants/index"
import { getToken } from "utils/helpers";

export function searchSaimeService(params) {
	return axios.post(`${BASE_API}/saime`, { params })
}

export function checkUserService(params) {
	return axios.post(`${BASE_API}/checkuser`, { params })
}

export function registerNewUserService(params) {
	return axios.post(`${BASE_API}/registro`, { params })
}

export function activateUserService(params) {
	return axios.post(`${BASE_API}/activateuser`, { params })
}

export function listaCursosService(params) {

	return axios.post(`${BASE_API}/cursos/cursosusuario`,  { params }, {headers:{
		"x-mppct-token": getToken()
	}});
}

export function getUsersService(params) {
	return axios.post(`${BASE_API}/usuarios`, { params: { borrado: false, ...params } }, {
		headers: {
			"x-mppct-token" : getToken()
		}
	})
}

export function deleteUserService(params) {
	return axios.post(`${BASE_API}/usuarios/eliminar`, { params }, {
		headers: {
			"x-mppct-token" : getToken()
		}
	})
}

export function updateUserService(params) {
	return axios.post(`${BASE_API}/usuarios/actualizar`, { params }, {
		headers: {
			"x-mppct-token" : getToken()
		}
	})
}

export function assignRoleService(params) {
	return axios.post(`${BASE_API}/roles/asignar`, { params }, {
		headers: { "x-mppct-token" : getToken() }
	})
}

export function rolesAsignadosServies(params = {}) {
	return axios.post(`${BASE_API}/roles/asignaciones`, { params }, {
		headers: { "x-mppct-token" : getToken() }
	})
}

export function revoqueRoleService(params = {}) {
	return axios.post(`${BASE_API}/roles/revocar`, { params }, {
		headers: { "x-mppct-token" : getToken() }
	})
}