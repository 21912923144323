// @Vendors
import React, { memo, Fragment } from 'react'

// @hooks
import useSettingsForms from  '../hooks/useSettingsForms'

// @Components
import ActionsButtons from 'components/ActionsButtons'
import PreViewTitles from './PreViewTitles'

// @Material ui
import {Divider} from '@material-ui/core'

function WrapperForms() {
	const { VIEWS, loading, actualVisible: actual, nextPrev } = useSettingsForms()
	const onPrev = React.useCallback(() => nextPrev(actual - 1), [actual, nextPrev])

	// ** Obtener valores del formulario a mostrar
	const ActualComponent = VIEWS[actual].component
	const actualSubmit = VIEWS[actual].onSubmit
	const actualValues = VIEWS[actual].initialValues


	// ** Montar componente de botones siguiente y anterior con su respectiva accion
	const Buttons = () => (
		<div>
			{ actual !== 0 && <Divider/> } 
				<br /><br />
			<ActionsButtons 
				actualVisible={actual}
				totalForms={(VIEWS.length -1)}
				onPrev={onPrev}
				disabledButton={loading}
			/>
		</div>
	)

	return (
		<Fragment>
			<PreViewTitles items={VIEWS} actual={actual}/>
			<ActualComponent
				initialValues={actualValues}
				onSubmit={actualSubmit}
				ActionsButtons={<Buttons />}
			/>
		</Fragment>		
			
	)
}

export default memo(WrapperForms)