//@Vendors
import React from "react";
import { makeStyles } from "@material-ui/core/styles";


//Material-UI

//@Components
import Desktop from "components/Menu/Desktop";
import SocialBar from "components/SocialBar";

//@variables
import { linksMenu } from "../Menu/MenuLinks";

//Statics
import "./index.scss";
import LogoMujer from "components/LogoMujer";

const styles = makeStyles((theme) => ({
  root: {
    boxShadow: "1px 0 6px -1px #949494",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 'initial',
    },  
  },
  nameCompany: {
    paddingBottom: "15px",
    textAlign:'center',
  },
  infoApp: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "200px 1fr 200px",
    alignItems: "center",
    justifyItems: "center",
    margin: "0 0 20px",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr"
    },    
  },
  socialBar:{
    justifySelf: "flex-end",
    fontSize: '2em',
    [theme.breakpoints.down("sm")]: {
      justifySelf: 'center',
    },  
  }
}));

export default function index() {
const classes = styles()
  return (
    <footer className={classes.root}>
      <div className={classes.infoApp}>
        <LogoMujer className="boxLogo" />
        <Desktop links={linksMenu} classe="menuFooter"/>
        <SocialBar className={classes.socialBar} />
      </div>

      <div className={classes.nameCompany}>
        Ministerio de Ciencia y Tecnología
      </div>
    </footer>
  );
}
