// @MaterialUI
import { makeStyles } from "@material-ui/core/styles";

export default function useStyles() {
  const styles = makeStyles((theme) => ({
    /* @Styles for entes */
    dots: {
      display: "flex",
      gap: "8px",
      justifyContent: "center",
      position: "absolute",
      left: "0",
      right: "0",
      top: "calc(100% / 2 + 90px)",
    },
    dot: {
      padding: "4px",
      borderRadius: "50%",
      border: "solid 1px #652385",

      "&.carousel__dot--selected": {
        background: "#652385",
      },
    },
    containerEntes: {
      height: "100%",
    },
    entes: {
      display: "flex",
      justifyContent: "center",
      "& svg ": {
        width: " 150px",
      },
    },
    carousel: {
      position: "relative",
    },

    /* @Styles for Cursos */
    section: {
      margin: "0 auto 4em",
    },
    titulo: {
      fontWeight: "700",
      letterSpacing: "-1px",
      marginBottom: "10px",
    },
    texto: {
      maxWidth: "580px",
      margin: "0 auto",
      lineHeight: "37.4px",
      padding: "0px 25px",

      [theme.breakpoints.down("sm")]: {
        textAlign: "justify",
        lineHeight: "20.4px",
        padding: 0
      },
    },
    card: {
      boxShadow: "0px 5px 80px rgba(0, 0, 0, 0.08)",
      borderRadius: "10px",
      height: "550px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    rootCard:{
      width:"100%",
    },
    separe: {
      margin: "0 auto 20px",
    },
    cursosimg: {
      marginBottom: "10px",
      width: "100%",
      padding: "25px 0",
      display: "flex",
      margin:"0 auto",
      justifyContent: "center",
      "& figure": {
          display:"flex",
          justifyContent:"center"
      },
      "& svg": {
        width: "80%",
        height: "auto",
      },
    },
    centrarBoton: {
      textAlign: "center",
      padding: "0 0 10px ",
      "& a": {
        textDecoration: "none",
      },
    },
    Boton: {
      borderRadius: "20px",
      padding: "8px 20px",
    },

    /* @Styles for Home*/
    root: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    registerBtn: {
      padding: "12px 60px",
      borderRadius: "65px",
      boxSizing: "border-box",
    },
    subtitle: {
      color: "#000",
      margin: "4px 0",
      fontWeight: "bold",
      lineHeight: "138%",
    },
    mainText: {
      lineHeight: "190%",
      margin: "15px 0",
      [theme.breakpoints.down("sm")]: {
        textAlign: "justify",
      },
    },
    infoMain: {
      maxWidth: "480px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    itemInfo: {
      margin: "20px auto",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  }));

  const classes = styles();

  return {
    classes,
  };
}
