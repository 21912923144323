// @Vendors
import React from 'react'

// @Material ui
import {
    Button ,
    Grid
}
from '@material-ui/core'

//  @Icons
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

function ActionsButtons({ texto=false, actualVisible=0, totalForms=0, disabledButton=false , onPrev = console.log("--") }) {
    
    const PrevButton = () => (
        actualVisible
        ? <Button 
            type="button"
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            onClick={onPrev}>
                Anterior
        </Button>
        : ""
    )

    return (
        <Grid container justify="center" spacing={2} alignItems="center">
            <Grid item>
                <PrevButton />
            </Grid>
            <Grid item>
                <Button 
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={disabledButton}
                    endIcon={texto ? null :<NavigateNextIcon />}
                    >
                    { actualVisible === totalForms ? texto ? texto: 'Finalizar' : 'Siguiente' }
                </Button>
            </Grid>
        </Grid>
	)
}
export default React.memo(ActionsButtons)