//@Vendors
import React from "react";
import { Link } from "react-router-dom";

//@Assests
import LogoMujerImg from "statics/images/logos/logoMujer.jpg";

function LogoMujer({ className: clase, ...rest }) {
  return (
    <Link to="/">
      <div className={clase} {...rest}>
        <img src={LogoMujerImg} alt="" />
      </div>
    </Link>
  );
}

export default LogoMujer;
